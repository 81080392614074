// React
import React, { Component } from "react";
// Translations
import { withTranslation } from "react-i18next";
// SVG
import {addSvg, deleteSvg, minimizeSvg} from "assets";
// Styles
import { withStyles } from "@material-ui/core/styles";
// Components
import "components/Accordion/styles/styles.scss";
import Inputs from "./AccordionIntents/inputs";
import Outputs from "./AccordionIntents/outputs";
import Params from "./AccordionIntents/params";
import Extensions from "./AccordionIntents/extensions";
import Tooltip from "@components/Tooltip";
import {FormControlLabel, Switch} from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";

const Accordion = withStyles({
    root: {
        border: "none",
        boxShadow: "none",
        borderRadius: "5px 5px 0 0",
        backgroundColor: "#565671",
        marginBottom: 25,
        filter: "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25))"
    }
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        minHeight: 35,
        height: 45,
        "&$expanded": {
            minHeight: 35,
            height: 45
        },
        fontSize: 14,
        padding: "0px 21px",
        color: "#f4f4f4"
    },
    content: {
        display: "grid",
        gridTemplateColumns: "auto auto",
        columnGap: "20px",
        alignItems: "center",
        margin: "8px 0",
        "&$expanded": {
            margin: "8px 0"
        }
    },
    expanded: {
        // minHeight: 'inherit'
    }
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
    root: {
        backgroundColor: "#f4f4f4",
        padding: "10px 20px 10px 20px"
    }
})(MuiAccordionDetails);

class AccordionIntents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: props.expanded
        };
    }

    returnContent = (type) => {
        switch (type) {
            case "phrases":
                return (<Inputs {...this.props} />);
            case "responses":
                return (<Outputs {...this.props} />);
            case "params":
                return (<Params {...this.props} />);
            case "extensions":
                return (<Extensions {...this.props} />);
            default: return type;
        }
    };

    isEnabledExtension = () => {
        const {intent} = this.props;
        if (intent.responses.length > 1) {
            return true;
        } else return intent.desks.name ? true : false;
    };

    render() {
        const { type, updateElement, intent, t } = this.props;
        const {expanded} = this.state;
        return (
            <Accordion
                square
                expanded={expanded}
            >
                <AccordionSummary
                    onClick={() => this.setState({ expanded: !expanded })}
                    expandIcon={
                        !expanded
                            ? <span>{addSvg()}</span>
                            : <span>{minimizeSvg()}</span>
                    }
                >
                    <div className={"accordion__title"}>
                        <span>{t(`intents.config.accordion.${type}.title`)} <Tooltip type={"secondary"} text={t(`intents.config.accordion.${type}.description`)}/></span>
                    </div>
                    {type === "extensions" && intent.extensions.method &&
                        <div className={"accordion__actions"}>
                            <FormControlLabel
                                checked={intent.extensions.active ? intent.extensions.active : false}
                                control={<Switch disabled={this.isEnabledExtension()}
                                    onClick={(e) => {updateElement(e, "extensions_active", "bool");
                                }} color="primary"/>}
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                label={t("intents.config.accordion.extensions.switch")}
                                labelPlacement="start"
                            />
                            <div onClick={(e) => updateElement(e, "extension", "delete")} className={"accordion__delete_header"}>
                                <span>{t("intents.config.accordion.extensions.delete")}</span>
                                {deleteSvg()}
                            </div>
                        </div>
                    }
                </AccordionSummary>
                <AccordionDetails>
                    {this.returnContent(type)}
                </AccordionDetails>
            </Accordion>
        );
    }
}


export default withTranslation("common")(AccordionIntents);