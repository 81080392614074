// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter } from "react-router-dom";
// Styles
import "date-fns";
// Translations
import { withTranslation } from "react-i18next";
// Vendor
import { showNotify } from "vendor/application/disptach";
// Material
import { CircularProgress } from "@material-ui/core";
// Components
import { Button } from "@components/Input";
// API
import { IntegrationsAPI } from "api/application/Integrations";
// Amplitude API
// Svg
import { channelsSvg } from "assets";
import { arrowLeftSvg } from "@assets";

class Integrations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      integrations: false
    };
  }

  componentDidMount() {
    this.getIntegrations();
  }

  componentDidUpdate = (nextProps) => {
    if (nextProps.data.actualProject.id !== this.props.data.actualProject.id) {
      this.getIntegrations();
    }
  };

  getIntegrations = async () => {
    const { access, actualProject } = this.props.data;
    try {
      const obj = new IntegrationsAPI(actualProject.id, access, this.props);
      const integrations = await obj.get_integrations();

      integrations.forEach((integration) => {
        if (integration.channel === "gpt-3") {
          integration.channel = "chatgpt";
        }
      });

      if (integrations.length === 0) {
        this.integrationDirectory();
      }

      this.setState({ integrations: integrations });
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  editIntegration = (integration) => {
    const { history, location } = this.props;
    history.push(
      `${location.pathname}/${integration.channel}/edit/${integration.id}`
    );
  };

  integrationDirectory = () => {
    const { history, location } = this.props;
    history.push(`${location.pathname}/directory`);
  };

  render() {
    const { integrations } = this.state;
    const t = this.props.t;
    return (
      <div className={"cen_integrations"}>
        <div className={"header"}>
          <div className={"header_title"}>
            <span className={"title"}>{t("created.title")}</span>
          </div>
          <div className={"header_actions"}>
            <Button
              type={"primary"}
              onClick={() => {
                this.integrationDirectory();
              }}
              text={t("created.action_button")}
            />
          </div>
        </div>
        <div className={"content"}>
          {!integrations ? (
            <div className="loading">
              <CircularProgress color="primary" size={70} />
            </div>
          ) : (
            <div className={"elements"}>
              {integrations.map((integration, i) => {
                return (
                  <div
                    onClick={() => this.editIntegration(integration)}
                    className={"element integrations"}
                    key={i}
                  >
                    <div className={"element_svg"}>
                      {channelsSvg(integration.channel)}
                    </div>
                    <div className={"element_info"}>
                      <span>{integration.name ?? integration.channel}</span>
                      <span>{t(`channels.${integration.channel}.title`)}</span>
                    </div>
                    <div className={"element_action"}>{arrowLeftSvg()}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const connect_integrations = connect(mapStateToProps)(Integrations);

export default withTranslation("integrations")(
  withRouter(connect_integrations)
);
