// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Routing
import {withRouter} from "react-router-dom";
// Material
import {Divider} from "@material-ui/core";
// Vendor
import {showNotify} from "vendor/application/disptach";
// Infrastructure
import {IntegrationsAPI} from "api/application/Integrations";
// Application
import {getFacebookMessengerBody} from "vendor/application";
import {ElementsHandleAPI} from "vendor/application/handleMethods";
// Translations
import {withTranslation} from "react-i18next";
// Components
import {ElementsCustom} from "@components/Elements";
// SVG
import {channelsSvg} from "assets";
import {arrowRightSvg} from "@assets";

class Create extends Component {
    constructor(props) {
        super(props);
        this.state = {
            body: getFacebookMessengerBody(),
            tokenValid: true,
            isValid: false,
            pageSelect: "",
            pages: ""
        };
    }

    componentDidMount = () => {
        this.getAppInfo();
    };

    getAppInfo = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props);
            const info = await obj.get_app_info("facebook");
            this.setState({info: info});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: "error"}));
        }
    };

    updateElement = (e, type, i) => {
        const {body} = this.state;
        const obj = new ElementsHandleAPI(body.info, e.target.value, type, i);
        const res = obj.updateObj();

        var init = true;

        Object.keys(body.info).forEach(el => {
            if (!body.info[el] && el !== "active") {
                init = false;
            }
        });
        body.info = res;
        this.setState({body: body, isValid: init});
    };

    return = () => {
        const {history} = this.props;
        const {actualProject} = this.props.data;
        history.push(`/${actualProject.id}/integrations/messenger`);
    };

    postIntegration = async (e) => {
        e.preventDefault();
        const {access, actualProject} = this.props.data;
        const {body} = this.state;
        const {history} = this.props;

        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props, body);
            const integration = await obj.post_integration();

            this.props.dispatch(showNotify({message: "integrations", type: "create", severity: "success"}));

            history.push(`/${actualProject.id}/integrations/messenger/edit/${integration.id}`);
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: "error"}));
        }
    };

    loginFacebook = () => {
        window.FB.init({
            appId: this.state.info.app_id,
            autoLogAppEvents: true,
            status: true,
            version: "v2.9"
        });

        window.FB.login((data) => {
            if (data.authResponse) {
                const userAccessToken = data.authResponse.accessToken;
                const userID = data.authResponse.userID;
                this.handleToken(userAccessToken, userID);
            }

        }, {
            scope: "pages_show_list,pages_messaging,pages_manage_metadata"
        });
    };

    handleToken = async (token, userID) => {
        const {body, info} = this.state;
        const obj = new IntegrationsAPI(null, null, this.props);
        const nonExpiringToken = await obj.getNonExpiringToken(token, info.app_id, info.app_secret);
        const data = await obj.getPagesWithTokens(nonExpiringToken, info.app_id, info.app_secret);

        var selector = data.data;

        window.FB.api(
            userID,
            function (response) {
                if (response && !response.error) {
                    body.info.user_name = response.name;
                    body.info.user_access_token = token;
                    this.setState({body: body, pages: selector});
                }
            }.bind(this)
        );
    };

    onSelectPage = (e) => {
        const {body, pages} = this.state;

        body.info.page_access_token = e?.access_token;

        pages.forEach(select => {
            if (select.access_token === e?.access_token) {
                this.state.pageSelect = select.name;
                body.info.page_name = select.name;
            }
        });

        this.setState({body: body, pageSelect: this.state.pageSelect});
    };

    render() {
        const {body, pageSelect, pages} = this.state;
        const {t} = this.props;
        return (
            body &&
            <div className={"cen_integrations"}>
                <div className={"header"}>
                    <div className={"header_content"}>
                        <div onClick={() => this.return()} className={"header_action"}>
                            {arrowRightSvg()}
                        </div>
                        <div className={"header_image"}>
                            {channelsSvg("messenger")}
                        </div>
                        <div className={"header_title"}>
                            <span className={"title"}>{t("integrations:channels.messenger.title")}</span>
                            <span className={"description"}>{t("integrations:categories.customer")}</span>
                        </div>
                    </div>
                </div>
                <div className={"integration"}>
                    {/* AUTHENTICATE */}
                    <ElementsCustom
                        t={t}
                        type={"messenger"}
                        input_type={"button"}
                        element_type={"authenticate"}
                        updateElement={(e) => this.loginFacebook(e)}
                    />
                    <Divider/>
                    {/* ACCESS TOKEN */}
                    <ElementsCustom
                        t={t}
                        type={"messenger"}
                        input_type={"select"}
                        element={pageSelect}
                        element_type={"select_page"}
                        elements={pages}
                        select_value={"name"}
                        disabled={!pages}
                        updateElement={(e) => this.onSelectPage(e)}
                    />
                    <Divider/>
                    {/* CONFIRM */}
                    <ElementsCustom
                        t={t}
                        type={"messenger"}
                        input_type={"button"}
                        element_type={"confirm"}
                        updateElement={(e) => this.postIntegration(e)}
                        disabled={!pageSelect}
                    />
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        data: state
    };
};

const create = connect(
    mapStateToProps
)(Create);

export default withTranslation(["commons", "common"])(withRouter(create));