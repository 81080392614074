// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Routing
import {withRouter} from "react-router-dom";
// Translations
import {withTranslation} from "react-i18next";
// Components
import {Input, SelectSimple} from "@components/Input";
import FloatButtons from "components/Input/float_buttons";
import {Divider, Switch} from "@material-ui/core";
// Vendor
import {ElementsHandleAPI} from "vendor/application/handleMethods";
import {addArtifact} from "vendor/application";

class Config extends Component {

    onWelcomeSelect = (type) => {
        const {config, updateConfig} = this.props;
        config.customization.welcome_selector = type;
        updateConfig(config);
    };

    updateElementConfig = (e, type) => {
        e.preventDefault();
        const {config, updateConfig} = this.props;
        const obj = new ElementsHandleAPI(config, e.target.value, type);
        const res = obj.updateObj("edit");
        updateConfig(res);
    };

    handleArtifacts = (e, i, artifact_type, type) => {
        const {config, updateConfig} = this.props;
        var res = addArtifact(i, artifact_type, type, config);
        updateConfig(res);
    };

    render() {
        const {t, config, updateCustomization} = this.props;
        return (
            <div className={"cen_card"}>
                {/* WELCOME SELECTOR */}
                <div className={"content"}>
                    <div className="elements_custom">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t("card.content.project.web_interface.configuration.welcome_selector.title")}</span>
                            <span
                                className="elements_custom__description">{t("card.content.project.web_interface.configuration.welcome_selector.description")}</span>
                        </div>
                        <SelectSimple
                            className={"select_secondary"}
                            valueSelected={t(`card.content.project.web_interface.configuration.welcome_selector.${config.customization.welcome_selector}`)}
                            items={["text", "incoming"]}
                            onSelect={this.onWelcomeSelect}
                            translate_pattern={"card.content.project.web_interface.configuration.welcome_selector."}
                            type={"translate"}
                            placeholder={t("card.content.project.web_interface.configuration.welcome_selector.placeholder")}
                            t={t}
                        />
                    </div>
                </div>
                <Divider/>
                {/* WELCOME MESSAGE */}
                {
                    config.customization.welcome_selector === "text" &&
                    <div className={"content"}>
                        <div className="elements_custom">
                            <div className="elements_custom__info">
                                <span
                                    className="elements_custom__title">{t("card.content.project.web_interface.configuration.welcome_message.title")}</span>
                                <span
                                    className="elements_custom__description">{t("card.content.project.web_interface.configuration.welcome_message.description")}</span>
                            </div>
                            <div className={"elements__artifacts"}>
                                <Input value={config.text_welcome} multiline type={"primary"}
                                       placeholder={t("card.content.project.web_interface.configuration.welcome_message.placeholder")}
                                       onChange={(e) => this.updateElementConfig(e, "text_welcome")}/>
                                <FloatButtons type={"web_interface"}
                                              addArtifact={this.handleArtifacts}
                                              positionBottom
                                              buttons_toggle_artifacts={["link", "button", "carousel", "bubble", "for"]}/>
                            </div>
                        </div>
                    </div>
                }
                {/* WELCOME INCOMING */}
                {
                    config.customization.welcome_selector === "incoming" &&
                    <div className={"content"}>
                        <div className="elements_custom">
                            <div className="elements_custom__info">
                                  <span
                                      className="elements_custom__title">{t("card.content.project.web_interface.configuration.welcome_incoming.title")}</span>
                                <span
                                    className="elements_custom__description">{t("card.content.project.web_interface.configuration.welcome_incoming.description")}</span>
                            </div>
                            <div className={"elements__info"}>
                                <Input value={config.customization.welcome_incoming}
                                       type={"primary"}
                                       placeholder={t("card.content.project.web_interface.configuration.welcome_incoming.placeholder")}
                                       onChange={(e) => updateCustomization(e, "string", "welcome_incoming")}/>
                            </div>
                        </div>
                    </div>
                }
                <Divider/>
                {/* OPEN ON LOAD */}
                <div className={"content"}>
                    <div className="elements_custom">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t("card.content.project.web_interface.configuration.open_on_load.title")}</span>
                            <span
                                className="elements_custom__description">{t("card.content.project.web_interface.configuration.open_on_load.description")}</span>
                            <span
                                className="elements_custom__description">{t("card.content.project.web_interface.configuration.open_on_load.description_2")}</span>
                        </div>
                        <Switch checked={!!config.open_on_load} onClick={(e) => {
                            this.updateElementConfig(e, "open_on_load");
                        }}/>
                    </div>
                </div>
                <Divider/>
                {/* CHAT TIMER */}
                <div className={"content"}>
                    <div className="elements_custom">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t("card.content.project.web_interface.customization.chat_timer.title")}</span>
                            <span
                                className="elements_custom__description">{t("card.content.project.web_interface.customization.chat_timer.description")}</span>
                        </div>
                        <div className={"elements__timer"}>
                            <div className={"elements__timer_content"}>
                                <Input
                                    value={config.customization.chat_timer}
                                    onChange={(e) => updateCustomization(e, "integer", "chat_timer")}
                                    inputProps={{max: 300, min: 0}}
                                />
                                <div className={"timer"}>
                                    <span>{t("card.content.project.web_interface.customization.seconds")}</span>
                                </div>
                            </div>
                            <span>{t("card.content.project.web_interface.customization.chat_timer.info")}</span>
                        </div>
                    </div>
                </div>
                <Divider/>
                {/* OPEN ON LOAD TIMER */}
                <div className={"content"}>
                    <div className="elements_custom">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t("card.content.project.web_interface.customization.open_on_load_timer.title")}</span>
                            <span
                                className="elements_custom__description">{t("card.content.project.web_interface.customization.open_on_load_timer.description")}</span>
                            <span
                                className="elements_custom__description">{t("card.content.project.web_interface.customization.open_on_load_timer.description_2")}</span>
                            <span
                                className="elements_custom__description">{t("card.content.project.web_interface.customization.open_on_load_timer.description_3")}</span>
                        </div>
                        <div className={"elements__timer"}>
                            <div className={"elements__timer_content"}>
                                <Input
                                    value={config.customization.open_on_load_timer}
                                    onChange={(e) => updateCustomization(e, "integer", "open_on_load_timer")}
                                    inputProps={{max: 300, min: 0}}
                                />
                                <div className={"timer"}>
                                    <span>{t("card.content.project.web_interface.customization.seconds")}</span>
                                </div>
                            </div>
                            <span>{t("card.content.project.web_interface.customization.open_on_load_timer.info")}</span>
                        </div>
                    </div>
                </div>
                <Divider/>
                {/* HISTORIC MESSAGES */}
                <div className={"content"}>
                    <div className="elements_custom">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t("card.content.project.web_interface.customization.historic_messages.title")}</span>
                            <span
                                className="elements_custom__description">{t("card.content.project.web_interface.customization.historic_messages.description")}</span>
                        </div>
                        <div className={"elements__timer"}>
                            <div className={"elements__timer_content"}>
                                <Input
                                    value={config.customization.historic_messages}
                                    onChange={(e) => updateCustomization(e, "integer", "historic_messages")}
                                    inputProps={{max: 720, min: 0}}
                                />
                                <div className={"timer"}>
                                    <span>{t("card.content.project.web_interface.customization.hours")}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider/>
                {/* GOOGLE ANALYTICS */}
                <div className={"content"}>
                    <div className="elements_custom">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t("card.content.project.web_interface.customization.google_analytics.title")}</span>
                            <span
                                className="elements_custom__description">{t("card.content.project.web_interface.customization.google_analytics.description")}</span>
                        </div>
                        <div className={"elements__info"}>
                            <Input value={config.customization.google_analytics} type={"primary"}
                                   placeholder={t("card.content.project.web_interface.customization.google_analytics.placeholder")}
                                   onChange={(e) => updateCustomization(e, "string", "google_analytics")}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const a = connect(
    mapStateToProps
)(Config);

export default withTranslation("common")(withRouter(a));