// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter } from "react-router-dom";
// Translations
import { withTranslation } from "react-i18next";
// Styles
import "views/Campaigns/styles/index.scss";
// API
import { CampaignsAPI } from "views/Campaigns/infrastructure";
// Components
import { Button } from "@components/Input";
import { ElementsCustom } from "@components/Elements";
import { CircularProgress, Divider } from "@material-ui/core";
import Preview from "views/Campaigns/components/Preview";
import Table from "views/Campaigns/components/Table";
// Assets
import { statusSvg, svgRefresh } from "assets";
import { returnElementFromArray } from "@helpers";
// Vendor
import { showNotify } from "vendor/application/disptach";

class CampaignsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group: false,
      group_detail: false,
      template: false,
      project_id: false,
      page: 0,
      max: 0,
      percentage: 0,
      color_class: "color_inherit",
      value: 0
    };
  }

  componentDidMount = () => {
    this.initServices();
  };

  initServices = () => {
    const { id, project_id } = this.props;
    this.getGroup(id, project_id);
    this.getGroupDetail(id, project_id);
  };

  getGroup = async (group_id, project_id) => {
    const { access } = this.props.data;
    try {
      let obj = new CampaignsAPI(project_id, access, this.props);
      let group = await obj.get_group(group_id);

      this.setState({ group: group });
      this.getTemplate(group.template_id, project_id);
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  cancelGroup = async () => {
    const { access } = this.props.data;
    const { project_id, group } = this.state;
    try {
      let obj = new CampaignsAPI(project_id, access, this.props);
      await obj.cancel_group(group.id);

      this.props.dispatch(
        showNotify({ message: "groups", type: "cancel", severity: "success" })
      );
      this.returnToList();
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  getGroupDetail = async (group_id, project_id) => {
    const { access } = this.props.data;
    try {
      let obj = new CampaignsAPI(project_id, access, this.props);
      let group_detail = await obj.get_group_detail(group_id);

      var array = group_detail.detail,
        arr = [];

      while (array.length) {
        arr.push(array.splice(0, 5));
      }

      group_detail.detail = arr;

      this.setState({ group_detail: group_detail, page: 1, max: arr.length });
      this.handleChartData(group_detail.total, group_detail.total_delivered);
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  getTemplate = async (template_id, project_id) => {
    const { access } = this.props.data;
    try {
      let obj = new CampaignsAPI(project_id, access, this.props);
      let template = await obj.get_template(template_id);

      this.setState({ template: template, project_id: project_id });
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  handleChartData = (total, totalDelivered) => {
    var percentage = (totalDelivered / total) * 100;
    var round = Math.round(percentage, 1);

    var color_class;
    if (round === 0) {
      color_class = "color_inherit";
    } else if (round <= 25) {
      color_class = "color_0";
    } else if (round <= 50) {
      color_class = "color_25";
    } else if (round <= 75) {
      color_class = "color_50";
    } else {
      color_class = "color_75";
    }

    this.setState({
      color_class: color_class,
      value: round === 0 ? 100 : round,
      percentage: round
    });
  };

  returnToList = () => {
    const { setQuery } = this.props;
    setQuery("campaigns");
  };

  handlePagination = (e, next_page) => {
    this.setState({ page: next_page });
  };

  render() {
    const {
      template,
      group,
      group_detail,
      percentage,
      color_class,
      value,
      page,
      max
    } = this.state;
    const { t } = this.props;
    const { actualLayout, isSending, projects } = this.props.data;

    var project = returnElementFromArray(template.project_id, projects, "id");
    return template && group && group_detail ? (
      <div className={"groups_card"}>
        <div className={"campaigns"}>
          <div className={"campaign_header"}>
            <div className={"campaign_header_title campaigns_title"}>
              <span>
                {t(
                  `common:card.content.${actualLayout.layout}.campaigns.view.campaign_view.title`
                )}
              </span>
              <span>
                {t(
                  `common:card.content.${actualLayout.layout}.campaigns.view.campaign_view.description`
                )}
              </span>
            </div>
            <div className={"campaign_header_actions"}>
              <Button
                type={"secondary"}
                text={t(
                  `common:card.content.${actualLayout.layout}.campaigns.button_return`
                )}
                onClick={() => this.returnToList()}
              />
              <Button
                type={"secondary"}
                text={svgRefresh()}
                onClick={() => this.initServices()}
              />
              {group.status === "pending" &&
                (isSending ? (
                  <Button
                    type={"primary"}
                    disabled
                    text={<CircularProgress size={21} />}
                  />
                ) : (
                  <Button
                    type={"normal_delete"}
                    text={t(
                      `common:card.content.${actualLayout.layout}.campaigns.button_cancel`
                    )}
                    onClick={() => this.cancelGroup()}
                  />
                ))}
            </div>
          </div>
          <div className={"campaign_content"}>
            <div className={"campaign_content_info"}>
              <ElementsCustom
                t={t}
                type={"groups_view"}
                input_type={"input"}
                element_type={"name"}
                element={group.name}
                className={"column"}
                disabled={true}
              />
              <Divider />
              <ElementsCustom
                t={t}
                type={"groups_view"}
                input_type={"input"}
                element_type={"template"}
                element={template.name}
                className={"column"}
                disabled={true}
              />
            </div>
            <div className={"campaign_content_preview"}>
              <Preview template_selected={template} />
            </div>
          </div>
        </div>
        <div className={"status"}>
          <div className={"status_header"}>
            <div className={"status_header_title campaigns_title"}>
              <span>
                {t(
                  `common:card.content.${actualLayout.layout}.campaigns.view.status.title`
                )}
              </span>
              <span>
                {t(
                  `common:card.content.${actualLayout.layout}.campaigns.view.status.description`
                )}
              </span>
            </div>
          </div>
          <div className={"status_content"}>
            <div className={`status_content_info ${group.status}`}>
              <span className={"status_content_info_svg"}>{statusSvg()}</span>
              <span>
                {t(
                  `common:card.content.${actualLayout.layout}.campaigns.view.status.info.${group.status}.title`
                )}
              </span>
              <span>
                {t(
                  `common:card.content.${actualLayout.layout}.campaigns.view.status.info.${group.status}.description`
                )}
              </span>
              <span className={"status_content_info_date"}>
                {group.status === "cancelled" && group.cancelled_at}
                {group.status === "finished" && group.finished_at}
                {(group.status === "pending" ||
                  group.status === "in_progress") &&
                  group.send_date}
                <span>{project?.timezone}</span>
              </span>
            </div>
          </div>
        </div>
        <div className={"details"}>
          <div className={"details_header"}>
            <div className={"details_header_title campaigns_title"}>
              <span>
                {t(
                  `common:card.content.${actualLayout.layout}.campaigns.view.details.title`
                )}
              </span>
              <span>
                {t(
                  `common:card.content.${actualLayout.layout}.campaigns.view.details.description`
                )}
              </span>
            </div>
          </div>
          <div className={"details_content"}>
            {group.status === "finished" ? (
              <Table
                conditional={group_detail?.detail}
                items={group_detail?.detail}
                type={"history.campaigns"}
                handlePagination={this.handlePagination}
                page={page}
                max={max}
                header={["phone", "status", "variables", "delivered_at"]}
              />
            ) : (
              <div className={"details_content_pending"}>
                <div>
                  <span>
                    {t(
                      `common:card.content.${actualLayout.layout}.campaigns.view.info.${group.status}.title`
                    )}
                  </span>
                  <span>
                    {t(
                      `common:card.content.${actualLayout.layout}.campaigns.view.info.${group.status}.details_description`
                    )}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={"chart"}>
          <div className={"chart_header"}>
            <div className={"chart_header_title campaigns_title"}>
              <span>
                {t(
                  `common:card.content.${actualLayout.layout}.campaigns.view.chart.title`
                )}
              </span>
              <span>
                {t(
                  `common:card.content.${actualLayout.layout}.campaigns.view.chart.description`
                )}
              </span>
            </div>
          </div>
          <div className={"chart_content"}>
            {group.status === "finished" ? (
              <div className={"chart_content_doughnut"}>
                <div className={"chart"}>
                  <span className={color_class}>{percentage}%</span>
                  <CircularProgress
                    className={color_class}
                    variant="determinate"
                    thickness={4}
                    size={200}
                    value={value}
                  />
                </div>
                <div className="fields">
                  <div>
                    <span />
                    <span>0 - 25</span>
                  </div>
                  <div>
                    <span />
                    <span>25 - 50</span>
                  </div>
                  <div>
                    <span /> <span>50 - 75</span>
                  </div>
                  <div>
                    <span /> <span>75 - 100</span>
                  </div>
                </div>
                <Divider />
                <div className="operation_divider">
                  <div className={"operation_divider_header"}>
                    <span>
                      {t(
                        `common:card.content.${actualLayout.layout}.campaigns.view.chart.total_delivered`
                      )}
                    </span>
                    <span>
                      {t(
                        `common:card.content.${actualLayout.layout}.campaigns.view.chart.total`
                      )}
                    </span>
                  </div>
                  <div className={"operation_divider_result"}>
                    <span className={color_class}>
                      {group_detail.total_delivered}
                    </span>
                    <span>/</span>
                    <span>{group_detail.total}</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className={"chart_content_pending"}>
                <div>
                  <span>
                    {t(
                      `common:card.content.${actualLayout.layout}.campaigns.view.info.${group.status}.title`
                    )}
                  </span>
                  <span>
                    {t(
                      `common:card.content.${actualLayout.layout}.campaigns.view.info.${group.status}.chart_description`
                    )}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    ) : (
      <div className="card">
        <div className="loading-data">
          <CircularProgress color="primary" size={70} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const campaign_view = connect(mapStateToProps)(CampaignsView);

export default withTranslation(["commons", "common"])(
  withRouter(campaign_view)
);
