// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter } from "react-router-dom";
// Styles
import "date-fns";
// Translations
import { withTranslation } from "react-i18next";
// Vendor
import {
  showNotify,
  updateLimitsIntegrations
} from "vendor/application/disptach";
import { getIntegrations } from "vendor/application";
// Material
import { CircularProgress } from "@material-ui/core";
// Components
import { Button, Search } from "@components/Input";
// API
import { IntegrationsAPI } from "api/application/Integrations";
// Svg
import { channelsSvg } from "assets";
import { arrowLeftSvg, arrowRightSvg } from "@assets";
// API
import { ProjectAPI } from "api/application/Projects";
import { UsersAPI } from "api/application/Users";

class IntegrationsDirectory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      integrations: false,
      search: ""
    };
  }

  componentDidMount() {
    this.getLimits();
  }

  componentDidUpdate = (nextProps) => {
    if (nextProps.data.actualProject.id !== this.props.data.actualProject.id) {
      this.getLimits();
    }
  };

  getLimits = async () => {
    const { access, actualProject } = this.props.data;
    try {
      let obj = new ProjectAPI(actualProject.id, access, this.props);
      var new_limits = await obj.get_project_limits();

      this.getIntegrations(new_limits.plan.integrations);
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  getIntegrations = async (new_limits) => {
    const { access, actualProject, plan, limitsIntegrations } = this.props.data;
    try {
      const obj = new IntegrationsAPI(actualProject.id, access, this.props);
      const integrations = await obj.get_integrations();

      var __integrations = getIntegrations();

      integrations.forEach((integration) => {
        if (integration.channel === "zendesk_full") {
          new_limits.zendesk_full.existIntegration = true;
        } else if (integration.channel === "centridesk") {
          new_limits.centridesk.existIntegration = true;
        } else if (integration.channel === "zendesk_chat") {
          new_limits.zendesk_chat.existIntegration = true;
        } else if (integration.channel === "sunco") {
          new_limits.sunco.existIntegration = true;
        }

        if (integration.channel === "gpt-3") {
          integration.channel = "chatgpt";
        }

        if (!limitsIntegrations[integration.channel].multiple) {
          __integrations = __integrations.filter(
            (int) => int.channel !== integration.channel
          );
        }
      });

      if (plan.plan.name !== "consulting_sunco") {
        __integrations = __integrations.filter(
          (int) => int.channel !== "sunco"
        );
      } else {
        __integrations = __integrations.filter((int) =>
          this.isAccesible(int.channel)
        );
      }

      this.props.dispatch(updateLimitsIntegrations(new_limits));
      this.setState({ integrations: __integrations });
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  isAccesible = (route) => {
    const { plan } = this.props.data;
    var res,
      accountsList = [
        "1f842ec122f94055afef189d8699be95",
        "eda8c8a6ea1342e3a03539bc3131b861",
        "d1a7a881424140908a8ce748a899af50",
        "77dc0ace8a38453ca477eb1efe1a246a",
        "d7ad104be2ad4361987a47fc521a1268",
        "9201694efd4c4abfa2dcfb8e2279be5f",
        "50c310d0f0544acaaa99a84c7365ddfb"
      ],
      plan_name = plan.plan.name;

    if (plan_name === "consulting_sunco") {
      switch (route) {
        case "chatgpt":
          res = true;
          break;
        default:
          res = false;
          break;
      }
    } else {
      switch (route) {
        case "sunco":
          res = accountsList.includes(this.props.data.user.account_id);
          break;
        default:
          res = true;
          break;
      }
    }
    return res;
  };

  goHubspot = async (channel) => {
    const { access } = this.props.data;
    try {
      let obj = new UsersAPI(access.userId, access, this.props);
      await obj.nextChannel(channel);
      this.props.dispatch(
        showNotify({
          message: "integrations_hubspot",
          type: "info",
          severity: "success"
        })
      );
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  redirect = (e, integration) => {
    const { history } = this.props;
    const { actualProject } = this.props.data;
    if (integration.active) {
      history.push(`/${actualProject.id}/integrations/${integration.channel}`);
    }
  };

  return = () => {
    const { history } = this.props;
    const { actualProject } = this.props.data;
    history.push(`/${actualProject.id}/integrations`);
  };

  render() {
    const { integrations, search } = this.state;
    const t = this.props.t;

    var __integrations = integrations;
    if (integrations?.length > 0 && search) {
      __integrations = integrations.filter((integration) =>
        integration.name.toLowerCase().includes(search?.toLowerCase())
      );
    }

    return (
      <div className={"cen_integrations"}>
        <div className={"header"}>
          <div className={"header_content"}>
            <div onClick={() => this.return()} className={"header_action"}>
              {arrowRightSvg()}
            </div>
            <div className={"header_title"}>
              <span className={"title"}>{t("directory.title")}</span>
            </div>
          </div>
          <div className={"header_actions"}>
            <Search
              t={t}
              placeholder={t("directory.search_placeholder")}
              onSearch={(e) => this.setState({ search: e.target.value })}
              value={search}
            />
          </div>
        </div>
        <div className={"content"}>
          {!integrations ? (
            <div className="loading">
              <CircularProgress color="primary" size={70} />
            </div>
          ) : (
            <div className={"elements"}>
              {__integrations.map((integration, i) => {
                return (
                  <div
                    onClick={(e) => {
                      this.redirect(e, integration);
                    }}
                    className={"element directory"}
                    key={i}
                  >
                    <div className={"element_svg"}>
                      {channelsSvg(integration.channel)}
                    </div>
                    <div className={"element_directory"}>
                      <span>{t(`channels.${integration.channel}.title`)}</span>
                      <span>
                        {t(`channels.${integration.channel}.description`)}
                      </span>
                    </div>
                    <div className={"element_action"}>
                      {integration.active ? (
                        <div className={"svg"}>{arrowLeftSvg()}</div>
                      ) : (
                        <div className={"button"}>
                          <Button
                            type={"outlined"}
                            onClick={() => {
                              this.goHubspot(integration.name);
                            }}
                            text={t("directory.button_unavailable")}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const connect_integrations = connect(mapStateToProps)(IntegrationsDirectory);

export default withTranslation("integrations")(
  withRouter(connect_integrations)
);
