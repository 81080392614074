// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter } from "react-router-dom";
// Translations
import { withTranslation } from "react-i18next";
// Material UI
import { Dialog, DialogContent } from "@material-ui/core";
// Components
import {Button} from "@components/Input";
// SVG
import {errorSvg} from "assets";
import { translate, convertException } from "vendor/application";

class Error extends Component {
    constructor(props) {
        super(props);
        this.state = {
            msg : ""
        };
    }

    componentDidMount() {
        this.handleMsg();
    }

    handleMsg = () => {
        const { message } = this.props;
        var ms;
        if ( message && message.responseJSON && message.responseJSON.message) {
            ms = message.responseJSON.message;
        } else {
            ms = message;
        }

        if (process.env.REACT_APP_ENV === "docker") {
            console.log("[v] ms", ms);
        }

        if (ms === "migrated") {
            window.location.reload();
        }

        if (ms?.statusText !== "error") {
            this.setState({ msg : ms });
        }
    };

    render() {
        const { t } = this.props;
        return (
            this.state.msg &&
            <Dialog onClose={this.props.onClose} style={{ margin: 0 }} className="dialog_error" open={true}>
                <DialogContent>
                    <div className={"dialog"}>
                        <span className={"title"}>{translate("backend_errors.title", t)}</span>
                        <span className={"svg"}>{errorSvg()}</span>
                        <span className={"description"} dangerouslySetInnerHTML={{__html: translate(`backend_errors.${convertException(this.state.msg)}`, t)}} />
                            <Button id={"notify_button_error"} type={"normal_delete"} text={t("card.dialog_notify.button_accept")}
                                    onClick={(e) => this.props.onClose(e)}/>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const error = connect(
    mapStateToProps
)(Error);

export default withTranslation("common")(withRouter(error));
