// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter } from "react-router-dom";
// Styles
import "date-fns";
// Translations
import { withTranslation } from "react-i18next";
import enLocale from "date-fns/locale/en-US";
import esLocale from "date-fns/locale/es";
import ptLocale from "date-fns/locale/pt";
// Infrastructure
import { ProjectAPI } from "../../../../../api/application/Projects";
import { StatsAPI } from "views/Bots/Stats/infrastructure";
// Vendor
import { showNotify } from "vendor/application/disptach";
// Components
import SelectMultiple from "components/Input/select_multiple";
import { AlertInfo } from "@components/Alert";
import { Divider } from "@material-ui/core";
import { Button } from "@components/Input";
// Material
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Alert from "@material-ui/lab/Alert";
// Svg
import { svgCalendar, svgClock, deploySvg } from "assets";
import AccordionStatsPolls from "components/Accordion/AccordionStatsPolls";
import PaginationTable from "components/Pagination/pagination";
import { CircularProgress } from "@material-ui/core";

const localeMap = {
  en: enLocale,
  es: esLocale,
  pt: ptLocale
};

class StatsPolls extends Component {
  constructor(props) {
    super(props);
    var date = new Date();
    this.state = {
      dateRange: {},
      start: false,
      end: false,
      objDateRange: {
        startDate: new Date(date.setMonth(date.getMonth() - 1)),
        endDate: new Date(date.setMonth(date.getMonth() + 1))
      },
      conversations_channels: [],
      selectedIdsArray: [],
      selectedNamesArray: [],
      polls: false,
      max: 0,
      polls_split: [],
      actualPage: 0,
      isLoading: true
    };
  }

  componentDidMount() {
    this.getConversationsChannels();
  }

  // method that reloads the component when the current project has been changed
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.actualProject.id !== this.props.data.actualProject.id) {
      setTimeout(() => {
        this.getConversationsChannels();
      }, 100);
    }
  };

  // get documents info from actual project id
  getConversationsChannels = async () => {
    const { access, actualProject } = this.props.data;

    try {
      let obj = new ProjectAPI(actualProject.id, access, this.props);
      let array = await obj.get_conversations_channels();

      array.forEach((el) => {
        el.name = `${el.channel} ${el.id.substring(0, 5)} ${el.deleted ? this.props.t("stats.conversations.deleted") : ""}`;
      });

      this.setState({ conversations_channels: array });
      this.getStats();
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  getStats = async () => {
    const { access, actualProject } = this.props.data;
    const { selectedIdsArray } = this.state;

    this.setState({ isLoading: true });
    try {
      let obj = new StatsAPI(actualProject.id, access, this.props);
      let array = await obj.get_polls_stats(
        this.getStartDate(),
        this.getEndDate(),
        selectedIdsArray.length > 0 ? selectedIdsArray : []
      );

      var arr = [];
      while (array.length) {
        arr.push(array.splice(0, 20));
      }

      this.setState({
        polls: arr[0],
        max: arr.length,
        polls_split: arr,
        actualPage: 1,
        isLoading: false
      });
    } catch (err) {
      this.setState({
        polls: [],
        max: 0,
        polls_split: [],
        actualPage: 1,
        isLoading: false
      });
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  doAction = async () => {
    const { access, actualProject } = this.props.data;
    const { selectedIdsArray } = this.state;
    try {
      const obj = new ProjectAPI(actualProject.id, access, this.props, {
        action: "request_polls",
        info: {
          from: this.getStartDate(),
          to: this.getEndDate(),
          channels: selectedIdsArray
        }
      });
      await obj.do_actions();

      this.props.dispatch(
        showNotify({
          message: "project",
          type: "request_polls",
          severity: "success"
        })
      );
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  // update objDateRange start
  handleSelectStart = (start) => {
    var end = this.state.objDateRange.endDate;
    if (start > end) {
      end = start;
    }
    this.setState({
      objDateRange: {
        startDate: start,
        endDate: end
      }
    });
  };

  // update initalDateRange end
  handleSelectEnd = (end) => {
    var start = this.state.objDateRange.startDate;
    if (end < start) {
      start = end;
    }
    this.setState({
      objDateRange: {
        startDate: start,
        endDate: end
      }
    });
  };

  getStartDate = (visibility) => {
    const { objDateRange } = this.state;
    var m = objDateRange.startDate.getMonth(),
      y = objDateRange.startDate.getFullYear(),
      d = objDateRange.startDate.getDate();

    if (visibility) {
      return `${d} / ${m + 1} / ${y}`;
    }
    return `${y}-${m + 1}-${d}`;
  };

  getEndDate = (visibility) => {
    const { objDateRange } = this.state;
    var m = objDateRange.endDate.getMonth(),
      y = objDateRange.endDate.getFullYear(),
      d = objDateRange.endDate.getDate();

    if (visibility) {
      return `${d} / ${m + 1} / ${y}`;
    }
    return `${y}-${m + 1}-${d}`;
  };

  openStart = () => {
    this.setState({ start: !this.state.start });
  };

  openEnd = () => {
    this.setState({ end: !this.state.end });
  };

  selectChannel = (arrayIncoming) => {
    const { conversations_channels } = this.state;

    var new_array = [];
    arrayIncoming.forEach((elementIncoming) => {
      conversations_channels.forEach((channels) => {
        if (elementIncoming === channels.name) {
          new_array.push(channels.id);
        }
      });
    });

    this.setState({
      selectedNamesArray: arrayIncoming,
      selectedIdsArray: new_array
    });
  };

  handlePagination = (e, page) => {
    const { polls_split } = this.state;

    this.setState({ polls: polls_split[page - 1], actualPage: page });
  };

  render() {
    const { t } = this.props;
    const {
      objDateRange,
      start,
      end,
      conversations_channels,
      selectedNamesArray,
      polls,
      max,
      actualPage,
      isLoading
    } = this.state;

    return (
      <div className="stats-container">
        <AlertInfo className={"app_bar"} text={t("stats.info_1")} />
        <Divider />
        <AlertInfo className={"app_bar"} text={t("stats.polls.info")} />
        <Divider />
        <div className="stats-header">
          <div className="stats-date-container">
            <div className="stats-date">
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={localeMap[this.props.i18n.language]}
              >
                <div className="start-date" onClick={() => this.openStart()}>
                  <div>
                    <span>{svgCalendar()}</span>
                    <span>{t("stats.start")}</span>
                    <span>{deploySvg(false)}</span>
                  </div>
                  <div>
                    <span>{svgClock()}</span>
                    <span>{this.getStartDate(true)}</span>
                  </div>
                </div>
                <DatePicker
                  value={objDateRange.startDate}
                  onChange={this.handleSelectStart}
                  animateYearScrolling
                  autoOk
                  onClose={this.openStart}
                  open={start}
                />
                <div className="end-date" onClick={() => this.openEnd()}>
                  <div>
                    <span>{svgCalendar()}</span>
                    <span>{t("stats.end")}</span>
                    <span>{deploySvg(false)}</span>
                  </div>
                  <div>
                    <span>{svgClock()}</span>
                    <span>{this.getEndDate(true)}</span>
                  </div>
                </div>
                <DatePicker
                  value={objDateRange.endDate}
                  onChange={this.handleSelectEnd}
                  animateYearScrolling
                  autoOk
                  onClose={this.openEnd}
                  open={end}
                />
              </MuiPickersUtilsProvider>
            </div>
            {conversations_channels && conversations_channels.length > 0 && (
              <SelectMultiple
                selectedArray={selectedNamesArray}
                elementsArray={conversations_channels}
                onSelect={(e) => this.selectChannel(e)}
                placeholder={t("stats.conversations.select_placeholder")}
                value={"name"}
                type={"conversations-channels"}
                id={"select-channel"}
                className={"select-secondary"}
                t={t}
              />
            )}
          </div>
          <div className="actions">
            <Button
              type={"primary"}
              text={t("stats.polls.action_button")}
              onClick={(e) => this.getStats(e)}
            />
            <Button
              type={"primary"}
              text={t("stats.polls.download_button")}
              onClick={(e) => this.doAction(e)}
            />
          </div>
        </div>
        <div className={"stats-content polls"}>
          {!isLoading ? (
            polls && polls.length > 0 ? (
              <React.Fragment>
                <div className={"stats-polls"}>
                  {polls.map((poll, i) => {
                    return (
                      <AccordionStatsPolls
                        key={i}
                        poll={poll}
                      ></AccordionStatsPolls>
                    );
                  })}
                </div>
                {max && max > 1 && (
                  <div className={"stats-pagination"}>
                    <PaginationTable
                      handlePagination={this.handlePagination}
                      max={max}
                      page={actualPage}
                    />
                  </div>
                )}
              </React.Fragment>
            ) : (
              <Alert style={{ marginBottom: 20 }} severity="info">
                {t("stats.polls.no_data")}
              </Alert>
            )
          ) : (
            <div className="element__progress">
              <CircularProgress size={80} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const connect_stats_polls = connect(mapStateToProps)(StatsPolls);

export default withTranslation("common")(withRouter(connect_stats_polls));
