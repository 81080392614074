// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Translations
import { withTranslation } from "react-i18next";
// Routing
import { withRouter } from "react-router-dom";
// Infrastructure
import { UsersAPI } from "api/application/Users";
// Material
import { AppBar, Tab, Tabs } from "@material-ui/core";
// Components
import { Button } from "@components/Input";
import { AlertInfo } from "@components/Alert";
import {
  Channels,
  Description,
  Feedback,
  Lang,
  Links,
  Loading,
  Name,
  Sandbox
} from "./elements";
import { TabPanel } from "components/Navigation/tab_panel";
// Vendor
import {
  showNotify,
  updateActualLayout,
  updateActualProject,
  updateFilters
} from "vendor/application/disptach";
// Styles
import "views/Bots/Dashboard/styles/index.scss";
// Helpers
import { getRoleName, returnElementFromArray } from "@helpers";
// Assets
import { IntegrationsAPI } from "api/application/Integrations";
// Amplitude API
import { AmplitudeAPI } from "@api/Amplitude";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      projects: false,
      projects_active: "",
      projects_deleted: "",
      open: false,
      expanded: false,
      isLimited: false
    };
  }

  componentDidMount() {
    this.handleTabs();
    this.setLayout();
    this.getProjects();
    this.handleLimits();
  }

  handleTabs = () => {
    const { history } = this.props;
    const queryString = window.location.search,
      urlParams = new URLSearchParams(queryString),
      tab = urlParams.get("tab");

    switch (tab) {
      case "active":
        this.setState({ tabValue: 0 });
        break;
      case "deleted":
        this.setState({ tabValue: 1 });
        break;
      default:
        history.push(`${this.props.location.pathname}?tab=active`);
        this.setState({ tabValue: 0 });
        break;
    }
  };

  a11yProps(index) {
    return {
      id: `stats-tab-${index}`,
      "aria-controls": `stats-tabpanel-${index}`
    };
  }

  setQuery = (e, to) => {
    e.preventDefault();
    this.props.history.push(`${this.props.location.pathname}?tab=${to}`);
    this.handleTabs();
  };

  setLayout = () => {
    const { actualLayout } = this.props.data;
    var data = { layout: "dashboard", project: actualLayout.project };
    this.props.dispatch(updateActualLayout(data));
  };

  handleLimits = () => {
    const { plan } = this.props.data;
    let max_projects = plan.plan.limits.projects.max;
    if (
      max_projects !== -1 &&
      plan.plan.limits.projects.total >= max_projects
    ) {
      this.setState({ isLimited: true });
    }
  };

  getProjects = async () => {
    const { access } = this.props.data;
    try {
      let obj = new UsersAPI(access.userId, access, this.props);
      var projects = await obj.get_projects();

      for (var i in projects) {
        projects[i].integrations = await this.getIntegrations(projects[i].id);
      }

      var projects_active = [],
        projects_deleted = [];

      projects.forEach((project) => {
        if (project.active) {
          projects_active.push(project);
        } else {
          projects_deleted.push(project);
        }
      });

      this.setState({ projects, projects_active, projects_deleted });
    } catch (err) {
      var data = { message: err, severity: "error" };
      this.props.dispatch(showNotify(data));
    }
  };

  getIntegrations = async (project_id) => {
    const { access } = this.props.data;
    try {
      const obj = new IntegrationsAPI(project_id, access, this.props);
      let res = await obj.get_integrations();
      var integrations = [];

      res.forEach((integration) => {
        integrations.push({ channel: integration.channel, id: integration.id });
      });

      return integrations;
    } catch (err) {}
  };

  handleClickOpen = () => {
    const { history } = this.props;
    history.push("project/create");
  };

  handleRedirect = (e, type, project, integration) => {
    e.stopPropagation();
    const { history } = this.props;
    const { user, device_id, account_integrations } = this.props.data;

    var event_type;
    this.isActualProject(type === "sandbox" ? project.sandbox_id : project.id);
    switch (type) {
      case "sandbox":
        history.push(`${project.sandbox_id}/chatbot`);
        break;
      case "share":
        event_type = "Test chatbot";
        history.push(`${project.id}/nlp/share`);
        break;
      case "stats":
      case "integrations":
        event_type = `Click on ${type}`;
        history.push(`${project.id}/${type}`);
        break;
      case "channels":
        event_type = `Click on edit ${integration.channel}`;
        const account_integration = account_integrations?.filter(
          (int) => int.channel === integration.channel
        )[0];
        if (account_integration) {
          window.location.href = `${process.env.REACT_APP_URL_CENTRIMANAGER}/integrations/${integration.channel}/edit/${account_integration.id}`;
        } else {
          history.push(
            `${project.id}/integrations/${integration.channel}/edit/${integration.id}`
          );
        }
        break;
    }

    if (event_type) {
      new AmplitudeAPI({
        event_type: event_type,
        device_id: device_id,
        user_id: user.email
      }).eventLog();
    }
  };

  selectProject = (project_id) => {
    const { history } = this.props;
    const { user, device_id, filters } = this.props.data;
    var project = this.isActualProject(project_id);

    filters.intents.pagination.page = 1;
    filters.intents.search = "";
    filters.intents.context_selector.id = "";
    filters.intents.context_selector.name = "";
    this.props.dispatch(updateFilters(filters));

    new AmplitudeAPI({
      event_type: "Select a bot",
      device_id: device_id,
      user_id: user.email,
      event_properties: {
        Bot_id: project?.chat_id,
        Bot_name: project?.name
      }
    }).eventLog();

    history.push(`${project_id}/chatbot`);
  };

  isActualProject = (project_id) => {
    const { actualProject } = this.props.data;

    var actual = returnElementFromArray(project_id, this.state.projects, "id");

    if (actualProject.id !== project_id) {
      this.props.dispatch(updateActualProject(actual));
    }
    return actual;
  };

  render() {
    const { tabValue, projects, projects_active, projects_deleted, isLimited } =
        this.state,
      { t } = this.props,
      { plan, isSending } = this.props.data,
      user_role = getRoleName(this.props.data.user.roles[0]),
      isSunco = plan.plan.name === "consulting_sunco";
    return (
      <div className={"dashboard"}>
        <div className={"dashboard_header"}>
          <span>{t("card.dashboard.title")}</span>
          {isLimited ? (
            <Button
              id={"new_project"}
              type={"primary"}
              disabled={true}
              text={t("card.dashboard.action_button")}
            />
          ) : (
            <Button
              id={"new_project"}
              type={"success"}
              onClick={() => {
                this.handleClickOpen();
              }}
              text={t("card.dashboard.action_button")}
            />
          )}
        </div>
        <AppBar id={"app_bar"} position="static" color="default">
          <Tabs
            value={tabValue}
            onChange={this.handleChange}
            variant="scrollable"
            scrollButtons="on"
            textColor="primary"
          >
            <Tab
              label={t("card.dashboard.tabs.active")}
              onClick={(e) => {
                this.setQuery(e, "active");
              }}
              {...this.a11yProps(0)}
            />
            <Tab
              label={t("card.dashboard.tabs.deleted")}
              onClick={(e) => {
                this.setQuery(e, "deleted");
              }}
              {...this.a11yProps(1)}
            />
          </Tabs>
        </AppBar>
        <div className={"dashboard_content"}>
          <TabPanel value={tabValue} index={0}>
            {user_role === "Trainer" ? (
              <AlertInfo
                text={t("card.content.project.no_functionality_trainer")}
              />
            ) : isSending || !projects ? (
              <Loading />
            ) : projects_active.length > 0 ? (
              <React.Fragment>
                {projects_active?.map((project, i) => {
                  return (
                    !project.sandbox && (
                      <div
                        className={"dashboard_elements"}
                        key={i}
                        onClick={() => this.selectProject(project.id)}
                      >
                        <Name text={project.name} />
                        <Description text={project.description} />
                        <Channels
                          handleRedirect={this.handleRedirect}
                          project={project}
                        />
                        <Lang text={project.lang} />
                        <Sandbox
                          onClick={(e) =>
                            project.sandbox_id &&
                            this.handleRedirect(e, "sandbox", project)
                          }
                          sandbox_id={project.sandbox_id}
                        />
                        <Links
                          handleRedirect={this.handleRedirect}
                          project={project}
                          isSunco={isSunco}
                        />
                      </div>
                    )
                  );
                })}
              </React.Fragment>
            ) : (
              <Feedback t={t} />
            )}
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {user_role === "Trainer" ? (
              <AlertInfo
                text={t("card.content.project.no_functionality_trainer")}
              />
            ) : isSending || !projects ? (
              <Loading />
            ) : projects_deleted?.length > 0 ? (
              <React.Fragment>
                {projects_deleted?.map((project, i) => {
                  return (
                    !project.sandbox && (
                      <div className={"dashboard_elements disabled"} key={i}>
                        <Name text={project.name} />
                        <Description text={project.description} />
                        <Channels
                          handleRedirect={this.handleRedirect}
                          project={project}
                          deleted
                        />
                        <Lang text={project.lang} />
                        <Sandbox sandbox_id={project.sandbox_id} />
                        <Links
                          handleRedirect={this.handleRedirect}
                          project={project}
                          deleted
                        />
                      </div>
                    )
                  );
                })}
              </React.Fragment>
            ) : (
              <Feedback t={t} />
            )}
          </TabPanel>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const connect_dashboard = connect(mapStateToProps)(Dashboard);

export default withTranslation("common")(withRouter(connect_dashboard));
