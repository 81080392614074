// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter } from "react-router-dom";
// Styles
import "date-fns";
// Translations
import { withTranslation } from "react-i18next";
import enLocale from "date-fns/locale/en-US";
import esLocale from "date-fns/locale/es";
import ptLocale from "date-fns/locale/pt";
// Infrastructure
import { ProjectAPI } from "../../../../../api/application/Projects";
// Vendor
import { showNotify } from "vendor/application/disptach";
// Componentes
import SelectMultiple from "components/Input/select_multiple";
import { AlertInfo } from "@components/Alert";
import { Divider } from "@material-ui/core";
import { Button } from "@components/Input";
// Material
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// Svg
import { svgCalendar, svgClock, deploySvg } from "assets";

const localeMap = {
  en: enLocale,
  es: esLocale,
  pt: ptLocale
};

class StatsConversations extends Component {
  constructor(props) {
    super(props);
    var date = new Date();
    this.state = {
      dateRange: {},
      start: false,
      end: false,
      objDateRange: {
        startDate: new Date(date.setMonth(date.getMonth() - 1)),
        endDate: new Date(date.setMonth(date.getMonth() + 1))
      },
      conversations_channels: [],
      selectedIdsArray: [],
      selectedNamesArray: []
    };
  }

  componentDidMount() {
    this.getConversationsChannels();
  }

  // method that reloads the component when the current project has been changed
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.actualProject.id !== this.props.data.actualProject.id) {
      setTimeout(() => {
        this.getConversationsChannels();
      }, 100);
    }
  };

  // get documents info from actual project id
  getConversationsChannels = async () => {
    const { access, actualProject } = this.props.data;
    try {
      let obj = new ProjectAPI(actualProject.id, access, this.props);
      let array = await obj.get_conversations_channels();

      array.forEach((el) => {
        el.name = `${el.channel} ${el.id.substring(0, 5)} ${el.deleted ? this.props.t("stats.conversations.deleted") : ""}`;
      });

      this.setState({ conversations_channels: array });
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  doAction = async () => {
    const { access, actualProject } = this.props.data;
    const { selectedIdsArray } = this.state;
    try {
      const obj = new ProjectAPI(actualProject.id, access, this.props, {
        action: "request_conversations",
        info: {
          from: this.getStartDate(),
          to: this.getEndDate(),
          channels: selectedIdsArray
        }
      });
      await obj.do_actions();
      this.props.dispatch(
        showNotify({
          message: "project",
          type: "request_conversations",
          severity: "success"
        })
      );
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  // update objDateRange start
  handleSelectStart = (start) => {
    var end = this.state.objDateRange.endDate;
    if (start > end) {
      end = start;
    }
    this.setState({
      objDateRange: {
        startDate: start,
        endDate: end
      }
    });
  };

  // update initalDateRange end
  handleSelectEnd = (end) => {
    var start = this.state.objDateRange.startDate;
    if (end < start) {
      start = end;
    }
    this.setState({
      objDateRange: {
        startDate: start,
        endDate: end
      }
    });
  };

  getStartDate = (visibility) => {
    const { objDateRange } = this.state;
    var m = objDateRange.startDate.getMonth(),
      y = objDateRange.startDate.getFullYear(),
      d = objDateRange.startDate.getDate();

    if (visibility) {
      return `${d} / ${m + 1} / ${y}`;
    }
    return `${y}-${m + 1}-${d}`;
  };

  getEndDate = (visibility) => {
    const { objDateRange } = this.state;
    var m = objDateRange.endDate.getMonth(),
      y = objDateRange.endDate.getFullYear(),
      d = objDateRange.endDate.getDate();

    if (visibility) {
      return `${d} / ${m + 1} / ${y}`;
    }
    return `${y}-${m + 1}-${d}`;
  };

  openStart = () => {
    this.setState({ start: !this.state.start });
  };

  openEnd = () => {
    this.setState({ end: !this.state.end });
  };

  selectChannel = (arrayIncoming) => {
    const { conversations_channels } = this.state;

    var new_array = [];
    arrayIncoming.forEach((elementIncoming) => {
      conversations_channels.forEach((channels) => {
        if (elementIncoming === channels.name) {
          new_array.push(channels.id);
        }
      });
    });

    this.setState({
      selectedNamesArray: arrayIncoming,
      selectedIdsArray: new_array
    });
  };

  render() {
    const { t } = this.props;
    const {
      objDateRange,
      start,
      end,
      conversations_channels,
      selectedNamesArray
    } = this.state;

    return (
      <div className="stats-container">
        <AlertInfo className={"app_bar"} text={t("stats.info_1")} />
        <Divider />
        <AlertInfo className={"app_bar"} text={t("stats.conversations.info")} />
        <Divider />
        <div className="stats-header">
          <div className="stats-date-container">
            <div className="stats-date">
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={localeMap[this.props.i18n.language]}
              >
                <div className="start-date" onClick={() => this.openStart()}>
                  <div>
                    <span>{svgCalendar()}</span>
                    <span>{t("stats.start")}</span>
                    <span>{deploySvg(false)}</span>
                  </div>
                  <div>
                    <span>{svgClock()}</span>
                    <span>{this.getStartDate(true)}</span>
                  </div>
                </div>
                <DatePicker
                  value={objDateRange.startDate}
                  onChange={this.handleSelectStart}
                  animateYearScrolling
                  autoOk
                  onClose={this.openStart}
                  open={start}
                />
                <div className="end-date" onClick={() => this.openEnd()}>
                  <div>
                    <span>{svgCalendar()}</span>
                    <span>{t("stats.end")}</span>
                    <span>{deploySvg(false)}</span>
                  </div>
                  <div>
                    <span>{svgClock()}</span>
                    <span>{this.getEndDate(true)}</span>
                  </div>
                </div>
                <DatePicker
                  value={objDateRange.endDate}
                  onChange={this.handleSelectEnd}
                  animateYearScrolling
                  autoOk
                  onClose={this.openEnd}
                  open={end}
                />
              </MuiPickersUtilsProvider>
            </div>
            {conversations_channels && conversations_channels.length > 0 && (
              <SelectMultiple
                selectedArray={selectedNamesArray}
                elementsArray={conversations_channels}
                onSelect={(e) => this.selectChannel(e)}
                placeholder={t("stats.conversations.select_placeholder")}
                value={"name"}
                type={"conversations-channels"}
                id={"select-channel"}
                className={"select-secondary"}
                t={t}
              />
            )}
          </div>

          <div className="actions">
            <Button
              type={"primary"}
              text={t("stats.conversations.action_button")}
              onClick={(e) => this.doAction(e)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const connect_stats_conversations =
  connect(mapStateToProps)(StatsConversations);

export default withTranslation("common")(
  withRouter(connect_stats_conversations)
);
