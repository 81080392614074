// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Routing
import {withRouter} from "react-router-dom";
// Infrastructure
import {IntegrationsAPI} from "api/application/Integrations";
// Vendor
import {showNotify} from "vendor/application/disptach";
// Components
import {CircularProgress, Divider} from "@material-ui/core";
import {Input} from "@components/Input";
import {CopyBlock, dracula} from "react-code-blocks";
import {ElementsCustom} from "@components/Elements";
// Translations
import {withTranslation} from "react-i18next";
// SVG
import {channelsSvg, openNewWindow} from "assets";
import {arrowRightSvg} from "@assets";


class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            integration: "",
            text: ""
        };
    }

    componentDidMount = () => {
        this.getIntegration();
    };

    getIntegration = async () => {
        const {access, actualProject} = this.props.data;
        try {
            let obj = new IntegrationsAPI(actualProject.id, access, this.props);
            let integration = await obj.get_integration_by_id();
            this.setState({integration: integration});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: "error"}));
            this.return();
        }
    };

    return = () => {
        const {history} = this.props;
        const {actualProject} = this.props.data;
        history.push(`/${actualProject.id}/integrations`);
    };

    getWhatsAppHTML = (phone, text) => {
        return (
            `<div id="cen_whatsapp_icon" style="position:fixed; right:10px; bottom:10px; text-align:center; padding:3px">
    <a href="${this.getUrl(phone, text)}" target="_blank">
        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/240px-WhatsApp.svg.png" width="80" height="80"/>
    </a>
</div>`
        );
    };

    getUrl = (phone, text) => {
        return `https://api.whatsapp.com/send?phone=${phone}&text=${text}`;
    };

    render() {
        const {integration, text} = this.state;
        const {t} = this.props;

        return (
            <React.Fragment>
                <div className={"cen_integrations"}>
                    <div className={"header"}>
                        <div className={"header_content"}>
                            <div onClick={() => this.return()} className={"header_action"}>
                                {arrowRightSvg()}
                            </div>
                            <div className={"header_image"}>
                                {channelsSvg("whatsapp")}
                            </div>
                            <div className={"header_title"}>
                                <span className={"title"}>{t("integrations:channels.whatsapp.title")}</span>
                                <span className={"description"}>{t("integrations:categories.customer")}</span>
                            </div>
                        </div>
                    </div>
                    {
                        integration
                            ? <div className={"integration"}>
                                <ElementsCustom
                                    t={t}
                                    type={"whatsapp"}
                                    input_type={"string"}
                                    element_type={"phone"}
                                    element={integration.info.integration.info.phone}
                                />
                                <Divider/>
                                <div className={"elements_custom"}>
                                    <div className={"elements_custom__info"}>
                                            <span
                                                className={"elements_custom__title"}>{t("elements.custom.whatsapp.card.text.title")} </span>
                                        <span
                                            className={"elements_custom__description"}>{t("elements.custom.whatsapp.card.text.description")}</span>
                                    </div>
                                    <div className={"elements_custom__icon"}>
                                        <Input
                                            placeholder={t("elements.custom.whatsapp.card.text.placeholder")}
                                            value={text}
                                            onChange={(e) => this.setState({text: e?.target.value})}
                                        />
                                        <div
                                            onClick={() => window.open(this.getUrl(integration.info.integration.info.phone, text))}
                                            className={"icon"}>
                                            {openNewWindow()}
                                        </div>
                                    </div>
                                </div>
                                <div className={"integration_copy_block"}>
                                    <CopyBlock
                                        text={this.getWhatsAppHTML(integration.info.integration.info.phone, text)}
                                        language={"html"}
                                        showLineNumbers={true}
                                        theme={dracula}
                                        wrapLines={true}
                                        codeBlock
                                    />
                                </div>
                            </div>
                            : <div className={"content"}>
                                <div className="loading">
                                    <CircularProgress color="primary" size={50}/>
                                </div>
                            </div>
                    }
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const edit = connect(
    mapStateToProps
)(Edit);

export default withTranslation(["commons", "common"])(withRouter(edit));