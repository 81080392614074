// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter } from "react-router-dom";
// Application
import { setCookieByName } from "vendor/application";
import { updateSending } from "vendor/application/disptach";
// Infrastructure
import { CustomLogin } from "vendor/infrastructure/custom_requests/login";
// Translations
import { withTranslation } from "react-i18next";
// Material
import { CircularProgress } from "@material-ui/core";
// Components
import { Button } from "@components/Input";
// Styles
import "views/Root/application/Routes/Landing/application/Verify/index.scss";
// SVG
import { verifySvg } from "@assets";
// Amplitude API
import { AmplitudeAPI } from "@api/Amplitude";

class Verify extends Component {
  constructor(props) {
    super(props);
    this.search = new URL(window.location.href).searchParams.get("v");
    this.state = {
      username: "",
      id: "",
      token: "",
      status: 2
    };
  }

  componentDidMount = () => {
    new AmplitudeAPI({
      event_type: "Verify user show",
      device_id: this.props.data.device_id
    }).eventLog();

    if (this.search) {
      var params = this.b64_to_utf8().split(".#/#.");
      this.setState({
        username: params[0],
        id: params[1],
        token: params[2]
      });
    } else {
      this.landingRedirect();
    }

    this.handlePathname();
  };

  handlePathname = () => {
    const url = window.location.search;
    const urlParams = new URLSearchParams(url);
    const lang = urlParams.get("lang");

    if (lang && (lang === "es" || lang === "en" || lang === "pt")) {
      this.props.i18n.changeLanguage(lang);
      setCookieByName(lang, "b__lg");
    }
  };

  landingRedirect = (username) => {
    if (username) {
      username = "/?u=" + username;
    }
    this.props.history.push(`/landing/login${username ? username : ""}`);
  };

  handleVerify = async (e) => {
    e.preventDefault();

    const { username, id, token } = this.state;
    this.props.dispatch(updateSending(true));
    let request = new CustomLogin(
      {
        username: username,
        token: token
      },
      "signins",
      this.props
    );

    request.make_verify(id).then(
      (resp) => {
        this.setState({ status: 1 });
        this.props.dispatch(updateSending(false));

        new AmplitudeAPI({
          event_type: "User verified",
          device_id: this.props.data.device_id,
          user_id: username
        }).eventLog();

        setTimeout(() => {
          this.landingRedirect(username);
        }, 8000);
      },
      () => {
        this.setState({ status: 0 });
        this.props.dispatch(updateSending(false));
        setTimeout(() => {
          this.landingRedirect(username);
        }, 5000);
      }
    );
  };

  b64_to_utf8 = () => {
    return decodeURIComponent(escape(window.atob(this.search)));
  };

  render() {
    const { t, handleSwitch } = this.props;
    const { status } = this.state;
    return (
      <React.Fragment>
        {status === 0 && (
          <form className="platform_landing">
            <div className={"platform_landing__header"}>
              <span>{t("landing.signin.verify_txt_ko")}</span>
              <img src={process.env.PUBLIC_URL + "/error.png"} alt={""} />
              <span
                dangerouslySetInnerHTML={{
                  __html: t("landing.signin.verify_txt_2_ko")
                }}
              />
            </div>
          </form>
        )}
        {status === 1 && (
          <form className="platform_landing">
            <div className={"platform_landing__header"}>
              <span>{t("landing.signin.verify_txt_ok")}</span>
              <img src={process.env.PUBLIC_URL + "/check.png"} alt={""} />
              <span
                dangerouslySetInnerHTML={{
                  __html: t("landing.signin.verify_txt_2_ok")
                }}
              />
            </div>
          </form>
        )}
        {status === 2 && (
          <form className="platform_landing">
            <div className={"platform_landing__header"}>
              <span>{t("landing.signin.verify_txt")}</span>
              {verifySvg()}
              <span
                dangerouslySetInnerHTML={{
                  __html: t("landing.signin.verify_txt2")
                }}
              />
            </div>
            <div className={"platform_landing__content"}>
              {this.props.data.isSending ? (
                <Button
                  id={"landing_verify"}
                  type={"success"}
                  text={<CircularProgress size={21} />}
                />
              ) : (
                <Button
                  id={"landing_verify"}
                  type={"success"}
                  onClick={this.handleVerify}
                  text={t("landing.signin.verify_txt")}
                />
              )}
            </div>
            <div className={"platform_landing__footer"}>
              <span>
                {t("landing.signin.info")}
                <a id={"signin_session"} onClick={() => handleSwitch("login")}>
                  {t("landing.signin.info_a")}
                </a>
              </span>
              <span>
                {t("landing.login.info")}
                <a id={"login_session"} onClick={() => handleSwitch("signin")}>
                  {t("landing.login.info_a")}
                </a>
              </span>
            </div>
          </form>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const connect_verify = connect(mapStateToProps)(Verify);

export default withTranslation("common")(withRouter(connect_verify));
