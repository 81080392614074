// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter } from "react-router-dom";
// Translations
import { withTranslation } from "react-i18next";
// Application
import { updateActualLayout } from "vendor/application/disptach";
// Components
import CardEmpty from "components/Card/CardEmpty";

class Default extends Component {
  componentDidMount() {
    const { history } = this.props;
    const { plan, actualLayout } = this.props.data;
    if (plan?.whatsapp_phones?.length > 0 && plan?.platform?.centripush) {
      history.push("/campaigns/templates");
    } else {
      this.props.dispatch(
        updateActualLayout({
          layout: "campaigns",
          project: actualLayout.project
        })
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <CardEmpty data={this.props.data} t={this.props.t} type={"campaign"} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const default_view = connect(mapStateToProps)(Default);

export default withTranslation("common")(withRouter(default_view));
