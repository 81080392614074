// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { Link, withRouter } from "react-router-dom";
// Translations
import { withTranslation } from "react-i18next";
// Components
// Assets
import { channelsSvg, svgZip } from "assets";
import { arrowRightSvg } from "@assets";
// Vendor

class View extends Component {
  return = () => {
    const { history } = this.props;
    const { actualProject } = this.props.data;
    history.push(`/${actualProject.id}/integrations/directory`);
  };

  render() {
    const { t } = this.props;
    return (
      <div className={"cen_integrations"}>
        <div className={"header"}>
          <div className={"header_content"}>
            <div onClick={() => this.return()} className={"header_action"}>
              {arrowRightSvg()}
            </div>
            <div className={"header_image"}>{channelsSvg("wordpress")}</div>
            <div className={"header_title"}>
              <span className={"title"}>{t("channels.wordpress.title")}</span>
              <span className={"description"}>{t("categories.customer")}</span>
            </div>
          </div>
        </div>
        <div className={"content"}>
          <div className={"content_view"}>
            <span>{t("channels.wordpress.description")}</span>
            <span
              dangerouslySetInnerHTML={{
                __html: t("channels.wordpress.info").replaceAll(
                  "[br]",
                  "<br /><br />"
                )
              }}
            />
            <div className={"content_view_plugin"}>
              <Link to={"/file/plugin_wordpress.zip"} download target="_blank">
                <div className="container">
                  <div>{svgZip()}</div>
                  <div>plugin wordpress</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const connect_view = connect(mapStateToProps)(View);

export default withTranslation("integrations")(withRouter(connect_view));
