// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Translations
import {withTranslation} from "react-i18next";
// Styles
import "components/Accordion/styles/styles.scss";
// Components
import {channelsSvg} from "assets";
import {Switch} from "@material-ui/core";

class IntegrationCard extends Component {
    selectIntegration = (channel) => {
        const {intent, integration, getGroups, updateIntent} = this.props;

        intent.desks.name = intent.desks.name === channel
            ? ""
            : intent.desks.name = channel;

        if (channel === "zendesk_full" && integration.multiple.length === 1) {
            var desk_id = integration.multiple[0].id;
            getGroups(desk_id);
            intent.desks.info.desk_id = desk_id;
        }

        updateIntent(intent);
    };

    render() {
        const {integration, intent, t} = this.props;
        var integration_channel = integration.channel,
            desk_name = intent?.desks?.name;

        if (integration_channel === "gpt-3") {
            integration_channel = "chatgpt";
        }

        if (desk_name === "gpt-3") {
            desk_name = "chatgpt";
        }

        return (
            <div onClick={() => {this.selectIntegration(integration.channel);}}
                 className={"element__integration"}
            >
                <div className={"element__integration_content"}>
                    <div className={"element__integration_content_img"}>
                        {channelsSvg(integration_channel)}
                    </div>
                    <span>{t(`integrations:channels.${integration_channel}.title`)}</span>
                </div>
                <Switch disabled={intent.extensions.active}
                        checked={desk_name === integration_channel}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const integration_card = connect(
    mapStateToProps
)(IntegrationCard);

export default withTranslation("integrations")(integration_card);