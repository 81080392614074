// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Routing
import {withRouter} from "react-router-dom";
// Translations
import {withTranslation} from "react-i18next";
// Chart
import {Line} from "react-chartjs-2";
// Vendor
import {getRGBColors, getRGBAColors} from "vendor/application";

class LineBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            legend: [],
            data: {
                labels: [],
                datasets: []
            },
            options: {
                legend: {
                    display: false
                },
                maintainAspectRatio: false,
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            }
                        },
                        {
                            type: "linear",
                            display: false,
                            position: "left"
                        }
                    ],
                }
            }
        };
    }

    // method that reloads the component when the current project has been changed
    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (JSON.stringify(this.props.arr) !== JSON.stringify(nextProps.arr)) {
            this.handleData(nextProps.arr.reverse());
        }
    };

    componentDidMount() {
        this.handleData(this.props.arr.reverse());
    }

    handleData = (arr) => {
        const {type} = this.props;
        const {data} = this.state;
        var total = 0,
            month,
            year,
            legend_array = [];

        data.datasets = [];
        data.labels = [];
        if (arr && arr.length > 0) {
            if (type === "detections") {
                arr = arr.reverse();
            }
            arr.forEach((iteration, i) => {
                month = iteration.month.split("-")[1];
                year = iteration.month.split("-")[0];
                total += iteration[type]?.total ?? 0;

                // SET LEGEND ARRAY
                legend_array.push({
                    month: `${month}/${year}`,
                    total: iteration[type]?.total ?? 0,
                    active: true,
                    primary: getRGBColors()[i],
                    secondary: getRGBAColors()[i]
                });
                // GET DATASETS ARRAY
                data.datasets.push({
                    label: `${month}/${year}`,
                    data: [],
                    fill: false,
                    backgroundColor: getRGBColors()[i],
                    borderColor: getRGBAColors()[i]
                });
                iteration[type]?.values.forEach((value, ii) => {
                    // GET LABELS DAYS RANGE
                    data.datasets[i].data.push(value.value);
                    if (data.labels.length < (ii + 1)) {
                        data.labels.push(ii + 1);
                    }
                });
            });
        }

        this.setState({data: data, legend: legend_array, total: total});
    };

    toggleDataset = (e, i) => {
        e.preventDefault();
        const {legend, data} = this.state;

        data.datasets[i].hidden = !data.datasets[i].hidden;
        legend[i].active = !legend[i].active;

        this.setState({data: data, legend: legend});
    };

    render() {
        const {data, options, legend, total} = this.state;
        const {type, t} = this.props;
        return (
            legend && legend.length > 0 ? (
                <div className={"legend"}>
                    <div className={"legend_content"}>
                        <div className={"legend_header"}>
                            <span className={"legend_title"}>{t(`stats.charts.${type}_title`)}</span>
                            <span className={"legend_total"}>{total}</span>
                        </div>
                        <div className={"legend_elements"}>
                            {
                                legend.map((el, i) => {
                                    return (
                                        <div
                                            className={`legend_element ${legend.length > 10 ? "simplify" : ""} ${el.active ? "" : "opacity_5"}`}
                                            onClick={(e) => this.toggleDataset(e, i)} key={i}>
                                            {!el.active && <div className={"inactive"}></div>}
                                            <div style={{color: el.primary, background: el.secondary}}
                                                 className={"legend_left"}>
                                                <span>{el.month}</span>
                                                {
                                                    legend.length <= 10 &&
                                                    <span>Mes</span>
                                                }
                                            </div>
                                            <div style={{color: el.primary, borderColor: el.secondary}}
                                                 className={"legend_right"}>
                                                <span>{el.total}</span>
                                                {
                                                    legend.length <= 10 &&
                                                    <span>Total</span>
                                                }
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div style={ {height:"500px"}}>
                    <Line options={options} data={data}/>
                    </div>      
                </div>
            ) : (
                <div className={"legend_empty"}>
                    <span className={"legend_title"}>{t(`stats.charts.${type}_title`)}</span>
                    <span>{t("stats.charts.no_results")}</span>
                </div>
            )
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const connect_line = connect(
    mapStateToProps
)(LineBar);

export default withTranslation("common")(withRouter(connect_line));