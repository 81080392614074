// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter } from "react-router-dom";
// Material
import { CircularProgress, Divider } from "@material-ui/core";
// Vendor
import {
  showNotify,
  updateLimitsIntegrations
} from "vendor/application/disptach";
// Infrastructure
import { IntegrationsAPI } from "api/application/Integrations";
// Application
import { getZendeskFullBody } from "vendor/application";
import { ElementsHandleAPI } from "vendor/application/handleMethods";
// Translations
import { withTranslation } from "react-i18next";
// Components
import { ElementsCustom } from "@components/Elements";
import { Button } from "@components/Input";
// SVG
import { channelsSvg } from "assets";
import { arrowRightSvg } from "@assets";

class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zendesk: getZendeskFullBody(),
      domain: ".zendesk.com",
      set_tag: ""
    };
  }

  // update element state
  updateElement = (e, type, i) => {
    const { zendesk } = this.state;
    const obj = new ElementsHandleAPI(zendesk.info, e.target.value, type, i);
    obj.updateObj();

    this.setState({ zendesk: zendesk });
  };

  postIntegration = async (e) => {
    e.preventDefault();
    const { access, actualProject } = this.props.data;
    const { history } = this.props;
    const { zendesk, domain } = this.state;

    zendesk.info.subdomain =
      zendesk.info.subdomain.replace(".zendesk.com", "") + domain;
    try {
      const obj = new IntegrationsAPI(
        actualProject.id,
        access,
        this.props,
        zendesk
      );
      let integration = await obj.post_integration();
      this.props.dispatch(
        showNotify({
          message: "integrations",
          type: "create",
          severity: "success"
        })
      );

      this.props.data.limitsIntegrations.zendesk_full.existIntegration = true;
      this.props.dispatch(
        updateLimitsIntegrations(this.props.data.limitsIntegrations)
      );

      history.push(
        `/${actualProject.id}/integrations/zendesk_full/edit/${integration.id}`
      );
    } catch (err) {
      zendesk.info.subdomain = zendesk.info.subdomain.replace(
        ".zendesk.com",
        ""
      );
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  handleTags = (e, type, elementType, chipToDelete, i) => {
    var { zendesk, set_tag } = this.state;
    var element_to_update;

    switch (elementType) {
      case "tags":
        element_to_update = zendesk.info.tags;
        break;
    }

    var tag = e.target.value;
    switch (type) {
      case "add":
        if (tag.match(/^[a-zA-Z0-9_\/]*$/)) {
          set_tag = tag;
          this.setState({ set_tag: set_tag });
        }
        break;
      case "delete":
        element_to_update.forEach((el) => {
          if (el === chipToDelete) {
            element_to_update.splice(i, 1);
          }
        });
        this.setState({ zendesk: zendesk });
        break;
      case "enter":
        element_to_update.push(tag);
        set_tag = "";
        this.setState({ zendesk: zendesk, set_tag: set_tag });
        break;
    }
  };

  handleSortSelect = (e) => {
    const { zendesk } = this.state;
    if (e) {
      zendesk.info.sort_messages = e;
      this.setState({ zendesk: zendesk });
    }
  };

  return = () => {
    const { history } = this.props;
    const { actualProject } = this.props.data;
    history.push(`/${actualProject.id}/integrations/zendesk_full`);
  };

  render() {
    const { zendesk, set_tag } = this.state;
    const { t } = this.props;
    const { isSending } = this.props.data;
    return (
      zendesk && (
        <div className={"cen_integrations"}>
          <div className={"header"}>
            <div className={"header_content"}>
              <div onClick={() => this.return()} className={"header_action"}>
                {arrowRightSvg()}
              </div>
              <div className={"header_image"}>
                {channelsSvg("zendesk_full")}
              </div>
              <div className={"header_title"}>
                <span className={"title"}>
                  {t("integrations:channels.zendesk_full.title")}
                </span>
                <span className={"description"}>
                  {t("integrations:categories.business")}
                </span>
              </div>
            </div>
            <div className={"header_actions"}>
              {isSending ? (
                <Button
                  type={"primary"}
                  disabled
                  text={<CircularProgress size={21} />}
                />
              ) : (
                <Button
                  type={"primary"}
                  text={t("integrations:create.action_button")}
                  onClick={(e) => this.postIntegration(e)}
                />
              )}
            </div>
          </div>
          <div className={"integration"}>
            {/* NAME */}
            <ElementsCustom
              t={t}
              type={"zendesk"}
              input_type={"input"}
              element_type={"name"}
              element={zendesk.name}
              updateElement={(e) => {
                zendesk.name = e.target.value;
                this.setState({ zendesk });
              }}
            />
            <Divider />
            {/* SUBDOMAIN */}
            <ElementsCustom
              t={t}
              type={"zendesk"}
              input_type={"input_zendesk"}
              element_type={"subdomain"}
              element={zendesk.info.subdomain.replace(".zendesk.com", "")}
              updateElement={this.updateElement}
            />
            <Divider />
            {/* ZENDESK EMAIL */}
            <ElementsCustom
              t={t}
              type={"zendesk"}
              input_type={"input"}
              element_type={"email"}
              element={zendesk.info.email}
              updateElement={this.updateElement}
            />
            <Divider />
            {/* TOKEN */}
            <ElementsCustom
              t={t}
              type={"zendesk"}
              input_type={"input"}
              element_type={"token"}
              element={zendesk.info.token}
              updateElement={this.updateElement}
            />
            <Divider />
            {/* TICKET SUBJECT */}
            <ElementsCustom
              t={t}
              type={"zendesk"}
              input_type={"input"}
              element_type={"subject"}
              element={zendesk.info.subject}
              updateElement={this.updateElement}
            />
            <Divider />
            {/* SORT MESSAGES */}
            <ElementsCustom
              t={t}
              type={"zendesk"}
              input_type={"select"}
              element_type={"sort_messages"}
              select_value={""}
              element={t(
                `elements.custom.zendesk.card.sort_messages.${zendesk.info.sort_messages}`
              )}
              elements={["asc", "desc"]}
              updateElement={this.handleSortSelect}
            />
            <Divider />
            {/* TAGS */}
            <ElementsCustom
              t={t}
              type={"zendesk"}
              input_type={"input_with_tags"}
              element_type={"tags"}
              element={set_tag}
              elements={zendesk.info.tags}
              updateElement={this.handleTags}
            />
            <Divider />
            {/* ZENDESK CUSTOM FIELD */}
            <ElementsCustom
              t={t}
              type={"zendesk"}
              input_type={"switch"}
              element_type={"use_custom_fields"}
              element={zendesk.info.use_custom_fields}
              updateElement={this.updateElement}
            />
          </div>
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const create = connect(mapStateToProps)(Create);

export default withTranslation(["commons", "common", "integrations"])(
  withRouter(create)
);
