// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter } from "react-router-dom";
// Infrastructure
import { CustomLogin } from "vendor/infrastructure/custom_requests/login";
// Material
import { CircularProgress } from "@material-ui/core";
// Application
import { handleActualTitle, getRoleName } from "@helpers";
import { ElementsHandleAPI } from "vendor/application/handleMethods";
// Components
import { Input, Button } from "@components/Input";
// Vendor
import {
  showNotify,
  updateInitApp,
  updateSending
} from "vendor/application/disptach";
// Translations
import { withTranslation } from "react-i18next";
// Amplitude API
import { AmplitudeAPI } from "@api/Amplitude";
// API
import { UsersAPI } from "../../../../../../../api/application/Users";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: ""
    };
  }

  componentDidMount() {
    sessionStorage.clear();
    new AmplitudeAPI({
      event_type: "Login show",
      device_id: this.props.data.device_id
    }).eventLog();

    handleActualTitle(this.props.t, "login");
  }

  // update element state
  updateElement = (e, type) => {
    const { username, password } = this.state;
    const obj = new ElementsHandleAPI(
      { username: username, password: password },
      e.target.value,
      type
    );
    const res = obj.updateObj();

    this.setState({ username: res.username, password: res.password });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { username, password } = this.state;
    this.props.dispatch(updateSending(true));
    try {
      // GET TOKEN
      let request = new CustomLogin(
        { username: username, password: password },
        "token",
        this.props
      );
      let res = await request.make_request_token();

      // GET USER ID FROM TOKEN ACCESS AND UPDATE STATE APP
      var userId = this.getUserId(res.access);
      this.props.handleAccess(res, userId);

      this.getUser(res.access, userId);
    } catch (err) {
      this.props.dispatch(updateSending(false));
      var data = { message: err, severity: "error" };
      this.props.dispatch(showNotify(data));
    }
  };

  getUser = async (access, userId) => {
    try {
      let obj = new UsersAPI(userId, { access: access }, this.props);
      let user = await obj.get_user_info();

      this.getPlan(user);
    } catch (err) {
      this.props.dispatch(updateInitApp(true));
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  getPlan = async (user) => {
    const { access } = this.props.data;
    try {
      const obj = new UsersAPI(access.userId, access, this.props, user);
      let res = await obj.get_account_by_id(user.account_id);

      new AmplitudeAPI({
        event_type: "Login ok",
        device_id: this.props.data.device_id,
        user_id: user.username,
        event_properties: {
          account_id: user.account_id
        },
        user_properties: {
          is_staff: user.is_staff,
          role: getRoleName(user.roles[0]),
          plan_name: res.plan.name
        },
        ip: user.ip
      }).eventLog();

      this.props.history.push("/");
    } catch (err) {
      console.log(err);
    }
  };

  getUserId = (access) => {
    var e = this.b64_to_utf8(access.split(".")[1]);
    return JSON.parse(e).user_id;
  };

  b64_to_utf8 = (str) => {
    return decodeURIComponent(escape(window.atob(str)));
  };

  forgot = () => {
    this.props.history.push(
      `/password/forgot?lang=${this.props.i18n.language}`
    );
  };

  render() {
    const { t, handleSwitch } = this.props;
    const { username, password } = this.state;
    return (
      <React.Fragment>
        <form className="platform_landing">
          <div className={"platform_landing__header"}>
            <span>{t("landing.login.title")}</span>
          </div>
          <div className={"platform_landing__content"}>
            <Input
              id={"login_user"}
              label={t("landing.login.username")}
              onChange={(e) => {
                this.updateElement(e, "username");
              }}
              value={username}
              autocomplete={"login_username"}
              type={"primary"}
            />
            <Input
              id={"login_password"}
              label={t("landing.login.password")}
              onChange={(e) => {
                this.updateElement(e, "password");
              }}
              value={password}
              inputType={"password"}
              autocomplete={"login_password"}
              type={"primary"}
            />
            {this.props.data.isSending ? (
              <Button
                id={"login_join"}
                type={"success"}
                text={<CircularProgress size={21} />}
              />
            ) : (
              <Button
                id={"login_join"}
                type={"success"}
                onClick={this.handleSubmit}
                text={t("landing.login.button")}
              />
            )}
          </div>
          <div className={"platform_landing__footer"}>
            <span>
              {t("landing.login.info")}
              <a id={"login_register"} onClick={() => handleSwitch("signin")}>
                {t("landing.login.info_a")}
              </a>
            </span>
            <a
              id={"login_remember_password"}
              onClick={() => handleSwitch("forgot")}
            >
              {t("landing.login.forgot")}
            </a>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const login = connect(mapStateToProps)(Login);

export default withTranslation("common")(withRouter(login));
