import React from "react";
import DropElements from "./dropElements";

const Sidebar = (props) => {
    var exist_df = false;
    if (props?.nodes.filter(node => node.data.is_default)[0]) {
        exist_df = true;
    }

    const openTestChat = () => {
        window.open(`${process.env.PUBLIC_URL}/test_chat?project_id=${props.actualProject.id}&chat_id=${props.actualProject.chat_id}`);
    };

    return (
        <div className={"flow_menu"}>
            <div className={"flow_menu_elements"}>
                <span>Tipo de Intent</span>
                <div className={"flow_menu_actions"}>
                    <DropElements type={"intent_initial"} text={"Intent inicial"}/>
                    <DropElements type={"intent"} text={"Intent"}/>
                    <DropElements type={"intent_df"} disabled={exist_df} text={"Intent default fallback"}/>
                </div>
                <span>Elementos</span>
                <div className={"flow_menu_actions"}>
                    <DropElements type={"text"} text={"Texto"}/>
                    <DropElements type={"button"} text={"Botón"}/>
                </div>
            </div>
            <div className={"flow_menu_test"} onClick={() => openTestChat()}>
                <span>Probar flow</span>
            </div>
        </div>
    );
};

export default Sidebar;