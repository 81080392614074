// React
import React from "react";
// Assets
import {flowSvg} from "assets";

const DeleteElement = ({data, type, i, selected, disabled}) => {
    return (
        selected &&
        <div className={"flow_element__component flow_element__component_delete"}>
            <span onClick={() => data.updateNode(`delete_${type}`, data, i)} className={`${disabled ? "is_disabled" : ""}`}>
                {flowSvg("delete")}
            </span>
        </div>
    );
};

export default DeleteElement;