// React
import React, { Component } from "react";
// Translations
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
// Styles
import "components/Accordion/styles/styles.scss";
// Components
import { Divider } from "@material-ui/core";
import {
  Button,
  Input,
  SelectAutocomplete,
  SelectSimple
} from "@components/Input";
import { handleCopy } from "vendor/application";
import Tooltip from "@components/Tooltip";
import { returnElementFromArray } from "vendor/application";
// SVG
import { closeSvg, copySvg, deleteSvg } from "assets";

/**
 *
 * @typedef {import('types/ident').IdentVariable} IdentVariable
 * @typedef {{
 *  variables: IdentVariable[]
 *  updateVariables(variables: IdentVariable[]): void
 * }} Props
 *
 *
 * @augments {Component<Props>}
 */
class Variables extends Component {
  /**
   * @param {Props} props
   */
  constructor(props) {
    super(props);
    this.state = {
      showComponents: false
    };
  }
  toggleComponents = () => {
    this.setState((prevState) => ({
      showComponents: !prevState.showComponents
    }));
  };
  componentDidMount = () => {
    this.getSuncoCustomFields();
  };

  componentDidUpdate = (prevProps) => {
    const { integrations } = this.props;
    if (prevProps.integrations !== integrations) {
      this.getSuncoCustomFields();
    }
  };

  integrationSunco() {
    const { integrations } = this.props;
    if (!integrations) {
      return null;
    }
    return integrations?.find((int) => int.channel === "sunco");
  }

  getDeskId() {
    const integration_sunco = this.integrationSunco();

    return integration_sunco?.channel_id;
  }

  getSuncoCustomFields = () => {
    const { intent, use_custom_fields, getCustomFields } = this.props;
    const integration_sunco = this.integrationSunco();
    if (integration_sunco?.info?.zendesk?.use_custom_fields?.active) {
      const desk_id = integration_sunco.channel_id;
      if (!use_custom_fields || !use_custom_fields[desk_id]) {
        getCustomFields("sunco", desk_id, intent);
      }
    }
  };

  updateVariable = (e, type, i) => {
    const { variables, updateVariables, updateIntent, intent } = this.props;
    if (type === "variable") {
      if (e.target.value.match(/^[\.a-zA-Z0-9_-]*$/gm)) {
        variables[i][type] = e.target.value;
      }
    } else {
      variables[i][type] = e.target.value;
    }
    updateIntent(intent);
    updateVariables(variables);
  };

  addVariable = () => {
    const { variables, updateVariables, updateIntent, intent } = this.props;
    variables.push({ variable: "", equivalence: [] });
    updateVariables(variables);
    updateIntent(intent);
  };

  deleteVariable = (e, i) => {
    const { variables, intent, updateVariables } = this.props;

    let { variables_desk } = intent.extensions;

    variables.splice(i, 1);
    variables_desk.splice(i, 1);

    updateVariables(variables);
  };

  updateEquivalence = (e, variable_pos, equivalence_pos, type) => {
    const { variables, updateVariables, updateIntent, intent } = this.props;
    variables[variable_pos].equivalence[equivalence_pos][type] = e.target.value;
    updateVariables(variables);
    updateIntent(intent);
  };

  addEquivalence = (e, i) => {
    const { variables, updateVariables } = this.props;
    variables[i].equivalence.push([{ key: "", value: "" }]);
    updateVariables(variables);
  };

  deleteEquivalence = (e, variable_pos, equivalence_pos) => {
    const { variables, updateVariables } = this.props;
    variables[variable_pos].equivalence.splice(equivalence_pos, 1);
    updateVariables(variables);
  };

  deleteTicketField = (i) => {
    const { variables, updateVariables } = this.props;
    const newVariables = [...variables];
    newVariables[i].zendesk_ticket_field = null;
    updateVariables(newVariables);
  };

  deleteEquivalence = (e, variable_pos, equivalence_pos) => {
    const { variables, updateVariables } = this.props;
    variables[variable_pos].equivalence.splice(equivalence_pos, 1);
    updateVariables(variables);
  };

  handleParameters = (e, type, i) => {
    const { variables, updateVariables } = this.props;

    const variable = variables[i];

    const newVariables = [...variables];
    const desk_id = variable?.zendesk_ticket_field?.desk_id;
    newVariables[i].zendesk_ticket_field = {
      id: e.id,
      type: e.type,
      title: e.title,
      equivalences: [],
      desk_id,
      custom_field_options: e.custom_field_options ?? [],
      custom_field_options_actual: e.custom_field_options
        ? JSON.parse(JSON.stringify(e.custom_field_options))
        : []
    };
    updateVariables(newVariables);
  };

  handleExtensions = (e, type, i) => {
    const { variables, updateVariables, intent, updateIntent } = this.props;
    const desk_id = this.getDeskId();
    if (!e) {
      return;
    }
    const options = {
      id: e.id,
      type: e.type,
      title: e.title,
      equivalences: [],
      desk_id,
      custom_field_options: e.custom_field_options ?? [],
      custom_field_options_actual: e.custom_field_options
        ? JSON.parse(JSON.stringify(e.custom_field_options))
        : []
    };

    const variable = variables[i];
    variable.zendesk_ticket_field = options;

    const { variables_desk } = intent.extensions;

    /**
     * @type {Map<string, import('types/zendesk').ZendeskVariable>}
     */
    const ticketFieldByVariableName = new Map(
      (variables_desk || [])
        .filter((variable_desk) => !!variable_desk)
        .map((variable_desk) => [variable_desk.name, variable_desk])
    );

    const name = "$." + variable.variable;

    ticketFieldByVariableName.set(name, {
      name,
      zendesk_ticket_field: options
    });

    intent.extensions.variables_desk = Array.from(
      ticketFieldByVariableName.values()
    );

    updateIntent(intent);
    updateVariables(variables);
  };

  addParameterEquivalence = (i) => {
    const { variables, updateVariables } = this.props;
    variables[i].zendesk_ticket_field.equivalences.push({
      text: "",
      value: "",
      title: ""
    });
    updateVariables(variables);
  };

  updateParameterEquivalence = (e, variable, equivalence) => {
    const { variables, updateVariables } = this.props;
    variables[variable].zendesk_ticket_field.equivalences[equivalence].text =
      e.target.value;
    updateVariables(variables);
  };

  /**
   * @param {{ name: string; value: number }} e
   * @param {{
   *  equivalenceIndex: number;
   *  variableIndex: number;
   * }} indexes
   */
  selectParameterEquivalence = (e, indexes) => {
    const { variableIndex, equivalenceIndex } = indexes;
    const { variables, updateVariables } = this.props;
    var equivalences =
        variables[variableIndex].zendesk_ticket_field.equivalences,
      all_custom_field =
        variables[variableIndex].zendesk_ticket_field.custom_field_options,
      actual_custom_field =
        variables[variableIndex].zendesk_ticket_field
          .custom_field_options_actual;

    if (equivalences[equivalenceIndex].value) {
      var element = returnElementFromArray(
        equivalences[equivalenceIndex].value,
        all_custom_field,
        "value"
      );
      actual_custom_field.push(element);
    }

    equivalences[equivalenceIndex].value = e.value;
    equivalences[equivalenceIndex].title = e.name;

    actual_custom_field.map((custom, ii) => {
      if (custom.value === e.value) {
        actual_custom_field.splice(ii, 1);
      }
    });

    variables[variableIndex].zendesk_ticket_field.custom_field_options_actual =
      actual_custom_field;

    updateVariables(variables);
  };

  deleteParameterEquivalence = (variable, equivalence) => {
    const { variables, updateVariables } = this.props;
    var equivalences = variables[variable].zendesk_ticket_field.equivalences,
      all_custom_field =
        variables[variable].zendesk_ticket_field.custom_field_options,
      actual_custom_field =
        variables[variable].zendesk_ticket_field.custom_field_options_actual;

    if (equivalences[equivalence].value) {
      var element = returnElementFromArray(
        equivalences[equivalence].value,
        all_custom_field,
        "value"
      );
      actual_custom_field.push(element);
    }

    equivalences.splice(equivalence, 1);
    updateVariables(variables);
  };

  render() {
    const { use_custom_fields, t, variables } = this.props;
    const desk_id = this.getDeskId();
    const zendesk_options = use_custom_fields?.[desk_id] || [];
    const has_zendesk = zendesk_options?.length > 0;
    const zendesk_options_title_map = zendesk_options?.map((option) => {
      return {
        ...option,
        title: `${option.title} ${
          option.regexp_for_validation
            ? `(${option.regexp_for_validation})`
            : ""
        }`
      };
    });
    const zendeskIdSet = new Set(zendesk_options.map((option) => option.id));

    return (
      <div className={"accordion__element_variables"}>
        <div className={"accordion__element_header"}>
          <span>
            {t("intents.config.accordion.extensions.content.variables.title")}{" "}
            <Tooltip
              text={t(
                "intents.config.accordion.extensions.content.variables.description"
              )}
            />
          </span>
        </div>
        <div className={"accordion__element_content"}>
          {variables?.map((el, i) => {
            const zendesk_ticket_field = el.zendesk_ticket_field;
            const showZendeskTicketField =
              zendesk_ticket_field?.custom_field_options?.length > 0 &&
              zendesk_ticket_field?.type === "tagger";
            const isIdValid =
              el.zendesk_ticket_field &&
              zendeskIdSet.has(el.zendesk_ticket_field?.id);
            const showError = el.zendesk_ticket_field && !isIdValid;
            return (
              <div
                className={"accordion__element_variable"}
                key={`variable_${i}`}
              >
                <div className={"accordion__element_variable_header"}>
                  <div className={"accordion__element_variable_header_content"}>
                    <span>$.</span>
                    <Input
                      type={"transparent"}
                      className={`${el.error ? "error" : ""}`}
                      placeholder={t(
                        "intents.config.accordion.extensions.content.variables.variable.name"
                      )}
                      inputProps={{ "aria-label": "description" }}
                      value={el.variable}
                      onChange={(e) => {
                        this.updateVariable(e, "variable", i);
                      }}
                    />
                  </div>
                  <div className={"accordion__element_variable_header_actions"}>
                    <div onClick={() => handleCopy(`$.${el.variable}`)}>
                      {copySvg()}
                    </div>
                    <div
                      onClick={(e) => {
                        this.deleteVariable(e, i);
                      }}
                    >
                      {deleteSvg()}
                    </div>
                  </div>
                </div>
                <div className={"accordion__element_variable_content"}>
                  {el.equivalence.length > 0 &&
                    el.equivalence.map((equivalence, equivalence_pos) => {
                      return (
                        <React.Fragment key={`equivalence_${equivalence_pos}`}>
                          <div
                            className={
                              "accordion__element_variable_content_equivalences"
                            }
                          >
                            <Input
                              onChange={(e) => {
                                this.updateEquivalence(
                                  e,
                                  i,
                                  equivalence_pos,
                                  "key"
                                );
                              }}
                              value={equivalence.key ? equivalence.key : ""}
                              placeholder={t(
                                "intents.config.accordion.extensions.content.variables.variable.key"
                              )}
                              className={"secondary"}
                              inputProps={{ maxLength: 4096 }}
                            />
                            <Input
                              onChange={(e) => {
                                this.updateEquivalence(
                                  e,
                                  i,
                                  equivalence_pos,
                                  "value"
                                );
                              }}
                              value={equivalence.value ? equivalence.value : ""}
                              placeholder={t(
                                "intents.config.accordion.extensions.content.variables.variable.value"
                              )}
                              className={"secondary"}
                              inputProps={{ maxLength: 4096 }}
                            />
                            <div
                              className={"platform_input_content_delete"}
                              onClick={(e) => {
                                this.deleteEquivalence(e, i, equivalence_pos);
                              }}
                            >
                              {deleteSvg()}
                            </div>
                          </div>
                          {equivalence_pos !== el.equivalence.length - 1 && (
                            <Divider />
                          )}
                        </React.Fragment>
                      );
                    })}
                  <>
                    <Button
                      type={"dashed"}
                      onClick={(e) => {
                        this.addEquivalence(e, i);
                      }}
                      text={t(
                        "intents.config.accordion.extensions.content.variables.variable.add"
                      )}
                    />
                  </>

                  {has_zendesk && (
                    <>
                      <div
                        className={"accordion__element_variable_content_custom"}
                      >
                        <div className={"accordion__element_use_custom_fields"}>
                          <div className={"actions"}>
                            <SelectAutocomplete
                              value={
                                el.zendesk_ticket_field
                                  ? el.zendesk_ticket_field
                                  : false
                              }
                              searchOption={"title"}
                              items={zendesk_options_title_map}
                              className={"select_secondary"}
                              onSelect={(e, type) => {
                                this.handleExtensions(e, type, i);
                              }}
                              placeholder={t(
                                "intents.config.accordion.params.zendesk_custom_field.custom_field"
                              )}
                              iteration={i}
                              t={t}
                              error={showError}
                            />
                            <Button
                              className={"btn_icon"}
                              type={"white"}
                              onClick={() => {
                                this.deleteTicketField(i);
                              }}
                              text={closeSvg()}
                            />
                          </div>
                          {showError ? (
                            <div
                              style={{
                                marginTop: 6,
                                color: "red",
                                fontSize: 12
                              }}
                            >
                              <span>
                                {t(
                                  "intents.config.accordion.extensions.content.variables.variable.error"
                                )}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <>
                        {showZendeskTicketField && (
                          <>
                            <Button
                              disabled={
                                el.zendesk_ticket_field.equivalences.length ===
                                el.zendesk_ticket_field.custom_field_options
                                  .length
                              }
                              type={"dashed"}
                              onClick={() => {
                                this.addParameterEquivalence(i);
                              }}
                              text={t(
                                "intents.config.accordion.params.zendesk_custom_field.add_button"
                              )}
                            />
                            {el.zendesk_ticket_field.equivalences.map(
                              (equivalence, equivalenceIndex) => {
                                return (
                                  <div
                                    className={
                                      "accordion__element_parameter_equivalence"
                                    }
                                    key={equivalenceIndex}
                                  >
                                    <Input
                                      id={`equivalences-${equivalenceIndex}`}
                                      onChange={(e) => {
                                        this.updateParameterEquivalence(
                                          e,
                                          i,
                                          equivalenceIndex
                                        );
                                      }}
                                      value={equivalence.text}
                                      placeholder={t(
                                        "intents.config.accordion.params.zendesk_custom_field.text"
                                      )}
                                    />
                                    <SelectSimple
                                      valueSelected={
                                        equivalence.title
                                          ? equivalence.title
                                          : false
                                      }
                                      items={
                                        el.zendesk_ticket_field
                                          .custom_field_options_actual || []
                                      }
                                      className={"select_secondary"}
                                      onSelect={(event) =>
                                        this.selectParameterEquivalence(event, {
                                          variableIndex: i,
                                          equivalenceIndex: equivalenceIndex
                                        })
                                      }
                                      placeholder={t(
                                        "intents.config.accordion.params.zendesk_custom_field.value"
                                      )}
                                      iteration={[i, equivalenceIndex]}
                                      t={t}
                                    />
                                    <div
                                      onClick={() =>
                                        this.deleteParameterEquivalence(
                                          i,
                                          equivalenceIndex
                                        )
                                      }
                                      className={"delete_equivalence"}
                                    >
                                      {closeSvg()}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </>
                        )}
                      </>
                    </>
                  )}
                </div>
              </div>
            );
          })}
          <Button
            type={"dashed"}
            onClick={(e) => {
              this.addVariable(e);
            }}
            text={t(
              "intents.config.accordion.extensions.content.variables.button"
            )}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const accordion_variables = connect(mapStateToProps)(Variables);

export default withTranslation("common")(accordion_variables);
