// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Translations
import {withTranslation} from "react-i18next";
// Styles
import "components/Accordion/styles/styles.scss";
// Components
import {SelectSimple} from "@components/Input";
import Skeleton from "@material-ui/lab/Skeleton";


class ZendeskFull extends Component {
    updateSelectorElement = (e, type) => {
        const {intent, zendesk_groups, updateIntent, getGroups} = this.props;
        switch (type) {
            case "zendesk_ticket_status":
                intent.desks.info = {
                    ...intent.desks.info,
                    ["zendesk_mode"]: e === "solved" ? "bot" : intent.desks.info["zendesk_mode"],
                    [type]: e
                };
                break;
            case "zendesk_mode":
                intent.desks.info = {
                    ...intent.desks.info,
                    [type]: e
                };
                break;
            case "desk_id":
                if (e !== intent.desks.info.desk_id) {
                    intent.desks.info = {
                        ...intent.desks.info,
                        [type]: e,
                    };

                    if (e) {
                        getGroups(e);
                    }
                }
                break;
            case "group_id":
                if (e !== "-") {
                    var id = zendesk_groups.filter(group => group.name === e)[0].id;
                    intent.desks.info = {
                        ...intent.desks.info,
                        [type]: id.toString()
                    };
                } else {
                    intent.desks.info = {
                        ...intent.desks.info,
                        [type]: ""
                    };
                }
                break;
            default:
                break;
        }

        updateIntent(intent);
    };

    handleSelectorValue = (item, type) => {
        const {t} = this.props;
        var value = item?.[type];
        switch (type) {
            case "zendesk_ticket_status":
                if (!value) {
                    return <span className={"status new"}>{t("elements.custom.status.new")}</span>;
                } else {
                    return <span className={`status ${value}`}>{t(`elements.custom.status.${value}`)}</span>;
                }
            default:
                break;
        }
    };

    handleSkeleton = () => {
        const {intent, zendesk_groups, t} = this.props;
        if (intent.desks.info.desk_id) {
            if (!zendesk_groups) {
                return <div className={"info__integration_content_skeleton"}>
                    <div>
                        <Skeleton variant="rect" width={"100%"} height={10}/>
                        <Skeleton variant="rect" width={"100%"} height={40}/>
                    </div>
                </div>;
            }
            return <SelectSimple
                valueSelected={this.getGroupName(intent.desks.info.group_id)}
                items={zendesk_groups}
                label={t("common:intents.integrations.group_id")}
                onSelect={(e) => this.updateSelectorElement(e, "group_id")}
                placeholder={t("common:intents.integrations.group_id_placeholder")}
                className={"select_secondary"}
                value={"name"}
                t={t}
            />;
        }
    };

    getGroupName = (group_id) => {
        const {zendesk_groups} = this.props;
        if (group_id) {
            var name = zendesk_groups.filter(group => group.id.toString() === group_id)[0]?.name;
            return name ?? "-";
        } return "-";
    };

    render() {
        const {intent, integrations, t} = this.props;
        const integration = integrations.filter(int => int.channel === "zendesk_full")[0];

        var desk_name = "-";
        if (intent.desks.info.desk_id) {
            desk_name = integration?.multiple?.filter(int => int.id === intent.desks.info.desk_id)[0]?.name;
        }
        return (
            <div className={"info__integration"}>
                <div className={"info__integration_content"}>
                    <SelectSimple
                        valueSelected={this.handleSelectorValue(intent.desks.info, "zendesk_ticket_status")}
                        items={["new", "open", "pending", "solved"]}
                        label={t("common:intents.integrations.zendesk_ticket_status")}
                        onSelect={(e) => this.updateSelectorElement(e, "zendesk_ticket_status")}
                        placeholder={t("common:intents.integrations.zendesk_ticket_status")}
                        type={"status"}
                        className={"select_secondary"}
                        t={t}
                    />
                    <SelectSimple
                        valueSelected={intent.desks.info.zendesk_mode}
                        items={["desk", "bot"]}
                        label={t("common:intents.integrations.mode")}
                        onSelect={(e) => this.updateSelectorElement(e, "zendesk_mode")}
                        placeholder={t("common:intents.integrations.mode")}
                        disabled={intent.desks.info.zendesk_ticket_status === "solved"}
                        className={"select_secondary"}
                        t={t}
                    />
                    {
                        integration?.multiple?.length > 1 &&
                            <SelectSimple
                                valueSelected={desk_name}
                                items={integration.multiple}
                                label={t("common:intents.integrations.desk_id")}
                                onSelect={(e) => this.updateSelectorElement(e, "desk_id")}
                                placeholder={t("common:intents.integrations.desk_id")}
                                value={"id"}
                                className={"select_secondary"}
                                t={t}
                            />
                    }
                    {this.handleSkeleton()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const channel = connect(
    mapStateToProps
)(ZendeskFull);

export default withTranslation(["commons", "common", "languages"])(channel);