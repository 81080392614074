// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter } from "react-router-dom";
// Components
import Individual from "./individual";
import Campaign from "./campaign";
// Translations
import { withTranslation } from "react-i18next";
// Styles
import "views/Bots/ChatBot/styles/index.scss";

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 1
    };
  }

  render() {
    const { tab } = this.state;
    return (
      <div className="centribal_card">
        <div className={"campaigns_tabs"}>
          <div
            onClick={() => this.setState({ tab: 1 })}
            className={`${tab === 1 ? "active" : ""}`}
          >
            {"Individual"}
          </div>
          <div
            onClick={() => this.setState({ tab: 2 })}
            className={`${tab === 2 ? "active" : ""}`}
          >
            {"Campañas"}
          </div>
        </div>
        {tab === 1 && <Individual />}
        {tab === 2 && <Campaign />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const connect_project_notifications = connect(mapStateToProps)(Notifications);

export default withTranslation(["commons", "common"])(
  withRouter(connect_project_notifications)
);
