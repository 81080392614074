// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter } from "react-router-dom";
// API
// Vendor
// Components
import { AppBar, Divider, Tab, Tabs } from "@material-ui/core";
import { TabPanel } from "components/Navigation/tab_panel";
import IndividualList from "views/Campaigns/application/History/Individual/list";
import CampaignsList from "views/Campaigns/application/History/Campaigns/list";
import CampaignsView from "views/Campaigns/application/History/Campaigns/view";
// Helpers
// Jquery
// Translations
import { withTranslation } from "react-i18next";
// Styles
import "views/Bots/ChatBot/styles/index.scss";
// Amplitude API

class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
      id: "",
      project_id: ""
    };
  }

  componentDidMount = () => {
    this.handleTabs();
  };

  a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  };

  setQuery = (to, id, project_id) => {
    if (to) {
      this.props.history.push(`${this.props.location.pathname}?tab=${to}`);
    } else {
      this.props.history.push(
        `${this.props.location.pathname}?tab=campaigns&id=${id}&project_id=${project_id}`
      );
    }

    setTimeout(() => {
      this.handleTabs(id);
    }, 10);
  };

  handleTabs = () => {
    const { history } = this.props;
    const queryString = window.location.search,
      urlParams = new URLSearchParams(queryString),
      tab = urlParams.get("tab"),
      id = urlParams.get("id"),
      project_id = urlParams.get("project_id");

    if (id) {
      this.setState({ tab: 1, id, project_id });
    } else {
      switch (tab) {
        case "individual":
          this.setState({ tab: 0, id: "", project_id: "" });
          break;
        case "campaigns":
          this.setState({ tab: 1, id: "", project_id: "" });
          break;
        default:
          history.push(`${this.props.location.pathname}?tab=individual`);
          this.setState({ tab: 0, id: "", project_id: "" });
          break;
      }
    }
  };

  render() {
    const { tab, id, project_id } = this.state;
    return (
      <div className="centribal_card">
        <AppBar id={"app_bar"} position="static" color="default">
          <Tabs
            value={tab}
            variant="scrollable"
            scrollButtons="on"
            textColor="primary"
          >
            {/* Project Stats */}
            <Tab
              label={"Individual"}
              onClick={() => {
                this.setQuery("individual");
              }}
              {...this.a11yProps(0)}
            />
            {/* Account Stats */}
            <Tab
              label={"Campaigns"}
              onClick={() => {
                this.setQuery("campaigns");
              }}
              {...this.a11yProps(1)}
            />
          </Tabs>
        </AppBar>
        <Divider />
        <TabPanel value={tab} index={0}>
          <IndividualList setQuery={this.setQuery} />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          {id ? (
            <CampaignsView
              setQuery={this.setQuery}
              id={id}
              project_id={project_id}
            />
          ) : (
            <CampaignsList setQuery={this.setQuery} />
          )}
        </TabPanel>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const connect_project_notifications = connect(mapStateToProps)(History);

export default withTranslation(["commons", "common"])(
  withRouter(connect_project_notifications)
);
