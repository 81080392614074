// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Routing
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
// Views
import NoProject from "views/Bots/ChatBot/application/Tabs/not_found";
import ChatbotCreate from "views/Bots/ChatBot/application/create";
import NotFound from "components/NotFound/application/404";
import Academy from "views/Academy/application";
import SuncoRedirect from "views/Bots/Integrations/application/channels/Sunco/redirect";
import ChatbotEdit from "views/Bots/ChatBot/application";
import NLP from "views/Bots/NLP/application";
import Default from "views/Campaigns/application/Default";
// Routes
import RouteCampaigns from "routes/application/route_campaigns";
import RouteMain from "routes/application/route_main";
// Components
import Maintenance from "@components/Maintenance";
import Dashboard from "views/Bots/Dashboard/application/dashboard";
// Helpers
import {getRoleName, isBannerShow, isMaintenance} from "@helpers";

class RouteApp extends Component {

    getStatsClass = () => {
        const {location} = this.props;
        const {projects, actualLayout, actualProject, user, plan} = this.props.data;

        if (isMaintenance(plan.id)) {
            return "cen-maintenance";
        } else if (actualLayout.layout === "academy") {
            return "cen-academy-wordpress";
        } else if (location.pathname.match("training")) {
            return "training-mosaic";
        } else if (actualLayout.layout === "dashboard" || (projects.length === 0 && !["Agent", "Supervisor"].includes(user.role.name)) || !this.handleVisibility() || location.pathname === "/project" || (!actualProject.active && getRoleName(user.roles[0]) === "Trainer")) {
            return "cen-all";
        }
        return "";
    };

    handleVisibility = () => {
        const {history} = this.props;
        const {plan} = this.props.data;
        var res = true;
        switch (true) {
            case history.location.pathname.includes("/academy"):
                res = false;
                break;
            case history.location.pathname.includes("/campaigns"):
                res = plan?.whatsapp_phones?.length > 0 && plan?.platform?.centripush;
                break;
        }
        return res;
    };

    handleRedirect = () => {
        const {plan} = this.props.data;
        return !(plan?.to_delete || (plan?.plan?.name === "consulting_sunco" && !plan?.plan?.integrations?.sunco));


    };

    render() {
        const {roles, actualProject, plan, user} = this.props.data;
        var is_banner_show = isBannerShow(plan, user) ? "banner" : "";
        return (
            <div className={`cen-content ${this.getStatsClass()} ${is_banner_show}`}>
                {
                    roles.length > 0 ? (
                        <React.Fragment>
                            {
                                isMaintenance(plan.id) &&
                                <Switch>
                                    <Route path="/*" exact component={Maintenance}/>
                                    <Redirect to={"/maintenance"}/>
                                </Switch>
                            }
                            {
                                !isMaintenance(plan.id) &&
                                <Switch>
                                    <Route path="/404/:type" exact component={NotFound}/>
                                    <Route path="/project" exact component={NoProject}/>
                                    <Route path="/dashboard" exact component={Dashboard}/>
                                    <Route path="/:project/chatbot" exact>
                                        <ChatbotEdit id={actualProject.id}/>
                                    </Route>
                                    <Route path="/:project/nlp" exact>
                                        <NLP project={actualProject.id} getLimits={this.props.getLimits}/>
                                    </Route>
                                    <Route path="/sunco/integration" exact component={SuncoRedirect}/>
                                    <Route path="/academy" exact component={Academy}/>
                                    <Route path="/campaigns" exact component={Default}/>
                                    <Route path="/campaigns/:type" component={RouteCampaigns}/>
                                    <Route path="/project/create" exact component={ChatbotCreate}/>
                                    <Route path="/:project/:layout">
                                        <RouteMain getLimits={this.props.getLimits}/>
                                    </Route>
                                    {
                                        this.handleRedirect() &&
                                        <Redirect to={"/404/not_found"}/>
                                    }
                                </Switch>
                            }
                        </React.Fragment>
                    ) : (
                        "This account not have any roles."
                    )
                }
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const connect_route_app = connect(
    mapStateToProps
)(RouteApp);

export default withRouter(connect_route_app);