// React
import React, { Component } from "react";

class Input extends Component {

    render() {
        const { type, placeholder, marginTop, marginBottom, onChange, onBlur, value, disabled, onKeyDown, error, max, isValue, id } = this.props;
        return (
            isValue ? (
                id ? (
                    <input
                        id={id}
                        className={`input-${type} mt-${marginTop} mg-${marginBottom} ${error ? "b-error" : ""}`}
                        placeholder={placeholder}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        disabled={disabled}
                        onKeyDown={onKeyDown}
                        maxLength={max ? max : ""}
                    />
                ) : (
                        <input
                            className={`input-${type} mt-${marginTop} mg-${marginBottom} ${error ? "b-error" : ""}`}
                            placeholder={placeholder}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            disabled={disabled}
                            onKeyDown={onKeyDown}
                            maxLength={max ? max : ""}
                        />
                    )
            ) : (
                    id ? (
                        <input
                            id={id}
                            className={`input-${type} mt-${marginTop} mg-${marginBottom} ${error ? "b-error" : ""}`}
                            placeholder={placeholder}
                            onChange={onChange}
                            onBlur={onBlur}
                            defaultValue={value}
                            disabled={disabled}
                            onKeyDown={onKeyDown}
                            maxLength={max ? max : ""}
                        />


                    ) : (
                            <input
                                className={`input-${type} mt-${marginTop} mg-${marginBottom} ${error ? "b-error" : ""}`}
                                placeholder={placeholder}
                                onChange={onChange}
                                onBlur={onBlur}
                                defaultValue={value}
                                disabled={disabled}
                                onKeyDown={onKeyDown}
                                maxLength={max ? max : ""}
                            />

                        )
                )
        );
    }
}

export default Input;