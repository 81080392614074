// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Translate
import { withTranslation } from "react-i18next";
// Styles
import "./styles/styles.scss";

class LongImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false
    };
  }

  downloadImage = (img) => {
    fetch(`${process.env.PUBLIC_URL}/img/views/${img}`, {
      method: "GET",
      headers: {}
    }).then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", img);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    });
  };

  render() {
    const { image, alt, handleClickOpen, t } = this.props;
    return (
      <div className={"image__element_long"}>
        <div className={"image__element_header"}>
          <button onClick={() => handleClickOpen()} className="btn btn-success">
            {t("image.button.return")}
          </button>
          <span>{image}</span>
          <button
            onClick={() => this.downloadImage(image)}
            className="btn btn-success"
          >
            {t("image.button.download")}
          </button>
        </div>
        <div className={"image__element_image"}>
          <img src={`${process.env.PUBLIC_URL}/img/views/${image}`} alt={alt} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const image = connect(mapStateToProps)(LongImage);

export default withTranslation("common")(image);
