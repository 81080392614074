// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Translations
import { withTranslation } from "react-i18next";
// SVG
import { addSvg, copySvg, minimizeSvg } from "assets";
// Views
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
// Styles
import { withStyles } from "@material-ui/core/styles";
import "components/Accordion/styles/styles.scss";
// Components
import { Radio, TextField } from "@material-ui/core";

const Accordion = withStyles({
  root: {
    border: "none",
    boxShadow: "none",
    backgroundColor: "white",
    filter: "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25))"
  },
  expanded: {
    borderRadius: "15px 15px 0 15px !important"
  }
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    minHeight: 35,
    fontSize: 14,
    padding: "0px 21px",
    color: "#565671"
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {
    minHeight: "inherit !important",
    borderRadius: "15px 15px 0 0",
    background: "#B2E6C8"
  },
  expandIcon: {
    padding: "0 15px",
    lineHeight: 0
  }
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    backgroundColor: "#f4f4f4",
    padding: "5px 21px 24px 21px",
    borderRadius: "0 0 0 15px"
  }
})(MuiAccordionDetails);

class AccordionStatsPolls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }

  handleToggleOne = (e) => {
    this.setState({ expanded: !this.state.expanded });
  };

  handleCopy = (e, copyText) => {
    e.stopPropagation();
    navigator && navigator.clipboard && navigator.clipboard.writeText(copyText);
  };

  render() {
    const { poll, t } = this.props;
    return (
      <Accordion square expanded={this.state.expanded}>
        <AccordionSummary
          onClick={(e) => this.handleToggleOne(e)}
          expandIcon={
            !this.state.expanded ? (
              <span>{addSvg()}</span>
            ) : (
              <span>{minimizeSvg()}</span>
            )
          }
        >
          <div className={"accordion__header_polls"}>
            <div>
              <span>{t("stats.polls.elements.date")}</span>
              <span>{poll.date}</span>
            </div>
            <div>
              <span>{t("stats.polls.elements.user")}</span>
              <span>
                <span>{poll.session}</span>
                <span onClick={(e) => this.handleCopy(e, poll.session)}>
                  {copySvg()}
                </span>
              </span>
            </div>
            <div>
              <span>{t("stats.polls.elements.channel")}</span>
              <span>{poll.channel}</span>
            </div>
            <div>
              <span>{t("stats.polls.elements.detected_inactivity")}</span>
              <div className={"checkbox"}>
                <Radio
                  checked={poll.detected_inactivity}
                  disabled
                  disableRipple
                />
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={"accordion__content"}>
            <div className={"accordion__element_text"}>
              <span>{t("stats.polls.elements.answer_1")}</span>
              <TextField
                className="input-accordion polls"
                id="answer_1"
                defaultValue={poll.answer_1}
                disabled
              />
            </div>
            <div className={"accordion__element_text"}>
              <span>{t("stats.polls.elements.answer_2")}</span>
              <TextField
                className="input-accordion polls"
                id="answer_2"
                defaultValue={poll.answer_2}
                disabled
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const c = connect(mapStateToProps)(AccordionStatsPolls);

export default withTranslation(["common", "languages"])(c);
