import { CustomRequest } from "vendor/infrastructure/custom_requests";
import {updateSending} from "vendor/application/disptach";

export class ProjectAPI {
    constructor(projectID, token, props, body) {
        this.projectID = projectID;
        this.token = token;
        this.props = props;
        this.body = body;
    }

    async get_project_by_id() {
        let request = new CustomRequest("GET", `projects/${this.projectID}`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async save_project() {
        this.props.dispatch(updateSending(true));
        let request = new CustomRequest("PUT", `projects/${this.projectID}`, JSON.stringify(this.body), this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async post_project() {
        this.props.dispatch(updateSending(true));
        let request = new CustomRequest("POST", "projects", JSON.stringify(this.body), this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async delete_project(id) {
        let request = new CustomRequest("DELETE", `projects/${id}`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_plans(id) {
        let request = new CustomRequest("GET", `plans/${id}`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_users() {
        let request = new CustomRequest("GET", `projects/${this.projectID}/users`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_project_limits() {
        let request = new CustomRequest("GET", `projects/${this.projectID}/limits`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async do_actions() {
        this.props.dispatch(updateSending(true));
        let request = new CustomRequest("POST", `projects/${this.projectID}/actions`, JSON.stringify(this.body), this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async importProject() {
        this.props.dispatch(updateSending(true));
        let request = new CustomRequest("POST", `projects/${this.projectID}/actions/import`, this.body, this.token, this.props );
        var req = await request.action(true);
        this.props.dispatch(updateSending(false));
        return req;
    }

    async predefined_bot(lang) {
        let request = new CustomRequest("GET", `projects/actions/bots?lang=${lang}`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_project_actions() {
        let request = new CustomRequest("GET", `projects/${this.projectID}/actions?num=5`, null, this.token, this.props );
        return await request.action();
    }

    async get_project_action(action_id) {
        let request = new CustomRequest("GET", `projects/${this.projectID}/actions/${action_id}`, null, this.token, this.props );
        return await request.action();
    }

    async get_countries() {
        let request = new CustomRequest("GET", "countries", null, this.token, this.props );
        return await request.action();
    }

    async get_conversations_channels() {
        let request = new CustomRequest("GET", `projects/${this.projectID}/conversations/channels`, null, this.token, this.props );
        return await request.action();
    }

    async get_inactivity() {
        let request = new CustomRequest("GET", `projects/${this.projectID}/inactivities`, null, this.token, this.props );
        return await request.action();
    }

    async do_inactivity(method) {
        this.props.dispatch(updateSending(true));
        let request = new CustomRequest(method, `projects/${this.projectID}/inactivities`, JSON.stringify(this.body), this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_codes() {
        let request = new CustomRequest("GET", "languages/codes", null, this.token, this.props );
        return await request.action();
    }

    async get_polls() {
        let request = new CustomRequest("GET", `projects/${this.projectID}/polls`, null, this.token, this.props );
        return await request.action();
    }

    async do_polls(method) {
        let request = new CustomRequest(method, `projects/${this.projectID}/polls`, JSON.stringify(this.body), this.token, this.props );
        return await request.action();
    }

    async get_webhook(id) {
        let request = new CustomRequest("GET", `accounts/${id}/webhooks`, null, this.token, this.props );
        return await request.action();
    }

    async post_webhook(id) {
        let request = new CustomRequest("POST", `accounts/${id}/webhooks`, JSON.stringify(this.body), this.token, this.props );
        return await request.action();
    }
}