// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Routing
import {withRouter} from "react-router-dom";
// Translations
import {withTranslation} from "react-i18next";
// Styles
import "./styles.scss";
// Icon
import {clipSvg, Close, Maximize, sendSvg, openNewWindow} from "assets";


const Message = (props) => {
    const {t, type, text, bgColor, txtColor, src, icon, button, link, config, handleTabs} = props;
    const buttonBgColor = config?.customization.artifacts_button_bg_color,
        buttonTxtColor = config?.customization.artifacts_button_text_color,
        buttonHoverBgColor = config?.customization.artifacts_button_hover_bg_color,
        buttonHoverTxtColor = config?.customization.artifacts_button_hover_text_color,
        buttonDisabledBgColor = config?.customization.artifacts_button_disabled_bg_color,
        buttonDisabledTxtColor = config?.customization.artifacts_button_disabled_text_color,
        linkBgColor = config?.customization.artifacts_link_button_bg_color,
        linkTxtColor = config?.customization.artifacts_link_button_text_color,
        linkHoverBgColor = config?.customization.artifacts_link_button_hover_bg_color,
        linkHoverTxtColor = config?.customization.artifacts_link_button_hover_text_color;
    return (
        <div className={`message ${type} ${config?.customization?.messages_rubberband ? "rubberBand" : ""}`}>
            {
                icon &&
                <img src={src} alt={"centribal_icon"}/>
            }
            <div style={{backgroundColor: bgColor, color: txtColor}}>
                <span>{text}</span>
                {
                    button &&
                        <>
                            <div onClick={(e) => {
                                e.stopPropagation();
                                handleTabs(e, "buttons", "content");
                            }} style={{backgroundColor: buttonBgColor, color: buttonTxtColor}}
                                 className={"message_button"}>{t("card.content.project.web_interface.customization.chat.button.normal")}</div>
                            <div onClick={(e) => {
                                e.stopPropagation();
                                handleTabs(e, "buttons", "content");
                            }} style={{backgroundColor: buttonHoverBgColor, color: buttonHoverTxtColor}}
                                 className={"message_button"}>{t("card.content.project.web_interface.customization.chat.button.hover")}</div>
                            <div onClick={(e) => {
                                e.stopPropagation();
                                handleTabs(e, "buttons", "content");
                            }} style={{backgroundColor: buttonDisabledBgColor, color: buttonDisabledTxtColor}}
                                 className={"message_button"}>{t("card.content.project.web_interface.customization.chat.button.disabled")}</div>
                        </>
                }
                {
                    link &&
                        <>
                            <div onClick={(e) => {
                                e.stopPropagation();
                                handleTabs(e, "buttons", "content");
                            }} style={{backgroundColor: linkBgColor, color: linkTxtColor}}
                                 className={"message_link"}>{t("card.content.project.web_interface.customization.chat.link.normal")} <span className={"message_link_svg"}>{openNewWindow(linkTxtColor)}</span></div>
                            <div onClick={(e) => {
                                e.stopPropagation();
                                handleTabs(e, "buttons", "content");
                            }} style={{backgroundColor: linkHoverBgColor, color: linkHoverTxtColor}}
                                 className={"message_link"}>{t("card.content.project.web_interface.customization.chat.link.hover")} <span className={"message_link_svg"}>{openNewWindow(linkHoverTxtColor)}</span></div>
                        </>
                }
            </div>

        </div>
    );
};

class Chat extends Component {

    render() {
        const {t, handleTabs, config} = this.props;
        const borderRadiusWidget = "25px 25px 0px",
            backgroundColorHeader = config.customization.header_bg_color,
            colorHeader = config.customization.header_text_color,
            backgroundColorContent = config.customization.chat_bg_color,
            backgroundColorList = config.customization.messages_content_bg_color,
            backgroundColorMessageAgent = config.customization.messages_agent_bg_color,
            colorMessageAgent = config.customization.messages_agent_text_color,
            backgroundColorMessageVisitor = config.customization.messages_user_bg_color,
            colorMessageVisitor = config.customization.messages_user_text_color,
            backgroundColorInput = config.customization.input_bg_color,
            colorInput = config.customization.input_text_color,
            backgroundColorClipSvg = config.customization.input_clip_bg_color,
            colorClipSvg = config.customization.input_clip_color,
            backgroundColorSendSvg = config.customization.input_icon_bg_color,
            colorSendSvg = config.customization.input_icon_color,
            colorSvg = config.customization.header_icons_color;

        return (
            <div className={"widget"} style={{borderRadius: borderRadiusWidget}}>
                {/* HEADER */}
                <div onClick={(e) => handleTabs(e, "header", "content")} className={"widget_header"} style={{backgroundColor: backgroundColorHeader}}>
                    <div className={"widget_header_title"}>
                        {
                            config.customization.header_icon_show &&
                            <img src={`${config.header_icon}`} alt={"centribal_icon"}/>
                        }
                        <span style={{color: colorHeader}} dangerouslySetInnerHTML={{__html: config.header_title}} />
                    </div>
                    <div className={"widget_header_actions"}>
                        {Maximize(colorSvg)}
                        {Close(colorSvg)}
                    </div>
                </div>
                {/* CONTENT */}
                <div onClick={(e) => handleTabs(e, "messages", "content")} className={"widget_content"} style={{backgroundColor: backgroundColorContent}}>
                    <div className={"widget_content_list"} style={{backgroundColor: backgroundColorList}}>
                        {/* VISITOR */}
                        <Message t={t} type={"visitor"} config={config} icon={config.customization.messages_user_icon_show}
                                 src={config.user_icon} bgColor={backgroundColorMessageVisitor}
                                 txtColor={colorMessageVisitor}
                                 text={"Lorem ipsum dolor"}/>
                        {/* AGENT */}
                        <Message t={t} handleTabs={handleTabs} type={"agent"} config={config} button
                                 icon={config.customization.messages_agent_icon_show}
                                 src={config.agent_icon} bgColor={backgroundColorMessageAgent}
                                 txtColor={colorMessageAgent}
                                 text={t("card.content.project.web_interface.customization.chat.button.title")}/>
                        {/* AGENT */}
                        <Message t={t} handleTabs={handleTabs} type={"agent"} config={config} link
                                 icon={false}
                                 src={config.agent_icon} bgColor={backgroundColorMessageAgent}
                                 txtColor={colorMessageAgent}
                                 text={t("card.content.project.web_interface.customization.chat.link.title")}/>
                        {/* VISITOR */}
                        <Message t={t} type={"visitor"} config={config} icon={config.customization.messages_user_icon_show}
                                 src={config.user_icon} bgColor={backgroundColorMessageVisitor}
                                 txtColor={colorMessageVisitor}
                                 text={"Lorem ipsum dolor"}/>
                        {/* AGENT */}
                        <Message t={t} type={"agent"} config={config} icon={config.customization.messages_agent_icon_show}
                                 src={config.agent_icon} bgColor={backgroundColorMessageAgent}
                                 txtColor={colorMessageAgent}
                                 text={"Lorem ipsum dolor"}/>
                        {/* VISITOR */}
                        <Message t={t} type={"visitor"} config={config} icon={config.customization.messages_user_icon_show}
                                 src={config.user_icon} bgColor={backgroundColorMessageVisitor}
                                 txtColor={colorMessageVisitor}
                                 text={"Lorem ipsum dolor amet"}/>
                    </div>
                </div>
                {/* INPUT */}
                {
                    config.customization.input_show &&
                    <div onClick={(e) => handleTabs(e, "input_text", "content")} className={"widget_input"} style={{backgroundColor: backgroundColorInput}}>
                        <div className={"widget_input_content"}>
                            <span style={{color: colorInput}}>{config.text_send}</span>
                        </div>
                        <div className={"widget_input_actions"}>
                            {
                                config.customization.attachment_clip &&
                                <span className={"attachment_clip"}
                                      style={{backgroundColor: backgroundColorClipSvg}}>{clipSvg(colorClipSvg)}</span>
                            }
                            <span className={"send"}
                                  style={{backgroundColor: backgroundColorSendSvg}}>{sendSvg(config.customization.input_icon, colorSendSvg)}</span>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const a = connect(
    mapStateToProps
)(Chat);

export default withTranslation("common")(withRouter(a));