// @ts-check

/**
 * @typedef {import('types/zendesk').ZendeskVariable} ZendeskVariable
 */

/**
 * @param {ZendeskVariable[]} value
 */

export const parseVariablesDeskCreate = (value = []) => {
  /**
   * @type {ZendeskVariable[]}
   *
   */
  const variablesDesk = [];
  (value || []).forEach((variable) => {
    if (!variable) return;
    const { name, zendesk_ticket_field } = variable;
    if (!zendesk_ticket_field?.desk_id || !zendesk_ticket_field.id) return;

    variablesDesk.push({
      name,
      zendesk_ticket_field: {
        id: zendesk_ticket_field.id,
        type: zendesk_ticket_field.type,
        title: zendesk_ticket_field.title,
        equivalences: zendesk_ticket_field.equivalences,
        desk_id: zendesk_ticket_field.desk_id
      }
    });
  });

  return variablesDesk;
};
