// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Translations
import { withTranslation } from "react-i18next";
// Material
import { FormControl, Select, MenuItem } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

class SelectCustom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      update: 0
    };
  }

  handleOnSelect = (e) => {
    e.preventDefault();
    this.props.onSelect(e.target.value, this.props.type, this.props.iteration);
  };

  onOpen = (e) => {
    e.preventDefault();
    this.setState({ update: this.state.update++ });
  };

  handleItem = (item, i) => {
    const { type, t, value } = this.props;

    switch (type) {
      case "customFields":
        return (
          <MenuItem key={i} value={item}>
            {item.title ?? item.name}
          </MenuItem>
        );
      case "conditional_responses":
        return (
          <MenuItem key={i} value={item}>
            {t(
              `intents.config.accordion.responses.conditional_responses.operators.${item}`
            )}
          </MenuItem>
        );
      case "plain_translate":
        return (
          <MenuItem key={i} value={item}>
            {t(`select.${item}_text`)}
          </MenuItem>
        );
      case "integrations":
        return (
          <MenuItem key={i} value={item.value}>
            {t(`select.${item.value}_text`)}
          </MenuItem>
        );
      case "status":
        return (
          <MenuItem key={i} value={item}>
            <span className={item}>
              {t(`integrations.hours_control.status_${item}`)}
            </span>
          </MenuItem>
        );
      case "method-training":
        return (
          <MenuItem key={i} value={item.id}>
            {t(`card.content.training.methods.${item.name}`)}
          </MenuItem>
        );
      case "method":
        return (
          <MenuItem key={i} value={item}>
            <span className={item}>{item}</span>
          </MenuItem>
        );
      case "lang":
        return (
          <MenuItem key={i} value={item.value}>
            {t(`languages:languages.${item.translate}`)}
          </MenuItem>
        );
      case "code_language":
        return (
          <MenuItem key={i} value={item}>
            {t(`languages:languages.${item}`)}
          </MenuItem>
        );
      case "sort_messages":
        return (
          <MenuItem key={i} value={item}>
            {t(`integrations.zendesk_lite_create.sort_messages_${item}`)}
          </MenuItem>
        );
      case "auth":
        return (
          <MenuItem key={i} value={item.value}>
            {t(
              `intents.config.accordion.extensions.content.auth.${item[value]}`
            )}
          </MenuItem>
        );
      case "country-timezone":
        return (
          <MenuItem key={i} value={item.code}>
            {item.name}
          </MenuItem>
        );
      case "conversations-channels":
        return (
          <MenuItem key={i} value={item.id}>
            {item.channel}
          </MenuItem>
        );
      case "country":
        return (
          <MenuItem key={i} value={`${item.name} - ${item.dial_code}`}>
            {item.name}
          </MenuItem>
        );
      case "entity":
        return (
          <MenuItem key={i} value={item}>
            {t(`entities.common.kind.${item}`)}
          </MenuItem>
        );
      case "intents":
        return (
          <MenuItem key={i} value={item.id}>
            {item.name}
          </MenuItem>
        );
      case "select_project":
        return (
          <MenuItem key={i} value={item.name}>
            <div className={"sandbox_selector"}>
              <span>{item.name}</span>{" "}
              {item.sandbox ? (
                <span className={"sandbox_label"}>sandbox</span>
              ) : (
                ""
              )}
            </div>
          </MenuItem>
        );
      default:
        return (
          <MenuItem key={i} value={value ? item[value] : item}>
            {item.name
              ? item.name
              : item === "\u221e"
                ? t("card.content.intents.all_pagination")
                : item}
          </MenuItem>
        );
    }
  };

  render() {
    const {
      strSelected,
      array,
      t,
      value,
      placeholder,
      type,
      id,
      disabled,
      className
    } = this.props;
    return (
      <FormControl
        className={`input-select ${id} ${className ? className : ""}`}
      >
        <Select
          disabled={disabled}
          onOpen={(e) => this.onOpen(e)}
          id={id}
          className={className}
          value={strSelected}
          displayEmpty
          onChange={(e) => this.handleOnSelect(e, value)}
          renderValue={(str) => {
            if (str && str.length > 0 && type === "lang") {
              return (
                <React.Fragment>
                  {t(`languages:languages.${str}`)}
                </React.Fragment>
              );
            } else if (
              (!str && placeholder) ||
              (str && str.length === 0 && placeholder)
            ) {
              return placeholder;
            } else if (
              str &&
              str.length > 0 &&
              (type === "method" || type === "status")
            ) {
              return <span className={str}>{str}</span>;
            }
            return str;
          }}
          MenuProps={{
            popoverclasses: id,
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            style: {
              maxHeight: 120 * 4.5,
              width: "20ch"
            }
          }}
          IconComponent={ExpandMoreIcon}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="">
            <em>{placeholder}</em>
          </MenuItem>
          {type === "method-contexts" && (
            <MenuItem value={""}>
              {t("card.content.intents.all_contexts")}
            </MenuItem>
          )}
          {array && array.length > 0 ? (
            array.map((item, i) => {
              return this.handleItem(item, i);
            })
          ) : (
            <MenuItem disabled value={0}>
              {t("select.no_results")}
            </MenuItem>
          )}
        </Select>
      </FormControl>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const connect_select = connect(mapStateToProps)(SelectCustom);

export default withTranslation(["common", "languages"])(connect_select);
