// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Routing
import {withRouter} from "react-router-dom";
// Material
import {CircularProgress, Divider} from "@material-ui/core";
// Vendor
import {showNotify} from "vendor/application/disptach";
// Infrastructure
import {IntegrationsAPI} from "../../../../../../api/application/Integrations";
import {IntentsAPI} from "views/Bots/NLP/application/Build/Tabs/Intents/infrastructure";
// Application
import {addArtifact, getChatGPTFullBody, returnElementFromArray} from "vendor/application";
// Translations
import {withTranslation} from "react-i18next";
// Components
import {ElementsCustom} from "@components/Elements";
import {Button, Input, SelectGrouping, SelectSimple} from "@components/Input";
import FloatButtons from "components/Input/float_buttons";
// SVG
import {channelsSvg} from "assets";
import {arrowRightSvg} from "@assets";

class Create extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: getChatGPTFullBody(),
            set_key_words: "",
            events: false
        };
    }

    componentDidMount = () => {
        this.getIntegrations();
        this.getEvents();
    };

    // get integrations from actual project id
    getIntegrations = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props);
            const integrations = await obj.get_integrations();
            var exist = returnElementFromArray("chatgpt", integrations, "channel");
            if (exist) {
                this.return();
            }
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: "error"}));
        }
    };

    getEvents = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new IntentsAPI(actualProject.id, access, this.props);
            const events = await obj.get_events();
            var events_arr = [];
            events.forEach((event => {
                var new_events = [];
                event.events.forEach((evt => {
                    new_events.push({
                        intent_id: event.id,
                        event: evt
                    });
                }));

                var group_events = {
                    group_name: event.name,
                    items: new_events
                };

                events_arr.push(group_events);
            }));

            this.setState({events: events_arr});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: "error"}));
        }
    };

    // update element state
    updateElement = (e, type) => {
        const {data} = this.state;

        switch (type) {
            case "max_interactions":
                data.info.back_to_bot[type] = parseInt(e?.target.value);
                break;
            case "max_tokens":
                data.info[type] = parseInt(e?.target.value);
                break;
            case "message_type":
                data.info.back_to_bot.message = {
                    type: e,
                    [e]: ""
                };
                break;
            case "event":
            case "response":
                data.info.back_to_bot.message[type] =  e?.target?.value ?? e;
                break;
            default:
                data.info[type] = e?.target.value;
                break;
        }

        this.setState({data: data});
    };

    return = () => {
        const {history} = this.props;
        const {actualProject} = this.props.data;
        history.push(`/${actualProject.id}/integrations/chatgpt`);
    };

    postIntegration = async (e) => {
        e.preventDefault();
        const {access, actualProject} = this.props.data;
        const {history} = this.props;
        const {data} = this.state;

        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props, data);
            let integration = await obj.post_integration();
            this.props.dispatch(showNotify({message: "integrations", type: "create", severity: "success"}));

            history.push(`/${actualProject.id}/integrations/chatgpt/edit/${integration.id}`);
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: "error"}));
        }
    };

    handleKeyWords = (e, type, elementType, chipToDelete, i) => {
        var {data} = this.state;
        var element_to_update;

        switch (elementType) {
            case "key_words":
                element_to_update = data.info.back_to_bot.key_words;
                break;
        }

        var key_word = e.target.value;

        switch (type) {
            case "add":
                if (key_word.match(/^[a-zAZä-ÿÄ-Ÿ0-9_]*$/) && key_word.length <= 24) {
                    this.setState({set_key_words: key_word});
                }
                break;
            case "delete":
                element_to_update.forEach(el => {
                    if (el === chipToDelete) {
                        element_to_update.splice(i, 1);
                    }
                });
                this.setState({data: data});
                break;
            case "enter":
                if (key_word.length > 3) {
                    element_to_update.push(key_word);
                    this.setState({data: data, set_key_words: ""});
                }
                break;
        }
    };

    updateArtifacts = (e, i, artifact_type, type) => {
        const {data} = this.state;
        var res = addArtifact(i, artifact_type, type, data);

        this.setState({data: res});
    };

    getEventName = (event) => {
        return event ? event : "-";
    };

    render() {
        const {data, set_key_words, events} = this.state;
        const {t} = this.props;
        const {isSending} = this.props.data;
        return (
            data &&
                <div className={"cen_integrations"}>
                    <div className={"header"}>
                        <div className={"header_content"}>
                            <div onClick={() => this.return()} className={"header_action"}>
                                {arrowRightSvg()}
                            </div>
                            <div className={"header_image"}>
                                {channelsSvg("chatgpt")}
                            </div>
                            <div className={"header_title"}>
                                <span className={"title"}>{t("integrations:channels.chatgpt.title")}</span>
                                <span className={"description"}>{t("integrations:categories.business")}</span>
                            </div>
                        </div>
                        <div className={"header_actions"}>
                            {
                                isSending ? (
                                    <Button type={"primary"} disabled text={<CircularProgress size={21}/>}/>
                                ) : (
                                    <Button type={"primary"}
                                            text={t("integrations:create.action_button")}
                                            onClick={(e) => this.postIntegration(e)}/>
                                )
                            }
                        </div>
                    </div>
                    <div className={"integration"}>
                        {/* API KEY */}
                        <ElementsCustom
                            t={t}
                            type={"chatgpt"}
                            input_type={"input"}
                            element_type={"api_key"}
                            element={data.info.api_key}
                            updateElement={this.updateElement}
                        />
                        <Divider/>
                        {/* MAX TOKENS */}
                        <ElementsCustom
                            t={t}
                            type={"chatgpt"}
                            input_type={"input"}
                            element_type={"max_tokens"}
                            element={data.info.max_tokens}
                            helper={"Mínimo tiene que tener 16"}
                            inputType={"number"}
                            updateElement={this.updateElement}
                        />
                        <Divider/>
                        {/* MAX INTERACTIONS */}
                        <ElementsCustom
                            t={t}
                            type={"chatgpt"}
                            input_type={"input"}
                            element_type={"max_interactions"}
                            element={data.info.back_to_bot.max_interactions}
                            helper={"Mínimo tiene que tener 1"}
                            inputType={"number"}
                            updateElement={this.updateElement}
                        />
                        <Divider/>
                        {/* KEY WORDS */}
                        <ElementsCustom
                            t={t}
                            type={"chatgpt"}
                            input_type={"input_with_tags"}
                            element_type={"key_words"}
                            element={set_key_words}
                            elements={data.info.back_to_bot.key_words}
                            updateElement={this.handleKeyWords}
                        />
                        <Divider/>
                        {/* MESSAGE */}
                        {
                            events &&
                            <div className={"elements_custom column"}>
                                <div className={"elements_custom__info"}>
                                    <span
                                            className={"elements_custom__title"}>{t("elements.custom.chatgpt.card.message.title")} </span>
                                    <span
                                        className={"elements_custom__description"}>{t("elements.custom.chatgpt.card.message.description")}</span>
                                </div>
                                <div className={"elements_custom__message"}>
                                    <SelectSimple
                                        valueSelected={data.info.back_to_bot.message.type ? t(`integrations:channels.chatgpt.message.type.${data.info.back_to_bot.message.type}`) : false}
                                        items={["response", "event"]}
                                        onSelect={(e) => this.updateElement(e, "message_type")}
                                        placeholder={t("integrations:channels.chatgpt.message.type.placeholder")}
                                        className={"select_secondary"}
                                        type={"translate"}
                                        translate_pattern={"integrations:channels.chatgpt.message.type."}
                                        t={t}
                                    />
                                    {
                                        data.info.back_to_bot.message.type === "response" &&
                                        <div className={"elements_custom__message_response"}>
                                            <Input
                                                placeholder={t("integrations:channels.chatgpt.message.response_placeholder")}
                                                inputProps={{maxLength: 4096}}
                                                multiline
                                                value={data.info.back_to_bot.message.response}
                                                onChange={(e) => this.updateElement(e, "response")}
                                            />
                                            <FloatButtons type={"chatgpt"}
                                                          element={"chatgpt"}
                                                          addArtifact={this.updateArtifacts}
                                                          buttons_toggle_artifacts={["img", "link", "button", "carousel", "bubble", "for"]}/>
                                        </div>
                                    }
                                    {
                                        data.info.back_to_bot.message.type === "event" &&
                                        <SelectGrouping
                                            valueSelected={this.getEventName(data.info.back_to_bot.message.event)}
                                            items={events}
                                            onSelect={(e) => this.updateElement(e, "event")}
                                            className={"select_secondary"}
                                            placeholder={t("integrations:channels.chatgpt.message.event_placeholder")}
                                            value={"event"}
                                            type={"chatgpt_event"}
                                            t={t}
                                        />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        data: state
    };
};

const create = connect(
    mapStateToProps
)(Create);

export default withTranslation(["commons", "integrations", "common"])(withRouter(create));