// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter } from "react-router-dom";
// Styles
import "date-fns";
// Translations
import { withTranslation } from "react-i18next";
import enLocale from "date-fns/locale/en-US";
import esLocale from "date-fns/locale/es";
import ptLocale from "date-fns/locale/pt";
// Infrastructure
import { ProjectAPI } from "api/application/Projects";
// Vendor
import { showNotify } from "vendor/application/disptach";
// Components
import { Divider } from "@material-ui/core";
import { AlertInfo } from "@components/Alert";
import { Button } from "@components/Input";
// Material
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// Svg
import { svgCalendar, svgClock, deploySvg } from "assets";

const localeMap = {
  en: enLocale,
  es: esLocale,
  pt: ptLocale
};

class StatsNumeric extends Component {
  constructor(props) {
    super(props);
    var date = new Date();
    this.state = {
      dateRange: {},
      start: false,
      end: false,
      objDateRange: {
        startDate: new Date(date.setMonth(date.getMonth() - 1)),
        endDate: new Date(date.setMonth(date.getMonth() + 1))
      }
    };
  }

  doAction = async () => {
    const { access, actualProject } = this.props.data;
    try {
      const obj = new ProjectAPI(actualProject.id, access, this.props, {
        action: "request_statistics",
        info: {
          from: this.getStartDate(),
          to: this.getEndDate()
        }
      });
      await obj.do_actions();

      this.props.dispatch(
        showNotify({
          message: "project",
          type: "request_statistics",
          severity: "success"
        })
      );
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  // update objDateRange start
  handleSelectStart = (start) => {
    var end = this.state.objDateRange.endDate;
    if (start > end) {
      end = start;
    }
    this.setState({
      objDateRange: {
        startDate: start,
        endDate: end
      }
    });
  };

  // update initalDateRange end
  handleSelectEnd = (end) => {
    var start = this.state.objDateRange.startDate;
    if (end < start) {
      start = end;
    }
    this.setState({
      objDateRange: {
        startDate: start,
        endDate: end
      }
    });
  };

  getStartDate = (visibility) => {
    const { objDateRange } = this.state;
    var m = objDateRange.startDate.getMonth(),
      y = objDateRange.startDate.getFullYear(),
      d = objDateRange.startDate.getDate();

    if (visibility) {
      return `${d} / ${m + 1} / ${y}`;
    }
    return `${y}-${m + 1}-${d}`;
  };

  getEndDate = (visibility) => {
    const { objDateRange } = this.state;
    var m = objDateRange.endDate.getMonth(),
      y = objDateRange.endDate.getFullYear(),
      d = objDateRange.endDate.getDate();

    if (visibility) {
      return `${d} / ${m + 1} / ${y}`;
    }
    return `${y}-${m + 1}-${d}`;
  };

  openStart = () => {
    this.setState({ start: !this.state.start });
  };

  openEnd = () => {
    this.setState({ end: !this.state.end });
  };

  render() {
    const { t } = this.props;
    const { objDateRange, start, end } = this.state;

    return (
      <div className="stats-container">
        <AlertInfo className={"app_bar"} text={t("stats.info_1")} />
        <Divider />
        <AlertInfo className={"app_bar"} text={t("stats.numeric.info")} />
        <Divider />
        <div className="stats-header">
          <div className="stats-date-container">
            <div className="stats-date">
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={localeMap[this.props.i18n.language]}
              >
                <div className="start-date" onClick={() => this.openStart()}>
                  <div>
                    <span>{svgCalendar()}</span>
                    <span>{t("stats.start")}</span>
                    <span>{deploySvg(false)}</span>
                  </div>
                  <div>
                    <span>{svgClock()}</span>
                    <span>{this.getStartDate(true)}</span>
                  </div>
                </div>
                <DatePicker
                  value={objDateRange.startDate}
                  onChange={this.handleSelectStart}
                  animateYearScrolling
                  autoOk
                  onClose={this.openStart}
                  open={start}
                />
                <div className="end-date" onClick={() => this.openEnd()}>
                  <div>
                    <span>{svgCalendar()}</span>
                    <span>{t("stats.end")}</span>
                    <span>{deploySvg(false)}</span>
                  </div>
                  <div>
                    <span>{svgClock()}</span>
                    <span>{this.getEndDate(true)}</span>
                  </div>
                </div>
                <DatePicker
                  value={objDateRange.endDate}
                  onChange={this.handleSelectEnd}
                  animateYearScrolling
                  autoOk
                  onClose={this.openEnd}
                  open={end}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>

          <div className="actions">
            <Button
              type={"primary"}
              text={t("stats.numeric.action_button")}
              onClick={(e) => this.doAction(e)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const connect_stats_statistics = connect(mapStateToProps)(StatsNumeric);

export default withTranslation("common")(withRouter(connect_stats_statistics));
