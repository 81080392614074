// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Translations
import {withTranslation} from "react-i18next";
// Styles
import "components/Accordion/styles/styles.scss";
// Components
import IntegrationCard from "views/Bots/Integrations/application/channels/InstagramChannels/integration_card";
import Centridesk from "views/Bots/Integrations/application/channels/InstagramChannels/centridesk";
import ZendeskFull from "views/Bots/Integrations/application/channels/InstagramChannels/zendesk_full";
// Material UI
import {Divider} from "@material-ui/core";

class Integrations extends Component {
    handleChannel = (channel) => {
        switch (channel) {
            case "zendesk_full":
                return <ZendeskFull {...this.props} />;
            case "centridesk":
                return <Centridesk {...this.props} />;
            default:
                break;
        }
    };

    render() {
        const {integrations, body, t} = this.props;
        const desk_name = body.info.story_replies.desks?.name;
        return (
            integrations.length > 0 &&
            <div>
                <Divider/>
                <div className={"intents__elements"}>
                    <div className={"intents__element grid"}>
                        <div className={"intents__element_header"}>
                            <span>{t("common:integrations.instagram.story_replies.description")}</span>
                        </div>
                        <div className={"elements__integrations"}>
                            {
                                integrations.map((integration, i) => {
                                    return (
                                        <IntegrationCard key={i} {...this.props} integration={integration}/>);
                                })
                            }
                        </div>
                        {
                            ["zendesk_full", "centridesk", "sunco", "chatgpt", "gpt-3"].includes(desk_name) &&
                            <React.Fragment>
                                <Divider/>
                                {this.handleChannel(desk_name)}
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const integrations_intent = connect(
    mapStateToProps
)(Integrations);

export default withTranslation(["common", "languages"])(integrations_intent);