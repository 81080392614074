// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Translations
import {withTranslation} from "react-i18next";
// Styles
import "components/Accordion/styles/styles.scss";
// Components
import {Button, Input} from "@components/Input";
import {deleteSvg} from "../../../../assets";

class Headers extends Component {
    addHeader = () => {
        const {intent, updateIntent} = this.props;
        intent.extensions.headers.push({
            "key": "",
            "value": ""
        });

        updateIntent(intent);
    };

    deleteHeader = (i) => {
        const {intent, updateIntent} = this.props;
        intent.extensions.headers.splice(i, 1);
        updateIntent(intent);
    };

    updateHeader = (e, type, i) => {
        const {intent, updateIntent} = this.props;
        intent.extensions.headers[i][type] = e.target.value;

        updateIntent(intent);
    };

    render() {
        const {intent, t} = this.props;
        return (
            <div className={"accordion__element_extension_headers"}>
                {
                    intent.extensions.headers.length > 0 &&
                        intent.extensions.headers.map((header, i) => {
                          return (
                              <div key={i} className={"accordion__element_extension_headers_element"}>
                                  <Input
                                      placeholder={t("intents.config.accordion.extensions.content.headers.key")}
                                      onChange={(e) => this.updateHeader(e, "key", i)}
                                      value={header.key ? header.key : ""}
                                  />
                                  <Input
                                      placeholder={t("intents.config.accordion.extensions.content.headers.value")}
                                      onChange={(e) => this.updateHeader(e, "value", i)}
                                      value={header.value ? header.value : ""}
                                  />
                                  <div onClick={() => this.deleteHeader(i)} className={"accordion__element_extension_headers_element_delete"}>
                                      {deleteSvg()}
                                  </div>
                              </div>
                          );
                        })
                }
                <Button type={"dashed"} onClick={(e) => {
                    this.addHeader(e);
                }}
                        text={t("intents.config.accordion.extensions.content.headers.button")}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const headers = connect(
    mapStateToProps
)(Headers);

export default withTranslation("common")(headers);