// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Routing
import {withRouter, Route, Switch} from "react-router-dom";
// Views
import Stats from "views/Bots/Stats/application";
import Integrations from "views/Bots/Integrations/application/integrations";
import NotFound from "components/NotFound/application/404";
import RouteConfig from "routes/application/route_config";
import RouteIntegrations from "routes/application/route_integrations";
import UpgradePlan from "components/Plan/application/upgrade_plan";
// Application
import {updateActualLayout} from "vendor/application/disptach";

class RouteMain extends Component {
    // method that selects which layout should display.
    // if the name of the layout that the user is allowed to access is the same as the one selected, the permissions are added in true
    // else redirects to the not found page
    setLayoutList = (layout, project) => {
        const {actualProject, plan, user} = this.props.data;

        setTimeout(() => {
            this.setLayout(layout, project);
        }, 10);
        var permissions = true;

        if (user?.role.name === "Trainer" && layout !== "training") {
            permissions = false;
        }

        if (permissions) {
            switch (layout) {
                case "stats":
                    if (plan.plan.name !== "free_trial") {
                        return (
                            <Stats project={project} actualProject={actualProject}/>
                        );
                    } else {
                        return (
                            <UpgradePlan />
                        );
                    }
                case "integrations":
                    return (
                        <Integrations project={project} actualProject={actualProject}/>
                    );
                default:
                    return (
                        <NotFound/>
                    );
            }
        } else {
            switch (layout) {
                case "config":
                case "stats":
                case "integrations":
                    return (
                        <NotFound type={"layout"}/>
                    );
                default:
                    return (
                        <NotFound/>
                    );
            }
        }
    };

    setLayout = (layout, project) => {
        const {actualLayout} = this.props.data;
        if (actualLayout.layout !== layout || actualLayout.project !== project) {
            var data = {layout: layout, project: project};
            this.props.dispatch(updateActualLayout(data));
        }
    };

    render() {
        const {match} = this.props;
        return (
            <Switch>
                <Route path="/:project/:layout" exact>
                    {this.setLayoutList(match.params.layout, match.params.project)}
                </Route>
                <Route path="/:project/integrations/:channel">
                    <RouteIntegrations/>
                </Route>
                <Route path="/:project/:layout/:action" exact>
                    <RouteConfig getLimits={this.props.getLimits}/>
                </Route>
            </Switch>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const connect_select_project = connect(
    mapStateToProps
)(RouteMain);

export default withRouter(connect_select_project);