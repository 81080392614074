// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter } from "react-router-dom";
// Material UI
import { Divider } from "@material-ui/core";
// Vendor
import { ElementsCustom } from "@components/Elements";
// Translations
import { withTranslation } from "react-i18next";
// Styles
import "views/Bots/ChatBot/styles/index.scss";

class Webhook extends Component {
  handleCopy = (e, copyText) => {
    e.stopPropagation();
    navigator && navigator.clipboard && navigator.clipboard.writeText(copyText);
  };

  render() {
    const { t, webhook, body } = this.props;
    return (
      <div className={"campaigns_container_webhook"}>
        <div>
          <ElementsCustom
            t={t}
            type={"webhook"}
            input_type={"input_copy"}
            element_type={"url"}
            element={webhook?.url ?? ""}
            disabled={true}
            actionElement={this.handleCopy}
          />
          <Divider />
          <ElementsCustom
            t={t}
            type={"webhook"}
            input_type={"input"}
            element_type={"method"}
            disabled={true}
            element={"POST"}
          />
          <Divider />
          <ElementsCustom
            t={t}
            type={"webhook"}
            input_type={"input"}
            element_type={"auth"}
            disabled={true}
            element={"Bearer"}
          />
          <Divider />
          <ElementsCustom
            t={t}
            type={"webhook"}
            input_type={"input_copy"}
            element_type={"token"}
            disabled={true}
            element={webhook?.token ?? ""}
            actionElement={this.handleCopy}
          />
          <Divider />
          <ElementsCustom
            t={t}
            type={"webhook"}
            input_type={"input_copy"}
            element_type={"body"}
            multiline={true}
            element={body}
            disabled={true}
            actionElement={this.handleCopy}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const connect_component = connect(mapStateToProps)(Webhook);

export default withTranslation(["commons", "common"])(
  withRouter(connect_component)
);
