// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter } from "react-router-dom";
// Application
import { ElementsHandleV2 } from "vendor/application/handleMethodsV2";
// Vendor
import {
  getIntentBody,
  handlePhrasesParams,
  isJsonString,
  isPlanAllowed,
} from "vendor/application";
// Material UI
import { CircularProgress, Divider, Switch } from "@material-ui/core";
// Components
import { Button, Input } from "@components/Input";
import Tooltip from "@components/Tooltip";
import AccordionIntents from "components/Accordion/AccordionIntents";
import Integrations from "views/Bots/NLP/application/Build/Tabs/Intents/application/Integrations";
// Translations
import { withTranslation } from "react-i18next";
import { showNotify } from "vendor/application/disptach";
// API
import { ContextsAPI } from "views/Bots/NLP/application/Build/Tabs/Contexts/infrastructure";
import { IntentsAPI } from "views/Bots/NLP/application/Build/Tabs/Intents/infrastructure";
import { IntegrationsAPI } from "api/application/Integrations";
import { get_users } from "api/application/Users";
// Styles
import "../styles/index.scss";
// Amplitude API
import { AmplitudeAPI } from "@api/Amplitude";
import { parseVariablesDeskCreate } from "./utils/parseVariablesCreate";
import { ElementsCustom } from "@components/Elements";
import { validExtensions } from "./utils/validExtensions";

class IntentCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contexts: "",
      intent: getIntentBody(),
      integrations: false,
      use_custom_fields: false,
      zendesk_groups: false,
      switchboard: false,
      limits: props.data.limits,
      params: "",
      users: [],
      events: [],
      variables: [],
      settings: {
        tags: "",
        tags_hours_control: "",
        tags_individual: "",
        tags_campaign: "",
      },

      zendesk: "",
    };
  }
  componentDidMount = () => {
    this.initServices();
  };
  initServices = async () => {
    this.getContexts();
    this.getParams();
    this.getEvents();
    this.getIntegrations();
  };

  getContexts = async () => {
    const { access, actualProject } = this.props.data;
    try {
      let obj = new ContextsAPI(actualProject.id, access, this.props);
      let contexts = await obj.get_contexts();
      this.setState({ contexts: contexts });
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  getParams = async () => {
    const { access, actualProject } = this.props.data;
    try {
      const obj = new IntentsAPI(actualProject.id, access, this.props);
      const entities = await obj.get_entities();
      this.setState({ params: entities.custom.concat(entities.system) });
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  getEvents = async () => {
    const { access, actualProject } = this.props.data;
    try {
      const obj = new IntentsAPI(actualProject.id, access, this.props);
      const events = await obj.get_events();
      var events_arr = [];
      events.forEach((event) => {
        var new_events = [];
        event.events.forEach((evt) => {
          new_events.push({
            intent_id: event.id,
            event: evt,
          });
        });

        var group_events = {
          group_name: event.name,
          items: new_events,
        };

        events_arr.push(group_events);
      });

      this.setState({ events: events_arr });
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  getIntegrations = async () => {
    const { access, actualProject, limitsIntegrations } = this.props.data;
    const { intent } = this.state;
    try {
      const obj = new IntegrationsAPI(actualProject.id, access, this.props);
      const res = await obj.get_integrations();
      var integrations = [];

      res.forEach((integration) => {
        var exist = integrations.filter(
          (int) => int.channel === integration.channel
        );
        if (
          [
            "zendesk_full",
            "zendesk_chat",
            "centridesk",
            "sunco",
            "gpt-3",
          ].includes(integration.channel) &&
          exist.length <= 0
        ) {
          if (limitsIntegrations[integration.channel]?.multiple) {
            var multiple = res.filter(
              (int) => int.channel === integration.channel
            );
            if (multiple.length > 0) {
              integration.multiple = [];
              multiple.forEach((m) =>
                integration.multiple.push({
                  id: m.id,
                  name: m.name ?? m.channel,
                  use_custom_fields: m.info.use_custom_fields,
                })
              );
            }
          }
          integrations.push(integration);
        }
      });

      var switchboard = {
        updated: false,
        array: [],
      };

      var sunco = integrations.filter(
        (integration) => integration.channel === "sunco"
      )[0];
      if (sunco?.active && sunco?.info?.switchboard.updated) {
        switchboard.updated = true;
        this.getSwitchboard(sunco);
      }

      var chatgpt = integrations.filter(
        (integration) => integration.channel === "gpt-3"
      )[0];
      if (chatgpt?.active) {
        intent.desks.info.max_tokens = chatgpt.info.max_tokens;
        intent.desks.info.max_interactions =
          chatgpt.info.back_to_bot.max_interactions;
      }

      var centridesk = integrations.filter(
        (integration) => integration.channel === "centridesk"
      )[0];
      if (centridesk) {
        this.getUsers();
      }

      this.setState({ integrations: integrations, switchboard: switchboard });
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  getSwitchboard = async (sunco) => {
    const { actualProject, access } = this.props.data;
    try {
      const obj = new IntegrationsAPI(actualProject.id, access, this.props);
      const res = await obj.get_integration_switchboard_by_id(sunco.id);

      this.setState({ switchboard: { updated: true, array: res } });
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  getGroups = async (channel_id) => {
    const { access, actualProject } = this.props.data;
    try {
      const obj = new IntegrationsAPI(actualProject.id, access, this.props);
      var res = await obj.get_zendesk_groups(channel_id);
      this.setState({ zendesk_groups: res });
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  getCustomFields = async (type, channel_id) => {
    const { access, actualProject } = this.props.data;
    var { use_custom_fields } = this.state;
    try {
      const obj = new IntegrationsAPI(actualProject.id, access, this.props);
      const res = await obj.custom_fields(channel_id);

      if (!use_custom_fields) {
        use_custom_fields = {
          [channel_id]: res,
        };
      } else {
        use_custom_fields[channel_id] = res;
      }
      this.setState({ use_custom_fields: use_custom_fields });
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  getUsers = async () => {
    get_users(this.props).then((users) => {
      this.setState({ users: users });
    });
  };

  updateIntent = (new_intent) => {
    this.setState({ intent: new_intent });
  };

  updateContexts = (new_contexts) => {
    this.setState({ contexts: new_contexts });
  };

  updateParams = (new_param) => {
    const { params } = this.state;
    params.unshift(`@${new_param.name}`);
    this.setState({ params: params });
  };

  updateVariables = (new_variables) => {
    this.setState({ variables: new_variables });
  };

  updateElement = (e, type, type_of, i) => {
    const { intent } = this.state;
    const obj = new ElementsHandleV2(
      intent,
      e?.target?.value,
      type,
      type_of,
      i
    );
    var res = obj.handleElement();

    if (type_of === "delete") {
      switch (type) {
        case "responses":
        case "phrases":
          this.handleLimits(type, "delete", res);
          break;
        case "extension":
          this.setState({ intent: res, variables: [] });
          break;
        case "conditional_responses":
        case "parameters":
          this.setState({ intent: res });
          break;
      }
    } else {
      if (res.is_default && res.phrases.length > 0) {
        res.phrases = [];
        this.setState({ limits: this.props.data.limits, intent: res });
      } else {
        this.setState({ intent: res });
      }
    }

    if (type.includes("extension")) {
      e.stopPropagation();
    }
  };

  handleLimits = (type, action, intent) => {
    const { limits } = this.state;
    switch (action) {
      case "delete":
        switch (type) {
          case "responses":
          case "phrases":
            limits[type].total = limits[type].total - 1;
            limits.intents_create[`intents_${type}_total`] =
              limits.intents_create[`intents_${type}_total`] !== 0 &&
              limits.intents_create[`intents_${type}_total`] - 1;
            break;
        }
        break;
      case "add":
        break;
    }

    this.setState({ limits: limits, intent: intent });
  };

  createIntent = async () => {
    const { intent, variables } = this.state;
    const { access, actualProject, user, device_id } = this.props.data;
    const extensionsExist = intent.extensions && intent.extensions.url;
    const initialPayload = intent.extensions.body?.payload;
    let updatedPayload;

    if (initialPayload && typeof initialPayload === "string") {
      updatedPayload = initialPayload.replace(
        /:\s*(\$\.\w+(\.\w+)*\b)/g,
        ': "$1"'
      );
    } else {
      updatedPayload = initialPayload;
    }

    if (!validExtensions(variables, this.props.dispatch)) {
      return;
    }
    if (extensionsExist && !intent.extensions.timeout) {
      intent.extensions.timeout = 15;
    }

    if (intent.extensions.method) {
      intent.extensions.variables = this.handleAddVariables(variables);
      intent.extensions.equivalences = this.handleAddEquivalences(variables);
    }

    var value = true,
      body = intent.extensions.body?.payload;
    const payload = updatedPayload && updatedPayload ? updatedPayload : body;

    if (
      intent.extensions.body?.type === "application/json" &&
      payload &&
      !isJsonString(payload)
    ) {
      value = false;
    }

    if (!value) {
      this.props.dispatch(
        showNotify({
          message: "extension_body_has_an_invalid_value",
          severity: "error",
        })
      );
    } else if (handlePhrasesParams(intent, this.props.dispatch)) {
      var send_intent = JSON.parse(JSON.stringify(intent));

      send_intent.parameters.forEach((param, i) => {
        if (param.zendesk_ticket_field) {
          delete send_intent.parameters[i].zendesk_ticket_field
            .custom_field_options;
          delete send_intent.parameters[i].zendesk_ticket_field
            .custom_field_options_actual;
        }

        if ( param.zendesk_ticket_field && (
          param.zendesk_ticket_field.desk_id === null ||
          (param.zendesk_ticket_field.desk_id && !param.zendesk_ticket_field.id)
        )) {
          send_intent.parameters[i].zendesk_ticket_field = {};
        }
      });

      if (send_intent.extensions.variables_desk) {
        send_intent.extensions.variables_desk = parseVariablesDeskCreate(
          send_intent.extensions.variables_desk
        );
      }

      var zendesk_ticket_status = send_intent.desks.info.zendesk_ticket_status,
        zendesk_mode = send_intent.desks.info.zendesk_mode,
        centridesk_ticket_status =
          send_intent.desks.info.centridesk_ticket_status,
        centridesk_mode = send_intent.desks.info.centridesk_mode;

      if (send_intent.desks.name === "zendesk_full") {
        send_intent.desks.info.ticket_status = zendesk_ticket_status;
        send_intent.desks.info.mode = zendesk_mode;
      } else if (send_intent.desks.name === "centridesk") {
        send_intent.desks.info.ticket_status = centridesk_ticket_status;
        send_intent.desks.info.mode = centridesk_mode;
      }

      if (send_intent.desks.name === "gpt-3") {
        send_intent.desks.info = {
          max_tokens: intent.desks.info.max_tokens,
          max_interactions: intent.desks.info.max_interactions,
        };
      } else {
        delete send_intent.desks.info.max_tokens;
        delete send_intent.desks.info.max_interactions;
      }

      if (send_intent.conditional_responses.length > 0) {
        send_intent.conditional_responses.forEach((conditional) => {
          delete conditional.param0;
        });
      }

      delete send_intent.desks.info.zendesk_ticket_status;
      delete send_intent.desks.info.centridesk_ticket_status;
      delete send_intent.desks.info.zendesk_mode;
      delete send_intent.desks.info.centridesk_mode;

      if (send_intent.extensions.auth) {
        delete send_intent.extensions.auth.access_key_input;
      }

      if (!send_intent.desks?.name) {
        send_intent.desks = null;
      }

      if (
        send_intent.desks?.name === "zendesk_full" &&
        !send_intent.desks?.info.desk_id
      ) {
        this.props.dispatch(
          showNotify({ message: "no_desk_id_intent", severity: "error" })
        );
        return;
      }

      try {
        delete send_intent.id;
        const obj = new IntentsAPI(
          actualProject.id,
          access,
          this.props,
          send_intent
        );
        await obj.post_intent();

        new AmplitudeAPI({
          event_type: "Intent created",
          device_id: device_id,
          user_id: user.email,
        }).eventLog();

        this.props.dispatch(
          showNotify({
            message: "intents",
            type: "create",
            severity: "success",
          })
        );
        this.returnToList();
      } catch (err) {
        this.props.dispatch(showNotify({ message: err, severity: "error" }));
      }
    }
  };

  handleAddVariables = () => {
    var obj = [],
      variables = this.state.variables;

    variables.forEach((el) => {
      if (el.variable) {
        obj.push(`$.${el.variable}`);
      }
    });

    return obj;
  };

  handleAddEquivalences = () => {
    var obj = {},
      variables = this.state.variables,
      value = {};
    variables.forEach((item, i) => {
      if (item.variable) {
        var element = `$.${item.variable}`;
        if (value) {
          obj = { [element]: this.handleEquivalence(item.equivalence), ...obj };
          this.state.variables[i].error = false;
          value = obj;
        } else {
          this.state.variables[i].error = true;
          value = false;

          this.props.dispatch(
            showNotify({
              message: "extension_equivalences_has_an_invalid_value",
              severity: "error",
            })
          );
        }
      } else {
        this.state.variables[i].error = true;
        value = false;

        this.props.dispatch(
          showNotify({
            message: "extension_variable_can_not_be_an_empty_string",
            severity: "error",
          })
        );
      }
    });

    this.setState({ variables: this.state.variables });

    return value;
  };

  handleEquivalence = (equivalences) => {
    var obj = [];
    equivalences.forEach((equivalence) => {
      obj = { [equivalence.key]: equivalence.value, ...obj };
    });

    return obj;
  };

  returnToList = () => {
    const { location, history, handleTabs } = this.props;
    history.push(`${location.pathname}?tab=intents`);
    handleTabs();
  };

  handleTags = (e, type, elementType, chipToDelete, i) => {
    const { settings, intent } = this.state;
    var element_to_update;

    switch (elementType) {
      case "tags":
        element_to_update = intent?.tags ? intent.tags : [];
        break;
      default:
    }

    var tag = e.target.value;
    switch (type) {
      case "add":
        if (tag.match(/^[a-zA-Z0-9_\/]*$/)) {
          settings[elementType] = tag;
          this.setState({ settings: settings });
        }
        break;
      case "delete":
        element_to_update.forEach((el) => {
          if (el === chipToDelete) {
            element_to_update.splice(i, 1);
          }
        });
        this.setState({ intent: intent });
        break;
      case "enter":
        element_to_update.push(tag);
        settings[elementType] = "";
        this.setState({ intent: intent, settings: settings });
        break;
      default:
    }
  };

  render() {
    const {
      contexts,
      limits,
      intent,
      params,
      variables,
      use_custom_fields,
      zendesk_groups,
      switchboard,
      integrations,
      events,
      settings,
    } = this.state;
    const { isSending, plan } = this.props.data;
    const { t } = this.props;
    return intent && contexts ? (
      <div>
        <div className={"cen_card sticky"}>
          <div className={"header"}>
            <div className={"header_content"}>
              <div className={"header_title"}>
                <span className={"title"}>
                  {t("common:intents.title_create")}
                </span>
              </div>
            </div>
            <div className={"header_actions"}>
              <Button
                type={"cancel"}
                onClick={(e) => this.returnToList(e)}
                text={t("common:intents.buttons.cancel")}
              />
              {isSending ? (
                <Button
                  type={"primary"}
                  disabled
                  text={<CircularProgress size={21} />}
                />
              ) : (
                <Button
                  type={"primary"}
                  onClick={(e) => {
                    this.createIntent();
                  }}
                  text={t("common:intents.buttons.create")}
                />
              )}
            </div>
          </div>
        </div>
        {/* INFO */}
        <div className={"cen_card intents__content"}>
          <div className={"content"}>
            <div className={"intents__elements"}>
              <div className={"intents__elements_column"}>
                <div>
                  {/* INTENT NAME */}
                  <div className={"intents__element"}>
                    <div className={"intents__element_header"}>
                      <span>{t("common:intents.name.title")}</span>
                    </div>
                    <Input
                      id={"intent-name"}
                      placeholder={t("common:intents.name.placeholder")}
                      value={intent.name}
                      onChange={(e) => {
                        this.updateElement(e, "name", "string");
                      }}
                    />
                  </div>
                  <Divider />
                  {/* INTENT DESCRIPTION */}
                  <div className={"intents__element"}>
                    <div className={"intents__element_header"}>
                      <span>{t("common:intents.descript.title")}</span>
                    </div>
                    <Input
                      id={"intent-description"}
                      placeholder={t("common:intents.descript.placeholder")}
                      multiline
                      inputProps={{ maxLength: 4096 }}
                      value={intent.description}
                      onChange={(e) => {
                        this.updateElement(e, "description", "string");
                      }}
                    />
                  </div>
                </div>
                <Divider className={"intents__elements_column_divider"} />
                {/* INTENT SWITCH */}
                <div className={"intents__element grid"}>
                  <div className={"intents__element_header"}>
                    <span>{t("common:intents.options.title")}</span>
                  </div>
                  {/* IS DEFAULT */}
                  <div className={"elements__switch"}>
                    <span>
                      {t("common:intents.options.switch.is_default.title")}{" "}
                      <Tooltip
                        text={t(
                          "common:intents.options.switch.is_default.description"
                        )}
                      />{" "}
                    </span>
                    <Switch
                      checked={intent.is_default ? intent.is_default : false}
                      onClick={(e) => {
                        this.updateElement(e, "is_default", "bool");
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ACCORDIONS */}
        <div className={"cen_card intents__content"}>
          <div className={"header"}>
            <div className={"header_content"}>
              <div className={"header_title"}>
                <span className={"title"}>
                  {t("common:intents.config.title")}
                </span>
              </div>
            </div>
          </div>
          <div className={"content"} style={{ overflow: "visible" }}>
            <div className={"intents__elements"}>
              <div className={"intents__element grid"}>
                <div className={"elements__accordion"}>
                  {/* PARAMS */}
                  {isPlanAllowed(plan.plan.name) && (
                    <AccordionIntents
                      expanded
                      intent={JSON.parse(JSON.stringify(intent))}
                      type={"params"}
                      params={JSON.parse(JSON.stringify(params))}
                      updateElement={this.updateElement}
                      updateIntent={this.updateIntent}
                      updateParams={this.updateParams}
                      integrations={integrations}
                      getCustomFields={this.getCustomFields}
                      use_custom_fields={
                        use_custom_fields
                          ? JSON.parse(JSON.stringify(use_custom_fields))
                          : null
                      }
                    />
                  )}
                  {/* PHRASES */}
                  <AccordionIntents
                    contexts={JSON.parse(JSON.stringify(contexts))}
                    expanded
                    limits={limits}
                    intent={JSON.parse(JSON.stringify(intent))}
                    type={"phrases"}
                    updateElement={this.updateElement}
                    updateIntent={this.updateIntent}
                    updateContexts={this.updateContexts}
                  />
                  {/* EXTENSIONS */}
                  {isPlanAllowed(plan.plan.name) && (
                    <AccordionIntents
                      expanded
                      intent={JSON.parse(JSON.stringify(intent))}
                      type={"extensions"}
                      params={JSON.parse(JSON.stringify(params))}
                      updateElement={this.updateElement}
                      updateIntent={this.updateIntent}
                      updateVariables={this.updateVariables}
                      updateParams={this.updateParams}
                      variables={variables}
                      getCustomFields={this.getCustomFields}
                      integrations={integrations}
                      use_custom_fields={
                        use_custom_fields
                          ? JSON.parse(JSON.stringify(use_custom_fields))
                          : null
                      }
                    />
                  )}
                  {/* RESPONSES */}
                  <AccordionIntents
                    contexts={JSON.parse(JSON.stringify(contexts))}
                    expanded
                    limits={limits}
                    intent={JSON.parse(JSON.stringify(intent))}
                    type={"responses"}
                    events={events}
                    updateElement={this.updateElement}
                    updateIntent={this.updateIntent}
                    updateContexts={this.updateContexts}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* INTEGRATIONS */}
        {integrations && (
          <div className={"cen_card intents__content"}>
            <div className={"header"}>
              <div className={"header_content"}>
                <div className={"header_title"}>
                  <span className={"title"}>
                    {t("common:intents.integrations.title")}
                  </span>
                </div>
              </div>
            </div>
            <div className={"content"}>
              <div
                className={`elements__integrations ${
                  integrations.some(
                    (integration) =>
                      integration.channel !== "zendesk_full" ||
                      integration.channel !== "sunco"
                  )
                    ? "elements__integrations--no-grid"
                    : ""
                }`}
              >
                {integrations.some(
                  (integration) =>
                    integration.channel === "zendesk_full" ||
                    integration.channel === "sunco"
                ) && (
                  <div>
                    <ElementsCustom
                      t={t}
                      type={"zendesk"}
                      input_type={"input_with_tags"}
                      element_type={"tags"}
                      element={settings.tags}
                      elements={intent.tags}
                      updateElement={this.handleTags}
                    />
                  </div>
                )}
              </div>

              <Integrations
                zendesk_groups={zendesk_groups}
                intent={JSON.parse(JSON.stringify(intent))}
                switchboard={switchboard}
                integrations={integrations}
                updateIntent={this.updateIntent}
                getGroups={this.getGroups}
              />
            </div>
          </div>
        )}
      </div>
    ) : (
      <div className="card">
        <div className="loading-data">
          <CircularProgress color="primary" size={70} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state,
  };
};

const connect_intent_create = connect(mapStateToProps)(IntentCreate);

export default withTranslation(["commons", "common"])(
  withRouter(connect_intent_create)
);
