// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter } from "react-router-dom";
// Application
import { updateActualLayout } from "vendor/application/disptach";
// Icons
import InfoIcon from "@material-ui/icons/Info";
// Translations
import { withTranslation } from "react-i18next";

class NotFound extends Component {

    componentDidMount() {
        this.setLayout();
    }

    setLayout = () => {
        const { actualLayout } = this.props.data;
        if ( actualLayout.project !== "404" ) {
            var data = { layout : "project", project : "404" };
            this.props.dispatch(updateActualLayout(data));
        }
    };

    render() {
        const { type, t } = this.props;
        return (
            <React.Fragment>
                {
                    type === "layout" ? (
                        <div className="permissions-access">
                            <span>{t("card.permissions.no_permissions")}</span>
                            <InfoIcon />
                        </div>
                    ) :
                    (
                        <div className="no-page-found">
                            <span>{"4 0 4"}</span>
                            <span>{t("card.permissions.not_found")}</span>
                            <InfoIcon />
                        </div>
                    )
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const connect_not_found = connect(
    mapStateToProps
  )(NotFound);

export default withTranslation("common")(withRouter(connect_not_found));