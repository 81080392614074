// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Routing
import {withRouter} from "react-router-dom";
// Translations
import {withTranslation} from "react-i18next";
// Material
import {CircularProgress} from "@material-ui/core";
// Application
import {updateActualLayout} from "vendor/application/disptach";
import {handleActualTitle} from "@helpers";
// Styles
import "../styles/index.scss";

class Academy extends Component {

    componentDidMount() {
        handleActualTitle(this.props.t, "academy");
        this.setLayout();
    }

    // update the status of the layout if it is not equal to the one that already exists
    setLayout = () => {
        const {actualLayout} = this.props.data;
        if (actualLayout.layout !== "academy") {
            var data = {layout: "academy", project: actualLayout.project};
            this.props.dispatch(updateActualLayout(data));
        }
    };

    getAcademyLang = () => {
        const {user} = this.props.data;
        let lang = user.lang;
        switch (lang) {
            case "en":
                return "https://academy.centribal.com/en/home/";
            case "pt":
                return "https://academy.centribal.com/pt/home-pt/";
            case "es":
            default:
                return "https://academy.centribal.com/";
        }
    };
    render() {
        return (
            <div className={"centribal_academy"}>
                <iframe className={"academy_iframe"} src={this.getAcademyLang()} allowFullScreen/>
                <div className={"centribal_academy_loading"}>
                    <CircularProgress size={80}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const connect_academy = connect(
    mapStateToProps
)(Academy);

export default withTranslation("common")(withRouter(connect_academy));