// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Routing
import {withRouter} from "react-router-dom";
// Styles
import "date-fns";
// Translations
import {withTranslation} from "react-i18next";
// Styles
import "./styles.scss";

class WhatsappSessions extends Component {

    render() {
        const {t, whatsapp_sessions} = this.props;
        return (
            <div>
                <div className={"ws_header"}>{whatsapp_sessions.month}</div>
                <div className={"ws_subheader"}>
                    <div><span>{"Total"}</span></div>
                    <div><span>{whatsapp_sessions.total}</span></div>
                </div>
                <div className={"ws_table"}>
                    <div className={"ws_table_header"}>
                        <div>
                            <span>{t("stats.consumption.whatsapp_sessions.day")}</span>
                        </div>
                        <div>
                            <span>{t("stats.consumption.whatsapp_sessions.hour")}</span>
                        </div>
                        <div>
                            <span>{t("stats.consumption.whatsapp_sessions.phone")}</span>
                        </div>
                    </div>
                    <div className={"ws_table_body"}>
                        {
                            whatsapp_sessions.details?.map(((detail, i) => {
                                return (
                                    <div key={i} className={"ws_table_body__element"}>
                                        <span>{detail.day}</span>
                                        <span>{detail.time}</span>
                                        <span>{detail.phone}</span>
                                    </div>
                                );
                            }))
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const connect_whatsapp_sessions = connect(
    mapStateToProps
)(WhatsappSessions);

export default withTranslation("common")(withRouter(connect_whatsapp_sessions));