// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter } from "react-router-dom";
// Infrastructure
import { EntitiesAPI } from "views/Bots/NLP/application/Build/Tabs/Entities/infrastructure";
// Application
import { ElementsHandleAPI } from "vendor/application/handleMethods";
// Vendor
import {
  getEntityBody,
  getEntityTypes,
  getEntityEntrieBody
} from "vendor/application";
import { showNotify } from "vendor/application/disptach";
// Material UI
import {
  IconButton,
  CircularProgress,
  FormControl,
  FormHelperText,
  TextField,
  Divider,
  Chip
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
// Components
import { Button, SelectSimple, Input } from "@components/Input";
// SVG
import { deleteSvg } from "assets";
// Translations
import { withTranslation } from "react-i18next";
// Amplitude API
import { AmplitudeAPI } from "@api/Amplitude";
// Styles
import "../styles/index.scss";

class EntityCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entity: "",
      errorName: false
    };
  }

  componentDidMount = () => {
    this.getEntity();
  };

  updateElement(e, type, i, output) {
    const { entity } = this.state;

    const obj = new ElementsHandleAPI(entity, e.target.value, type, i, output);
    const res = obj.updateObj();
    this.setState({ entity: res });

    if (type === "name") {
      this.handleNameEntityError(e.target.value);
    }
  }

  addSynonym = (e, i) => {
    e.preventDefault();
    const { entity } = this.state;

    if (entity.entries[i].synonyms.length === 0 && e.target.value.length > 0) {
      entity.entries[i].synonyms.push(e.target.value);
      this.setState({ entity: entity });
    }
  };

  createEntity = async (e) => {
    e.preventDefault();
    const { entity } = this.state;
    const { access, actualProject, device_id, user } = this.props.data;

    try {
      const obj = new EntitiesAPI(actualProject.id, access, this.props, entity);
      await obj.post_entity();

      new AmplitudeAPI({
        event_type: "Create entity",
        device_id: device_id,
        user_id: user.email
      }).eventLog();

      this.props.dispatch(
        showNotify({ message: "entities", type: "create", severity: "success" })
      );
      this.returnToList();
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  handleNameEntityError = (name) => {
    var regExp = /^[a-zA-Z][a-zA-Z0-9_\-]+$/gm;
    const { errorName } = this.state;
    if (name.match(regExp)) {
      if (errorName) {
        this.setState({ errorName: false });
      }
    } else {
      if (!errorName && name.length > 1) {
        this.setState({ errorName: true });
      }
    }
  };

  getEntity = async () => {
    this.setState({ entity: getEntityBody() });
  };

  onSelectType = (e) => {
    var actualKind = this.state.entity.kind;

    if (!actualKind) {
      this.state.entity.entries.push(getEntityEntrieBody(e));
    } else {
      switch (e) {
        case "list":
        case "regexp":
          this.state.entity.entries.forEach((el) => {
            delete el.synonyms;
          });
          break;
        case "map":
          this.state.entity.entries.forEach((el) => {
            el.synonyms = [];
          });
          break;
        case "constant":
          this.state.entity.entries.forEach((el) => {
            delete el.synonyms;
          });
          break;
        default:
      }
    }

    this.state.entity.kind = e;
    this.setState({ entity: this.state.entity });
  };

  addElementSynonym = (e, i) => {
    var key = e.key,
      value = e.target.value;

    if ((key === "Tab" || key === "Enter") && value) {
      this.state.entity.entries[i].synonyms.push(value);

      this.setState({ entity: this.state.entity });
      e.target.value = "";
    }
  };

  handleDeleteEntry = (e, i) => {
    this.state.entity.entries.splice(i, 1);
    this.setState({ entity: this.state.entity });
  };

  addElementEntity = () => {
    this.state.entity.entries.push(getEntityEntrieBody(this.state.entity.kind));
    this.setState({ entity: this.state.entity });
  };

  handleDeleteSynonim = (e, i, ii) => {
    this.state.entity.entries[i].synonyms.splice(ii, 1);
    this.setState({ entity: this.state.entity });
  };

  returnToList = () => {
    const { location, history, handleTabs } = this.props;
    history.push(`${location.pathname}?tab=entities`);

    handleTabs();
  };

  render() {
    const { entity, errorName } = this.state;
    const { isSending } = this.props.data;
    const { t } = this.props;

    return (
      entity && (
        <React.Fragment>
          <div className={"cen_card"}>
            <div className={"header"}>
              <div className={"header_content"}>
                <div className={"header_title"}>
                  <span className={"title"}>{t("entities.create.title")}</span>
                </div>
              </div>
              <div className={"header_actions"}>
                <Button
                  type={"cancel"}
                  onClick={(e) => this.returnToList(e)}
                  text={t("entities.common.return_button")}
                />
                {isSending ? (
                  <Button
                    type={"primary"}
                    disabled
                    text={<CircularProgress size={21} />}
                  />
                ) : (
                  <Button
                    disabled={errorName}
                    type={"primary"}
                    onClick={(e) => {
                      this.createEntity(e);
                    }}
                    text={t("entities.create.action_button")}
                  />
                )}
              </div>
            </div>
            <div className={"content"}>
              <div className={"elements__main"}>
                <div className={"elements__error"}>
                  <div>
                    <div>
                      <span>{t("entities.common.name.title")}</span>
                      <span>{t("entities.common.name.description")}</span>
                    </div>
                    <FormControl className={`${errorName ? "error" : ""}`}>
                      <TextField
                        onChange={(e) => {
                          this.updateElement(e, "name");
                        }}
                        placeholder={t("entities.common.name.placeholder")}
                        defaultValue={entity.name}
                        maxLength={30}
                      />
                      {errorName && (
                        <FormHelperText>
                          {t("entities.common.name.helper_error")}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>
                </div>
                <Divider />
                <div>
                  <div>
                    <div>
                      <span>{t("entities.common.kind.title")}</span>
                      <span>{t("entities.common.kind.description")}</span>
                    </div>
                    <SelectSimple
                      valueSelected={
                        entity.kind
                          ? t(`entities.common.kind.${entity.kind}`)
                          : false
                      }
                      items={getEntityTypes()}
                      onSelect={this.onSelectType}
                      placeholder={t("entities.common.kind.placeholder")}
                      type={"entity"}
                      className={"select_secondary"}
                      t={t}
                    />
                  </div>
                </div>
                {entity.kind === "map" && (
                  <div className={"elements__alert"}>
                    <Alert severity="info">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t("entities.common.info_alert.key")
                        }}
                      ></span>
                    </Alert>
                  </div>
                )}
                <div>
                  <div className={"elements__synonyms"}>
                    {entity.kind ? (
                      entity.kind === "map" ? (
                        <React.Fragment>
                          {entity.entries.map((el, i) => {
                            return (
                              <React.Fragment key={i}>
                                <div className={"elements__synonym"}>
                                  <div className={"elements__content"}>
                                    <div className={"elements__input_map"}>
                                      <Input
                                        onChange={(e) => {
                                          this.updateElement(e, "value", i);
                                        }}
                                        onBlur={(e) => {
                                          this.addSynonym(e, i);
                                        }}
                                        value={el.value}
                                        type={"primary"}
                                        placeholder={t(
                                          "entities.common.map.value_placeholder"
                                        )}
                                      />
                                      <Input
                                        onKeyDown={(e) =>
                                          this.addElementSynonym(e, i)
                                        }
                                        type={"primary"}
                                        placeholder={t(
                                          "entities.common.map.add_placeholder"
                                        )}
                                      />
                                      <IconButton
                                        style={{
                                          backgroundColor: "transparent"
                                        }}
                                        onClick={(e) =>
                                          this.handleDeleteEntry(e, i)
                                        }
                                        disableRipple
                                        disableFocusRipple
                                        aria-label="delete"
                                      >
                                        {deleteSvg()}
                                      </IconButton>
                                    </div>
                                    <div className={"elements__chips"}>
                                      {el.synonyms && el.synonyms.length > 0 ? (
                                        el.synonyms.map((chip, ii) => {
                                          return (
                                            <Chip
                                              key={ii}
                                              label={chip}
                                              onDelete={(e) =>
                                                this.handleDeleteSynonim(
                                                  e,
                                                  i,
                                                  ii
                                                )
                                              }
                                              variant="outlined"
                                            />
                                          );
                                        })
                                      ) : (
                                        <Alert severity="info">
                                          {t(
                                            "entities.common.info_alert.synonyms_data"
                                          )}
                                        </Alert>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <Divider />
                              </React.Fragment>
                            );
                          })}
                          <div
                            onClick={(e) => this.addElementEntity(e)}
                            className={"elements__new"}
                          >
                            {t("entities.common.add_row")}
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {entity.entries.map((el, i) => {
                            return (
                              <React.Fragment key={i}>
                                <div className={"elements__synonym"}>
                                  <div className={"elements__content"}>
                                    <div className={"elements__input_list"}>
                                      <Input
                                        onChange={(e) => {
                                          this.updateElement(e, "value", i);
                                        }}
                                        value={el.value}
                                        type={"primary"}
                                        placeholder={t(
                                          "entities.common.list.value_placeholder"
                                        )}
                                      />
                                      {entity.kind !== "constant" && (
                                        <IconButton
                                          style={{
                                            backgroundColor: "transparent"
                                          }}
                                          onClick={(e) =>
                                            this.handleDeleteEntry(e, i)
                                          }
                                          disableRipple
                                          disableFocusRipple
                                          aria-label="delete"
                                        >
                                          {deleteSvg()}
                                        </IconButton>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <Divider />
                              </React.Fragment>
                            );
                          })}
                          {entity.kind !== "constant" && (
                            <div
                              onClick={(e) => this.addElementEntity(e)}
                              className={"elements__new"}
                            >
                              {t("entities.common.add_row")}
                            </div>
                          )}
                        </React.Fragment>
                      )
                    ) : (
                      t("entities.common.info_alert.entity")
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const connect_intent_create = connect(mapStateToProps)(EntityCreate);

export default withTranslation("common")(withRouter(connect_intent_create));
