// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Routing
import {withRouter} from "react-router-dom";
// Translations
import {withTranslation} from "react-i18next";
// Components
import {Button} from "@components/Input";
import {AlertInfo} from "@components/Alert";
// Assets
import {channelsSvg} from "assets";
import {arrowRightSvg} from "@assets";
// Vendor
import {isSandbox} from "vendor/application";
import {showNotify} from "vendor/application/disptach";

class View extends Component {
    return = () => {
        const {history} = this.props;
        const {actualProject} = this.props.data;
        history.push(`/${actualProject.id}/integrations/directory`);
    };

    integrateChannel = (channel) => {
        const {limitsIntegrations} = this.props.data;
        const {history, location} = this.props;
        if (!limitsIntegrations[channel].in_plan) {
            this.props.dispatch(showNotify({type: "link", severity: "info"}));
        } else if (!limitsIntegrations[channel].available) {
            this.props.dispatch(showNotify({message: "channel_not_available", severity: "error"}));
        } else {
            history.push(`${location.pathname}/create`);
        }
    };

    render() {
        const {t} = this.props;

        const is_sandbox = isSandbox(this.props.data, "chatgpt");
        return (
            <div className={"cen_integrations"}>
                {
                    is_sandbox &&
                    <AlertInfo text={t("view.sandbox_info")}/>
                }
                <div className={"header"}>
                    <div className={"header_content"}>
                        <div onClick={() => this.return()} className={"header_action"}>
                            {arrowRightSvg()}
                        </div>
                        <div className={"header_image"}>
                            {channelsSvg("chatgpt")}
                        </div>
                        <div className={"header_title"}>
                            <span className={"title"}>{t("channels.chatgpt.title")}</span>
                            <span className={"description"}>{t("categories.business")}</span>
                        </div>
                    </div>
                    <div className={"header_actions"}>
                        {
                            !is_sandbox &&
                            <Button type={"primary"} onClick={() => {this.integrateChannel("chatgpt");}} text={t("view.action_button")}/>
                        }
                    </div>
                </div>
                <div className={"content"}>
                    <div className={"content_view"}>
                        <span>{t("channels.chatgpt.description")}</span>
                        <span dangerouslySetInnerHTML={{__html: t("channels.chatgpt.info").replaceAll("[br]", "<br /><br />")}} />
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const connect_view = connect(
    mapStateToProps
)(View);

export default withTranslation("integrations")(withRouter(connect_view));