// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Translations
import { withTranslation } from "react-i18next";
// Styles
import "components/Accordion/styles/styles.scss";
// Components
import { Button, Input, SelectSimple } from "@components/Input";
import Auth from "./auth";
import Body from "./body";
import Headers from "./headers";
import Prompt from "./prompt";
import Variables from "./variables";
// Vendor
import { addArtifact } from "vendor/application";
import { Grid, Typography } from "@material-ui/core";

class Extensions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      timeout: 0,
      timeoutError: ""
    };
  }

  componentDidMount = () => {
    const { intent, updateIntent } = this.props;
    if (
      intent.extension?.auth &&
      (!intent.extensions.body?.type ||
        typeof intent.extensions.body !== "object")
    ) {
      intent.extensions.body = {
        type: "application/json",
        payload: intent.extensions.body
      };

      updateIntent(intent);
    }
  };

  updateExtension = (e, type) => {
    const { intent, updateIntent } = this.props;
    intent.extensions[type] = e?.target?.value ?? e;

    if (type === "method" && e === "GET") {
      intent.extensions.body = "";
    }

    updateIntent(intent);
  };

  updateAuthExtension = (e, type) => {
    const { intent, updateIntent } = this.props;

    var value = e?.target?.value ?? e;

    if (type === "access_key") {
      if (value.match(/^[\.a-zA-Z0-9_-]*$/gm)) {
        intent.extensions.auth.access_key_input = value;
        intent.extensions.auth.access_key = `$.${value}`;
      }
    } else {
      intent.extensions.auth[type] = value;
    }

    if (type === "type") {
      intent.extensions.auth.method = e === "jwt" ? "POST" : null;
    }

    this.setState({ intent: intent });

    updateIntent(intent);
  };

  isEnabledExtension = () => {
    const { intent } = this.props;
    if (intent.responses.length > 1) {
      return true;
    } else return intent.desks.name;
  };

  handleTabs = (type) => {
    switch (type) {
      case "body":
        this.setState({ value: 0 });
        break;
      case "auth":
        this.setState({ value: 1 });
        break;
      case "header":
        this.setState({ value: 2 });
        break;
      default:
        this.setState({ value: 0 });
        break;
    }
  };

  updateArtifacts = (e, i, artifact_type, type) => {
    const { intent, updateIntent } = this.props;

    var res = addArtifact(i, artifact_type, type, intent);

    updateIntent(res);
  };

  handleTimeoutChange = (e) => {
    const { intent, updateIntent, t } = this.props;
    const value = e.target.value.trim();
    const numericValue = Number(value);
    const onlyNumbers = /^\d*$/;
    if (!onlyNumbers.test(value)) {
      this.setState({
        timeoutError: t(
          "intents.config.accordion.extensions.content.valid_letter"
        )
      });
      return;
    }

    if (value === "" && intent?.extensions?.timeout === "") {
      this.setState({
        timeout: 15,
        timeoutError: ""
      });
      this.extension.timeout = 15;
      updateIntent(intent);
    } else if (value === "") {
      delete intent.extensions.timeout;
      updateIntent(intent);
    } else if (
      !isNaN(numericValue) &&
      numericValue >= 0 &&
      numericValue <= 15
    ) {
      this.setState({
        timeout: numericValue,
        timeoutError: ""
      });
      intent.extensions.timeout = numericValue;
      updateIntent(intent);
    } else {
      this.setState({
        timeoutError: t(
          "intents.config.accordion.extensions.content.only_number"
        )
      });
    }
  };

  render() {
    const { updateElement, intent, t, updateIntent, updateVariables } =
      this.props;
    const { value } = this.state;
    return (
      <div className={"accordion__content"}>
        {intent.extensions.method ? (
          <div className={"accordion__element_extension"}>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <div className={"accordion__element_extension_header"}>
                  {/* METHOD SELECTOR */}
                  <SelectSimple
                    valueSelected={
                      intent.extensions.method
                        ? intent.extensions.method
                        : false
                    }
                    items={["GET", "POST", "PUT", "PATCH", "DELETE"]}
                    onSelect={(e) => this.updateExtension(e, "method")}
                    placeholder={t(
                      "intents.config.accordion.extensions.content.method"
                    )}
                    type={"method"}
                    t={t}
                  />
                  {/* URL */}
                  <Input
                    onChange={(e) => {
                      this.updateExtension(e, "url");
                    }}
                    value={intent.extensions.url}
                    type={"secondary"}
                    placeholder={t(
                      "intents.config.accordion.extensions.content.url"
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Grid
                  container
                  alignItems="center"
                  style={{
                    backgroundColor: "white",
                    width: "100%",
                    height: "40px",
                    borderRadius: "5px"
                  }}
                >
                  <Grid item xs={12} sm={7} md={7}>
                    <Typography
                      variant="body2"
                      style={{
                        marginLeft: "10px",
                        color: "#565671",
                        marginBottom: this.state.timeoutError ? "42px" : "0"
                      }}
                    >
                      {t("intents.config.accordion.extensions.content.timeout")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={5}
                    md={5}
                    style={{ backgroundColor: "#f5f5f5" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #f0eaea",
                        borderRadius: "4px",
                        backgroundColor: "#fff",
                        justifyContent: "space-between"
                      }}
                    >
                      <Input
                        id="timeoutInput"
                        placeholder="0"
                        type={"secondary"}
                        inputProps={{ maxLength: 2 }}
                        value={intent?.extensions?.timeout}
                        onChange={this.handleTimeoutChange}
                        style={{
                          border: "none",
                          paddingLeft: "10px",
                          width: "60%"
                        }}
                      />
                      <Typography
                        variant="body2"
                        style={{
                          margin: "0 6px",
                          width: "30%",
                          color: "#565671"
                        }}
                      >
                        {t(
                          "intents.config.accordion.extensions.content.seconds"
                        )}
                      </Typography>
                    </div>
                    {this.state.timeoutError && (
                      <span
                        className={"error"}
                        style={{ fontSize: "12px", display: "block" }}
                      >
                        {this.state.timeoutError}
                      </span>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div className={"accordion__element_extension_tabs"}>
              <div className={"accordion__element_extension_tabs_panels"}>
                <div
                  className={`accordion__element_extension_tabs_panel ${
                    value === 0 ? "active" : ""
                  }`}
                  onClick={() => {
                    this.handleTabs("body");
                  }}
                >
                  <span>BODY</span>
                </div>
                <div
                  className={`accordion__element_extension_tabs_panel ${
                    value === 1 ? "active" : ""
                  }`}
                  onClick={() => {
                    this.handleTabs("auth");
                  }}
                >
                  <span>AUTH</span>
                </div>
                <div
                  className={`accordion__element_extension_tabs_panel ${
                    value === 2 ? "active" : ""
                  }`}
                  onClick={() => {
                    this.handleTabs("header");
                  }}
                >
                  <span>HEADER</span>
                </div>
              </div>
              <div className={"accordion__element_extension_tabs_elements"}>
                <div className={"accordion__element_extension_tabs_element"}>
                  {value === 0 && <Body {...this.props} />}
                  {value === 1 && (
                    <Auth
                      {...this.props}
                      updateAuthExtension={this.updateAuthExtension}
                    />
                  )}
                  {value === 2 && <Headers {...this.props} />}
                </div>
              </div>
            </div>
            {/* VARIABLES */}
            <Variables
              {...this.props}
              updateIntent={updateIntent}
              updateVariables={updateVariables}
            />
            {/* PROMPT */}
            <Prompt {...this.props} updateExtension={this.updateExtension} />
          </div>
        ) : (
          <Button
            type={"dashed"}
            disabled={this.isEnabledExtension()}
            onClick={(e) => {
              updateElement(e, "extension", "add");
            }}
            text={
              <React.Fragment>
                <span>
                  {t("intents.config.accordion.extensions.empty.button")}
                </span>
                {this.isEnabledExtension() && (
                  <span className={"error"}>
                    {t("intents.config.accordion.extensions.empty.info")}
                  </span>
                )}
              </React.Fragment>
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const accordion_extensions = connect(mapStateToProps)(Extensions);

export default withTranslation("common")(accordion_extensions);
