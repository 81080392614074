export class ElementsHandleV2 {

    constructor(element, value, type, type_of, i) {
        this.element = element;
        this.value = value;
        this.type = type;
        this.type_of = type_of;
        this.i = i;
    }

    handleElement = () => {
        switch (this.type_of) {
            case "add":
                switch (this.type) {
                    case "conditional_responses":
                        this.element.conditional_responses.push({
                            "param0": "",
                            "param1": "",
                            "param2": "",
                            "operator": "",
                            "type": "response",
                            "response": "",
                            "event": ""
                        });
                        break;
                    case "parameters":
                        this.element.parameters.push({
                            "name": "",
                            "variable": "",
                            "entity_type": "",
                            "required": false,
                            "identification": false,
                            "zendesk_ticket_field": {
                                "desk_id": null
                            },
                            "prompts": [""]
                        });
                        break;
                    case "output":
                        this.element.push({id: "", lifespan: ""});
                        break;
                    case "extension":
                        this.element.extensions = {
                            "url": "",
                            "method": "GET",
                            "auth": {
                                "type": "no_authentication",
                                "username": null,
                                "password": null,
                                "token": null,
                                "url": null,
                                "access_key": null,
                                "header_key": "",
                                "prefix": null,
                                "method": null,
                                "payload": "{}",
                                "headers": []
                            },
                            "body": {
                                "type": "application/json",
                                "payload": "{}"
                            },
                            "prompt": "",
                            "variables": [],
                            "headers": [],
                            "active": true,
                            "variables_desk": [],
                            "timeout": "",
                        };
                        break;
                    default:
                        this.element[this.type].push("");
                        break;
                }
                break;
            case "delete":
                switch (this.type) {
                    case "extension":
                        this.element.extensions = {};
                        break;
                    default:
                        this.element[this.type].splice(this.i, 1);
                        break;
                }
                break;
            case "string_array":
                switch (this.type) {
                    case "phrases":
                    case "responses":
                        this.element[this.type][this.i] = this.value;
                        break;
                }
                break;
            case "string":
                this.element[this.type] = this.value;
                break;
            case "bool":
                switch (this.type) {
                    case "extensions_active":
                        this.element.extensions.active = !this.element.extensions.active;
                        break;
                    default:
                        this.element[this.type] = !this.element[this.type];
                        break;
                }
                break;
        }

        return this.element;
    };
}