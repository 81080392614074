// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Translations
import {withTranslation} from "react-i18next";
// Styles
import "components/Accordion/styles/styles.scss";
// Components
import {SelectSimple} from "@components/Input";
import {get_agents} from "api/application/Customers";
import {get_users} from "api/application/Users";
// Helpers
import {returnElementFromArray} from "@helpers";

class Centridesk extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: false
        };
    }
    componentDidMount = () => {
        this.getUsers();
    };

    getUsers = () => {
        get_users(this.props).then((users) => {
            this.setState({users: users});
        });
    };

    updateSelectorElement = (e, type) => {
        const {body, updateElement} = this.props;
        switch (type) {
            case "agent_id":
                body.info.story_replies.desks.info = {
                    ...body.info.story_replies.desks.info,
                    [type]: e.id
                };
                break;
            case "centridesk_ticket_status":
                body.info.story_replies.desks.info = {
                    ...body.info.story_replies.desks.info,
                    mode: e === "solved" ? "bot" : body.info.story_replies.desks.info.mode,
                    ticket_status: e
                };
                break;
            case "centridesk_mode":
                body.info.story_replies.desks.info = {
                    ...body.info.story_replies.desks.info,
                    mode: e
                };
                break;
            default:
                break;
        }

        updateElement(body);
    };

    handleSelectorValue = (item, type) => {
        const {t} = this.props;
        const {users} = this.state;
        var value = item?.[type];
        switch (type) {
            case "agent_id":
                if (!value) {
                    return "-";
                } else {
                    var user = returnElementFromArray(value, users, "id");
                    return `${user.first_name} ${user.last_name}`;
                }
            case "ticket_status":
                if (!value) {
                    return <span className={"status new"}>{t("elements.custom.status.new")}</span>;
                } else {
                    return <span className={`status ${value}`}>{t(`elements.custom.status.${value}`)}</span>;
                }
            default:
                break;
        }
    };

    render() {
        const {body, t} = this.props;
        const {roles} = this.props.data;
        const {users} = this.state;
        return (
            users.length > 0 &&
            <div className={"info__integration"}>
                <div className={"info__integration_content"}>
                    <SelectSimple
                        valueSelected={this.handleSelectorValue(body.info.story_replies.desks.info, "agent_id")}
                        items={get_agents(users, roles)}
                        label={t("common:intents.integrations.agent_id")}
                        onSelect={(e) => this.updateSelectorElement(e, "agent_id")}
                        placeholder={t("common:intents.integrations.agent_id")}
                        type={"channel_centridesk_selector"}
                        className={"select_secondary"}
                        t={t}
                    />
                    <SelectSimple
                        valueSelected={this.handleSelectorValue(body.info.story_replies.desks.info, "ticket_status")}
                        items={["new", "open", "pending", "solved"]}
                        label={t("common:intents.integrations.centridesk_ticket_status")}
                        onSelect={(e) => this.updateSelectorElement(e, "centridesk_ticket_status")}
                        placeholder={t("common:intents.integrations.centridesk_ticket_status")}
                        type={"status"}
                        className={"select_secondary"}
                        t={t}
                    />
                    <SelectSimple
                        valueSelected={body.info.story_replies.desks.info.mode}
                        items={["desk", "bot"]}
                        label={t("common:intents.integrations.mode")}
                        onSelect={(e) => this.updateSelectorElement(e, "centridesk_mode")}
                        placeholder={t("common:intents.integrations.mode")}
                        disabled={body.info.story_replies.desks.info.ticket_status === "solved"}
                        className={"select_secondary"}
                        t={t}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const channel = connect(
    mapStateToProps
)(Centridesk);

export default withTranslation(["commons", "common", "languages"])(channel);