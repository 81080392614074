// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter } from "react-router-dom";
// Translations
import { withTranslation } from "react-i18next";
// Components
import { Divider } from "@material-ui/core";
import { PopUp } from "@components/Modals";
import { Button } from "@components/Input";
import ElementColor from "../element_color";
import ElementSwitch from "../element_switch";

class Themes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      custom_open: false,
      save_config: "",
      themes: [
        {
          name: "centribal_dark",
          description: "Centribal Dark",
          colors: {
            primary: "#565671",
            secondary: "#FFFFFF",
            tertiary: "#F4AC1A"
          }
        },
        {
          name: "centribal_light",
          description: "Centribal Light",
          colors: {
            primary: "#FFFFFF",
            secondary: "#565671",
            tertiary: "#69ACE5"
          }
        },
        {
          name: "dark",
          description: "Dark",
          colors: {
            primary: "#000000",
            secondary: "#FFFFFF",
            tertiary: "#00C0FF"
          }
        },
        {
          name: "dracula_dark",
          description: "Dracula Dark",
          colors: {
            primary: "#282A36",
            secondary: "#F8F8F2",
            tertiary: "#6272A4"
          }
        },
        {
          name: "dracula_light",
          description: "Dracula Light",
          colors: {
            primary: "#F8F8F2",
            secondary: "#282A36",
            tertiary: "#6272A4"
          }
        },
        {
          name: "coffee_dark",
          description: "Coffee Dark",
          colors: {
            primary: "#4B4B49",
            secondary: "#F1EDEA",
            tertiary: "#C1A58D"
          }
        },
        {
          name: "coffee_light",
          description: "Coffee Light",
          colors: {
            primary: "#F1EDEA",
            secondary: "#4B4B49",
            tertiary: "#C1A58D"
          }
        },
        {
          name: "new",
          description: "custom",
          colors: {
            primary: "#565671",
            secondary: "#FFFFFF",
            tertiary: "#F4AC1A"
          }
        }
      ]
    };
  }

  updateIconConfig = (bool) => {
    const { config, updateConfig } = this.props;
    config.customization.messages_user_icon_show = bool;
    config.customization.messages_agent_icon_show = bool;

    updateConfig(config);
  };

  updateElementConfig = (theme) => {
    const { config, updateConfig } = this.props;

    var primary = theme.colors.primary,
      secondary = theme.colors.secondary,
      tertiary = theme.colors.tertiary;

    /* HEADER */
    config.customization.header_text_color = primary;
    config.customization.header_bg_color = secondary;
    config.customization.header_icons_color = primary;
    /* CONTENT */
    config.customization.messages_content_bg_color = primary;
    config.customization.chat_bg_color = primary;
    /* MESSAGES */
    config.customization.messages_agent_text_color = primary;
    config.customization.messages_agent_bg_color = tertiary;
    config.customization.messages_user_text_color = primary;
    config.customization.messages_user_bg_color = secondary;
    /* BUTTONS*/
    config.customization.artifacts_button_text_color = primary;
    config.customization.artifacts_button_bg_color = secondary;
    config.customization.artifacts_button_hover_text_color = secondary;
    config.customization.artifacts_button_hover_bg_color = primary;
    config.customization.artifacts_button_disabled_text_color = primary;
    config.customization.artifacts_button_disabled_bg_color = "#C4C4C4";
    config.customization.artifacts_link_button_text_color = primary;
    config.customization.artifacts_link_button_bg_color = secondary;
    config.customization.artifacts_link_button_hover_text_color = secondary;
    config.customization.artifacts_link_button_hover_bg_color = primary;
    /* INPUT */
    config.customization.input_text_color = primary;
    config.customization.input_bg_color = secondary;
    config.customization.input_icon_color = secondary;
    config.customization.input_icon_bg_color = primary;
    config.customization.input_clip_color = secondary;
    config.customization.input_clip_bg_color = primary;
    /* OPEN CHAT */
    config.customization.open_text_color = secondary;
    config.customization.open_bg_color = primary;

    updateConfig(config);
  };

  activeTheme = (theme) => {
    const { config } = this.props;

    var primary = theme.colors.primary,
      secondary = theme.colors.secondary,
      tertiary = theme.colors.tertiary;

    var active = [];

    /* HEADER */
    active.push(config.customization.header_text_color === primary);
    active.push(config.customization.header_bg_color === secondary);
    active.push(config.customization.header_icons_color === primary);
    /* CONTENT */
    active.push(config.customization.messages_content_bg_color === primary);
    active.push(config.customization.chat_bg_color === primary);
    /* MESSAGES */
    active.push(config.customization.messages_agent_text_color === primary);
    active.push(config.customization.messages_agent_bg_color === tertiary);
    active.push(config.customization.messages_user_text_color === primary);
    active.push(config.customization.messages_user_bg_color === secondary);
    /* BUTTONS*/
    active.push(config.customization.artifacts_button_text_color === primary);
    active.push(config.customization.artifacts_button_bg_color === secondary);
    active.push(
      config.customization.artifacts_button_hover_text_color === secondary
    );
    active.push(
      config.customization.artifacts_button_hover_bg_color === primary
    );
    active.push(
      config.customization.artifacts_button_disabled_text_color === primary
    );
    active.push(
      config.customization.artifacts_button_disabled_bg_color === "#C4C4C4"
    );
    active.push(
      config.customization.artifacts_link_button_text_color === primary
    );
    active.push(
      config.customization.artifacts_link_button_bg_color === secondary
    );
    active.push(
      config.customization.artifacts_link_button_hover_text_color === secondary
    );
    active.push(
      config.customization.artifacts_link_button_hover_bg_color === primary
    );
    /* INPUT */
    active.push(config.customization.input_text_color === primary);
    active.push(config.customization.input_bg_color === secondary);
    active.push(config.customization.input_icon_color === secondary);
    active.push(config.customization.input_icon_bg_color === primary);
    active.push(config.customization.input_clip_color === secondary);
    active.push(config.customization.input_clip_bg_color === primary);
    /* OPEN CHAT */
    active.push(config.customization.open_text_color === secondary);
    active.push(config.customization.open_bg_color === primary);

    var a = active.filter((act) => act === false);
    return a.length === 0;
  };

  returnPopUpContent = () => {
    const { themes } = this.state;
    const theme = themes.filter((th) => th.name === "new")[0];
    return (
      <React.Fragment>
        <div className="elements_custom grid">
          <div>
            <ElementColor
              updateCustomization={this.updateColors}
              element={"primary"}
              value={theme.colors.primary}
              value_updated={"primary"}
            />
            <ElementSwitch
              color_1={"primary"}
              color_2={"secondary"}
              updateConfig={this.updateColors}
              config={theme}
            />
            <ElementColor
              updateCustomization={this.updateColors}
              element={"secondary"}
              value={theme.colors.secondary}
              value_updated={"secondary"}
            />
          </div>
          <ElementColor
            updateCustomization={this.updateColors}
            element={"tertiary"}
            value={theme.colors.tertiary}
            value_updated={"tertiary"}
          />
        </div>
      </React.Fragment>
    );
  };

  updateColors = (e, type, subtype) => {
    const { themes } = this.state;
    const theme = themes.filter((th) => th.name === "new")[0];

    if (!e?.error) {
      theme.colors[subtype] = e?.css?.backgroundColor;
    } else {
      theme.colors[subtype] = e.raw;
    }

    this.setState({ themes: themes });
    this.updateElementConfig(theme);
  };

  saveConfig = (theme) => {
    this.setState({
      custom_open: true,
      config: JSON.parse(JSON.stringify(this.props.config))
    });
    this.updateElementConfig(theme);
  };

  cancelSave = () => {
    const { config } = this.state;
    const { updateConfig } = this.props;
    updateConfig(config);
    this.setState({ custom_open: false });
  };

  render() {
    const { t, config } = this.props;
    const { themes, custom_open } = this.state;
    return (
      <div className={"cen_card"}>
        {/* THEME COLOR */}
        <div className={"content"}>
          <div className="elements_custom grid">
            <div className="elements_custom__info">
              <span className="elements_custom__title">
                {t(
                  "card.content.project.web_interface.customization.themes.colors.title"
                )}
              </span>
              <span className="elements_custom__description">
                {t(
                  "card.content.project.web_interface.customization.themes.colors.description"
                )}
              </span>
            </div>
            <div className={"elements__themes"}>
              {themes.map((theme, i) => {
                return theme.name === "new" ? (
                  <div
                    onClick={() => this.saveConfig(theme)}
                    key={i}
                    className={"elements__themes_element_new"}
                  >
                    <span>+</span>
                    <span>Custom</span>
                  </div>
                ) : (
                  <div
                    onClick={() => this.updateElementConfig(theme)}
                    key={i}
                    className={"elements__themes_element"}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/img/design/themes/${theme.name}.png`}
                      alt={theme.name}
                    />
                    <span>{theme.description}</span>
                    {this.activeTheme(theme) && (
                      <div className={"elements__themes_element_selected"} />
                    )}
                  </div>
                );
              })}
            </div>
            <PopUp
              open={custom_open}
              className={"popup_container"}
              handleClose={() => this.cancelSave()}
              t={t}
              width={400}
              content={this.returnPopUpContent()}
              buttons={
                <Button
                  type={"success"}
                  onClick={() => this.setState({ custom_open: false })}
                  text={t("card.content.project.web_interface.button_accept")}
                />
              }
            />
          </div>
        </div>
        <Divider />
        {/* ICONS */}
        <div className={"content"}>
          <div className="elements_custom grid">
            <div className="elements_custom__info">
              <span className="elements_custom__title">
                {t(
                  "card.content.project.web_interface.customization.themes.icons.title"
                )}
              </span>
              <span className="elements_custom__description">
                {t(
                  "card.content.project.web_interface.customization.themes.icons.description"
                )}
              </span>
            </div>
            <div className={"elements__themes"}>
              <div
                onClick={() => this.updateIconConfig(true)}
                className={"elements__themes_element"}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/img/design/themes/show_icons.png`}
                  alt={"normal"}
                />
                <span>
                  {t(
                    "card.content.project.web_interface.customization.themes.icons.show"
                  )}
                </span>
                {config.customization.messages_user_icon_show &&
                  config.customization.messages_agent_icon_show && (
                    <div className={"elements__themes_element_selected"} />
                  )}
              </div>
              <div
                onClick={() => this.updateIconConfig(false)}
                className={"elements__themes_element"}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/img/design/themes/hide_icons.png`}
                  alt={"normal"}
                />
                <span>
                  {t(
                    "card.content.project.web_interface.customization.themes.icons.hide"
                  )}
                </span>
                {!config.customization.messages_user_icon_show &&
                  !config.customization.messages_agent_icon_show && (
                    <div className={"elements__themes_element_selected"} />
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const a = connect(mapStateToProps)(Themes);

export default withTranslation("common")(withRouter(a));
