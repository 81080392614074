// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Routing
import {withRouter} from "react-router-dom";
// Translations
import {withTranslation} from "react-i18next";
// Material UI
import {Dialog, DialogContent} from "@material-ui/core";
// Components
import {Button} from "@components/Input";
// Helpers
import {getRoleName} from "@helpers";
// SVG
import {infoSvg, bigCloseSvg} from "assets";
// Chargebee
import {ChargebeeAPI} from "@api/Chargebee";

class Info extends Component {

    handleChargebeeCheckout = () => {
        const {t, type} = this.props;
        const {user, plan} = this.props.data;

        const plan_name = plan.plan.name,
              role_name = getRoleName(user.roles[0]),
              isChargebee = user.chargebee_customer_id !== "-";

        if (isChargebee) {
            if (["consulting_enterprise", "consulting_professional"].includes(plan_name)) {
                return <span className={"description"}>{t("info_messages.chargebee_action.comercial")}</span>;
            } else if (plan_name === "professional" || plan_name === "consulting_sunco") {
                if (role_name === "SuperAdmin") {
                    return <Button id={"notify_button_info"} type={"info"} text={t(`info_messages.${type}.link_text`)}
                                   onClick={(e) => this.handleChargebee(e)}/>;
                }
                return <span className={"description"}>{t("info_messages.chargebee_action.contact")}</span>;
            }
        } else {
            if (role_name === "SuperAdmin") {
                return <span className={"description"}>{t("info_messages.chargebee_action.comercial")}</span>;
            }
            return <span className={"description"}>{t("info_messages.chargebee_action.contact")}</span>;
        }
    };

    handleChargebee = async (e) => {
        const {access, user, plan} = this.props.data;

        let chargebeeSubscription = plan.chargebee_plans.filter(s => !s.is_dummy)[0];

        var obj = {
            checkout: false,
            redirection: false,

            chargebeeSubscription: chargebeeSubscription,
            frequency: chargebeeSubscription.frequency,
            currency: chargebeeSubscription.currency,

            plan_id: this.props.plan,

            cbInstance: window.Chargebee.init({
                site: process.env.REACT_APP_CHARGEBEE_SITE
            })
        };

        await obj.cbInstance.setPortalSession(() => {
            let data = JSON.stringify({chargebee_customer_id: user.chargebee_customer_id});
            let chargebee_api = new ChargebeeAPI(user.id, access, this.props, data);
            return chargebee_api.get_chargebee_user_portal();
        });

        var callbacks = {};

        this.props.onClose(e);
        obj.cbInstance.createChargebeePortal().open(callbacks,
            {
                sectionType: window.Chargebee.getPortalSections().EDIT_SUBSCRIPTION,
                params: {
                    subscriptionId: obj.chargebeeSubscription.chargebee_subscription_id
                }
            }
        );

    };

    render() {
        const {t, type} = this.props;
        return (
            <Dialog onClose={this.props.onClose} className="dialog_info" open={true}>
                <DialogContent>
                    <div className={"dialog"}>
                        <span className={"title"}>{t("info_messages.title")}</span>
                        {
                            type && type === "link" &&
                            <div className={"dialog_close"} onClick={(e) => {
                                this.props.onClose(e);
                            }}>{bigCloseSvg()}</div>
                        }
                        <span className={"svg"}>{infoSvg()}</span>
                        <span className={"description"}>{t(`info_messages.${type}.description`)}</span>
                        {
                            type && type === "link" && this.props.data.user.username !== "gonzalo.berrmejo@makro.es" ?
                                this.handleChargebeeCheckout() :
                                <Button id={"notify_button_info"} type={"info"} text={t("info_messages.action_button")}
                                        onClick={(e) => this.props.onClose(e)}/>
                        }
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const success = connect(
    mapStateToProps
)(Info);

export default withTranslation("common")(withRouter(success));
