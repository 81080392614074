// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Routing
import {withRouter} from "react-router-dom";
// Material
import {CircularProgress, Divider} from "@material-ui/core";
// Vendor
import {showNotify, updateLimitsIntegrations} from "vendor/application/disptach";
// Infrastructure
import {IntegrationsAPI} from "api/application/Integrations";
// Application
import {getZendeskChatBody, returnElementFromArray} from "vendor/application";
import {ElementsHandleAPI} from "vendor/application/handleMethods";
// Components
import {Button} from "@components/Input";
import {ElementsCustom} from "@components/Elements";
// Translations
import {withTranslation} from "react-i18next";
// SVG
import {channelsSvg} from "assets";
import {arrowRightSvg} from "@assets";

class Create extends Component {
    constructor(props) {
        super(props);
        this.state = {
            zendesk: getZendeskChatBody(),
            domain: ".zendesk.com"
        };
    }

    componentDidMount() {
        this.getIntegrations();
    }

    getIntegrations = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props);
            const integrations = await obj.get_integrations();
            var exist = returnElementFromArray("zendesk_chat", integrations, "channel");
            /*if (exist) {
                this.props.dispatch(showNotify({ message: "zendesk_chat_restriction", severity: 'error' }));
                this.return();
            }*/
        } catch (err) {
            var data = {message: err, severity: "error"};
            this.props.dispatch(showNotify(data));
        }
    };

    updateElement = (e, type, i) => {
        const {zendesk} = this.state;
        const obj = new ElementsHandleAPI(zendesk.info, e.target.value, type, i);
        obj.updateObj();

        this.setState({zendesk: zendesk});
    };

    return = () => {
        const {history} = this.props;
        const {actualProject} = this.props.data;
        history.push(`/${actualProject.id}/integrations/zendesk_chat`);
    };

    postIntegration = async (e) => {
        e.preventDefault();
        const {access, actualProject} = this.props.data;
        const {zendesk, domain} = this.state;
        const {history} = this.props;

        zendesk.info.subdomain = zendesk.info.subdomain.replace(".zendesk.com", "") + domain;
        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props, zendesk);
            const integration = await obj.post_integration();
            this.props.dispatch(showNotify({message: "integrations", type: "create", severity: "success"}));

            this.props.data.limitsIntegrations.zendesk_chat.existIntegration = true;
            this.props.dispatch(updateLimitsIntegrations(this.props.data.limitsIntegrations));

            history.push(`/${actualProject.id}/integrations/zendesk_chat/edit/${integration.id}`);
        } catch (err) {
            zendesk.info.subdomain = zendesk.info.subdomain.replace(".zendesk.com", "");
            this.props.dispatch(showNotify({message: err, severity: "error"}));
        }
    };

    render() {
        const {zendesk} = this.state;
        const {isSending} = this.props.data;
        const {t} = this.props;
        return (
            zendesk &&
            <div className={"cen_integrations"}>
                <div className={"header"}>
                    <div className={"header_content"}>
                        <div onClick={() => this.return()} className={"header_action"}>
                            {arrowRightSvg()}
                        </div>
                        <div className={"header_image"}>
                            {channelsSvg("zendesk_chat")}
                        </div>
                        <div className={"header_title"}>
                            <span className={"title"}>{t("integrations:channels.zendesk_chat.title")}</span>
                            <span className={"description"}>{t("integrations:categories.business")}</span>
                        </div>
                    </div>
                    <div className={"header_actions"}>
                        {
                            isSending ? (
                                <Button type={"primary"} disabled text={<CircularProgress size={21}/>}/>
                            ) : (
                                <Button type={"primary"}
                                        text={t("integrations:create.action_button")}
                                        onClick={(e) => this.postIntegration(e)}/>
                            )
                        }
                    </div>
                </div>
                <div className={"integration"}>
                    {/* SUBDOMAIN */}
                    <ElementsCustom
                        t={t}
                        type={"zendesk_chat"}
                        input_type={"input_zendesk"}
                        element_type={"subdomain"}
                        element={zendesk.info.subdomain.replace(".zendesk.com", "")}
                        updateElement={this.updateElement}
                    />
                    <Divider/>
                    {/* TOKEN */}
                    <ElementsCustom
                        t={t}
                        type={"zendesk_chat"}
                        input_type={"input"}
                        element_type={"account_key"}
                        element={zendesk.info.account_key}
                        updateElement={this.updateElement}
                    />
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        data: state
    };
};

const create = connect(
    mapStateToProps
)(Create);

export default withTranslation(["commons", "common", "integrations"])(withRouter(create));