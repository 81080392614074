
// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter } from "react-router-dom";
// Components
import CardEmpty from "components/Card/CardEmpty";
// Vendor
import { updateActualLayout } from "vendor/application/disptach";
// Translations
import { withTranslation } from "react-i18next";

class NoProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            array: "",
            open: false
        };
    }

    componentDidMount() {
        this.setActualProject();
    }

    setActualProject = () => {
        this.props.dispatch(updateActualLayout({}));
    };

    handleClickOpen = () => {
        const { history } = this.props;
        history.push(`${history.location.pathname}/create`);
    };

    render() {
        const { open } = this.state;
        return (
            !this.props.data.isSending &&
            <React.Fragment>
                <CardEmpty
                    data={this.props.data}
                    t={this.props.t}
                    handleClickOpen={this.handleClickOpen}
                    open={open}
                    type={"project"}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const connect_no_project = connect(
    mapStateToProps
)(NoProject);

export default withTranslation("common")(withRouter(connect_no_project));