// React
import React, {Component} from "react";

export class Name extends Component {
    render() {
        const {text} = this.props;
        return (
            <div className={"dashboard_element name"}>
                <span>{text}</span>
            </div>
        );
    }
}
