// React
import React, { useState, useEffect } from "react";
import { Handle, useStoreApi } from "reactflow";
// Components
import { Input } from "@components/Input";
import DeleteElement from "./components/delete_element";
import AddElement from "./components/add_element";
import InputElement from "./components/input_element";

const IntentInitial = ({ data, isConnectable, selected, dragging }) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const store = useStoreApi();
  const { edges } = store.getState();

  var artifacts = data.artifacts,
    responseText = artifacts.filter(
      (artifact) =>
        (artifact.element === "text" && artifact.text) ||
        (artifact.element === "button" && artifact.text && artifact.value)
    );

  const handleButtonClass = (handleID, isDisabled) => {
    var exist = edges.filter((edge) => edge.sourceHandle === handleID)[0];
    if (isDisabled) {
      return "is_disabled";
    } else if (exist) {
      return "is_connected";
    }
    return "";
  };

  const handleResponses = (response, i) => {
    var handleID = `handle-${data.id}-${response.value}`;
    switch (response.element) {
      case "text":
        return (
          <div
            data-id={`${data.id ?? 1}_${i}_flow_element`}
            data-nodeid={data.id ?? "1"}
            className={"flow_element__text"}
            key={i}
          >
            <InputElement
              data={data}
              i={i}
              value={response.text.replaceAll("<br>", "\n")}
              type={"text"}
              multiline
              on_change_type={"update_artifact_type"}
              on_blur_type={"update_artifact_save"}
            />
            <DeleteElement
              selected={selected}
              type={"artifact"}
              data={data}
              i={i}
            />
          </div>
        );
      case "button":
        return (
          <div key={i} className={"flow_element__button"}>
            <div
              data-id={`${data.id ?? "1"}_${i}_flow_element`}
              data-nodeid={data.id ?? "1"}
              className={"flow_element__handle"}
            >
              <Handle
                id={handleID}
                type={"source"}
                position="right"
                className={handleButtonClass(handleID, response.disabled)}
                style={{
                  borderRadius: "0 3px 3px 0",
                  width: 16,
                  height: 36,
                  border: "inherit",
                  right: 2,
                  zIndex: 10
                }}
                isConnectable={
                  isConnectable &&
                  !response.disabled &&
                  response.value &&
                  response.text
                }
              />

              <div className={"flow_element__inputs"}>
                <InputElement
                  data={data}
                  i={i}
                  value={response.value}
                  type={"value"}
                  on_change_type={"update_artifact_type"}
                  on_blur_type={"update_artifact_save"}
                />
                <InputElement
                  data={data}
                  i={i}
                  value={response.text.replaceAll("<br>", "\n")}
                  type={"text"}
                  on_change_type={"update_artifact_type"}
                  on_blur_type={"update_artifact_save"}
                />
              </div>
            </div>
            <DeleteElement
              selected={selected}
              data={data}
              type={"artifact"}
              i={i}
            />
          </div>
        );
    }
  };

  const handlePhrases = (phrase, i) => {
    return (
      <div
        data-id={`${data.id ?? 1}_${i}_flow_element`}
        data-nodeid={data.id ?? "1"}
        className={"flow_element__text"}
        key={i}
      >
        <DeleteElement
          disabled={data.phrases.length <= 1}
          selected={selected}
          data={data}
          type={"phrase"}
          i={i}
        />
        <InputElement
          data={data}
          i={i}
          value={phrase.replaceAll("<br>", "\n")}
          type={"text"}
          multiline
          on_change_type={"update_phrase_type"}
          on_blur_type={"update_phrase_save"}
        />
        <AddElement selected={selected} data={data} type={"phrase"} i={i} />
      </div>
    );
  };

  useEffect(() => {
    if (isDeleting) {
      setTimeout(() => {
        setIsDeleting(false);
      }, 3000);
    }
  }, [isDeleting]);

  return (
    <React.Fragment>
      <Handle type={"target"} position="left" />
      {selected && (
        <div className={"flow_element__actions intent_initial"}>
          {!data.id && (
            <span
              className={`${!responseText.length > 0 || !data.name ? "disabled" : ""}`}
              onClick={() => data.updateIntent("create", data)}
            >
              Crear
            </span>
          )}
          {!isDeleting ? (
            <span onClick={() => setIsDeleting(true)}>Eliminar</span>
          ) : (
            <span onClick={() => data.updateNode("delete", data)}>
              Click para confirmar
            </span>
          )}
        </div>
      )}
      {(!selected || dragging) && (
        <div
          data-nodeid={data.id ?? "1"}
          className={"flow_element__dragged flow_element__draggable"}
        />
      )}
      <div className={"flow_element intent_initial"}>
        <div className={"flow_element__drag flow_element__draggable"}>
          {selected && (
            <span>
              <img src={`${process.env.PUBLIC_URL}/img/flow/drag.png`} alt="" />
            </span>
          )}
        </div>
        <div className={"flow_element__header"}>
          <Input
            id={data.id}
            value={data.name}
            placeholder={"Inserta el nombre del intent"}
            onChange={(e) =>
              data.updateNode("update_intent_name", {
                id: data.id,
                value: e?.target?.value
              })
            }
            onBlur={() => {
              data.updateNode("update_intent_name", data, true);
            }}
          />
        </div>
        <div className={"flow_element__body"} data-nodeid={data.id ?? "1"}>
          <div className={"response"}>
            <span className={"title"}>Entrada</span>
            {data.phrases?.map((phrase, i) => {
              return handlePhrases(phrase, i);
            })}
          </div>
        </div>
      </div>
      <div className={"intent_initial_divider"} />
      <div className={"flow_element intent_initial responses"}>
        <div className={"flow_element__body"} data-nodeid={data.id ?? "1"}>
          <div className={"response"}>
            <span className={"title"}>Respuesta</span>
            {artifacts &&
              artifacts?.map((response, i) => {
                return handleResponses(response, i);
              })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default IntentInitial;
