/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, {
  addEdge,
  Background,
  Controls,
  ReactFlowProvider,
  useEdgesState,
  useNodesState
} from "reactflow";
import dagre from "dagre";
// Styles
import "reactflow/dist/style.css";
// Helpers
import { getIntentBody } from "./helpers";
import { handleArtifacts } from "@helpers";
// Components
import Sidebar from "views/Bots/NLP/application/Build/Flow/application/sideBar";
// API
import { IntentsAPI } from "views/Bots/NLP/application/Build/Tabs/Intents/infrastructure";
import { ContextsAPI } from "views/Bots/NLP/application/Build/Tabs/Contexts/infrastructure";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const getLayoutNodes = (nodes, updateIntent, updateNode) => {
  dagreGraph.setGraph({ rankdir: "LR" });

  var nodeWidth = 300,
    nodeHeight = 150;

  if (nodes?.length > 0) {
    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });
  }

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = "left";
    node.sourcePosition = "right";
    node.data.updateIntent = updateIntent;
    node.data.updateNode = updateNode;

    if (node.position.x === 0 && node.position.y === 0) {
      node.position = {
        x: nodeWithPosition?.x + 10 - nodeWidth / 2 + Math.random() / 1000,
        y: nodeWithPosition?.y + 10 - nodeHeight / 2
      };
    }
    return node;
  });

  return nodes;
};

const getLayoutEdges = (edges) => {
  dagreGraph.setGraph({ rankdir: "LR" });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  return edges;
};

const Diagram = (props) => {
  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const { access, actualProject } = props.extends.data;

  const deleteContext = async (context_id) => {
    try {
      // Delete context
      var api_contexts = new ContextsAPI(
        actualProject.id,
        access,
        props.extends
      );
      return await api_contexts.delete_context(context_id);
    } catch (e) {}
  };

  // called when the intent is need to be updated
  const updateIntent = async (type, element) => {
    var id = element?.id ?? element?.data?.id;

    switch (type) {
      // update coordinates
      case "coordinates":
        var change_detected = false,
          metadata = element?.data?.metadata,
          coordinates = metadata?.coordinates,
          position = element?.position;

        change_detected =
          coordinates.x !== position.x || coordinates.y !== position.y;

        if (id && id !== "1" && change_detected) {
          var put_coordinates = new IntentsAPI(
            actualProject.id,
            access,
            props.extends,
            {
              ...metadata,
              coordinates: position
            }
          );
          await put_coordinates.put_intent_metadata(id);
        }

        setNodes((nds) =>
          nds.map((node) => {
            if (node.id === id) {
              node.data = {
                ...node.data,
                metadata: {
                  ...node.data.metadata,
                  coordinates: element.position
                }
              };
            }
            return node;
          })
        );
        break;
      // delete intent and updateNode
      case "delete_intent":
        if (id) {
          var delete_intent = new IntentsAPI(
            actualProject.id,
            access,
            props.extends
          );
          return await delete_intent.delete_intent(id);
        } else {
          setNodes((nds) => nds.filter((node) => node.id !== "1"));
        }
        break;
      // save an intent
      case "save":
        if (id) {
          const element_update = JSON.parse(JSON.stringify(element));
          element_update.responses[0] = artifactsToString(
            element_update.artifacts
          );
          delete element_update.artifacts;

          try {
            var save = new IntentsAPI(
              actualProject.id,
              access,
              props.extends,
              element_update
            );
            var res = await save.put_intent(id);
            setNodes((nds) =>
              nds.map((node) => {
                if (node.id === element_update.id) {
                  node.data.artifacts = handleArtifacts(res.responses[0])[0];
                }
                return node;
              })
            );
          } catch (e) {}
        }
        break;
      case "update_node":
      case "update_intent":
        if (id) {
          const element_update = JSON.parse(JSON.stringify(element));
          element_update.responses[0] = artifactsToString(
            element_update.artifacts
          );
          delete element_update.artifacts;
          delete element_update.type_update;
          try {
            var update_node = new IntentsAPI(
              actualProject.id,
              access,
              props.extends,
              element_update
            );
            return await update_node.put_intent(id);
          } catch (e) {}
        }
        break;
      case "delete_context":
        try {
          var api_contexts = new ContextsAPI(
            actualProject.id,
            access,
            props.extends
          );
          return await api_contexts.delete_context(id);
        } catch (e) {}
        break;
      // create an intent
      case "create":
        if (!id) {
          setSendingRequests(true);
          const element_update = JSON.parse(JSON.stringify(element));
          element_update.responses[0] = artifactsToString(
            element_update.artifacts
          );
          delete element_update.artifacts;

          try {
            var post_intent = new IntentsAPI(
              actualProject.id,
              access,
              props.extends,
              element_update
            );
            const res = await post_intent.post_intent();
            // set new id to node
            setNodes((nds) =>
              nds.map((node) => {
                if (node.id === "1") {
                  node = {
                    ...node,
                    id: res.id
                  };

                  node.data = {
                    ...node.data,
                    id: res.id
                  };

                  node.data.artifacts.forEach((artifact) => {
                    delete artifact.disabled;
                  });
                }
                return node;
              })
            );
            setSendingRequests(false);
          } catch (e) {
            setSendingRequests(false);
          }
          break;
        }
        break;
    }
  };

  // method that updates a node actions
  const updateNode = async (type, node_to_update, value) => {
    var id = node_to_update?.id ?? "1";
    switch (type) {
      // INTENT
      case "delete":
        var relations = node_to_update.metadata?.relations,
          nodes_action = [];
        setSendingRequests(true);

        if (relations.length > 0) {
          var context_output = node_to_update.contexts.output[0];

          var relations_source = relations
              .filter((relation) => relation.source)
              .map((relation) => relation.source),
            relations_target = relations
              .filter((relation) => relation.target)
              .map((relation) => relation.target);

          let source_ids = [...new Set(relations_source)],
            target_ids = [...new Set(relations_target)];

          if (context_output?.id) {
            nodes_action.push({
              id: context_output?.id,
              type: "delete_context"
            });
          }

          await setNodes((nds) =>
            nds.map((node) => {
              // Source Intents
              if (source_ids.includes(node?.id)) {
                var source_relations = node?.data.metadata.relations.filter(
                    (relation) => relation.target !== id
                  ),
                  source_relations_target = source_relations.filter(
                    (relation) => relation.target
                  ),
                  source_context_id = node.data.contexts.output[0]?.id;

                if (source_relations_target.length <= 0) {
                  node.data.contexts.output = node.data.contexts.output.filter(
                    (output) => output?.id !== source_context_id
                  );
                  nodes_action.push({
                    id: source_context_id,
                    type: "delete_context"
                  });
                }

                node.data.metadata.relations = source_relations;

                nodes_action.unshift({
                  data: node.data,
                  type: "update_intent"
                });
              }

              // Target Intents
              if (target_ids.includes(node?.id)) {
                var target_relations = node?.data.metadata.relations;

                target_relations.map((relation) => {
                  if (relation.source === id) {
                    var value = relation.actions.value,
                      value_exist = target_relations.filter(
                        (rl) => rl.actions.value === value && rl.source !== id
                      );

                    if (value_exist.length <= 0) {
                      node.data.phrases = node.data.phrases.filter(
                        (phrase) => phrase !== value
                      );
                    }
                  }
                });

                node.data.contexts.input = node.data.contexts.input.filter(
                  (input) => input !== context_output?.id
                );
                node.data.metadata.relations = target_relations.filter(
                  (relation) => relation.source !== id
                );

                nodes_action.unshift({
                  data: node.data,
                  type: "update_intent"
                });
              }

              return node;
            })
          );
        }

        if (id !== "1") {
          nodes_action.unshift({ id: id, type: "delete_intent" });
        }

        setEdges((edges) =>
          edges.filter((edge) => edge.target !== id && edge.source !== id)
        );
        setNodes((nds) => nds.filter((node) => node?.id !== id));

        for (const node of nodes_action) {
          await updateIntent(node.type, node?.data ?? node);
        }
        setSendingRequests(false);

        break;
      case "update_intent_name":
        var intent;
        setNodes((nds) =>
          nds.map((node) => {
            if (node.id === id) {
              intent = node.data;
              if (!value) {
                node.data = {
                  ...node.data,
                  name: node_to_update.value
                };
              }
            }
            return node;
          })
        );

        if (value && intent) {
          setSendingRequests(true);
          await updateIntent("update_node", intent);
          setSendingRequests(false);
        }
        break;
      // ARTIFACT
      case "update_artifact":
        setNodes((nds) =>
          nds.map((node) => {
            if (node.id === id) {
              node.data = {
                ...node.data,
                artifacts: node_to_update.artifacts
              };
            }
            return node;
          })
        );
        break;
      case "update_artifact_type":
        setNodes((nds) =>
          nds.map((node) => {
            if (node.id === id) {
              var _artifacts = node.data.artifacts;
              _artifacts[node_to_update.iteration][node_to_update.type] =
                node_to_update[node_to_update.type];
              node.data = {
                ...node.data,
                artifacts: _artifacts
              };
            }
            return node;
          })
        );
        break;
      case "delete_artifact":
        var source_node = "",
          target_node = "",
          context_to_delete = false,
          source_context_id = "";

        var artifact_to_delete = node_to_update.artifacts[value],
          relation_to_delete = node_to_update.metadata.relations.filter(
            (relation) => relation.actions.value === artifact_to_delete.value
          )[0];

        // delete artifact, context and relation from source node
        await setNodes((nds) =>
          nds.map((node) => {
            if (node.id === id) {
              var artifacts = node.data.artifacts,
                relations = node.data.metadata.relations.filter(
                  (relation) =>
                    relation.actions.value !== artifact_to_delete.value
                );

              if (artifact_to_delete.element !== "text") {
                target_node = { id: relation_to_delete?.target };

                source_context_id = node.data.contexts.output[0]?.id;
                if (relations.length === 0) {
                  context_to_delete = node.data.contexts.output[0];
                  node.data.contexts.output.splice(0, 1);
                }
              }

              source_node = node;
              artifacts.splice(value, 1);
              node.data = {
                ...node.data,
                artifacts: artifacts,
                metadata: {
                  ...node.data.metadata,
                  relations: relations,
                  type: node.data.metadata.type
                }
              };
            }
            return node;
          })
        );

        if (
          relation_to_delete?.target &&
          artifact_to_delete.element !== "text"
        ) {
          // delete context, relation and phrase from target node
          await setNodes((nds) =>
            nds.map((node) => {
              if (node.id === target_node.id) {
                target_node = node;
                var node_relations = node.data.metadata.relations;
                const relation_index = node_relations.findIndex(
                    (relation) =>
                      relation.actions.value === artifact_to_delete.value &&
                      relation.source === source_node.id
                  ),
                  relations_with_source = node_relations.filter(
                    (relation) => relation.source === source_node.id
                  ),
                  relations_with_source_value = node_relations.filter(
                    (relation) =>
                      relation.actions.value === artifact_to_delete.value
                  );

                node_relations.splice(relation_index, 1);

                if (node_relations.length === 0) {
                  node.data.contexts.input.splice(0, 1);
                } else if (relations_with_source.length <= 1) {
                  node.data.contexts.input = node.data.contexts.input.filter(
                    (input) => input !== source_context_id
                  );
                }

                if (relations_with_source_value.length === 0) {
                  node.data.phrases.filter(
                    (phrase) => phrase !== artifact_to_delete.value
                  );
                }

                node.data = {
                  ...node.data,
                  metadata: {
                    ...node.data.metadata,
                    relations: node_relations,
                    type: node.data.metadata.type
                  }
                };
              }
              return node;
            })
          );

          await setEdges((edges) =>
            edges.map((edge) => {
              if (
                edge?.sourceHandle !==
                `handle-${node_to_update.id}-${artifact_to_delete.value}`
              ) {
                return edge;
              }
            })
          );
        }

        // Hacer actualizaciones de peticiones
        if (artifact_to_delete && !artifact_to_delete.disabled) {
          setSendingRequests(true);

          await updateIntent("update_node", source_node.data);

          if (relation_to_delete?.target) {
            await updateIntent("update_node", target_node.data);
          }

          if (context_to_delete?.id) {
            await deleteContext(context_to_delete.id);
          }
          setSendingRequests(false);
        }
        break;
      case "update_artifact_save":
        var artifact = node_to_update.artifacts[value],
          relation = node_to_update.metadata?.relations?.findIndex(
            (relation) =>
              relation?.actions?.value === artifact.value_save &&
              relation?.target
          ),
          update_nodes = [],
          old_value = JSON.parse(JSON.stringify(artifact?.value_save ?? "")),
          old_text = JSON.parse(JSON.stringify(artifact?.text_save ?? ""));

        if (
          (node_to_update.type_update === "value" &&
            old_value !== artifact.value) ||
          (node_to_update.type_update === "text" && old_text !== artifact.text)
        ) {
          setSendingRequests(true);
          await setNodes((nds) =>
            nds.map((node) => {
              if (node.id === id) {
                var _artifacts = node.data.artifacts;
                _artifacts[value].value_save = artifact.value;
                _artifacts[value].text_save = artifact.text;

                if (relation >= 0) {
                  setEdges((edges) =>
                    edges.map((edge) => {
                      if (edge?.sourceHandle === `handle-${id}-${old_value}`) {
                        edge.sourceHandle = `handle-${id}-${artifact.value}`;
                      }
                      return edge;
                    })
                  );

                  node.data.metadata.relations[relation].actions.value =
                    artifact.value_save;
                }

                if (
                  ((artifact.value && artifact.text) ||
                    (artifact.element === "text" && artifact.text)) &&
                  id !== "1"
                ) {
                  update_nodes.push(node.data);
                  _artifacts[value].disabled = false;
                }

                node.data = {
                  ...node.data,
                  artifacts: _artifacts
                };
              }
              return node;
            })
          );

          if (relation >= 0 && old_value !== artifact.value) {
            await setNodes((nds) =>
              nds.map((node) => {
                if (
                  node.id === node_to_update.metadata.relations[relation].target
                ) {
                  update_nodes.push(node.data);
                  var relations = node.data.metadata.relations,
                    relations_with_new_value =
                      node.data.metadata.relations.filter(
                        (relation) =>
                          relation.actions.value === artifact.value &&
                          relation.source !== id
                      ),
                    relations_with_source =
                      node.data.metadata.relations.findIndex(
                        (relation) =>
                          relation.actions.value === old_value &&
                          relation.source === id
                      ),
                    objIndex = node.data.phrases.findIndex(
                      (obj) => obj === old_value
                    );

                  if (relations_with_new_value.length <= 0) {
                    node.data.phrases[objIndex] = artifact.value;
                  } else {
                    node.data.phrases.splice(1, objIndex);
                  }

                  if (relations_with_source >= 0) {
                    relations[relations_with_source].actions.value =
                      artifact.value;
                  }

                  node.data = {
                    ...node.data,
                    metadata: {
                      ...node.data.metadata,
                      relations: relations
                    }
                  };
                }
                return node;
              })
            );
          }

          for (const node of update_nodes) {
            await updateIntent("update_intent", node);
          }
          setSendingRequests(false);
        }

        break;
      // PHRASES
      case "add_phrase":
        await setNodes((nds) =>
          nds.map((node) => {
            if (node.id === id) {
              node.data.phrases.push("");
              var phrases = node.data.phrases;

              node.data = {
                ...node.data,
                phrases: phrases
              };
            }
            return node;
          })
        );
        break;
      case "update_phrase_type":
        setNodes((nds) =>
          nds.map((node) => {
            if (node.id === id) {
              var phrases = node.data.phrases;
              phrases[node_to_update.iteration] = node_to_update["text"];
              node.data = {
                ...node.data,
                phrases: phrases
              };
            }
            return node;
          })
        );
        break;
      case "update_phrase_save":
        if (node_to_update.phrases[value]) {
          setSendingRequests(true);
          await updateIntent("update_intent", node_to_update);
          setSendingRequests(false);
        }
        break;
      case "delete_phrase":
        await setNodes((nds) =>
          nds.map((node) => {
            if (node.id === id) {
              var phrases = node.data.phrases;
              phrases.splice(value, 1);

              node.data = {
                ...node.data,
                phrases: phrases
              };

              node_to_update = node.data;
            }
            return node;
          })
        );

        setSendingRequests(true);
        await updateIntent("update_intent", node_to_update);
        setSendingRequests(false);

        break;
    }
  };

  // Parse artifacts array to string
  const artifactsToString = (artifacts) => {
    if (artifacts.length > 0) {
      var string = "";
      artifacts.forEach((artifact) => {
        switch (artifact.element) {
          case "text":
            string += artifact.text;
            break;
          case "button":
            if (artifact.text && artifact.value) {
              string += `{button value="${artifact.value}"}${artifact.text}{/button}`;
            }
            break;
        }
      });

      return string;
    }
    return "";
  };

  const [nodes, setNodes, onNodesChange] = useNodesState(
    getLayoutNodes(props.nodes, updateIntent, updateNode)
  );
  const [edges, setEdges] = useEdgesState(getLayoutEdges(props.edges));
  const [sendingRequests, setSendingRequests] = useState(false);

  const [contexts, setContexts] = useState(props.contexts);

  // when the node property of props is updated the state property is updated too
  useEffect(() => {
    setNodes(getLayoutNodes(props.nodes, updateIntent, updateNode));
  }, [props.nodes]);

  // when the edge property of props is updated the state property is updated too
  useEffect(() => {
    setEdges(getLayoutEdges(props.edges));
  }, [props.edges]);

  // when the contexts property of props is updated the state property is updated too
  useEffect(() => {
    setContexts(props.contexts);
  }, [props.contexts]);

  const onDrop = useCallback(
    (e) => {
      e.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = e.dataTransfer.getData("application/reactflow");

      if (typeof type === "undefined" || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: e.clientX - reactFlowBounds.left,
        y: e.clientY - reactFlowBounds.top
      });

      var node = e?.target?.firstChild?.attributes?.["data-nodeid"]?.value,
        parent = e?.target?.offsetParent?.dataset?.id,
        child = e?.target?.id,
        drag = e?.target?.attributes?.["data-nodeid"]?.value;

      var intent_id = node || parent || child || drag;

      switch (type) {
        case "intent":
        case "intent_initial":
        case "intent_df":
          // Set all nodes to selected false
          setNodes((nds) =>
            nds.map((node) => {
              node.selected = false;
              return node;
            })
          );

          setNodes((nds) =>
            nds.concat(getIntentBody(type, position, updateIntent, updateNode))
          );
          break;
        case "button":
        case "text":
          setNodes((nds) =>
            nds.map((node) => {
              if (
                node.data.id === intent_id ||
                (node.data.id === undefined && intent_id === "1")
              ) {
                switch (type) {
                  case "button":
                    node.data.artifacts.push({
                      element: "button",
                      value: "",
                      text: "",
                      value_save: "",
                      disabled: true
                    });
                    break;
                  case "text":
                    node.data.artifacts.push({ element: "text", text: "" });
                    break;
                }
              }

              var new_node = JSON.parse(JSON.stringify(node));
              new_node.data.updateIntent = updateIntent;
              new_node.data.updateNode = updateNode;
              return new_node;
            })
          );
          break;
      }
    },
    [reactFlowInstance]
  );

  const onDragOver = useCallback((e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  }, []);

  // called when user connects two nodes
  const onConnect = async (params) => {
    setSendingRequests(true);

    setEdges((els) => addEdge({ ...params, type: "edge" }, els));

    const target_node = nodes.filter((node) => node.id === params.target)[0],
      target_relations = target_node.data.metadata?.relations,
      source_node = nodes.filter((node) => node.id === params.source)[0],
      source_relations = source_node.data.metadata?.relations,
      artifact_value = params.sourceHandle.split("-")[2];

    var context_source = contexts.filter(
      (context) => context.name === `flow_${source_node.id}`
    )[0];
    if (!context_source) {
      context_source = await props.createContext(`flow_${source_node.id}`);
      setContexts((cnt) => cnt.concat(context_source));
    }

    const exist_context_target = target_node.data.contexts.input.filter(
        (input) => input === context_source.id
      )[0],
      exist_context_source = source_node.data.contexts.output.filter(
        (output) => output.id === context_source.id
      )[0],
      target_phrase = target_node.data.phrases.filter(
        (phrase) => phrase === artifact_value
      )[0],
      exist_relation = source_relations?.filter(
        (relation) =>
          relation.target === target_node.id &&
          relation.actions.value === artifact_value
      )[0];

    if (!exist_context_source) {
      source_node.data.contexts.output.push({
        id: context_source.id,
        lifespan: 1
      });
    }

    if (!exist_relation) {
      source_relations?.push({
        target: target_node.id,
        actions: {
          type: "button",
          value: artifact_value
        }
      });
    }

    await updateIntent("update_node", source_node.data);

    if (!exist_context_target) {
      target_node.data.contexts.input.push(context_source.id);
    }

    if (!target_phrase) {
      target_node.data.phrases.push(artifact_value);
    }

    target_relations?.push({
      source: source_node.id,
      actions: {
        type: "button",
        value: artifact_value
      }
    });

    await updateIntent("update_node", target_node.data);

    setSendingRequests(false);
  };

  // when the node drag stop, intent new coordinates is updated
  const onNodeDragStop = (e, element) => {
    e.preventDefault();
    updateIntent("coordinates", element);
  };

  // set element selected when drag start
  const onNodeDragStart = () => {};

  return (
    <ReactFlowProvider>
      <div
        style={{
          width: "100%",
          height: "100%",
          marginTop: 20,
          background: "white",
          borderRadius: "15px 0 0 15px"
        }}
        ref={reactFlowWrapper}
      >
        {sendingRequests && <div className={"is_sending"} />}
        <ReactFlow
          onInit={setReactFlowInstance}
          nodes={nodes}
          edges={JSON.parse(JSON.stringify(edges))}
          onNodesChange={onNodesChange}
          onConnect={onConnect}
          onDrop={onDrop}
          onDragOver={onDragOver}
          onNodeDragStart={onNodeDragStart}
          onNodeDragStop={onNodeDragStop}
          nodeTypes={props.nodeTypes}
          deleteKeyCode={null}
          selectionKeyCode={null}
          multiSelectionKeyCode={null}
          selectionOnDrag={false}
          fitView
        >
          <Background />
          <Controls />
        </ReactFlow>
      </div>
      <Sidebar nodes={nodes} actualProject={actualProject} />
    </ReactFlowProvider>
  );
};

export default Diagram;
