// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Routing
import {withRouter} from "react-router-dom";
// Translations
import {withTranslation} from "react-i18next";
//Components
import {ColorPicker} from "material-ui-color";

class ElementColor extends Component {

    render() {
        const {t, updateCustomization, element, value, value_updated} = this.props;
        return (
            <div className={"element__colors"}>
                <div className={"element__picker"}>
                    <ColorPicker
                        value={value}
                        disablePlainColor
                        onChange={(e) => {
                            updateCustomization(e, "color", value_updated);
                        }}
                    />
                </div>
                <span>{t(`card.content.project.web_interface.customization.color_picker.${element}`)}</span>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const a = connect(
    mapStateToProps
)(ElementColor);

export default withTranslation("common")(withRouter(a));