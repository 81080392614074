// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Routing
import {withRouter} from "react-router-dom";
// Translations
import {withTranslation} from "react-i18next";
// Material UI
import Pagination from "@material-ui/lab/Pagination";

import $ from "jquery";

class PaginationTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: this.props.i18n.language
        };
    }

    componentDidMount() {
        this.handleLabels();
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.max !== this.props.max || nextProps.i18n.language !== this.state.lang) {
            this.setState({lang: nextProps.i18n.language});
            this.handleLabels();
        }
    };

    handleLabels = () => {
        const {t} = this.props;
        $(".prev").remove();
        $(".next").remove();
        setTimeout(() => {
            if ($(".prev").length === 0) {
                var prevTxt = t("card.content.pagination.prev");
                $('[aria-label="Go to previous page"]:first-child').append($("<p>").addClass("prev").text(prevTxt));
            }
            if ($(".next").length === 0) {
                var nextTxt = t("card.content.pagination.next");
                $('[aria-label="Go to next page"]:first-child').append($("<p>").addClass("next").text(nextTxt));
            }
        }, 10);
    };

    render() {
        const {handlePagination, max, page} = this.props;
        return (
            <React.Fragment>
                <Pagination page={page} count={max} shape="rounded" onChange={handlePagination}/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const pagination = connect(
    mapStateToProps
)(PaginationTable);

export default withTranslation("common")(withRouter(pagination));
