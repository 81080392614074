// React
import React, { Component } from "react";

export class TabPanel extends Component {

    render() {
        const {children, value, index, ...other} = this.props;
        return (
            <div
                role="tabpanel"
                className={"cen_tabpanel"}
                hidden={value !== index}
                id={`project-tabpanel-${index}`}
                aria-labelledby={`project-tab-${index}`}
                {...other}
            >
                {value === index && (
                    children
                )}
            </div>
        );
    }
}