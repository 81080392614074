// React
import React, {Component} from "react";
import {integrationsSvg} from "@assets";

export class Channels extends Component {

    handleIntegrations = (integrations, project) => {
        return integrations?.map((integration, i) => {
            if (integration.channel === "gpt-3") {
                integration.channel = "chatgpt";
            }
            return <span key={i}
                         onClick={(e) => this.props.handleRedirect(e, "channels", project, integration)}>{integrationsSvg(integration.channel)}</span>;
        });
    };

    render() {
        const {project, deleted} = this.props;
        return (
            <div className={"dashboard_element channels"}>
                <span>{deleted ? project.deactivated_at : this.handleIntegrations(project.integrations, project)}</span>
            </div>
        );
    }
}
