import React, { Component } from "react";
import Success from "./success";
import Error from "./error";
import Info from "./info";

class Notify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.notify.isNotify !== nextProps.notify.isNotify &&
      nextProps.notify.isNotify
    ) {
      this.setState({ open: true });
    }
  }

  handleClose = (e) => {
    e.preventDefault();
    this.setState({ open: false });
  };

  render() {
    const { message, severity, type, item } = this.props.notify;
    return (
      <React.Fragment>
        {severity === "success" && this.state.open && (
          <Success
            onClose={this.handleClose}
            type={type}
            message={message}
            item={item}
          />
        )}
        {severity === "info" && this.state.open && (
          <Info onClose={this.handleClose} type={type} message={message} />
        )}
        {severity === "error" && this.state.open && (
          <Error onClose={this.handleClose} message={message} />
        )}
      </React.Fragment>
    );
  }
}

export default Notify;
