// React
import React, { Component } from "react";
// Styles
import "./styles.scss";

class OpenButton extends Component {

    render() {
        const {config, handleTabs} = this.props;
        return (
            <div onClick={(e) => handleTabs(e, "open_chat", "content")} className={`open_button ${!config.expanded ? "no_expand" : ""}`}>
                <div style={{backgroundColor: config.customization.open_bg_color, borderRadius: config.customization.open_shape}} className="open_button_container">
                    {
                        config.expanded &&
                            <span style={{color: config.customization.open_text_color}}>{config.text_expanded}</span>
                    }
                    {
                        config.customization.open_icon_show &&
                            <img src={config.chat_icon} alt={""}/>
                    }
                </div>
            </div>
        );
    }
}

export default OpenButton;