// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter } from "react-router-dom";
// Translations
import { withTranslation } from "react-i18next";
// Styles
import "views/Campaigns/styles/index.scss";
// Assets
import { campaignsSvg, whatsappSvg } from "assets";
import { documentSvg, downloadSvg } from "@assets";

class Preview extends Component {
  renderHeader = (type) => {
    const { template_selected } = this.props;
    switch (type) {
      case "text":
        return (
          <span className={"message_header text"}>
            {template_selected.header.text}
          </span>
        );
      case "image":
        return (
          <div className={"message_header image"}>
            <img src={template_selected.header.url} />
          </div>
        );
      case "document":
        var split_length = template_selected.header.url.split(".").length,
          ext = template_selected.header.url.split(".")[split_length - 1];
        return (
          <div
            onClick={() => window.open(template_selected.header.url)}
            className={"message_header document"}
          >
            <span>
              {documentSvg()}
              {`document.${ext}`}
            </span>
            <div>{downloadSvg()}</div>
          </div>
        );
    }
  };

  handleBody = (template, params) => {
    if (params?.length > 0) {
      params.forEach((param) => {
        if (param.value) {
          template.body = template?.body.replaceAll(
            param.match,
            `<strong>${param.value}</strong>`
          );
        }
      });
    } else if (
      template?.components?.length > 0 &&
      template?.components[0].type === "body"
    ) {
      template.components[0].example.body_text.forEach((param, i) => {
        template.body = template.body.replaceAll(
          `{{${i + 1}}}`,
          `<strong>${param}</strong>`
        );
      });
    }
    return template?.body;
  };

  render() {
    const { template_selected, params } = this.props;
    return (
      <div className={"campaigns_container_elements_preview"}>
        <div className={"campaigns_preview"}>
          <div
            className={"campaigns_preview_container"}
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/img/views/campaigns/whatsapp_wallpaper.png)`
            }}
          >
            <div className={"campaigns_preview_message"}>
              <span className={"campaigns_preview_message_svg"}>
                {whatsappSvg()}
              </span>
              <div className={"campaigns_preview_message_content"}>
                <div className={"message"}>
                  {template_selected?.header &&
                    this.renderHeader(template_selected.header.type)}
                  <div className={"message_content"}>
                    <div className={"message_content_body"}>
                      <div>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: this.handleBody(template_selected, params)
                          }}
                        />
                      </div>
                    </div>
                    <span className={"message_content_time"}>12:18</span>
                  </div>
                  {template_selected?.footer && (
                    <span className={"message_footer"}>
                      {template_selected.footer}
                    </span>
                  )}
                  <div className={"time"}>
                    <div>
                      <span>12:18</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {template_selected?.buttons && (
              <div className={"campaigns_preview_message"}>
                <div className={"message_button"}>
                  {template_selected.buttons.buttons.map((button, i) => {
                    return (
                      <div key={i}>
                        {template_selected.buttons.type === "call_to_action" &&
                          campaignsSvg(button.action)}
                        <span>{button.text}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const templates = connect(mapStateToProps)(Preview);

export default withTranslation(["commons", "common"])(withRouter(templates));
