// React
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// Translations
import { withTranslation } from "react-i18next";
// Components
import { Divider } from "@material-ui/core";
import { CopyBlock, dracula } from "react-code-blocks";
// Styles
import "./styles/styles.scss";
// Helpers
import { getCodeScript } from "@helpers";
import { openNewWindow } from "assets";
// Amplitude API
import { AmplitudeAPI } from "@api/Amplitude";

export class Share extends Component {
  openTestChat = () => {
    const { user, device_id } = this.props.data;
    const { project_id, chat_id } = this.props;
    window.open(
      `${process.env.PUBLIC_URL}/test_chat?project_id=${project_id}&chat_id=${chat_id}`
    );

    new AmplitudeAPI({
      event_type: "Test chatbot",
      device_id: device_id,
      user_id: user.email
    }).eventLog();
  };

  render() {
    const { t, project_id, chat_id } = this.props;
    return (
      <div className={"cen_card"}>
        <div className={"header"}>
          <div className={"header_content"}>
            <div className={"header_title"}>
              <span className="title">
                {t("commons:modals.copy.project.title")}
              </span>
            </div>
          </div>
          <div className={"header_actions"}>
            <div className={"test_chat"} onClick={() => this.openTestChat()}>
              {t("header.title.test_chat")}
              {openNewWindow()}
            </div>
          </div>
        </div>
        <div className={"content"}>
          <div className={"copy_main__content"}>
            <span>Widget</span>
            <p className="p2">
              {t("commons:modals.copy.project.widget_description")}
            </p>
            <CopyBlock
              text={getCodeScript("widget", project_id, chat_id)}
              language={"html"}
              showLineNumbers={true}
              theme={dracula}
              wrapLines={true}
              codeBlock
            />
            <Divider />
            <span>Div</span>
            <p className="p2">
              {t("commons:modals.copy.project.div_description")}
            </p>
            <CopyBlock
              text={getCodeScript("div", project_id, chat_id)}
              language={"html"}
              showLineNumbers={true}
              theme={dracula}
              wrapLines={true}
              codeBlock
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const connect_share = connect(mapStateToProps)(Share);

export default withTranslation(["common", "commons"])(
  withRouter(connect_share)
);
