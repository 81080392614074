import { CentribotAPI } from "api/infrastructure/centribot_api";

export const get_users = async (props) => {
  const { access, user } = props.data;

  let request = new CentribotAPI(
    "GET",
    `accounts/${user.account_id}/users`,
    null,
    access,
    props
  );
  var res = await request.action();

  return res ?? [];
};
