// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter } from "react-router-dom";
// API
import { CampaignsAPI } from "views/Campaigns/infrastructure";
// Vendor
import { showNotify } from "vendor/application/disptach";
// Components
import Preview from "../../components/Preview";
import Template from "./template";
import Webhook from "views/Campaigns/application/Webhook/webhook";
import { Button } from "@components/Input";
import { AlertInfo } from "@components/Alert";
// Helpers
import { isJSONString } from "@helpers";
// Jquery
import $ from "jquery";
// Translations
import { withTranslation } from "react-i18next";
// Styles
import "views/Bots/ChatBot/styles/index.scss";
// Amplitude API
import { AmplitudeAPI } from "@api/Amplitude";

class WebhookContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      webhook: false,
      isLoading: true,
      templates: false,
      template: "",
      whatsapp_phone: "",
      params: "",
      body: "",
      sender_phone: ""
    };
  }

  componentDidMount = () => {
    const { plan } = this.props.data;
    this.getTemplates(plan?.whatsapp_phones[0]);
  };

  getTemplates = async (phone) => {
    const { access } = this.props.data;
    try {
      let obj = new CampaignsAPI(phone.project_id, access, this.props);
      let templates = await obj.get_templates();

      var approved_templates = templates.filter(
        (template) => template?.status === "approved"
      );

      this.getWebhook(approved_templates, phone);
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  getWebhook = async (templates, phone) => {
    const { access, user } = this.props.data;
    try {
      let obj = new CampaignsAPI(user.account_id, access, this.props);
      let webhook = await obj.get_webhook();

      var centripush_active = true;
      webhook?.projects.forEach((project) => {
        if (!project.centripush_active) {
          centripush_active = false;
        }
      });

      if (!centripush_active) {
        await this.addWebhook();
      }

      this.handleTemplates(templates, phone, webhook, undefined);
    } catch (err) {
      var data = { message: err, severity: "error" };
      this.props.dispatch(showNotify(data));
    }
  };

  handleTemplates = (templates, phone, webhook, template_in) => {
    var template = "";
    if (template_in === undefined) {
      template = "";
    } else if (templates?.length > 0) {
      template = template_in
        ? template_in
        : this.state.template
          ? this.state.template
          : templates[0];
    }

    // TODO añadir aquí header y footer si lo tiene
    var body = JSON.stringify(
      {
        account_id: webhook.account_id,
        project_id: phone.project_id,
        message: {
          phone: this.state.sender_phone ? this.state.sender_phone : "",
          template_name: template?.name,
          language: template?.language,
          parameters: []
        }
      },
      null,
      4
    );

    var variable_regex = /{{\w*}}/gm,
      variables_count = template?.body?.match(variable_regex)?.length ?? 0;

    var params = [];
    for (var i = 0; i < variables_count; i++) {
      params.push("");
    }

    this.setState({
      webhook: webhook ?? this.state.webhook,
      templates: templates,
      whatsapp_phone: phone,
      template: template,
      isLoading: false,
      body: body,
      params: params
    });
  };

  addWebhook = async () => {
    const { access, user } = this.props.data;
    this.setState({ isLoading: true });
    try {
      let obj = new CampaignsAPI(user.account_id, access, this.props, {});
      var res = await obj.post_webhook(user.account_id);

      this.setState({ webhook: res, isLoading: false });

      return true;
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  updateElement = (e, type, sub_type, i) => {
    const { template } = this.state;
    var str = e?.target?.value;
    switch (type) {
      case "body":
        this.setState({ body: str });
        break;
      case "sender_phone":
        this.setState({
          sender_phone: str,
          body: this.state.body.replace(
            /(?:"phone": ")(.*)(?:")/g,
            `"phone": "${str}"`
          )
        });
        break;
      case "header_type":
        template.header.url = str;
        this.setState({ template: template });
        break;
      case "payload":
        template.buttons.buttons[i].payload = str;
        this.setState({ template: template });
        break;
    }
  };

  getUrl = () => {
    const env = process.env.REACT_APP_ENV;
    switch (env) {
      case "docker":
        return "http://messages.centribal.local/api/v1/push/single";
      case "pre":
        return "https://messagespre.centribal.com/api/v1/push/single";
      case "pro":
        return "https://messages.centribal.com/api/v1/push/single";
    }
  };

  handleSelect = (e, type, sub_type, i) => {
    var { params } = this.state;
    switch (type) {
      case "whatsapp_phone":
        this.setState({ whatsapp_phone: e, template: false });
        this.getTemplates(e);
        break;
      case "template":
        this.setState({ template: e });
        this.handleTemplates(
          this.state.templates,
          this.state.whatsapp_phone,
          this.state.webhook,
          e
        );
        break;
      case "params":
        params[i] = e.target.value;
        var body = JSON.parse(this.state.body);

        body.message.parameters = params;

        this.setState({ params: params, body: JSON.stringify(body, null, 4) });
        break;
    }
  };

  doRequest = async () => {
    const { body, webhook, template } = this.state;
    const { user, device_id } = this.props.data;

    var isJSON = isJSONString(body);

    if (!isJSON) {
      this.props.dispatch(
        showNotify({ message: "body_has_an_invalid_format", severity: "error" })
      );
    } else if (
      ["document", "image"].includes(template.header?.type) &&
      !template.header.url
    ) {
      this.props.dispatch(
        showNotify({ message: "url_is_required", severity: "error" })
      );
    } else {
      var new_body = this.handleBody();

      try {
        await $.ajax({
          url: webhook.url,
          type: "POST",
          data: JSON.stringify(new_body),
          contentType: "application/json",
          headers: {
            Authorization: `Bearer ${webhook.token}`
          },
          success: () => {
            new AmplitudeAPI({
              event_type: "Click on send individual campaign",
              device_id: device_id,
              user_id: user.email
            }).eventLog();

            this.props.dispatch(
              showNotify({
                message: "webhook_push",
                type: "create",
                severity: "success"
              })
            );
          }
        });
      } catch (err) {
        this.props.dispatch(showNotify({ message: err, severity: "error" }));
      }
    }
  };

  handleBody = (no_add_requester_id) => {
    const { user } = this.props.data;
    const { body, template } = this.state;

    if (body) {
      var requester_body = JSON.parse(body);
      if (!no_add_requester_id) {
        requester_body.requester_id = user.id;
      }

      if (["document", "image"].includes(template.header?.type)) {
        requester_body.message.header = {
          type: template.header.type,
          url: template.header.url
        };
      }
      if (
        template.buttons?.buttons.length > 0 &&
        template.buttons.type === "quick_reply"
      ) {
        template.buttons?.buttons.forEach((button, i) => {
          if (button.payload) {
            if (!requester_body.message.buttons) {
              requester_body.message.buttons = [];
            }
            requester_body.message.buttons.push({
              index: i,
              parameters: [
                {
                  type: "payload",
                  payload: button.payload
                }
              ]
            });
          }
        });
      }
      return requester_body;
    }
  };

  render() {
    const { actualLayout } = this.props.data;
    const {
      webhook,
      templates,
      template,
      sender_phone,
      whatsapp_phone,
      params
    } = this.state;
    const { t } = this.props;

    var body = this.handleBody(true);

    return (
      <div className="centribal_card">
        <AlertInfo
          text={t(`common:card.content.${actualLayout.layout}.info`)}
        />
        <div className={"campaigns_container_elements"}>
          <div className={"webhook"}>
            <Webhook
              body={JSON.stringify(body, null, 4)}
              webhook={JSON.parse(JSON.stringify(webhook))}
            />
            <AlertInfo
              text={t(`common:card.content.${actualLayout.layout}.info_2`)}
            />
            <Template
              handleSelect={this.handleSelect}
              templates={templates}
              template={JSON.parse(JSON.stringify(template))}
              whatsapp_phone={whatsapp_phone}
              updateElement={this.updateElement}
              sender_phone={sender_phone}
              params={params}
              doRequest={this.doRequest}
            />
          </div>
          <div className={"campaigns_container_preview"}>
            <Preview template_selected={JSON.parse(JSON.stringify(template))} />
            <div className={"campaigns_container_footer"}>
              <Button
                type={"success"}
                text={t(
                  `common:card.content.${actualLayout.layout}.action_button`
                )}
                onClick={() => this.doRequest()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const connect_project_webhook = connect(mapStateToProps)(WebhookContainer);

export default withTranslation(["commons", "common"])(
  withRouter(connect_project_webhook)
);
