// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Translations
import {withTranslation} from "react-i18next";
// Styles
import "components/Accordion/styles/styles.scss";
// Components
import {Input} from "@components/Input";
import {addArtifact} from "vendor/application";
import FloatButtons from "components/Input/float_buttons";

class Prompt extends Component {
    updateArtifacts = (e, i, artifact_type, type) => {
        const {intent, updateIntent} = this.props;

        var res = addArtifact(i, artifact_type, type, intent);

        updateIntent(res);
    };

    render() {
        const {intent, updateExtension, t} = this.props;
        return (
            <div className={"accordion__element_extension_prompt"}>
                <div className={"accordion__element_extension_prompt_header"}>
                    <span>{t("intents.config.accordion.extensions.content.prompt.title")}</span>
                </div>
                <div className={"accordion__element_extension_prompt_content"}>
                    <Input
                        type={"secondary"}
                        placeholder={t("intents.config.accordion.extensions.content.prompt.placeholder")}
                        multiline
                        inputProps={{maxLength: 4096}}
                        value={intent.extensions.prompt}
                        onChange={(e) => {
                            updateExtension(e, "prompt");
                        }}
                    />
                    <FloatButtons type={"extensions"} addArtifact={this.updateArtifacts}
                                  buttons_toggle_artifacts={["file", "img", "link", "button", "carousel", "bubble", "for"]}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const prompt = connect(
    mapStateToProps
)(Prompt);

export default withTranslation("common")(prompt);