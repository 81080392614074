import common from "./common.json";
import profile from "./profile.json";
import languages from "./languages.json";
import notifications from "./notifications.json";
import float_buttons from "./float_buttons.json";
import inputs from "./inputs.json";
import integrations from "./integrations.json";
import commons from "@translations/pt";
import tables from "./tables.json";

export default {
    commons: commons,
    common: common,
    profile: profile,
    languages: languages,
    notifications: notifications,
    float_buttons: float_buttons,
    tables: tables,
    inputs: inputs,
    integrations: integrations
};