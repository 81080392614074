// React
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
// Components
import TableElement from "./tableElement";
import PaginationTable from "components/Pagination/pagination";
import { AlertInfo } from "@components/Alert";
import { CircularProgress, Checkbox } from "@material-ui/core";

class TableV2 extends Component {
  handleItems = (isPaginated, page, items, normal) => {
    if (isPaginated && !normal) {
      if (items.length > 0) {
        return items[page - 1];
      }
      return items;
    }
    return items;
  };

  handleHeaderElements = (type, item, i) => {
    const { t, all_selected, handleSelect } = this.props;
    switch (item) {
      case "selected":
        return (
          <Checkbox
            checked={all_selected}
            onChange={() => {
              handleSelect("all");
            }}
            key={i}
          />
        );
      default:
        return (
          <span className={`campaigns_table_header_${item}`} key={i}>
            {t(`card.content.${type}.table.${item ? item : "null"}`)}
          </span>
        );
    }
  };

  render() {
    const {
      t,
      agents,
      items,
      header,
      categories,
      languages,
      templates,
      element_selected,
      type,
      onClick,
      conditional,
      max,
      page,
      handlePagination,
      handleSelect,
      normal
    } = this.props;

    return (
      <React.Fragment>
        <div className={"campaigns_table"}>
          <div className={"campaigns_table_header"}>
            {header.map((header_item, header_i) => {
              return this.handleHeaderElements(type, header_item, header_i);
            })}
          </div>

          <div className={"campaigns_table_elements"}>
            {conditional ? (
              items.length > 0 ? (
                this.handleItems(handlePagination, page, items, normal).map(
                  (item, row_i) => {
                    return (
                      <TableElement
                        element_selected={element_selected}
                        agents={agents}
                        onClick={onClick}
                        key={row_i}
                        i={row_i}
                        item={item}
                        type={type}
                        header={header}
                        categories={categories}
                        templates={templates}
                        languages={languages}
                        handleSelect={handleSelect}
                      />
                    );
                  }
                )
              ) : (
                <AlertInfo
                  className={"template_alert"}
                  text={t(`common:card.content.${type}.table.no_results`)}
                />
              )
            ) : (
              <div className="loading-data">
                <CircularProgress color="primary" size={70} />
              </div>
            )}
          </div>
          <div className={"campaigns_table_pagination"}>
            {handlePagination && max > 1 && (
              <PaginationTable
                handlePagination={handlePagination}
                max={max}
                page={page}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const connect_app = connect(mapStateToProps)(TableV2);

export default withRouter(withTranslation("common")(connect_app));
