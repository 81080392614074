// React
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
// Components
import { Tooltip, Checkbox } from "@material-ui/core";

class TableElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      height: 0
    };
  }

  handleElement = (item, type_element, i_element) => {
    const { t, agents, type, categories, languages, templates, handleSelect } =
      this.props;
    switch (type_element) {
      case "":
        return "";
      case "name":
        return (
          <div>
            <span>{item["name"]}</span>
            <span>{item["description"]}</span>
          </div>
        );
      case "status":
        return (
          <span className={`status ${item[type_element]}`}>
            {t(`card.content.${type}.table.status_type.${item[type_element]}`)}
          </span>
        );
      case "category":
        return (
          <span>
            {
              categories?.filter(
                (category) => category.tag === item.category
              )[0]?.name
            }
          </span>
        );
      case "language":
        return (
          <span>
            {
              languages?.filter(
                (language) => language.code === item.language
              )[0]?.language
            }
          </span>
        );
      case "template":
        return (
          <span>
            {
              templates?.filter(
                (template) => template.id === item.template_id
              )[0]?.name
            }
          </span>
        );
      case "agent":
        return (
          <span>
            {agents?.filter((agent) => agent.id === item.agent_id)[0]?.name}
          </span>
        );
      case "delivered_at":
        var delivered_failure_at = item["delivered_failure_at"],
          delivered_at = item["delivered_at"];
        if (delivered_failure_at) {
          return (
            <Tooltip
              title={item?.error}
              placement="bottom"
              enterDelay={200}
              leaveDelay={100}
            >
              <span>{delivered_failure_at}</span>
            </Tooltip>
          );
        }
        return delivered_at ?? "-";
      case "selected":
        return (
          <Checkbox
            checked={item.selected}
            onChange={() => {
              handleSelect("item", i_element);
            }}
          />
        );
      case "variables":
        return item.parameters.map((parameter, i) => {
          return <span key={i}>{parameter}</span>;
        });
      default:
        return <span>{item[type_element]}</span>;
    }
  };

  render() {
    const { item, header, onClick, element_selected, i } = this.props;

    var is_active = false;
    if (element_selected?.id && element_selected.id === item.id) {
      is_active = true;
    }

    return (
      <div
        onClick={() => (onClick ? onClick(item) : "")}
        className={`campaigns_table_element ${is_active ? "active" : ""}`}
      >
        {header.map((field_item, field_i) => {
          return (
            <div
              className={`campaigns_table_element_${field_item}`}
              key={field_i}
            >
              {this.handleElement(item, field_item, i)}
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const connect_app = connect(mapStateToProps)(TableElement);

export default withRouter(withTranslation("common")(connect_app));
