// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Routing
import {withRouter} from "react-router-dom";
// Material
// Translations
import {withTranslation} from "react-i18next";
import {IntegrationsAPI} from "api/application/Integrations";
import {showNotify} from "vendor/application/disptach";
// SVG
import {channelsSvg} from "assets";
import {arrowRightSvg} from "@assets";

class Create extends Component {
    constructor(props) {
        super(props);
        this.state = {
            info: ""
        };
    }

    componentDidMount = () => {
        this.getAppInfo();
    };

    getAppInfo = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props);
            const info = await obj.get_sunco_info("sunco");

            this.setState({info: info});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: "error"}));
        }
    };

    return = () => {
        const {history} = this.props;
        const {actualProject} = this.props.data;
        history.push(`/${actualProject.id}/integrations/sunco`);
    };

    render() {
        const {actualProject} = this.props.data;
        const {t} = this.props;
        return (
            <div className={"cen_integrations"}>
                <div className={"header"}>
                    <div className={"header_content"}>
                        <div onClick={() => this.return()} className={"header_action"}>
                            {arrowRightSvg()}
                        </div>
                        <div className={"header_image"}>
                            {channelsSvg("sunco")}
                        </div>
                        <div className={"header_title"}>
                            <span className={"title"}>{t("integrations:channels.sunco.title")}</span>
                            <span className={"description"}>{t("integrations:categories.customer")}</span>
                        </div>
                    </div>
                </div>
                <div className={"integration"}>
                    <div className={"elements_custom"}>
                        <div className={"elements_custom__info"}>
                                <span
                                    className="elements_custom__title">{t("integrations:channels.sunco.oauth.title")}</span>
                            <span
                                className="elements_custom__description">{t("integrations:channels.sunco.oauth.description")}</span>
                        </div>
                        <div className="platform_input_content platform_input_link">
                            <a href={`https://app.smooch.io/oauth/authorize?client_id=centribal&response_type=code&state=${actualProject.id}&redirect_uri=${this.state.info.redirect_url}`}>
                                <div>{channelsSvg("sunco")}</div>
                                <span>{t("integrations:channels.sunco.oauth.action_button")}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        data: state
    };
};

const create = connect(
    mapStateToProps
)(Create);

export default withTranslation("common")(withRouter(create));