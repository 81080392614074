
// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter, Redirect } from "react-router-dom";
// Components
import NotFound from "components/NotFound/application/404";
import NLP from "views/Bots/NLP/application";
// Containers

class RouteConfig extends Component {

    // method that selects which layout should display.
    setContent(project, layout, action) {
        const {user} = this.props.data;

        var permissions = true;

        if (user?.role?.name === "Trainer" && layout !== "training") {
            permissions = false;
        }

        if (permissions) {
            switch (action) {
                case "edit":
                    return <Redirect to={`${project}/${layout}`} />;
                case "create":
                    switch (layout) {
                        case "project":
                            return <Redirect to={"/project/create"} />;
                        default:
                            return <NotFound />;
                    }
                case "build": case "design": case "share":
                    return <NLP project={project} getLimits={this.props.getLimits} />;
                default:
                    return <NotFound />;
            }
        }
        else {
            switch(layout) {
                case "project" :
                    return (
                        <NotFound type={"layout"}/>
                    );
                default:
                    return (
                        <NotFound />
                    );
            }
        }
    }

    render() {
        const { match } = this.props;
        return (
            this.setContent(match.params.project, match.params.layout, match.params.action)
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const connect_route_config = connect(
    mapStateToProps
)(RouteConfig);

export default withRouter(connect_route_config);