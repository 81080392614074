// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Routing
import {withRouter} from "react-router-dom";
// Material
import {CircularProgress, Divider} from "@material-ui/core";
// Vendor
import {showNotify} from "vendor/application/disptach";
// Infrastructure
import {IntegrationsAPI} from "api/application/Integrations";
// Application
import {ElementsHandleAPI} from "vendor/application/handleMethods";
// Translations
import {withTranslation} from "react-i18next";
// Components
import DialogAction from "components/Dialog/DialogAction";
import {Button} from "@components/Input";
import {ElementsCustom} from "@components/Elements";
import Integrations from "views/Bots/Integrations/application/channels/InstagramChannels";
// SVG
import {channelsSvg} from "assets";
import {arrowRightSvg} from "@assets";

class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            body: "",
            tokenValid: true,
            isValid: true,
            open: false,
            delete: {
                name: ""
            },
            modes: ["bot", "desk", "no_response"],
            integrations: []
        };
    }

    componentDidMount() {
        this.getIntegration();
    }

    getIntegration = async () => {
        const {access, actualProject} = this.props.data;
        try {
            let obj = new IntegrationsAPI(actualProject.id, access, this.props);
            let integration = await obj.get_integration_by_id();

            this.getIntegrations(integration);
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: "error"}));
            this.return();
        }
    };

    getIntegrations = async (integration) => {
        const {access, actualProject} = this.props.data;
        const {modes} = this.state;
        try {
            let obj = new IntegrationsAPI(actualProject.id, access, this.props);
            let integrations = await obj.get_integrations();

            if (!integration.info.story_replies) {
                integration.info.story_replies = {
                    mode: "bot"
                };
            }

            var arr = [];
            integrations.forEach(integration => {
                if (["centridesk", "zendesk_full"].includes(integration.channel)) {
                    arr.push(integration.channel);
                }
            });

            if (!arr.length > 0) {
                modes.splice(1, 1);
            }

            this.setState({body: integration, modes, integrations: arr});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: "error"}));
            this.return();
        }
    };

    putIntegration = async (e) => {
        e.preventDefault();
        const {access, actualProject} = this.props.data;
        const {body} = this.state;

        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props, body);
            await obj.put_integration();

            this.props.dispatch(showNotify({message: "integrations", type: "edit", severity: "success"}));
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: "error"}));
        }
    };

    updateElement = (e, type, i) => {
        const {body} = this.state;
        const obj = new ElementsHandleAPI(body.info, e.target.value, type, i);
        const res = obj.updateObj();

        var init = true;

        Object.keys(body.info).forEach(el => {
            if (!body.info[el] && el !== "active") {
                init = false;
            }
        });
        body.info = res;
        this.setState({body: body, isValid: init});
    };

    updateMode = (e) => {
        const {body} = this.state;

        body.info.story_replies.mode = e;
        body.info.story_replies.desks = null;

        this.setState(body);
    };

    updateBody = (body) => {
        this.setState({body});
    };

    handleDisable = () => {
        const {body} = this.state;
        return body.info?.story_replies?.mode === "desk" && !body.info.story_replies.desks?.name;
    };

    return = () => {
        const {history} = this.props;
        const {actualProject} = this.props.data;
        history.push(`/${actualProject.id}/integrations`);
    };

    handleClickOpen = () => {
        this.setState({open: !this.state.open});
    };

    handleDelete = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props);
            await obj.delete_integration(this.state.body.id);

            this.props.dispatch(showNotify({message: "integrations", type: "delete", severity: "success"}));

            this.handleClickOpen();
            this.return();
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: "error"}));
        }
    };

    render() {
        const {body, open, modes, integrations} = this.state;
        const {actualLayout, isSending} = this.props.data;
        const {t} = this.props;
        return (
            <React.Fragment>
                <div className={"cen_integrations"}>
                    <div className={"header"}>
                        <div className={"header_content"}>
                            <div onClick={() => this.return()} className={"header_action"}>
                                {arrowRightSvg()}
                            </div>
                            <div className={"header_image"}>
                                {channelsSvg("instagram")}
                            </div>
                            <div className={"header_title"}>
                                <span className={"title"}>{t("integrations:channels.instagram.title")}</span>
                                <span className={"description"}>{t("integrations:categories.customer")}</span>
                            </div>
                        </div>
                        <div className={"header_actions"}>
                            {
                                isSending ? (
                                    <Button type={"primary"} disabled text={<CircularProgress size={21}/>}/>
                                ) : (
                                    <Button type={"primary"}
                                            disabled={this.handleDisable()}
                                            text={t("integrations:save.action_button")}
                                            onClick={(e) => this.putIntegration(e)}/>
                                )
                            }
                        </div>
                    </div>
                    {
                        body
                            ?
                            <div className={"integration"}>
                                <ElementsCustom
                                    t={t}
                                    type={"instagram"}
                                    input_type={"string"}
                                    element_type={"user_name_show"}
                                    element={body.info.user_name}
                                />
                                <Divider/>
                                <ElementsCustom
                                    t={t}
                                    type={"instagram"}
                                    input_type={"string"}
                                    element_type={"name_show"}
                                    element={body.info.name}
                                />
                                <Divider/>
                                <ElementsCustom
                                    t={t}
                                    type={"instagram"}
                                    input_type={"string"}
                                    element_type={"page_name_show"}
                                    element={body.info.page_name}
                                />
                                <Divider/>
                                <ElementsCustom
                                    t={t}
                                    type={"instagram"}
                                    input_type={"string"}
                                    element_type={"business_name_show"}
                                    element={body.info.business_name}
                                />
                            </div>
                            : <div className={"content"}>
                                <div className="loading">
                                    <CircularProgress color="primary" size={50}/>
                                </div>
                            </div>
                    }
                </div>
                {
                    body &&
                    <div className={"cen_integrations"}>
                        <div className={"header"}>
                            <div className={"header_content"}>
                                <div/>
                                <div/>
                                <div className={"header_title"}>
                                    <span className={"title"}>{t("integrations:channels.instagram.story_replies.title")}</span>
                                    <span className={"description"}>{t("integrations:channels.instagram.story_replies.description")}</span>
                                </div>
                            </div>
                        </div>
                        <div className={"integration"}>
                            <ElementsCustom
                                t={t}
                                type={"instagram"}
                                input_type={"select"}
                                element={t(`integrations:channels.instagram.story_replies.mode.${body.info.story_replies.mode}`)}
                                element_type={"mode"}
                                elements={modes}
                                updateElement={this.updateMode}
                            />
                            {
                                body.info.story_replies.mode === "desk" &&
                                <Integrations body={JSON.parse(JSON.stringify(body))} integrations={integrations}
                                              updateElement={this.updateBody}/>
                            }
                        </div>
                    </div>
                }
                {/* DELETE */}
                {
                    body &&
                    <div className={"cen_integrations delete"}>
                        <div className={"integration"}>
                            <div className={"integration_info"}>
                            <span
                                className={"integration_info_title"}>{t("integrations:channels.instagram.delete.title")}</span>
                                <span
                                    className={"integration_info_description"}>{t("integrations:channels.instagram.delete.description")}</span>
                            </div>
                            <Button type={"normal_delete"}
                                    text={t("integrations:channels.instagram.delete.action_button")}
                                    onClick={() => this.setState({open: true})}/>
                        </div>
                    </div>
                }
                <DialogAction t={t} actualLayout={actualLayout.layout} handleAction={this.handleDelete}
                              handleClickOpen={this.handleClickOpen} common open={open} item={this.state.delete}/>

            </React.Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        data: state
    };
};

const edit = connect(
    mapStateToProps
)(Edit);

export default withTranslation(["commons", "common"])(withRouter(edit));