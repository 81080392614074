// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Routing
import {withRouter} from "react-router-dom";
// Material
import {CircularProgress, Divider} from "@material-ui/core";
// Vendor
import {showNotify} from "vendor/application/disptach";
// Infrastructure
import {IntegrationsAPI} from "api/application/Integrations";
// Translations
import {withTranslation} from "react-i18next";
// Components
import DialogAction from "components/Dialog/DialogAction";
import {ElementsCustom} from "@components/Elements";
import {Button} from "@components/Input";
// SVG
import {channelsSvg} from "assets";
import {arrowRightSvg} from "@assets";

class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            body: "",
            tokenValid: true,
            isValid: true,
            open: false,
            delete: {
                name: ""
            }
        };
    }

    componentDidMount() {
        this.getIntegration();
    }

    getIntegration = async () => {
        const {access, actualProject} = this.props.data;
        try {
            let obj = new IntegrationsAPI(actualProject.id, access, this.props);
            let integration = await obj.get_integration_by_id();
            this.setState({body: integration});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: "error"}));
            this.return();
        }
    };

    return = () => {
        const {location, match, history} = this.props;
        var url = location.pathname.replace(`/messenger/edit/${match.params.id}`, "");
        history.push(url);
    };

    handleClickOpen = () => {
        this.setState({open: !this.state.open});
    };

    handleDelete = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props);
            await obj.delete_integration(this.state.body.id);

            this.props.dispatch(showNotify({message: "integrations", type: "delete", severity: "success"}));

            this.handleClickOpen();
            this.return();
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: "error"}));
        }
    };

    render() {
        const {body, open} = this.state;
        const {actualLayout} = this.props.data;
        const {t} = this.props;
        return (
            <React.Fragment>
                <div className={"cen_integrations"}>
                    <div className={"header"}>
                        <div className={"header_content"}>
                            <div onClick={() => this.return()} className={"header_action"}>
                                {arrowRightSvg()}
                            </div>
                            <div className={"header_image"}>
                                {channelsSvg("messenger")}
                            </div>
                            <div className={"header_title"}>
                                <span className={"title"}>{t("integrations:channels.messenger.title")}</span>
                                <span className={"description"}>{t("integrations:categories.customer")}</span>
                            </div>
                        </div>
                    </div>
                    {
                        body
                            ? <div className={"integration"}>
                                <ElementsCustom
                                    t={t}
                                    type={"messenger"}
                                    input_type={"string"}
                                    element_type={"user_name_show"}
                                    element={body.info.user_name}
                                />
                                <Divider/>
                                <ElementsCustom
                                    t={t}
                                    type={"messenger"}
                                    input_type={"string"}
                                    element_type={"page_name_show"}
                                    element={body.info.page_name}
                                />
                            </div>
                            : <div className={"content"}>
                                <div className="loading">
                                    <CircularProgress color="primary" size={50}/>
                                </div>
                            </div>
                    }
                </div>
                {/* DELETE */}
                {
                    body &&
                    <div className={"cen_integrations delete"}>
                        <div className={"integration"}>
                            <div className={"integration_info"}>
                            <span
                                className={"integration_info_title"}>{t("integrations:channels.messenger.delete.title")}</span>
                                <span
                                    className={"integration_info_description"}>{t("integrations:channels.messenger.delete.description")}</span>
                            </div>
                            <Button type={"normal_delete"}
                                    text={t("integrations:channels.messenger.delete.action_button")}
                                    onClick={() => this.setState({open: true})}/>
                        </div>
                    </div>
                }
                <DialogAction t={t} actualLayout={actualLayout.layout} handleAction={this.handleDelete}
                              handleClickOpen={this.handleClickOpen} common open={open} item={this.state.delete}/>
            </React.Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        data: state
    };
};

const edit = connect(
    mapStateToProps
)(Edit);

export default withTranslation(["commons", "common"])(withRouter(edit));