// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Translations
import { withTranslation } from "react-i18next";
// Styles
import "components/Accordion/styles/styles.scss";
// Components
import FloatButtons from "components/Input/float_buttons";
import Tooltip from "@components/Tooltip";
import { Input, Button } from "@components/Input";
// Vendor
import { addArtifact } from "vendor/application";

class Responses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focus: false,
      active: false,
      iteration: false,
    };
  }

  updateArtifacts = (e, i, artifact_type, type) => {
    const { intent, updateIntent } = this.props;

    var res = addArtifact(i, artifact_type, type, intent);

    updateIntent(res);
  };

  handleLimitsResponses = () => {
    const { limits, intent } = this.props;

    if (!intent.extensions.active) {
      if (limits.responses.total < limits.responses.max) {
        return (
          limits.intents_create.intent_responses_total >=
          limits.intents_create.intent_responses
        );
      }
      return true;
    } else if (intent.responses.length >= 1) return true;
  };

  render() {
    const { type, updateElement, intent, t } = this.props;
    return (
      <div className={"accordion__element"}>
        <div className={"accordion__element_header"}>
          <span>
            {t(`intents.config.accordion.${type}.responses.title`)}{" "}
            <Tooltip
              text={t(`intents.config.accordion.${type}.responses.description`)}
            />
          </span>
        </div>
        <div className={"accordion__element_content phrases"}>
          {intent.responses.length > 0 &&
            intent.responses.map((el, i) => {
              return (
                <div className={"accordion__elements"} key={i}>
                  <Input
                    id={`intent-phrases-${i}`}
                    type={`secondary`}
                    placeholder={t(
                      `intents.config.accordion.${type}.responses.placeholder`
                    )}
                    multiline
                    inputProps={{ maxLength: 4096 }}
                    value={el}
                    onChange={(e) =>
                      updateElement(e, "responses", "string_array", i)
                    }
                  />
                  <FloatButtons
                    type={"responses"}
                    addArtifact={this.updateArtifacts}
                    buttons_toggle_actions={["delete"]}
                    buttons_toggle_artifacts={[
                      "file",
                      "img",
                      "link",
                      "button",
                      "carousel",
                      "bubble",
                      "for",
                    ]}
                    iteration={i}
                    onDelete={() =>
                      updateElement(null, "responses", "delete", i)
                    }
                  />
                </div>
              );
            })}
          <Button
            disabled={this.handleLimitsResponses()}
            type={"dashed"}
            onClick={(e) => {
              updateElement(e, "responses", "add");
            }}
            text={t(`intents.config.accordion.${type}.responses.button`)}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state,
  };
};

const accordion_phrases = connect(mapStateToProps)(Responses);

export default withTranslation("common")(accordion_phrases);
