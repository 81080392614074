// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Svg
import {artifactsSvg, configSvg, deleteSvg} from "assets";
// Jquery
import $ from "jquery";
// Translate
import {withTranslation} from "react-i18next";
// Components
import {Conditional} from "@components/Modals";

class FloatButtons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            action: false,
            open: false
        };
    }

    componentDidMount() {
        this.handleClickOutside();
    }

    handleClickOutside = () => {

        $(document).on("click", function (e) {
            const {active, action} = this.state;
            if (action && $(e.target).is(".float_toggle") === true) {
                this.setState({action: false});
            } else if (action && $(e.target).is(".float_toggle") === false) {
                this.setState({action: false, active: false});
            } else if (active || active && $(e.target).is(".float_toggle") === true) {
                this.setState({active: false, action: false});
            }
        }.bind(this));

    };

    handleActive = (e) => {
        e.preventDefault();
        this.setState({action: this.state.active === false, active: !this.state.active});

        if ($(".float_buttons").length === 1) {
            e.stopPropagation();
        }
    };

    getActionItem = (button, i) => {
        const {type, onDelete, iteration} = this.props;
        switch (button) {
            case "delete":
                return (
                    <div onClick={(e) => {
                        onDelete(e, type, iteration);
                    }} key={i} className={`float_item float_item_${i + 1} ${this.state.active ? "active" : ""}`}>
                        <a>{deleteSvg()}</a>
                    </div>
                );
        }
    };

    getArtifactItem = (button, i) => {
        const {addArtifact, type, iteration, t, element, positionBottom} = this.props;
        switch (button) {
            case "button":
            case "for":
            case "link":
            case "img":
            case "carousel":
            case "bubble":
            case "reply_button":
            case "list_message":
            case "file":
                return (
                    <div onClick={(e) => addArtifact(e, iteration, button, type, element)} key={i}
                         className={`float_item float_item_${i + 1} ${positionBottom ? "bottom" : ""} ${this.state.active ? "active" : ""}`}>
                        <div>{t(button)}</div>
                        <a>{artifactsSvg(button)}</a>
                    </div>
                );
        }
    };

    handleClickOpen = () => {
        this.setState({open: !this.state.open});
    };

    render() {
        const {t, buttons_toggle_artifacts, buttons_toggle_actions, type, iteration, onDelete} = this.props;
        return (
            <React.Fragment>
                <div className={"float_buttons"}>
                    {
                        buttons_toggle_actions && buttons_toggle_actions.length > 0 &&
                        <div className={"float_buttons_actions"}>
                            {buttons_toggle_actions.map((button, i) => {
                                return (
                                    this.getActionItem(button, i)
                                );
                            })}
                        </div>
                    }
                    {
                        buttons_toggle_artifacts && buttons_toggle_artifacts.length > 0 &&
                        <div className={`float_buttons_artifacts ${type}_${iteration ?? ""}`}>
                            <a onClick={(e) => this.handleActive(e)}
                               className={`float_toggle ${this.state.active ? "active" : ""}`}>
                                {configSvg()}
                            </a>
                            <div className={"float_actions"}>
                                {buttons_toggle_artifacts.map((button, i) => (
                                    this.getArtifactItem(button, i)
                                ))}
                            </div>
                        </div>
                    }
                </div>
                <Conditional t={t} type={type} open={this.state.open} handleClose={this.handleClickOpen}
                             handleAction={(e) => {
                                 onDelete(e, iteration, type);
                                 this.handleClickOpen();
                             }}/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const float_buttons = connect(
    mapStateToProps
)(FloatButtons);

export default withTranslation("float_buttons")(float_buttons);