// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Routing
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
// Components
import NotFound from "components/NotFound/application/404";
import {showNotify} from "vendor/application/disptach";
// Views
import ChatGPTCreate from "views/Bots/Integrations/application/channels/ChatGPT/create";
import ChatGPTEdit from "views/Bots/Integrations/application/channels/ChatGPT/edit";
import ChatGPTView from "views/Bots/Integrations/application/channels/ChatGPT/view";
import WhatsappEdit from "views/Bots/Integrations/application/channels/Whatsapp/edit";
import WhatsappView from "views/Bots/Integrations/application/channels/Whatsapp/view";
import ZendeskFullCreate from "views/Bots/Integrations/application/channels/ZendeskFull/create";
import ZendeskFullEdit from "views/Bots/Integrations/application/channels/ZendeskFull/edit";
import ZendeskFullView from "views/Bots/Integrations/application/channels/ZendeskFull/view";
import ZendeskGuideCreate from "views/Bots/Integrations/application/channels/ZendeskGuide/create";
import ZendeskGuideEdit from "views/Bots/Integrations/application/channels/ZendeskGuide/edit";
import ZendeskGuideView from "views/Bots/Integrations/application/channels/ZendeskGuide/view";
import ZendeskChatCreate from "views/Bots/Integrations/application/channels/ZendeskChat/create";
import ZendeskChatEdit from "views/Bots/Integrations/application/channels/ZendeskChat/edit";
import ZendeskChatView from "views/Bots/Integrations/application/channels/ZendeskChat/view";
import ZendeskCentrideskCreate from "views/Bots/Integrations/application/channels/Centridesk/create";
import ZendeskCentrideskEdit from "views/Bots/Integrations/application/channels/Centridesk/edit";
import ZendeskCentrideskView from "views/Bots/Integrations/application/channels/Centridesk/view";
import FacebookMessengerCreate from "views/Bots/Integrations/application/channels/Messenger/create";
import FacebookMessengerEdit from "views/Bots/Integrations/application/channels/Messenger/edit";
import FacebookMessengerView from "views/Bots/Integrations/application/channels/Messenger/view";
import InstagramCreate from "views/Bots/Integrations/application/channels/Instagram/create";
import InstagramEdit from "views/Bots/Integrations/application/channels/Instagram/edit";
import InstagramView from "views/Bots/Integrations/application/channels/Instagram/view";
import TelegramCreate from "views/Bots/Integrations/application/channels/Telegram/create";
import TelegramEdit from "views/Bots/Integrations/application/channels/Telegram/edit";
import TelegramView from "views/Bots/Integrations/application/channels/Telegram/view";
import SuncoCreate from "views/Bots/Integrations/application/channels/Sunco/create";
import SuncoEdit from "views/Bots/Integrations/application/channels/Sunco/edit";
import SuncoView from "views/Bots/Integrations/application/channels/Sunco/view";
import WordpressView from "views/Bots/Integrations/application/channels/Wordpress/view";
import IntegrationsDirectory from "views/Bots/Integrations/application/directory";

class RouteIntegrations extends Component {
    setContentView = (channel) => {
        switch (channel) {
            case "chatgpt":
                return <ChatGPTView/>;
            case "whatsapp" :
                return <WhatsappView/>;
            case "zendesk_full" :
                return <ZendeskFullView/>;
            case "zendesk_chat" :
                return <ZendeskChatView/>;
            case "zendesk_guide" :
                return <ZendeskGuideView/>;
            case "centridesk" :
                return <ZendeskCentrideskView/>;
            case "messenger" :
                return <FacebookMessengerView/>;
            case "telegram" :
                return <TelegramView/>;
            case "sunco" :
                return <SuncoView/>;
            case "instagram" :
                return <InstagramView/>;
            case "wordpress":
                return <WordpressView />;
            default :
                return <NotFound/>;
        }
    };

    setContentEdit = (channel) => {
        switch (channel) {
            case "chatgpt":
                return <ChatGPTEdit/>;
            case "whatsapp" :
                return <WhatsappEdit/>;
            case "zendesk_full" :
                return <ZendeskFullEdit/>;
            case "zendesk_chat" :
                return <ZendeskChatEdit/>;
            case "zendesk_guide" :
                return <ZendeskGuideEdit/>;
            case "centridesk" :
                return <ZendeskCentrideskEdit/>;
            case "messenger" :
                return <FacebookMessengerEdit/>;
            case "telegram" :
                return <TelegramEdit/>;
            case "sunco" :
                return <SuncoEdit/>;
            case "instagram" :
                return <InstagramEdit/>;
            default :
                return <NotFound/>;
        }
    };

    setContentCreate = (channel) => {
        if (!this.props.location.pathname.includes("/edit") && channel !== "directory" && this.handleIntegrationsLimits(channel)) {
            switch (channel) {
                case "chatgpt":
                    return <ChatGPTCreate/>;
                case "zendesk_full" :
                    return <ZendeskFullCreate/>;
                case "zendesk_chat" :
                    return <ZendeskChatCreate/>;
                case "zendesk_guide" :
                    return <ZendeskGuideCreate/>;
                case "centridesk" :
                    return <ZendeskCentrideskCreate/>;
                case "messenger" :
                    return <FacebookMessengerCreate/>;
                case "telegram" :
                    return <TelegramCreate/>;
                case "sunco" :
                    return <SuncoCreate/>;
                case "instagram" :
                    return <InstagramCreate/>;
                default :
                    return <NotFound/>;
            }
        } else {
            const {location} = this.props;
            return <Redirect to={location.pathname.replace(`/${channel}`, "")}/>;
        }
    };

    handleIntegrationsLimits = (channel) => {
        const {limitsIntegrations, plan} = this.props.data;
        if (plan?.platform?.centridesk) {
            return true;
        } else if (!limitsIntegrations[channel]) {
            this.props.dispatch(showNotify({message: "channel_not_found", severity: "error"}));
            return false;
        } else if (!limitsIntegrations[channel].in_plan) {
            this.props.dispatch(showNotify({message: "channel_no_plan", severity: "error"}));
            return false;
        } else if (!limitsIntegrations[channel].available) {
            this.props.dispatch(showNotify({message: "channel_not_available", severity: "error"}));
            return false;
        }
        return true;
    };

    render() {
        const {match} = this.props;
        return (
            <Switch>
                <Route path="/:project/integrations/directory" exact>
                    <IntegrationsDirectory />
                </Route>
                <Route path="/:project/integrations/:channel" exact>
                    {this.setContentView(match.params.channel)}
                </Route>
                <Route path="/:project/integrations/:channel/edit/:id" exact>
                    {this.setContentEdit(match.params.channel)}
                </Route>
                <Route path="/:project/integrations/:channel/create" exact>
                    {this.setContentCreate(match.params.channel)}
                </Route>
            </Switch>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const connect_route_integrations = connect(
    mapStateToProps
)(RouteIntegrations);

export default withRouter(connect_route_integrations);