// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Components
import DialogAction from "components/Dialog/DialogAction";
import {
  Button,
  Search,
  SelectMultiple,
  SelectSimple
} from "@components/Input";
import { Copy } from "@components/Modals";
import PaginationTable from "components/Pagination/pagination";
import Tooltip from "@components/Tooltip";
// Material
import { CircularProgress, IconButton } from "@material-ui/core";
// Infrastructure
import {
  getMonthNames,
  handleFilterMethod,
  handleFilterSort,
  handleRedirectToSandbox,
  multipleIntentSelector,
  paginationSelector,
  returnElementFromArray
} from "vendor/application";
// Vendor
import { updateFilters } from "vendor/application/disptach";
// SVG
import {
  channelsSvg,
  deleteSvg,
  editSvg,
  openNewWindow,
  sortBySvg,
  svgDownload,
  tableDisableSvg,
  tableSuccessSvg
} from "assets";
// Translations
import { withTranslation } from "react-i18next";

class CardList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delete: false,
      deploy: [],
      list: this.props.list,
      completeArray: this.props.completeArray,
      actualPage: 1,
      max: this.props.max ?? 0,
      actualLayout: props.layout || props.data.actualLayout.layout
    };
  }

  componentDidMount = () => {
    const { actualLayout } = this.state;
    const { filters } = this.props.data;

    switch (actualLayout) {
      case "stats":
        this.arrayUpdate(0, null, null, actualLayout);
        break;
      case "entities":
      case "contexts":
        this.handleFilter();
        break;
      case "intents":
        this.handleFilter(filters.intents.context_selector.id, "contexts");
        break;
      default:
        break;
    }
  };

  // method that reloads the component when the current project has been changed
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    var arrayPrev = JSON.stringify(this.props.completeArray);
    var arrayNext = JSON.stringify(nextProps.completeArray);

    var listPrev = JSON.stringify(this.props.list);
    var listNext = JSON.stringify(nextProps.list);

    var layout = nextProps.layout;

    if (arrayPrev !== arrayNext) {
      if (layout === "intents" || layout === "stats" || layout === "entities") {
        this.setState({
          list: nextProps.list,
          completeArray: nextProps.completeArray,
          max: nextProps.max
        });
      }
    }

    if (
      listPrev !== listNext &&
      layout !== "intents" &&
      layout !== "stats" &&
      layout !== "entities"
    ) {
      this.setState({ list: nextProps.list });
    }
  };

  arrayUpdate = (i, data, page, from) => {
    const { completeArray } = this.state;
    const { filters } = this.props.data;

    var array = JSON.parse(JSON.stringify(data ? data : completeArray)),
      arr = [],
      spl;

    switch (from) {
      case "stats":
        spl = 4;
        break;
      case "intents":
      case "contexts":
      case "entities":
        spl =
          filters[from].pagination.interval === "\u221e"
            ? this.props.completeArray.length
            : filters[from].pagination.interval;
    }

    while (array.length) {
      arr.push(array.splice(0, spl));
    }

    if (arr.length < page && from === "entities") {
      page = 1;
      i = 0;
      filters[from].pagination.page = page;
      this.props.dispatch(updateFilters(filters));
    }

    if (arr.length > 0) {
    }

    arr.length > 0
      ? from === "intents"
        ? this.setState({
            list: completeArray,
            max: this.props.max,
            actualPage: page ? page : 1
          })
        : this.setState({
            list: arr[i ? i : 0],
            max: arr.length,
            actualPage: page ? page : 1
          })
      : this.setState({
          list: arr[0],
          max: arr.length,
          actualPage: page ? page : 1
        });
  };

  redirect = () => {
    const { history, type } = this.props;
    var status;
    switch (type) {
      case "add":
      case "project":
        status = "create";
    }
    history.push(`${history.location.pathname}/${status}`);
  };

  handleHeader = (layout) => {
    const { t } = this.props;
    const { actualLayout } = this.state;
    const { filters } = this.props.data;
    switch (layout) {
      case "contexts":
        return (
          <tr>
            <th>{t(`card.content.${actualLayout}.table.name`)}</th>
            <th>{t(`card.content.${actualLayout}.table.description`)}</th>
            <th />
          </tr>
        );
      case "users":
        return (
          <tr>
            <th>{t(`card.content.${actualLayout}.table.name`)}</th>
            <th>{t(`card.content.${actualLayout}.table.role`)}</th>
            <th>{t(`card.content.${actualLayout}.table.project`)}</th>
            <th />
          </tr>
        );
      case "intents":
        return (
          <tr>
            <th>
              <div onClick={() => this.handleSortHeader("name", "intents")}>
                {t(`card.content.${actualLayout}.table.name`)}
                {sortBySvg(filters.intents, "name")}
              </div>
            </th>
            <th>{t(`card.content.${actualLayout}.table.input_context`)}</th>
            <th>{t(`card.content.${actualLayout}.table.output_context`)}</th>
            <th>
              <div
                onClick={() => this.handleSortHeader("updated_at", "intents")}
              >
                {t(`card.content.${actualLayout}.table.date`)}
                {sortBySvg(filters.intents, "updated_at")}
              </div>
            </th>
            <th>Desk</th>
            <th>Params</th>
            <th>Ext</th>
            <th>Cond</th>
            <th>DF</th>
            <th />
          </tr>
        );
      case "entities":
        return (
          <tr>
            <th>{t(`card.content.${actualLayout}.table.name`)}</th>
            <th>{t(`card.content.${actualLayout}.table.kind`)}</th>
            <th>{t(`card.content.${actualLayout}.table.date`)}</th>
            <th />
          </tr>
        );
      case "chats":
        return (
          <tr>
            <th>{t(`card.content.${actualLayout}.table.name`)}</th>
            <th>{t(`card.content.${actualLayout}.table.description`)}</th>
            <th />
            <th />
          </tr>
        );
      case "stats":
        return (
          <tr>
            <th>{t("stats.sessions_and_intents.table.year")}</th>
            <th>{t("stats.sessions_and_intents.table.month")}</th>
            <th>{t("stats.sessions_and_intents.table.access")}</th>
            <th>{t("stats.sessions_and_intents.table.intents")}</th>
          </tr>
        );
    }
  };

  handleContent = (layout, handleClickOpen) => {
    const { list, actualLayout } = this.state;
    const { t, isLoading } = this.props;
    switch (layout) {
      case "contexts":
        return list && list.length > 0 ? (
          list.map((el, i) => {
            return (
              <tr key={i}>
                <td className="bck-contexts">{el.name}</td>
                <td>{el.description}</td>
                <td className="svg delete">
                  <IconButton
                    onClick={(e) => {
                      handleClickOpen(e);
                      this.changeValue(e, el);
                    }}
                    style={{ backgroundColor: "transparent" }}
                    disableRipple
                    disableFocusRipple
                    aria-label="delete"
                  >
                    {deleteSvg()}
                  </IconButton>
                </td>
              </tr>
            );
          })
        ) : (
          <tr className="table-search">
            <td colSpan="3">
              {this.props.t("card.content.contexts.table.no_results")}
            </td>
          </tr>
        );
      case "project":
        return list.map((el, i) => {
          return (
            <React.Fragment key={i}>
              {!el.sandbox && (
                <tr key={i}>
                  <td className="bck-project">{el.name}</td>
                  <td>
                    <div>
                      <span className={"ellipsis"}>{el.description}</span>
                      <Copy
                        project_id={el.id}
                        chat_id={el.chat_id}
                        type={actualLayout}
                        t={t}
                      />
                    </div>
                  </td>
                  <td style={{ textTransform: "uppercase" }}>{el.lang}</td>
                  <td className={`svg ${el.sandbox_id ? "active" : ""}`}>
                    {el.sandbox_id &&
                      (el.sandbox_id === this.props.data.actualProject.id ? (
                        <IconButton
                          className={"edit"}
                          onClick={() => {
                            handleRedirectToSandbox(el.sandbox_id, false);
                          }}
                          style={{ backgroundColor: "transparent" }}
                          disableRipple
                          disableFocusRipple
                          aria-label={"sandbox"}
                        >
                          {editSvg()}
                        </IconButton>
                      ) : (
                        <IconButton
                          className={"open_window"}
                          onClick={() => {
                            handleRedirectToSandbox(el.sandbox_id, true);
                          }}
                          style={{ backgroundColor: "transparent" }}
                          disableRipple
                          disableFocusRipple
                          aria-label={"sandbox"}
                        >
                          {openNewWindow()}
                        </IconButton>
                      ))}
                  </td>
                  <td className="svg edit">
                    <IconButton
                      onClick={(e) => {
                        this.editElement(e, el.id);
                      }}
                      style={{ backgroundColor: "transparent" }}
                      disableRipple
                      disableFocusRipple
                      aria-label="delete"
                    >
                      {editSvg()}
                    </IconButton>
                  </td>
                </tr>
              )}
            </React.Fragment>
          );
        });
      case "intents":
        return list && list.length > 0 ? (
          isLoading ? (
            <tr className={"table-loading"}>
              <td style={{ borderRadius: 10 }} colSpan={"10"}>
                <div style={{ height: 427 }} className="">
                  <div className="loading-data">
                    <CircularProgress color="primary" size={70} />
                  </div>
                </div>
              </td>
            </tr>
          ) : (
            list.map((el, i) => {
              return (
                <tr
                  key={i}
                  onClick={(e) => {
                    this.editElement(e, el.id);
                  }}
                >
                  <td className="bck-intents">{el.name}</td>
                  <td>
                    <div className={"text-selector"}>
                      {el.contexts.input.map((el_input, el_input_iteration) => {
                        return (
                          <div key={el_input_iteration}>
                            {this.getContextElement(el_input)}
                          </div>
                        );
                      })}
                    </div>
                  </td>
                  <td>
                    <div className={"text-selector"}>
                      {el.contexts.output.map(
                        (el_output, el_output_iteration) => {
                          return (
                            <div key={el_output_iteration}>
                              {this.getContextElement(el_output.id)}
                              <div>
                                <span>{el_output.lifespan}</span>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </td>
                  <td>{this.handleDate(el.created_at, el.updated_at)}</td>
                  <td>
                    <div>{el?.desks?.name && channelsSvg(el?.desks?.name)}</div>
                  </td>
                  <td>
                    <div>{el.parameters?.length > 0 && tableSuccessSvg()}</div>
                  </td>
                  <td>
                    <div>
                      {el.extensions?.active && tableSuccessSvg()}
                      {el.extensions?.active === false && tableDisableSvg()}
                    </div>
                  </td>
                  <td>
                    <div>
                      {el.conditional_responses?.length > 0 &&
                        tableSuccessSvg()}
                    </div>
                  </td>
                  <td>
                    <div>{el.is_default && tableSuccessSvg()}</div>
                  </td>
                  <td className="svg">
                    <div className="delete">
                      <IconButton
                        onClick={(e) => {
                          handleClickOpen(e);
                          this.changeValue(e, el);
                        }}
                        style={{ backgroundColor: "transparent" }}
                        disableRipple
                        disableFocusRipple
                        aria-label="delete"
                      >
                        {deleteSvg()}
                      </IconButton>
                    </div>
                  </td>
                </tr>
              );
            })
          )
        ) : (
          <tr className="table-search">
            <td colSpan="10">
              {this.props.t("card.content.intents.table.no_results")}
            </td>
          </tr>
        );
      case "entities":
        return list && list.length > 0 ? (
          list.map((el, i) => {
            return (
              <tr
                key={i}
                onClick={(e) => {
                  this.editElement(e, el.id);
                }}
              >
                <td className="bck-entities">{el.name}</td>
                <td>
                  {this.props.t(
                    `card.content.entities.table.kind_types.${el.kind}`
                  )}
                </td>
                <td>{this.handleDate(el.created_at, el.updated_at)}</td>
                <td className="svg">
                  <div className="delete">
                    <IconButton
                      disabled={el.in_use}
                      onClick={(e) => {
                        handleClickOpen(e);
                        this.changeValue(e, el);
                      }}
                      style={{ backgroundColor: "transparent" }}
                      disableRipple
                      disableFocusRipple
                      aria-label="delete"
                    >
                      {deleteSvg()}
                    </IconButton>
                  </div>
                </td>
              </tr>
            );
          })
        ) : (
          <tr className="table-search">
            <td colSpan="5">
              {this.props.t("card.content.entities.table.no_results")}
            </td>
          </tr>
        );
      case "stats":
        return list.map((el, i) => {
          return (
            <tr key={i}>
              <td className="bck-stats">{el.year}</td>
              <td>{this.setMonthName(el.month)}</td>
              <td className="">
                <IconButton
                  onClick={(e) => {
                    this.target(e, el, "access");
                  }}
                  style={{ backgroundColor: "transparent" }}
                  disableRipple
                  disableFocusRipple
                  aria-label="delete"
                >
                  {svgDownload()}
                </IconButton>
              </td>
              <td className="">
                <IconButton
                  onClick={(e) => {
                    this.target(e, el, "intents");
                  }}
                  style={{ backgroundColor: "transparent" }}
                  disableRipple
                  disableFocusRipple
                  aria-label="delete"
                >
                  {svgDownload()}
                </IconButton>
              </td>
            </tr>
          );
        });
    }
  };

  target = (e, element, type) => {
    e.preventDefault();
    var urls = element.urls;
    urls.forEach((el) => {
      if (el.type === type) {
        window.open(el.url);
      }
    });
  };

  setMonthName = (month) => {
    const monthNames = getMonthNames(this.props.i18n.language);
    return monthNames[month - 1];
  };

  handleDate = (created, updated) => {
    var date = updated ?? created,
      split;
    split = date.split(":");
    return `${split[0]}:${split[1]}`;
  };

  handleSortHeader = (sort_by, type) => {
    const { filters } = this.props.data;

    var old_value = filters[type].order_by.value,
      old_type = filters[type]?.order_by.type;

    if (sort_by !== old_type) {
      filters[type].order_by.type = sort_by;
      filters[type].order_by.value = "asc";
    } else if (sort_by === old_type) {
      filters[type].order_by.value = old_value === "desc" ? "asc" : "desc";
    }

    this.props.dispatch(updateFilters(filters));

    this.props.handlePagination();
  };

  handleSort = (type, action, arrayIncome) => {
    const { completeArray } = this.state;
    const { filters } = this.props.data;

    handleFilterSort(
      type,
      action,
      arrayIncome,
      completeArray,
      filters,
      this,
      updateFilters
    );
  };

  getContextElement = (id) => {
    const { contexts } = this.props;
    var element = returnElementFromArray(id, contexts, "id");
    if (element) {
      return element.name;
    }
  };

  handleFilter = (e, type, arrayIncome) => {
    const { completeArray, handlePagination } = this.props;
    const { filters } = this.props.data;

    handleFilterMethod(
      e,
      type,
      arrayIncome,
      completeArray,
      filters,
      this,
      updateFilters,
      handlePagination
    );
  };

  handlePagination = (e, value, array) => {
    const { filters } = this.props.data;
    const { actualLayout } = this.state;

    if (value) {
      if (
        actualLayout === "intents" ||
        actualLayout === "entities" ||
        actualLayout === "contexts"
      ) {
        filters[actualLayout].pagination.page = value;

        this.props.dispatch(updateFilters(filters));
      }
      if (actualLayout === "intents") {
        this.props.handlePagination();
      } else {
        this.arrayUpdate(value - 1, "", value, actualLayout);
      }
    } else {
      this.arrayUpdate(
        filters[actualLayout].pagination.page - 1,
        array,
        filters[actualLayout].pagination.page,
        actualLayout
      );
    }
  };

  selectPagination = (e) => {
    const { filters } = this.props.data;
    const { actualLayout } = this.state;

    filters[actualLayout].pagination.interval = e;
    filters[actualLayout].pagination.page = 1;

    this.props.dispatch(updateFilters(filters));

    if (actualLayout === "intents") {
      this.props.handlePagination();
    } else {
      this.arrayUpdate(null, null, null, actualLayout);
    }
  };

  changeValue = (e, value) => {
    e.preventDefault();
    this.setState({ delete: value });
    e.stopPropagation();
  };

  editElement = (e, id) => {
    e.preventDefault();
    const { history, handleTabs } = this.props;
    const { actualLayout } = this.props.data;
    if (actualLayout.layout === "nlp") {
      history.push(
        `${history.location.pathname}${history.location.search}&action=edit&id=${id}`
      );
      handleTabs();
    } else {
      history.push(`${history.location.pathname}/edit/${id}`);
    }
  };

  render() {
    const { max, actualPage, actualLayout } = this.state;
    const { filters } = this.props.data;
    const {
      t,
      createModal,
      handleClickOpen,
      handleClickLink,
      handleDelete,
      open,
      isLimited,
      contexts
    } = this.props;
    return (
      <React.Fragment>
        <div className={"cen_card"}>
          <div className={"header"}>
            <div className={"header_content"}>
              <div className={"header_title"}>
                <span className={"title"}>
                  {t(`card.content.${actualLayout}.header_title`)}
                </span>
                <span className={"description"}>
                  {t(`card.content.${actualLayout}.header_description`)}
                </span>
              </div>
            </div>
            <div className={"header_actions"}>
              {/* PAGINATION LEVELS */}
              {(actualLayout === "intents" || actualLayout === "entities") && (
                <SelectSimple
                  label={t(`card.content.${actualLayout}.selector_pagination`)}
                  maxWidth={200}
                  valueSelected={
                    filters[actualLayout].pagination.interval === "\u221e"
                      ? t(`card.content.${actualLayout}.all_pagination`)
                      : filters[actualLayout].pagination.interval
                  }
                  placeholder={t(
                    `card.content.${actualLayout}.selector_pagination`
                  )}
                  items={paginationSelector(actualLayout)}
                  onSelect={(e) => this.selectPagination(e)}
                  className={"select_secondary"}
                  t={t}
                />
              )}
              {/* FILTER BY PARAMS ITEMS */}
              {actualLayout === "intents_v2" && (
                <SelectMultiple
                  label
                  valueSelected={filters.intents.params}
                  items={multipleIntentSelector()}
                  placeholder={t(
                    `card.content.${actualLayout}.selector_multiple`
                  )}
                  onSelect={(e) => this.handleFilter(e, "params")}
                  id={"select_multiple_params"}
                  className={"select_secondary"}
                  t={t}
                />
              )}
              {/* FILTER BY CONTEXT */}
              {actualLayout === "intents" && (
                <SelectSimple
                  label={t("card.content.intents.filter_contexts")}
                  valueSelected={
                    filters?.intents?.context_selector?.name
                      ? filters?.intents?.context_selector?.name
                      : false
                  }
                  items={contexts}
                  onSelect={(e) => this.handleFilter(e, "contexts")}
                  placeholder={t("card.content.intents.filter_contexts")}
                  type={"contexts_intents"}
                  className={"select_secondary"}
                  t={t}
                />
              )}
              {/* SEARCH BAR */}
              {(actualLayout === "intents" ||
                actualLayout === "entities" ||
                actualLayout === "contexts") && (
                <Search
                  label={actualLayout === "intents"}
                  t={t}
                  placeholder={t("card.content.intents.search")}
                  onSearch={this.handleFilter}
                  value={filters[actualLayout]?.search}
                />
              )}
              <React.Fragment>
                {["intents", "entities"].includes(actualLayout) ? (
                  <React.Fragment>
                    {isLimited ? (
                      <Button
                        id={`new_${actualLayout}`}
                        type={"primary"}
                        disabled={true}
                        text={t(`card.content.${actualLayout}.button_create`)}
                      />
                    ) : (
                      <Button
                        id={`new_${actualLayout}`}
                        type={"primary"}
                        onClick={(e) => {
                          actualLayout === "project"
                            ? handleClickOpen()
                            : handleClickLink();
                          this.changeValue(e, false);
                        }}
                        text={t(`card.content.${actualLayout}.button_create`)}
                      />
                    )}
                  </React.Fragment>
                ) : (
                  <Button
                    id={`new_${actualLayout}`}
                    type={"primary"}
                    onClick={(e) => {
                      createModal();
                    }}
                    text={t(`card.content.${actualLayout}.button_create`)}
                  />
                )}
              </React.Fragment>
            </div>
          </div>
          <div className={"content"}>
            <table className={`table-${actualLayout}`}>
              <thead>{this.handleHeader(actualLayout)}</thead>
              <tbody>{this.handleContent(actualLayout, handleClickOpen)}</tbody>
            </table>
            {actualLayout === "intents" && max > 1 && (
              <PaginationTable
                handlePagination={this.handlePagination}
                max={max}
                page={filters.intents.pagination.page}
              />
            )}
            {actualLayout === "entities" && max > 1 && (
              <PaginationTable
                handlePagination={this.handlePagination}
                max={max}
                page={filters.entities.pagination.page}
              />
            )}
            {this.state.delete && (
              <DialogAction
                t={t}
                actualLayout={actualLayout}
                handleAction={handleDelete}
                handleClickOpen={handleClickOpen}
                open={open}
                item={this.state.delete}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const connect_cardlist = connect(mapStateToProps)(CardList);

export default withTranslation(["common", "commons"])(connect_cardlist);
