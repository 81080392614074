// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Translations
import { withTranslation } from "react-i18next";
// SVG
import { addSvg, minimizeSvg, deleteSvg } from "assets";
// Views
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
// Styles
import { withStyles } from "@material-ui/core/styles";
import "components/Accordion/styles/styles.scss";
// Components
import {TextField} from "@material-ui/core";
import FloatButtons from "../Input/float_buttons";

const Accordion = withStyles({
    root: {
        border: "none",
        boxShadow: "none",
        borderRadius: "5px 5px 0 0",
        backgroundColor: "#565671",
        marginBottom: 25,
        filter: "drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25))"
    }
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        minHeight: 40,
        "&$expanded": {
            minHeight: 40,
        },
        fontSize: 14,
        padding: "0px 21px",
        color: "#f4f4f4"
    },
    content: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "&$expanded": {
            margin: "12px 0"
        }
    },
    expanded: {
        // minHeight: 'inherit'
    }
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
    root: {
        backgroundColor: "#f4f4f4",
        padding: "10px"
    }
})(MuiAccordionDetails);

class AccordionPolls extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        };
    }

    handleToggleOne = () => {
        this.setState({ expanded: !this.state.expanded });
    };

    render() {
        const { item, t, onDelete, i, updateElement, defaultLang, handleArtifacts } = this.props;
        return (
            <Accordion
                square
                expanded={this.state.expanded}
            >
                <AccordionSummary
                    onClick={(e) => this.handleToggleOne(e)}
                    expandIcon={
                        !this.state.expanded
                            ? <span>{addSvg()}</span>
                            : <span>{minimizeSvg()}</span>
                    }
                >
                    {t(`languages:languages.${item.lang}`)}
                    {item.lang !== defaultLang &&
                    <div onClick={(e) => onDelete(e, item.lang)} className={"accordion__delete_header"}>
                        <span>{t("card.content.project.polls.messages.card.delete")}</span>
                        {deleteSvg()}
                    </div>
                    }
                </AccordionSummary>
                <AccordionDetails>
                    <div className={"accordion__content"}>
                        <div className={"accordion__element_text"}>
                            {/* question_one */}
                            <span>{t("card.content.project.polls.messages.card.question_one")}</span>
                            <div className={"accordion__element_artifacts"}>
                                <TextField
                                    className="input-accordion"
                                    id="question_one"
                                    placeholder={t("card.content.project.polls.messages.card.placeholder")}
                                    multiline
                                    value={item.question_one ? item.question_one : ""}
                                    onChange={(e) => {
                                        updateElement(e, "question_one", i);
                                    }}
                                />
                                <FloatButtons type={"polls"} element={"question_one"} addArtifact={handleArtifacts} iteration={i} buttons_toggle_artifacts={["file", "img", "link", "button", "carousel", "bubble", "for"]} />
                            </div>
                        </div>
                        {/* question_two */}
                        <div className={"accordion__element_text"}>
                            <span>{t("card.content.project.polls.messages.card.question_two")}</span>
                            <div className={"accordion__element_artifacts"}>
                                <TextField
                                    className="input-accordion"
                                    id="question_two"
                                    placeholder={t("card.content.project.polls.messages.card.placeholder")}
                                    multiline
                                    value={item.question_two ? item.question_two : ""}
                                    onChange={(e) => {
                                        updateElement(e, "question_two", i);
                                    }}
                                />
                                <FloatButtons type={"polls"} element={"question_two"} addArtifact={handleArtifacts} iteration={i} buttons_toggle_artifacts={["file", "img", "link", "button", "carousel", "bubble", "for"]} />
                            </div>
                        </div>
                        {/* goodbye */}
                        <div className={"accordion__element_text"}>
                            <span>{t("card.content.project.polls.messages.card.goodbye")}</span>
                            <div className={"accordion__element_artifacts"}>
                                <TextField
                                    className="input-accordion"
                                    id="goodbye"
                                    placeholder={t("card.content.project.polls.messages.card.placeholder")}
                                    multiline
                                    value={item.goodbye ? item.goodbye : ""}
                                    onChange={(e) => {
                                        updateElement(e, "goodbye", i);
                                    }}
                                />
                                <FloatButtons type={"polls"} element={"goodbye"} addArtifact={handleArtifacts} iteration={i} buttons_toggle_artifacts={["file", "img", "link", "button", "carousel", "bubble", "for"]} />
                            </div>
                        </div>
                        {/* inactivity */}
                        <div className={"accordion__element_text"}>
                            <span>{t("card.content.project.polls.messages.card.inactivity")}</span>
                            <div className={"accordion__element_artifacts"}>
                                <TextField
                                    className={"input-accordion"}
                                    id={"inactivity"}
                                    placeholder={t("card.content.project.polls.messages.card.placeholder")}
                                    multiline
                                    value={item.inactivity ? item.inactivity : ""}
                                    onChange={(e) => {
                                        updateElement(e, "inactivity", i);
                                    }}
                                />
                                <FloatButtons type={"polls"} element={"inactivity"} addArtifact={handleArtifacts} iteration={i} buttons_toggle_artifacts={["file", "img", "link", "button", "carousel", "bubble", "for"]} />
                            </div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const c = connect(
    mapStateToProps
)(AccordionPolls);

export default withTranslation(["common", "languages"])(c);