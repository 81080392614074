import {CentrideskAPI} from "api/infrastructure/centridesk_api";

export const get_customers_filter = async (props, filter) => {
    const {access, user} = props.data;

    var obj = {};
    if (filter?.str && filter?.type !== "agent_id") {
        obj[filter.type] = filter.str;
    } else if (filter?.type === "agent_id" && filter?.str !== "-") {
        obj[filter.type] = filter.str;
    }

    let request = new CentrideskAPI("POST", `accounts/${user.account_id}/customers/filter?page_size=${1000}&sort=created_at&order=desc`, JSON.stringify(obj), access, props);
    return await request.action();
};