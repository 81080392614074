// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter } from "react-router-dom";
// Infrastructure
import { EntitiesAPI } from "views/Bots/NLP/application/Build/Tabs/Entities/infrastructure";
// Components
import CardList from "components/Card/CardList";
import CardEmpty from "components/Card/CardEmpty";
// Vendor
import { showNotify } from "vendor/application/disptach";
// Translations
import { withTranslation } from "react-i18next";
import {CircularProgress} from "@material-ui/core";

class Entities extends Component {
    constructor(props) {
        super(props);
        this.state = {
            array: false,
            isLoading: true,
            completeArray: false,
            open: false
        };
    }

    componentDidMount() {
        this.getEntities();
    }

    // method that reloads the component when the current project has been changed
    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.actualProject.id !== this.props.data.actualProject.id) {
            setTimeout(() => {
                this.setState({isLoading: true});
                this.getEntities();
            }, 100);
        }
    };

    getEntities = async () => {
        const { access, actualProject } = this.props.data;
        try {
            let obj = new EntitiesAPI(actualProject.id, access, this.props);
            let array = await obj.get_entities();
            this.setState({ array: array.entities, completeArray : array.entities, isLoading: false });
        } catch (err) {
            this.props.dispatch(showNotify({ message: err, severity: "error" }));
        }
    };

    deleteEntity = async (e, id) => {
        e.stopPropagation();
        const { access, actualProject } = this.props.data;
        try {
            let obj = new EntitiesAPI(actualProject.id, access, this.props);
            await obj.delete_entity(id);

            this.props.dispatch(showNotify({ message: "entities", type: "delete", severity: "success" }));
            this.handleClickToggle();
            this.setState({ isLoading: true });
            this.getEntities();
        } catch (err) {
            this.props.dispatch(showNotify({ message: err, severity: "error" }));
        }
    };

    handleClickLink = () => {
        const { history, location } = this.props;
        history.push(`${history.location.pathname}${location.search}&action=create`);

        this.props.handleTabs();
    };

    handleClickToggle = () => {
        const { open } = this.state;
        this.setState({ open: !open });
    };

    render() {
        const { array, open, completeArray, isLoading } = this.state;
        return (
            !isLoading && array ?
            <React.Fragment>
                {array.length > 0 ? (
                    <CardList
                        data={this.props.data}
                        t={this.props.t}
                        handleClickOpen={this.handleClickToggle}
                        handleClickLink={this.handleClickLink}
                        handleDelete={this.deleteEntity}
                        handleTabs={this.props.handleTabs}
                        history={this.props.history}
                        list={array}
                        completeArray={completeArray}
                        open={open}
                        layout={"entities"}
                    />
                ) : (
                    <CardEmpty
                        data={this.props.data}
                        t={this.props.t}
                        handleClickLink={this.handleClickLink}
                        open={open}
                        type={"entities"}
                    />
                )}
            </React.Fragment> :
            <div className="card">
                <div className="loading-data">
                    <CircularProgress color="primary" size={70} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const connect_intents = connect(
    mapStateToProps
)(Entities);

export default withTranslation("common")(withRouter(connect_intents));