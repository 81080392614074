// Helpers
import {returnElementFromArray} from "@helpers";

export const get_agents = (users, roles) => {
    var agents = [];

    users.forEach(user => {
        const role = user.roles[0];
        const user_role = returnElementFromArray(role, roles, "id");

        if (user_role.desk) {
            agents.push({name: `${user.first_name} ${user.last_name}`, id: user.id});
        }
    });

    agents.unshift({name: "-", id: "-"});

    return agents;
};