import { showNotify } from "vendor/application/disptach";

export const validExtensions = (variables, dispatch) => {
  for (let variable of variables) {
    if (variable.zendesk_ticket_field) {
      if (
        variable.zendesk_ticket_field.custom_field_options &&
        variable.zendesk_ticket_field.custom_field_options.length > 0 &&
        variable.zendesk_ticket_field.equivalences.length === 0
      ) {
        dispatch(
          showNotify({
            message: "extension_equivalences_type_is_required",
            severity: "error",
          })
        );
        return false;
      }
    }

    if (variable.variable === "") {
      dispatch(
        showNotify({
          message: "extensions_name_is_required",
          severity: "error",
        })
      );
      return false;
    }
  }
  return true;
};
