// React
import React, { Component } from "react";
// Styles
import "components/Accordion/styles/styles.scss";
// Components
import { Chip } from "@material-ui/core";
import { Input } from "@components/Input";
import { AlertInfo } from "@components/Alert";

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: ""
    };
  }

  deleteEvent = (i) => {
    const { intent, updateIntent } = this.props;

    intent.events.splice(i, 1);

    updateIntent(intent);
  };

  handleEvents = (e, type, i) => {
    const { intent, updateIntent } = this.props;

    var event = e.target.value;
    switch (type) {
      case "update":
        if (event.length < 151 && event.match(/^[a-zA-Z0-9_\-\/]*$/)) {
          this.setState({ event: event });
        }
        break;
      case "delete":
        intent.events.splice(i, 1);
        updateIntent(intent);
        break;
      case "enter":
        if (/[a-zA-Z]/.test(event)) {
          intent.events.push(event);
          this.setState({ event: "" });
          updateIntent(intent);
        }
        break;
    }
  };

  render() {
    const { intent, t } = this.props;
    const { event } = this.state;
    return (
      <div className={"accordion__element"}>
        <div className={"accordion__element_header"}>
          <div>
            <span>{t("intents.config.accordion.phrases.events.title")}</span>
          </div>
        </div>
        <div
          className={`accordion__element_events ${intent.events?.length === 0 ? "accordion__element_contexts_flex" : ""}`}
        >
          <Input
            type="secondary"
            value={event}
            onChange={(e) => this.handleEvents(e, "update")}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.handleEvents(e, "enter");
              }
            }}
            placeholder={t(
              "intents.config.accordion.phrases.events.placeholder"
            )}
          />
          <div className={"elements__chips"}>
            {intent.events?.length > 0 ? (
              intent.events?.map((el, i) => {
                return (
                  <Chip
                    key={i}
                    label={<span>{el}</span>}
                    variant="outlined"
                    onDelete={(e) => this.handleEvents(e, "delete", i)}
                  />
                );
              })
            ) : (
              <AlertInfo
                text={t("intents.config.accordion.phrases.events.info")}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Events;
