import React from "react";
import { showNotify } from "./disptach";

export const getCookieByName = (cname) => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      var a = atob(c.substring(name.length, c.length));
      if (a !== "undefined") {
        return JSON.parse(a);
      } else return false;
    }
  }
  return false;
};

export const setCookieTraining = (state) => {
  var expires = new Date();
  expires.setTime(new Date().getTime() + 60 * 60 * 1000);

  document.cookie = [
    `bc__t=${btoa(
      JSON.stringify(state)
    )}; expires=${expires}; SameSite=None; Secure;`,
  ].join("");
};

export const setCookieByName = (state, cname) => {
  var date = new Date();
  if (cname === "b__ck") {
    date.setTime(date.getTime() + 3600 * 1000 * 24 * 30);
  } else if (cname === "b__lg" || cname === "b__di") {
    date.setTime(date.getTime() + 3600 * 1000 * 24 * 365);
  } else {
    date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
  }
  var expires = "; path=/; expires=" + date.toGMTString();
  var cookie = `${cname}=${btoa(JSON.stringify(state))}${expires}`;
  document.cookie = cookie;
};

export const deleteAllCookies = () => {
  const cookies = ["b__ac", "b__ap", "b__ly"];
  for (var cookie of cookies) {
    document.cookie = [
      `${cookie}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/;`,
    ].join("");
  }
};

export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

export const idExist = (id, array, iteration) => {
  for (var x = 0; x < array.length; x++) {
    if (array[x].id === id) {
      return iteration ? x : true;
    }
  }
  return false;
};

export const isFreeTrial = (plan) => {
  return plan === "free_trial";
};

export const isPlanS = (plan) => {
  return plan === "plan_s";
};

export const isPlanAllowed = (plan) => {
  return (
    plan.startsWith("consulting") ||
    plan === "professional" ||
    plan === "professional_trial"
  );
};

export const emailValidity = (email) => {
  var regEx =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !!email.match(regEx);
};

export const getRGBColors = () => {
  return [
    "rgb(95, 158, 252)",
    "rgb(255, 152, 0)",
    "rgb(0, 230, 118)",
    "rgb(237, 152, 140)",
    "rgb(0, 115, 230)",
    "rgb(0, 31, 143)",
    "rgb(0, 158, 120)",
    "rgb(194, 151, 76)",
    "rgb(89, 74, 45)",
    "rgb(255,120,82)",
    "rgb(61, 211, 102)",
    "rgb(110, 140, 255)",
    "rgb(61, 12, 78)",
  ];
};

export const getRGBAColors = () => {
  return [
    "rgba(95, 158, 252, 0.4)",
    "rgba(255, 152, 0, 0.4)",
    "rgba(0, 230, 118, 0.4)",
    "rgba(237, 152, 140, 0.4)",
    "rgba(0, 115, 230, 0.4)",
    "rgba(0, 31, 143, 0.4)",
    "rgba(0, 158, 120, 0.4)",
    "rgba(194, 151, 76, 0.4)",
    "rgba(89, 74, 45, 0.4)",
    "rgba(255,120,82, 0.4)",
    "rgba(61, 211, 102, 0.4)",
    "rgba(110, 140, 255, 0.4)",
    "rgba(61, 12, 78, 0.4)",
  ];
};

export const returnElementFromArray = (el, array, type) => {
  for (var x = 0; x < array.length; x++) {
    if (array[x][type] === el) {
      return array[x];
    }
  }
  return false;
};

export const addParamsProjectValue = (array) => {
  for (var x = 0; x < array.length; x++) {
    var str = array[x].name.replace(" ", "-");
    array[x].text = str.toLowerCase();
  }
  return array;
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const expiredDate = (day, date) => {
  var date_set = new Date(date.setDate(date.getDate() + day));
  var actualDate = new Date();
  if (date_set < actualDate) {
    return date_set;
  }
  return false;
};

export const convertException = (exc) => {
  var res;
  if (typeof exc === "object") {
    res = "internal_server_error";
  } else {
    res = exc
      .toLowerCase()
      .replaceAll(".", "")
      .replaceAll("/", "")
      .replaceAll("'", "")
      .replaceAll('"', "")
      .replaceAll(",", "")
      .replaceAll(":", "")
      .replaceAll("-", "")
      .replaceAll(" ", "_")
      .replaceAll("(", "")
      .replaceAll(")", "");
  }
  return res;
};

export const getMonthNames = (lang) => {
  switch (lang) {
    case "es":
      return [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
    case "pt":
      return [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
    case "en":
    case "default":
      return [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
  }
};

export const alphaNumeric = (str) => {
  var regEx = /^[a-z0-9]+$/i,
    isValid = false;
  str.match(regEx) ? (isValid = true) : (isValid = false);
  return isValid;
};

export const subdomainIsValid = (subdomain) => {
  var regEx = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/,
    isValid = false;
  subdomain.match(regEx) ? (isValid = true) : (isValid = false);
  return isValid;
};

export const isJsonString = (str) => {
  const type = str
    .replace("'c", '"c')
    .replaceAll(" ", "")
    .replaceAll(/\s+/g, "");
  try {
    JSON.parse(type);
    return true;
  } catch (e) {
    return false;
  }
};

export const handleRedirectToSandbox = (sandbox_id, new_window) => {
  new_window
    ? window.open(`${process.env.PUBLIC_URL}/${sandbox_id}/chatbot`)
    : (window.location.href = `${process.env.PUBLIC_URL}/${sandbox_id}/chatbot`);
};

export const addArtifact = (i, artifact_type, type, element, sub_element) => {
  var line_break = "",
    text = "",
    artifact = "";

  switch (type) {
    case "inactivity":
      text = element.messages[i].response;
      break;
    case "business":
      text = element.messages[i].message;
      break;
    case "web_interface":
      text = element.text_welcome;
      break;
    case "sunco_bot_message":
      text = element.info.zendesk.return_to_bot_message;
      break;
    case "polls":
      text = element.messages[i][sub_element];
      break;
    case "responses":
      sub_element === "edit"
        ? (text = element.responses[i].text)
        : (text = element.responses[i]);
      break;
    case "params":
      text = element.parameters[i].prompts[0];
      break;
    case "extensions":
      text = element.extensions.prompt;
      break;
    case "conditional_responses":
      text = element.conditional_responses[i].response;
      break;
    case "chatgpt":
      text = element.info.back_to_bot.message.response;
      break;
  }

  if (text && text.length > 0) {
    line_break = "\n";
  }

  switch (artifact_type) {
    case "whatsapp":
      artifact = "{whatsapp}text{/whatsapp}";
      break;
    case "button":
      artifact = '{button value=""}text{/button}';
      break;
    case "for":
      artifact = "{for $. in $.}\n" + "   text\n" + "{/for}";
      break;
    case "link":
      artifact = '{link type="text|button" href=""}text{/link}';
      break;
    case "img":
      artifact = '{img src=""}';
      break;
    case "carousel":
      artifact =
        "{carousel}\n" +
        '   {item type="image|video" src="" link="" value=""}text{/item}\n' +
        "{/carousel}";
      break;
    case "bubble":
      artifact = "{bubble}";
      break;
    case "reply_button":
      artifact =
        '{reply_button body="" header_type="text|document|image|video" header_value="" footer=""}\n' +
        '   {reply value=""}\n' +
        "{/reply_button}";
      break;
    case "list_message":
      artifact =
        '{list_message body="" button_text="" header="" footer=""}\n' +
        '  {section title=""}\n' +
        '    {row title="" desc=""}\n' +
        '    {row title="" desc=""}\n' +
        "  {/section}\n" +
        "{/list_message}";
      break;
    case "file":
      artifact = '{file type="pdf|txt|csv|xlsx|docx|other" src=""}';
  }

  switch (type) {
    case "inactivity":
      element.messages[i].response += line_break + artifact;
      break;
    case "business":
      element.messages[i].message += line_break + artifact;
      break;
    case "web_interface":
      element.text_welcome += line_break + artifact;
      break;
    case "sunco_bot_message":
      element.info.zendesk.return_to_bot_message += line_break + artifact;
      break;
    case "polls":
      element.messages[i][sub_element] += line_break + artifact;
      break;
    case "responses":
      sub_element === "edit"
        ? (element.responses[i].text += line_break + artifact)
        : (element.responses[i] += line_break + artifact);
      break;
    case "params":
      element.parameters[i].prompts[0] += line_break + artifact;
      break;
    case "extensions":
      element.extensions.prompt += line_break + artifact;
      break;
    case "conditional_responses":
      text = element.conditional_responses[i].response += line_break + artifact;
      break;
    case "chatgpt":
      text = element.info.back_to_bot.message.response += line_break + artifact;
      break;
  }

  return element;
};

export const handleCopy = (copyText) => {
  const textWithoutDollar = copyText.replace("$", "");

  const textArea = document.createElement("textarea");
  textArea.value = textWithoutDollar;
  document.body.appendChild(textArea);
  textArea.select();
  try {
    document.execCommand("copy");
    showNotify("success", "Copied to clipboard");
  } catch (err) {
    showNotify("error", "Failed to copy");
  }
  document.body.removeChild(textArea);
};

export const returnEntrenadorRole = () => {
  return [{ tooltip: "Academy", name: "academy", type: "centribot" }];
};

export const returnAdministradorRole = () => {
  return [
    { tooltip: "Chatbot", name: "chatbot", type: "centribot" },
    { tooltip: "NLP", name: "nlp", type: "centribot" },
    { tooltip: "Stats", name: "stats", type: "centribot" },
    { tooltip: "Integrations", name: "integrations", type: "centribot" },
    { tooltip: "Test", name: "test_chat", type: "centribot", new_tab: true },
    { tooltip: "Help", name: "help_chat", type: "centribot", new_tab: true },
    { tooltip: "Sandbox", name: "sandbox", type: "centribot", new_tab: true },
    { tooltip: "Academy", name: "academy", type: "centribot" },
  ];
};

export const returnSuperAdminRole = () => {
  return [
    { tooltip: "Chatbot", name: "chatbot", type: "centribot" },
    { tooltip: "NLP", name: "nlp", type: "centribot" },
    { tooltip: "Stats", name: "stats", type: "centribot" },
    { tooltip: "Integrations", name: "integrations", type: "centribot" },
    { tooltip: "Test", name: "test_chat", type: "centribot", new_tab: true },
    { tooltip: "Help", name: "help_chat", type: "centribot", new_tab: true },
    { tooltip: "Sandbox", name: "sandbox", type: "centribot", new_tab: true },
    { tooltip: "Academy", name: "academy", type: "centribot" },
  ];
};

export const returnCampaignsSidebar = () => {
  return [
    {
      tooltip: "Templates",
      name: "templates",
      type: "campaigns",
      disabled: false,
    },
    { tooltip: "History", name: "history", type: "campaigns", disabled: false },
    {
      tooltip: "Notifications",
      name: "notifications",
      type: "campaigns",
      disabled: false,
    },
    { tooltip: "Webhook", name: "webhook", type: "campaigns", disabled: false },
  ];
};

export const weekdaysBody = () => {
  return [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
};

export const notifyBody = () => {
  return [1, 2, 3, 4].reverse();
};

export const academyBody = () => {
  return [
    "academy_chat",
    "academy_first",
    "academy_chatbot",
    "academy_whatsapp",
    "academy_zendesk",
    "academy_facebook_messenger",
    "academy_telegram",
    "academy_artifacts",
    "academy_tracking",
    "academy_whatsapp_free",
    "academy_inactivity",
    "academy_sunco",
    "academy_videortc",
    "academy_instagram",
    "academy_campaigns",
  ];
};

export const academyPlayList = (name) => {
  switch (name) {
    case "academy_first":
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    case "academy_chat":
    case "academy_whatsapp":
      return [0, 1];
    case "academy_zendesk":
    case "academy_campaigns":
      return [0, 1, 2];
    default:
      return [];
  }
};

export const academyEmbedList = (name) => {
  switch (name) {
    case "academy_first":
      return {
        en: "PLoKF4Xhno0eWXwkhjTRxLGzq8Ri7UkGJt",
        pt: "PLoKF4Xhno0eVmRroi245mLsWTLSs23eHi",
        es: "PLoKF4Xhno0eVmRroi245mLsWTLSs23eHi",
      };
    case "academy_chat":
      return {
        en: "PLoKF4Xhno0eUTchi8rwd0KJ6LUJY1dUqf",
        pt: "PLoKF4Xhno0eXrxF-eZ5V_3GAzyIiSYdSV",
        es: "PLoKF4Xhno0eXrxF-eZ5V_3GAzyIiSYdSV",
      };
    case "academy_whatsapp":
      return {
        en: "PLoKF4Xhno0eV5FNdSf1dMYptUj081KGJB",
        pt: "PLoKF4Xhno0eXzgvlfwWz-zZDzobUUd3x9",
        es: "PLoKF4Xhno0eXzgvlfwWz-zZDzobUUd3x9",
      };
    case "academy_zendesk":
      return {
        en: "PLoKF4Xhno0eVMSiD0UKF85KM1EscR4K_m",
        pt: "PLoKF4Xhno0eWIc7ls9Y9Fsr0KzKuq9s6c",
        es: "PLoKF4Xhno0eWIc7ls9Y9Fsr0KzKuq9s6c",
      };
    case "academy_artifacts":
      return { en: "gwMm_xqMrRM", pt: "zKl_1VoRspI", es: "zKl_1VoRspI" };
    case "academy_tracking":
      return { en: "yV-JnI9YlAI", pt: "2Qlzpt-D4L4", es: "2Qlzpt-D4L4" };
    case "academy_inactivity":
      return { en: "yzLsMVXl8aI", pt: "ztVpFIlsSvI", es: "ztVpFIlsSvI" };
    case "academy_whatsapp_free":
      return { en: "dO6StmXJqYw", pt: "O0_afG0xswk", es: "O0_afG0xswk" };
    case "academy_facebook_messenger":
      return { en: "p6Izuhh_lf4", pt: "UykYLtNMrK4", es: "UykYLtNMrK4" };
    case "academy_telegram":
      return { en: "Ttk1lkb8qlM", pt: "0O9ATvdWLus", es: "0O9ATvdWLus" };
    case "academy_chatbot":
      return { en: "ehHfIvUZO4E", pt: "dVD5nDxHWi8", es: "dVD5nDxHWi8" };
    case "academy_videortc":
      return { en: "EnQ_zENuseg", pt: "EnQ_zENuseg", es: "EnQ_zENuseg" };
    case "academy_instagram":
      return { en: "cB7FxFBXfBM", pt: "cB7FxFBXfBM", es: "cB7FxFBXfBM" };
    case "academy_campaigns":
      return {
        en: "PLoKF4Xhno0eULq-PCTlInyx9j1AwhJSA4",
        pt: "PLoKF4Xhno0eULq-PCTlInyx9j1AwhJSA4",
        es: "PLoKF4Xhno0eULq-PCTlInyx9j1AwhJSA4",
      };
  }
};

export const paginationSelector = (layout) => {
  if (layout === "intents") {
    return [10, 25, 50];
  }
  return [10, 25, 50, "\u221e"];
};

export const multipleIntentSelector = () => {
  return [
    "all_intents",
    "desk",
    "parameters",
    "extensions",
    "conditional_response",
    "is_default",
  ];
};

export const getSignInBody = () => {
  return {
    hs_legal_basis: false,
    company: "",
    name: "",
    last_name: "-",
    country: "",
    phone: "666666666",
    plan: "prof_trial",
    dial_code: "-",
    web_page: "-",
    email: "",
    password: "",
    lang: "es",
    role: "",
    utm: {
      campaign: "",
      source: "",
      content: "",
      medium: "",
    },
  };
};

export const isSignInEmail = (email) => {
  var json = [
    "gmail.com",
    "hotmail.com",
    "outlook.com",
    "yahoo.com",
    "gmail.es",
    "hotmail.es",
    "outlook.es",
    "yahoo.es",
  ];

  var r = false;

  json.forEach((str) => {
    if (email.includes(str)) {
      r = true;
    }
  });
  return r;
};

export const isValidPassword = (password) => {
  const validity = [
    { text: "char", regEx: /(?=.{8,})/ },
    { text: "uppercase", regEx: /(?=.*[A-Z])/ },
    { text: "lowercase", regEx: /(?=.*[a-z])/ },
    { text: "number", regEx: /(?=.*[0-9])/ },
    { text: "sign", regEx: /(?=.*[.,!?+\-*])/ },
    { text: ";", regEx: /(?=;)/ },
  ];

  var res = true;

  validity.forEach((item) => {
    if (!password?.match(item.regEx) && item.text !== ";") {
      res = false;
    } else if (password?.match(item.regEx) && item.text === ";") {
      res = false;
    }
  });

  return res;
};

export const isConvertedJSON = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getIntentBody = () => {
  return {
    name: "",
    description: "",
    is_default: false,
    phrases: [],
    responses: [],
    contexts: {
      input: [],
      output: [],
    },
    trainable: false,
    desks: {
      name: false,
      info: {
        zendesk_ticket_status: "",
        centridesk_ticket_status: "",
        ticket_status: "",
        agent_id: null,
        mode: "desk",
        zendesk_mode: "desk",
        centridesk_mode: "desk",
        group_id: null,
        answerbot_send_hidden_message: false,
        max_tokens: 16,
        max_interactions: 1,
        desk_id: null,
      },
    },
    parameters: [],
    extensions: {},
    conditional_responses: [],
    events: [],
    active: true,
    tags: [],
  };
};

export const getTemplateBody = () => {
  return {
    category: "",
    name: "",
    description: "",
    header: null,
    language: "",
    body: "",
    footer: "",
    buttons: null,
  };
};

export const getGroupBody = () => {
  return {
    name: "",
    phone: "",
    template_id: "",
    content: [],
    send_date: new Date(),
  };
};

export const getEntityBody = () => {
  return {
    name: "",
    kind: "",
    entries: [],
  };
};

export const getEntityEntrieBody = (type) => {
  switch (type) {
    case "list":
      return {
        value: "",
      };
    case "regexp":
      return {
        value: "",
      };
    case "map":
      return {
        value: "",
        synonyms: [],
      };
    case "constant":
      return {
        value: "",
      };
  }
};

export const getEntityTypes = () => {
  return ["list", "map", "regexp", "constant"];
};

export const getProjectBody = () => {
  return {
    name: "",
    description: "",
    lang: "",
    template_id: "",
  };
};

export const getCustomizationBody = () => {
  return {
    chat_bg_color: "#FFFFFF",
    welcome_incoming: "",
    welcome_selector: "text",
    open_text_color: "#565671",
    open_bg_color: "#FFFFFF",
    open_shape: "50px 50px 50px 50px",
    open_on_load_timer: 0,
    chat_timer: 0,
    open_icon_show: true,
    header_text_color: "#565671",
    header_bg_color: "#FFFFFF",
    header_icon_show: true,
    header_icons_color: "#565671",
    messages_rubberband: true,
    messages_content_bg_color: "#F7F7F7",
    messages_user_text_color: "#565671",
    messages_user_bg_color: "#FFFFFF",
    messages_user_icon_show: false,
    messages_agent_text_color: "#565671",
    messages_agent_bg_color: "#D8D8DD",
    messages_agent_icon_show: false,
    artifacts_button_text_color: "#FFFFFF",
    artifacts_button_bg_color: "#565671",
    artifacts_button_hover_text_color: "#565671",
    artifacts_button_hover_bg_color: "#88FAB8",
    artifacts_button_disabled_text_color: "#FFFFFF",
    artifacts_button_disabled_bg_color: "#808080",
    artifacts_link_button_text_color: "#FFFFFF",
    artifacts_link_button_bg_color: "#565671",
    artifacts_link_button_hover_text_color: "#565671",
    artifacts_link_button_hover_bg_color: "#88FAB8",
    buttons_disable: false,
    google_analytics: "",
    input_text_color: "#565671",
    input_bg_color: "#FFFFFF",
    input_icon: 1,
    input_icon_color: "#FFFFFF",
    input_clip_color: "#FFFFFF",
    input_clip_bg_color: "#565671",
    input_icon_bg_color: "#565671",
    input_show: true,
    historic_messages: 8,
    attachment_clip: true,
  };
};

export const getUserBody = () => {
  return {
    password: "",
    email: "",
    first_name: "",
    last_name: "",
    lang: "",
    projects: [],
    roles: [],
  };
};

export const getWhatsappBody = () => {
  return {
    brand_name: "",
    brand_address: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    facebook_id: "",
    whatsapp_phone: "",
    whatsapp_name: "",
  };
};

export const getZendeskFullBody = () => {
  return {
    channel: "zendesk_full",
    info: {
      name: "",
      subdomain: "",
      email: "",
      token: "",
      subject: "",
      sort_messages: "desc",
      tags: [],
      use_custom_fields: false,
      hours_control: {
        subject: "",
        tags: [],
        status: "new",
        active: false,
      },
      centripush: {
        individual: {
          subject: "",
          tags: [],
          status: "new",
          active: false,
        },
        campaign: {
          subject: "",
          tags: [],
          status: "new",
          active: false,
        },
      },
    },
    active: true,
  };
};

export const getZendeskGuideBody = () => {
  return {
    channel: "zendesk_guide",
    info: {
      subdomain: "",
      title: "",
      input_text: "",
      show_in_chat: false,
      show_article_in_iframe: false,
    },
    active: true,
  };
};
export const getChatGPTFullBody = () => {
  return {
    channel: "gpt-3",
    info: {
      api_key: "",
      max_tokens: 100,
      back_to_bot: {
        max_interactions: 1,
        key_words: [],
        message: {
          type: "",
        },
      },
    },
  };
};

export const getStatusArray = () => {
  return ["new", "open", "pending", "solved"];
};

export const getZendeskChatBody = () => {
  return {
    channel: "zendesk_chat",
    info: {
      subdomain: "",
      account_key: "",
    },
  };
};

export const getCentrideskBody = () => {
  return {
    channel: "centridesk",
    info: {
      account_id: "",
      token: "",
      subject: "",
      tags: [],
      hours_control: null,
      centripush: {
        individual: {
          subject: "",
          tags: [],
          status: "new",
          active: false,
        },
        campaign: {
          subject: "",
          tags: [],
          status: "new",
          active: false,
        },
      },
    },
  };
};

export const getFacebookMessengerBody = () => {
  return {
    channel: "messenger",
    info: {
      user_name: "",
      user_access_token: "",
      page_name: "",
      page_access_token: "",
    },
  };
};

export const getInstagramBody = () => {
  return {
    channel: "instagram",
    info: {
      name: "Instagram Direct",
      user_name: "",
      page_name: "",
      page_access_token: "",
    },
  };
};

export const getTelegramBody = () => {
  return {
    channel: "telegram",
    info: {
      bot_name: "",
      bot_token: "",
    },
  };
};

export const getRolesTypes = () => {
  return [
    { id: "29827f3b28404490afb0c77766a3279a", name: "Trainer" },
    { id: "dfa856ce88104968a7010b9a5e31fb58", name: "Administrator" },
    { id: "c35851102cab49ff9d31e71c4a82b4e1", name: "SuperAdmin" },
  ];
};

export const langProject = () => {
  return [
    { name: "English", value: "es", translate: "english" },
    { name: "Spanish", value: "en", translate: "spanish" },
  ];
};

export const translate = (str, t) => {
  var s = t(str);
  if (s === str) {
    return t("backend_errors.internal_server");
  }
  return s;
};

export const userLangObject = () => {
  return [
    { name: "English", value: "en", translate: "english" },
    { name: "Spanish", value: "es", translate: "spanish" },
    { name: "Portuguese", value: "pt", translate: "portuguese" },
  ];
};

export const authTypes = () => {
  return [
    { value: "no_authentication", name: "No Authentication" },
    { value: "bearer", name: "Bearer" },
    { value: "basic", name: "Basic Auth" },
    { value: "jwt", name: "JWT Auth" },
  ];
};

export const formTypes = () => {
  return [
    { value: "application/json", name: "JSON" },
    { value: "application/x-www-form-urlencoded", name: "Form URL Encoded" },
  ];
};

export const inactivityBody = (default_lang) => {
  return {
    active: false,
    inactivity_time: "00:01:00",
    default_lang: default_lang,
    messages: [inactivityMessagesBody(default_lang)],
    intents: [],
  };
};

export const inactivityMessagesBody = (lang) => {
  return {
    lang: lang,
    response: "",
  };
};

export const hoursMessagesBody = (lang) => {
  return {
    lang: lang,
    message: "",
  };
};

export const calcDateDiff = (date1, date2) => {
  var diff = Math.floor(date1.getTime() - date2.getTime());
  var day = 1000 * 60 * 60 * 24;

  var days = Math.floor(diff / day);
  var months = Math.floor(days / 31);
  var years = Math.floor(months / 12);

  return {
    days: days,
    months: months,
    years: years,
  };
};

export const pollsBody = () => {
  return {
    messages: [pollsMessageBody("es")],
    default_lang: "es",
    detect_inactivity: false,
    send_close_desk: true,
    send_inactivity_bot: true,
    active: false,
  };
};

export const pollsMessageBody = (lang) => {
  return {
    lang: lang,
    question_one: "",
    question_two: "",
    goodbye: "",
    inactivity: "",
  };
};

export const getIntegrations = () => {
  return [
    {
      name: "Zendesk Full",
      channel: "zendesk_full",
      active: true,
    },
    {
      name: "Zendesk Chat",
      channel: "zendesk_chat",
      active: true,
    },
    {
      name: "Zendesk Guide",
      channel: "zendesk_guide",
      active: true,
    },
    {
      name: "Sunco",
      channel: "sunco",
      active: true,
    },
    {
      name: "Centridesk",
      channel: "centridesk",
      active: true,
    },
    {
      name: "ChatGPT",
      channel: "chatgpt",
      active: true,
    },
    {
      name: "WhatsApp",
      channel: "whatsapp",
      active: true,
    },
    {
      name: "Messenger",
      channel: "messenger",
      active: true,
    },
    {
      name: "Instagram",
      channel: "instagram",
      active: true,
    },
    {
      name: "Telegram",
      channel: "telegram",
      active: true,
    },
    {
      name: "Wordpress",
      channel: "wordpress",
      active: true,
    },
    {
      name: "SMS",
      channel: "sms",
      active: false,
    },
    {
      name: "Zoho",
      channel: "zoho",
      active: false,
    },
    {
      name: "Hubspot",
      channel: "hubspot",
      active: false,
    },
    {
      name: "Salesforce",
      channel: "salesforce",
      active: false,
    },
    {
      name: "VideoRTC",
      channel: "videortc",
      active: false,
    },
    {
      name: "Live Chat",
      channel: "live_chat",
      active: false,
    },
    {
      name: "Playvox",
      channel: "playvox",
      active: false,
    },
  ];
};

export const isSandbox = (data, channel) => {
  const is_sandbox = data.actualProject.sandbox;

  switch (channel) {
    case "telegram":
    case "whatsapp":
    case "sunco":
    case "messenger":
    case "instagram":
      if (is_sandbox) {
        return true;
      }
      break;
    default:
      return false;
  }
};

export const getIntegrationsData = (filter = "all", actualIntegrations) => {
  let integrations = [
    {
      channel: "Zendesk Support",
      category: "business",
      img: "zendesk_full.svg",
      img_big: "zendesk_full.png",
      img_info_left: "zendesk_full.png",
      img_info_right: "integration-info-zendesk_full-right.png",
      translate_text: "integrations.integration.zendesk_full_text",
      translate_text_full: "integrations.integration.zendesk_full_text_full",
      route: "zendesk_full",
    },
    {
      channel: "Zendesk Chat",
      category: "business",
      img: "integration-zendesk-chat.png",
      img_big: "zendesk_chat.png",
      img_info_left: "integration-info-zendesk_chat-left.png",
      img_info_right: "integration-info-zendesk_chat-right.png",
      translate_text: "integrations.integration.zendesk_chat_text",
      translate_text_full: "integrations.integration.zendesk_chat_text_full",
      route: "zendesk_chat",
    },
    {
      channel: "Zendesk Guide",
      category: "business",
      img: "zendesk_guide.svg",
      img_big: "zendesk_guide.png",
      img_info_left: "zendesk_guide.png",
      img_info_right: "integration-info-zendesk_guide-right.png",
      translate_text: "integrations.integration.zendesk_guide_text",
      translate_text_full: "integrations.integration.zendesk_guide_text_full",
      route: "zendesk_guide",
    },
    {
      channel: "Sunshine Conversations",
      category: "customer",
      img: "integration-sunco.png",
      img_big: "integration-sunco.png",
      img_info_left: "integration-info-sunco-left.png",
      img_info_right: "integration-info-sunco-right.png",
      translate_text: "integrations.integration.sunco_text",
      translate_text_full: "integrations.integration.sunco_text_full",
      route: "sunco",
    },
    {
      channel: "Centridesk",
      category: "business",
      img: "integration-centridesk.png",
      img_big: "integration-centridesk-big.png",
      img_info_left: "integration-centridesk-left.png",
      img_info_right: "integration-centridesk-right.png",
      translate_text: "integrations.integration.centridesk_text",
      translate_text_full: "integrations.integration.centridesk_text_full",
      route: "centridesk",
    },
    {
      channel: "Chat GPT",
      category: "business",
      img: "chatgpt.svg",
      img_big: "chatgpt.svg",
      translate_text: "integrations.integration.chatgpt_text",
      translate_text_full: "integrations.integration.chatgpt_text_full",
      route: "chatgpt",
    },
    {
      channel: "WhatsApp",
      category: "customer",
      img: "integration-whatsapp.png",
      img_big: "integration-whatsapp.png",
      img_info_left: "integration-info-whatsapp-left.png",
      img_info_right: "integration-info-whatsapp-right.png",
      translate_text: "integrations.integration.whatsapp_text",
      translate_text_full: "integrations.integration.whatsapp_text_full",
      route: "whatsapp",
    },
    {
      channel: "Facebook Messenger",
      category: "customer",
      img: "integration-facebook.png",
      img_big: "integration-facebook.png",
      img_info_left: "integration-info-messenger-left.png",
      img_info_right: "integration-info-messenger-right.png",
      translate_text: "integrations.integration.messenger_text",
      translate_text_full: "integrations.integration.messenger_text_full",
      route: "messenger",
    },
    {
      channel: "Instagram",
      category: "customer",
      img: "integration-instagram.png",
      img_big: "integration-instagram.png",
      img_info_left: "integration-info-instagram-left.png",
      img_info_right: "integration-info-instagram-right.png",
      translate_text: "integrations.integration.instagram_text",
      translate_text_full: "integrations.integration.instagram_text_full",
      route: "instagram",
    },
    {
      channel: "Telegram",
      category: "customer",
      img: "integration-telegram.png",
      img_big: "integration-telegram.png",
      translate_text: "integrations.integration.telegram_text",
      translate_text_full: "integrations.integration.telegram_text_full",
      route: "telegram",
    },
    {
      channel: "Wordpress",
      category: "customer",
      img: "integration-wordpress.png",
      img_big: "integration-wordpress.png",
      translate_text: "integrations.integration.wordpress_text",
      translate_text_full: "integrations.integration.wordpress_text_full",
      route: "wordpress",
    },
    {
      channel: "SMS",
      category: "customer",
      img: "integration-sms.png",
      img_big: "sms.png",
      translate_text: "integrations.integration.sms_text",
      translate_text_full: "integrations.integration.sms_text_full",
      route: "sms",
    },
    {
      channel: "Zoho",
      category: "business",
      img: "integration-zoho.png",
      img_big: "integration-zoho.png",
      translate_text: "integrations.integration.zoho_text",
      translate_text_full: "integrations.integration.zoho_text_full",
      route: "zoho",
    },
    {
      channel: "Hubspot",
      category: "business",
      img: "integration-hubspot.png",
      img_big: "integration-hubspot.png",
      translate_text: "integrations.integration.hubspot_text",
      translate_text_full: "integrations.integration.hubspot_text_full",
      route: "hubspot",
    },
    {
      channel: "Salesforce",
      category: "business",
      img: "integration-salesforce.png",
      img_big: "integration-salesforce.png",
      translate_text: "integrations.integration.salesforce_text",
      translate_text_full: "integrations.integration.salesforce_text_full",
      route: "salesforce",
    },
    {
      channel: "VideoRTC",
      category: "business",
      img: "ivrpowers.png",
      img_big: "ivrpowers.jpg",
      translate_text: "integrations.integration.videortc_text",
      translate_text_full: "integrations.integration.zvideortc_text_full",
      route: "videortc",
    },
    {
      channel: "Live Chat",
      category: "business",
      img: "livechat.png",
      img_big: "livechat.jpg",
      translate_text: "integrations.integration.live_chat_text",
      translate_text_full: "integrations.integration.live_chat_text_full",
      route: "live_chat",
    },
    {
      channel: "Playvox",
      category: "business",
      img: "playvox.png",
      img_big: "playvox.svg",
      translate_text: "integrations.integration.playvox_text",
      translate_text_full: "integrations.integration.playvox_text_full",
      route: "playvox",
    },
  ];

  if (filter !== "all") {
    switch (filter) {
      case "active":
        var res = [];
        actualIntegrations?.forEach((element) => {
          integrations.forEach((integration) => {
            if (element.channel === integration.route) {
              res.push(integration);
            }
          });
        });
        integrations = res;
        break;

      case "customer":
        integrations = integrations.filter((i) => i.category === "customer");
        break;
      case "business":
        integrations = integrations.filter((i) => i.category === "business");
        break;
    }
  }
  return integrations;
};

export const getIntegrationsCategories = () => {
  return [
    {
      id: 1,
      translate: "business_platforms",
      value: "business",
    },
    {
      id: 2,
      translate: "customer_channels",
      value: "customer",
    },
  ];
};

export const handleIntentsChannels = (channels) => {
  var res = false;
  Object.keys(channels).forEach((el) => {
    if (channels[el] && el !== "info") {
      res = el;
    }
  });

  return res;
};

export const handleFilterMethod = (
  e,
  type,
  arrayIncome,
  completeArray,
  filters,
  that,
  updateFilters,
  getIntents
) => {
  const { actualLayout } = that.state;

  var array = [],
    context_id,
    search_name,
    complete_array,
    new_params = filters.intents.params;

  complete_array = arrayIncome ? arrayIncome : completeArray;

  switch (type) {
    case "contexts":
      context_id = e;
      search_name = filters.intents.search;
      break;
    case "params":
      new_params = e.target.value;

      var old_params = filters.intents.params,
        old_all_param = old_params.filter((param) => param === "all_intents"),
        new_all_param = new_params.filter((param) => param === "all_intents");

      if (old_all_param.length > 0 && new_params.length !== 0) {
        new_params.splice(
          new_params.findIndex((param) => param === "all_intents"),
          1
        );
      } else if (new_all_param.length > 0 || new_params.length === 0) {
        new_params = ["all_intents"];
      }

      filters.intents.params = new_params;
      search_name = filters.intents.search;
      break;
    case "search":
      context_id = filters.intents.context_selector.id;
      search_name = e.target.value;
      break;
    default:
      search_name = filters[actualLayout].search;
      break;
  }

  /*Filter by contexts*/
  if (actualLayout === "intents_v2") {
    complete_array.forEach((el) => {
      var input = el.contexts.input,
        output = el.contexts.output,
        inside = false;

      if (input.length > 0) {
        input.forEach((elInput) => {
          if (elInput === context_id) {
            inside = true;
            array.push(el);
          }
        });
      }
      if (output.length > 0 && !inside) {
        output.forEach((elOutput) => {
          if (elOutput.id === context_id) {
            array.push(el);
          }
        });
      }
    });

    context_id && context_id !== "all_contexts"
      ? (array = array)
      : (array = complete_array);

    filters.intents.context_selector.id = context_id;
    filters.intents.context_selector.name = context_id
      ? that.getContextElement(context_id)
      : "";
  } else if (actualLayout === "entities" || actualLayout === "contexts") {
    array = complete_array;
  }

  if (actualLayout === "intents") {
    filters.intents.context_selector.id = context_id;
    filters.intents.context_selector.name = context_id
      ? that.getContextElement(context_id)
      : "";
    filters.intents.search = search_name;
    that.props.dispatch(updateFilters(filters));

    if (
      type !== "search" ||
      (type === "search" && filters.intents.search.length === 0) ||
      filters.intents.search.length >= 3
    ) {
      getIntents();
    }
  } else {
    filters[actualLayout].search = search_name;

    /*Filter by search*/
    var array_filtered = array.filter(({ name }) =>
      name.toLowerCase().includes(search_name.toLowerCase())
    );

    that.props.dispatch(updateFilters(filters));

    that.setState({ completeArray: array_filtered });

    if (filters[actualLayout].order_by) {
      handleFilterSort(
        filters[actualLayout].order_by.type,
        "update",
        array_filtered,
        completeArray,
        filters,
        that,
        updateFilters
      );
    } else {
      that.setState({ list: array_filtered });
    }
  }
};

export const handleFilterSort = (
  type,
  action,
  arrayIncome,
  completeArray,
  filters,
  that,
  updateFilters
) => {
  const { actualLayout } = that.state;

  filters[actualLayout].order_by.type = type;
  if (action === "click") {
    filters[actualLayout].order_by.value =
      !filters[actualLayout].order_by.value;
  }

  if (type) {
    var arr, complete_array;

    complete_array = arrayIncome ? arrayIncome : completeArray;

    if (type !== "date") {
      let sortAlphaNum = "";
      if (filters[actualLayout].order_by.value) {
        sortAlphaNum = (a, b) =>
          a[type].localeCompare(b[type], "en", { numeric: true });
      } else {
        sortAlphaNum = (a, b) =>
          b[type].localeCompare(a[type], "en", { numeric: true });
      }
      arr = complete_array.sort(sortAlphaNum);
    } else {
      if (filters[actualLayout].order_by.value) {
        arr = complete_array.sort(function (a, b) {
          return (
            new Date(b.updated_at ? b.updated_at : b.created_at) -
            new Date(a.updated_at ? a.updated_at : a.created_at)
          );
        });
      } else {
        arr = complete_array.sort(function (a, b) {
          return (
            new Date(a.updated_at ? a.updated_at : a.created_at) -
            new Date(b.updated_at ? b.updated_at : b.created_at)
          );
        });
      }
    }

    that.props.dispatch(updateFilters(filters));
  }

  that.handlePagination(false, false, arr ? arr : arrayIncome);
};

export const isSessionParam = (entity) => {
  return ["@sys.email", "@sys.person"].includes(entity);
};

export const handlePhrasesParams = (intent, dispatch) => {
  var phrases = intent.phrases,
    res = false,
    params = intent.parameters,
    paramsName = [],
    reg_ex_split =
      /(\$[a-zA-Z0-9_-]*\$[\w\W\s][^$/]*[^$/]+\$\/[a-zA-Z0-9_-]*\$*)/gm,
    reg_ex_exec =
      /((\$[a-zA-Z0-9_-]*\$)([\w\W\s\][^$/]*[^$/]+)(\$\/[a-zA-Z0-9_-]*\$)*)/gm;

  params.map((param) => {
    var name = param.name;
    paramsName.push(name);
  });

  var paramsExist = [],
    paramsNoExist = [];

  phrases.map((phrase) => {
    var parts = phrase.split(reg_ex_split);
    parts.map((part) => {
      var exec = reg_ex_exec.exec(part);
      if (exec?.length > 1) {
        var name = exec[2].replaceAll("$", "");
        if (!paramsExist.some((n) => name === n)) {
          if (
            paramsName.some((v) => name === v) &&
            !paramsExist.some((n) => name === n)
          ) {
            paramsExist.push(name);
          } else {
            paramsNoExist.push(name);
          }
        }
      }
    });
  });

  var noExist = paramsNoExist.length,
    exist = paramsExist.length,
    paramsLength = params.length;

  if (noExist === 0 && paramsLength >= exist) {
    res = true;
  }

  if (noExist === 0 && exist === 0) {
    res = true;
  }

  if (!res) {
    dispatch(
      showNotify({ message: "parameter_congruence", severity: "error" })
    );
  }

  return res;
};
