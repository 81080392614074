// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter } from "react-router-dom";
// Vendor
import { showNotify } from "vendor/application/disptach";
// Infrastructure
import { IntegrationsAPI } from "api/application/Integrations";
// Application
import { ElementsHandleAPI } from "vendor/application/handleMethods";
// Translations
import { withTranslation } from "react-i18next";
// Components
import { ElementsCustom } from "@components/Elements";
import { Divider } from "@material-ui/core";
import DialogAction from "components/Dialog/DialogAction";
// Assets
import { arrowRightSvg } from "@assets";
// Styles
import "views/Bots/Integrations/styles/index.scss";
// SVG
import { channelsSvg } from "assets";

class Redirect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      state: "",
      info: "",
      project_selected: "",
      open: false
    };
  }

  componentDidMount = () => {
    this.getAppInfo();
    this.getParamsURL();
  };

  getAppInfo = async () => {
    const { access, actualProject } = this.props.data;
    try {
      const obj = new IntegrationsAPI(actualProject.id, access, this.props);
      const info = await obj.get_sunco_info("sunco");

      this.setState({ info: info });
    } catch (err) {
      var data = { message: err, severity: "error" };
      this.props.dispatch(showNotify(data));
    }
  };

  getParamsURL = () => {
    const queryString = window.location.search,
      urlParams = new URLSearchParams(queryString),
      code = urlParams.get("code"),
      state = urlParams.get("state");

    if (state && code) {
      this.setState({ code: code, state: state });
    } else {
      this.return();
    }
  };

  // update element state
  updateElement = (e, type, i) => {
    const { body } = this.state;
    const obj = new ElementsHandleAPI(body.info, e.target.value, type, i);
    const res = obj.updateObj();

    var init = true;

    Object.keys(body.info).forEach((el) => {
      if (!body.info[el] && el !== "active") {
        init = false;
      }
    });
    body.info = res;
    this.setState({ body: body, isValid: init });
  };

  return = () => {
    const { history } = this.props;
    const { actualProject } = this.props.data;
    history.push(`/${actualProject.id}/integrations/sunco/create`);
  };

  postIntegration = async (e) => {
    e.preventDefault();
    const { access, actualProject, user } = this.props.data;
    const { history } = this.props;
    const { code, state, project_selected } = this.state;

    const body = { channel: "sunco", info: { code: code } };

    if (state === "admin") {
      body.info.default_project_id = project_selected;
      try {
        const obj = new IntegrationsAPI(
          user.account_id,
          access,
          this.props,
          body
        );
        const res = await obj.post_integration_account();

        window.location.href = `${process.env.REACT_APP_URL_CENTRIMANAGER}/integrations/sunco/edit/${res.id}`;
      } catch (err) {
        this.handleClickOpen();
      }
    } else {
      try {
        const obj = new IntegrationsAPI(
          actualProject.id,
          access,
          this.props,
          body
        );
        const res = await obj.post_integration();

        var url = `/${actualProject.id}/integrations/sunco/edit/${res.id}`;
        history.push(url);
      } catch (err) {
        this.handleClickOpen();
      }
    }
  };

  handleClickOpen = () => {
    this.setState({ open: !this.state.open });
  };

  getProjectName = (project_id) => {
    const { projects } = this.props.data;
    return projects.filter((project) => project.id === project_id)[0]?.name;
  };

  selectDefaultProject = (e) => {
    const { projects } = this.props.data,
      project_id = e?.id;
    var project = projects.filter((project) => project?.id === project_id)[0]
      ?.id;

    this.setState({ project_selected: project });
  };

  render() {
    const { info, open, project_selected, state } = this.state;
    const { t } = this.props;
    const { isSending, projects } = this.props.data;

    const projects_active = projects.filter(
      (project) => project.active && !project.sandbox
    );
    return (
      <React.Fragment>
        {info && (
          <React.Fragment>
            <div className={"cen_integrations"}>
              <div className={"header"}>
                <div className={"header_content"}>
                  <div
                    onClick={() => this.return()}
                    className={"header_action"}
                  >
                    {arrowRightSvg()}
                  </div>
                  <div className={"header_image"}>{channelsSvg("sunco")}</div>
                  <div className={"header_title"}>
                    <span className={"title"}>
                      {t("integrations:channels.sunco.title")}
                    </span>
                    <span className={"description"}>
                      {t("integrations:categories.customer")}
                    </span>
                  </div>
                </div>
              </div>
              <div className={"integration"}>
                {state === "admin" && (
                  <React.Fragment>
                    <ElementsCustom
                      t={t}
                      type={"sunco"}
                      input_type={"select"}
                      element={this.getProjectName(project_selected)}
                      element_type={"default_project"}
                      elements={projects_active}
                      select_value={"name"}
                      updateElement={(e) => this.selectDefaultProject(e)}
                    />
                    <Divider />
                  </React.Fragment>
                )}
                <ElementsCustom
                  t={t}
                  type={"sunco"}
                  input_type={"button"}
                  element_type={"exchange"}
                  loading={isSending}
                  disabled={state === "admin" && !project_selected}
                  updateElement={(e) => this.postIntegration(e)}
                />
              </div>
            </div>
          </React.Fragment>
        )}
        <DialogAction
          t={t}
          actualLayout={"sunco"}
          handleAction={this.return}
          handleClickOpen={this.handleClickOpen}
          common
          open={open}
          item={"sunco"}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const redirect = connect(mapStateToProps)(Redirect);

export default withTranslation(["commons", "common"])(withRouter(redirect));
