// React
import React, { Component } from "react";
// Styles
import "components/Accordion/styles/styles.scss";
// Components
import Contexts from "../contexts";
import Responses from "./responses";
import ConditionalResponses from "./conditional_responses";
import {Divider} from "@material-ui/core";

class Outputs extends Component {

    render() {
        return (
            <div className={"accordion__content"}>
                <Contexts key={this.props.type} context_type={"output"} {...this.props} />
                <Divider/>
                <Responses {...this.props} />
                <Divider/>
                <ConditionalResponses {...this.props} />
            </div>
        );
    }
}

export default Outputs;