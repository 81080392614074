// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Translations
import { withTranslation } from "react-i18next";
// Styles
import "components/Accordion/styles/styles.scss";
// Components
import { Divider } from "@material-ui/core";
import Tooltip from "@components/Tooltip";
import FloatButtons from "components/Input/float_buttons";
import { Button, Input, SelectGrouping, SelectSimple } from "@components/Input";
// Vendor
import { addArtifact } from "vendor/application";

class ConditionalResponses extends Component {
  updateConditionalResponse = (e, type, i, no_update) => {
    const {intent, updateIntent} = this.props;
    if (type === "param0" && !no_update) {
        if (["$.sys_is_in_business_hours"].includes(e)) {
            intent.conditional_responses[i].param1 = e;
            intent.conditional_responses[i].operator = "eq";
        } else {
            intent.conditional_responses[i].param1 = "";
            intent.conditional_responses[i].operator = "";
        }
        intent.conditional_responses[i].param2 = "";
    }
    if (type === "operator") {
      intent.conditional_responses[i].operator = e;
      
      if (e === "undefined") {
        intent.conditional_responses[i].param2 = 'undefined';
      } else if (intent.conditional_responses[i].param2 === 'undefined') {
        intent.conditional_responses[i].param2 = "";
      }
    }
    if (type === "param2" && intent.conditional_responses[i].operator !== "undefined") {
      intent.conditional_responses[i].param2 = e?.target?.value ?? e;
    }

    intent.conditional_responses[i][type] = e?.target?.value ?? e;

    updateIntent(intent);
}

  updateArtifacts = (e, i, artifact_type, type) => {
    const { intent, updateIntent } = this.props;

    var res = addArtifact(i, artifact_type, type, intent);

    updateIntent(res);
  };

  getEventName = (event) => {
    return event ? event : false;
  };

  render() {
    const { type, updateElement, intent, events, t } = this.props;
    return (
      <div className={"accordion__element"}>
        <div className={"accordion__element_header"}>
          <span>
            {t(`intents.config.accordion.${type}.conditional_responses.title`)}{" "}
            <Tooltip
              text={t(
                `intents.config.accordion.${type}.conditional_responses.description`
              )}
            />
          </span>
        </div>
        <div className={"accordion__element_content phrases"}>
          {intent.conditional_responses.length > 0 &&
            intent.conditional_responses.map((el, i) => {
              return (
                <React.Fragment key={i}>
                  <div className={"accordion__elements"}>
                    <div className={"accordion__elements_conditionals"}>
                      <SelectSimple
                        valueSelected={
                          el.param0
                            ? t(
                                `intents.config.accordion.responses.conditional_responses.param0.${el.param0}`
                              )
                            : false
                        }
                        items={["custom", "$.sys_is_in_business_hours"]}
                        onSelect={(e) =>
                          this.updateConditionalResponse(e, "param0", i)
                        }
                        placeholder={t(
                          "intents.config.accordion.responses.conditional_responses.param0.placeholder"
                        )}
                        iteration={i}
                        translate_pattern={
                          "intents.config.accordion.responses.conditional_responses.param0."
                        }
                        type={"conditional_responses"}
                        t={t}
                      />
                      {el.param0 === "custom" && (
                        <Input
                          type={"secondary"}
                          placeholder={t(
                            `intents.config.accordion.${type}.conditional_responses.param1_placeholder`
                          )}
                          inputProps={{ maxLength: 4096 }}
                          value={el.param1}
                          onChange={(e) =>
                            this.updateConditionalResponse(e, "param1", i)
                          }
                        />
                      )}
                      {el.param0 === "custom" && (
                        <SelectSimple
                          valueSelected={t(
                            `intents.config.accordion.${type}.conditional_responses.operators.${
                              el.operator ? el.operator : "any"
                            }`
                          )}
                          items={[
                            "undefined",
                            "eq",
                            "dif",
                            "lt",
                            "lte",
                            "gt",
                            "gte",
                            "in",
                            "notin",
                          ]}
                          onSelect={(e) =>
                            this.updateConditionalResponse(e, "operator", i)
                          }
                          placeholder={t(
                            `intents.config.accordion.${type}.conditional_responses.operator_placeholder`
                          )}
                          iteration={i}
                          type={"conditional_responses"}
                          disabled={["$.sys_is_in_business_hours"].includes(
                            el.param0
                          )}
                          translate_pattern={
                            "intents.config.accordion.responses.conditional_responses.operators."
                          }
                          t={t}
                        />
                      )}
                      {el.param0 === "custom" && (
                        <Input
                          disabled={el.operator === "undefined"}
                          type={"secondary"}
                          placeholder={t(
                            `intents.config.accordion.${type}.conditional_responses.param2_placeholder`
                          )}
                          inputProps={{ maxLength: 4096 }}
                          value={
                            el.operator === "undefined"
                              ? ""
                              : el.param2 === "undefined"
                              ? ""
                              : el.param2
                          }
                          onChange={(e) =>
                            this.updateConditionalResponse(e, "param2", i)
                          }
                        />
                      )}
                      {["$.sys_is_in_business_hours"].includes(el.param0) && (
                        <SelectSimple
                          valueSelected={
                            el.param2
                              ? t(
                                  `intents.config.accordion.responses.conditional_responses.param2.${el.param2}`
                                )
                              : false
                          }
                          items={["YES", "NO"]}
                          onSelect={(e) =>
                            this.updateConditionalResponse(e, "param2", i)
                          }
                          placeholder={"-"}
                          iteration={i}
                          type={"conditional_responses"}
                          translate_pattern={
                            "intents.config.accordion.responses.conditional_responses.param2."
                          }
                          t={t}
                        />
                      )}
                    </div>
                  </div>
                  <div className={"accordion__elements"}>
                    <div className={"accordion__elements_events"}>
                      <SelectSimple
                        valueSelected={t(
                          `intents.config.accordion.${type}.conditional_responses.type.${el.type}`
                        )}
                        items={["response", "event"]}
                        onSelect={(e) =>
                          this.updateConditionalResponse(e, "type", i)
                        }
                        placeholder={t(
                          `intents.config.accordion.${type}.conditional_responses.type.placeholder`
                        )}
                        iteration={i}
                        type={"conditional_responses"}
                        translate_pattern={
                          "intents.config.accordion.responses.conditional_responses.type."
                        }
                        t={t}
                      />
                      {el.type === "response" && (
                        <div className={"accordion__elements_events_response"}>
                          <Input
                            type={"secondary"}
                            placeholder={t(
                              `intents.config.accordion.${type}.conditional_responses.response_placeholder`
                            )}
                            inputProps={{ maxLength: 4096 }}
                            multiline
                            value={el.response}
                            onChange={(e) =>
                              this.updateConditionalResponse(e, "response", i)
                            }
                          />
                          <FloatButtons
                            type={"conditional_responses"}
                            element={"conditional_responses"}
                            addArtifact={this.updateArtifacts}
                            iteration={i}
                            buttons_toggle_artifacts={[
                              "file",
                              "img",
                              "link",
                              "button",
                              "carousel",
                              "bubble",
                              "for",
                            ]}
                          />
                        </div>
                      )}
                      {el.type === "event" && (
                        <SelectGrouping
                          valueSelected={this.getEventName(el.event)}
                          items={events}
                          onSelect={(e) =>
                            this.updateConditionalResponse(e, "event", i)
                          }
                          placeholder={t(
                            `intents.config.accordion.${type}.conditional_responses.type.event_placeholder`
                          )}
                          iteration={i}
                          value={"event"}
                          type={"conditional_responses_event"}
                          t={t}
                        />
                      )}
                    </div>
                  </div>
                  <div className={"accordion__elements align-right"}>
                    <Button
                      type={"delete"}
                      onClick={() => {
                        updateElement(
                          null,
                          "conditional_responses",
                          "delete",
                          i
                        );
                      }}
                      text={t(
                        `intents.config.accordion.${type}.conditional_responses.delete_button`
                      )}
                    />
                  </div>
                  {intent.conditional_responses.length !== i + 1 && <Divider />}
                </React.Fragment>
              );
            })}
          <Button
            type={"dashed"}
            onClick={(e) => {
              updateElement(e, "conditional_responses", "add");
            }}
            text={t(
              `intents.config.accordion.${type}.conditional_responses.add_button`
            )}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state,
  };
};

const conditional_responses = connect(mapStateToProps)(ConditionalResponses);

export default withTranslation("common")(conditional_responses);
