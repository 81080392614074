// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Translations
import {withTranslation} from "react-i18next";
// Styles
import "components/Accordion/styles/styles.scss";
// Components
import {Input, SelectSimple, Button} from "@components/Input";
import {AlertInfo} from "@components/Alert";
import {authTypes} from "vendor/application";
import {deleteSvg} from "../../../../assets";

class Auth extends Component {
    addHeader = () => {
        const {intent, updateIntent} = this.props;
        intent.extensions.auth.headers.push({
            "key": "",
            "value": ""
        });

        updateIntent(intent);
    };

    updateHeader = (e, type, i) => {
        const {intent, updateIntent} = this.props;
        intent.extensions.auth.headers[i][type] = e.target.value;

        updateIntent(intent);
    };

    deleteHeader = (i) => {
        const {intent, updateIntent} = this.props;
        intent.extensions.auth.headers.splice(i, 1);
        updateIntent(intent);
    };

    handleAuth = (auth_type) => {
        const {intent, updateAuthExtension, t} = this.props;
        switch (auth_type) {
            case "no_authentication":
                return (
                    <AlertInfo text={t("intents.config.accordion.extensions.content.auth.info")}/>
                );
            case "bearer":
                return (
                    <Input
                        placeholder={t("intents.config.accordion.extensions.content.auth.token")}
                        onChange={(e) => updateAuthExtension(e, "token")}
                        value={intent.extensions.auth.token ? intent.extensions.auth.token : ""}
                    />
                );
            case "basic":
                return (
                    <React.Fragment>
                        <Input
                            placeholder={t("intents.config.accordion.extensions.content.auth.user")}
                            onChange={(e) => updateAuthExtension(e, "username")}
                            value={intent.extensions.auth.username ? intent.extensions.auth.username : ""}
                        />
                        <Input
                            placeholder={t("intents.config.accordion.extensions.content.auth.password")}
                            onChange={(e) => updateAuthExtension(e, "password")}
                            value={intent.extensions.auth.password ? intent.extensions.auth.password : ""}
                            inputType={"password"}
                        />
                    </React.Fragment>
                );
            case "jwt":
                return (
                    <React.Fragment>
                        <div className={"jwt_header"}>
                            <SelectSimple
                                valueSelected={intent.extensions.auth.method ? intent.extensions.auth.method : false}
                                items={["GET", "PUT", "POST"]}
                                onSelect={(e) => updateAuthExtension(e, "method")}
                                placeholder={t("intents.config.accordion.extensions.content.auth.method")}
                                type={"method"}
                                className={"select_secondary"}
                                t={t}
                            />
                            <Input
                                placeholder={t("intents.config.accordion.extensions.content.auth.url")}
                                onChange={(e) => updateAuthExtension(e, "url")}
                                value={intent.extensions.auth.url ? intent.extensions.auth.url : ""}
                            />
                        </div>
                        <div className={"jwt_content"}>
                            <Input
                                placeholder={t("intents.config.accordion.extensions.content.auth.header_key")}
                                onChange={(e) => updateAuthExtension(e, "header_key")}
                                value={intent.extensions.auth.header_key ? intent.extensions.auth.header_key : ""}
                            />
                            <Input
                                placeholder={t("intents.config.accordion.extensions.content.auth.prefix")}
                                onChange={(e) => updateAuthExtension(e, "prefix")}
                                value={intent.extensions.auth.prefix ? intent.extensions.auth.prefix : ""}
                            />
                            <div className="jwt_access_key">
                                <Input
                                    placeholder={t("intents.config.accordion.extensions.content.auth.access_key")}
                                    onChange={(e) => updateAuthExtension(e, "access_key")}
                                    value={intent.extensions.auth.access_key_input ?? ""}
                                    id="jwt_access_key"
                                />
                            </div>
                        </div>
                        <div className={"jwt_headers"}>
                            {
                                intent.extensions.auth.headers.length > 0 &&
                                intent.extensions.auth.headers.map((header, i) => {
                                    return (
                                        <div key={i} className={"accordion__element_extension_headers_element"}>
                                            <Input
                                                placeholder={t("intents.config.accordion.extensions.content.headers.key")}
                                                onChange={(e) => this.updateHeader(e, "key", i)}
                                                value={header.key ? header.key : ""}
                                            />
                                            <Input
                                                placeholder={t("intents.config.accordion.extensions.content.headers.value")}
                                                onChange={(e) => this.updateHeader(e, "value", i)}
                                                value={header.value ? header.value : ""}
                                            />
                                            <div onClick={() => this.deleteHeader(i)} className={"accordion__element_extension_headers_element_delete"}>
                                                {deleteSvg()}
                                            </div>
                                        </div>
                                    );
                                })
                            }
                            <Button type={"dashed"} onClick={(e) => {
                                this.addHeader(e);
                            }}
                                    text={t("intents.config.accordion.extensions.content.headers.button")}/>
                        </div>
                        <div className={"jwt_body"}>
                            <Input
                                placeholder={t("intents.config.accordion.extensions.content.auth.payload")}
                                multiline
                                value={intent.extensions.auth.payload ? intent.extensions.auth.payload : "{}"}
                                onChange={(e) => updateAuthExtension(e, "payload")}
                            />
                        </div>
                    </React.Fragment>
                );
        }
    };

    render() {
        const {intent, updateAuthExtension, t} = this.props;

        const auth_type = intent.extensions.auth.type;
        return (
            <div className={`accordion__element_extension_auth ${auth_type}`}>
                <SelectSimple
                    valueSelected={auth_type ? t(`intents.config.accordion.extensions.content.auth.${auth_type}`) : false}
                    items={authTypes()}
                    onSelect={(e) => updateAuthExtension(e, "type")}
                    placeholder={t("intents.config.accordion.extensions.content.auth.title")}
                    type={"auth"}
                    className={"select_secondary"}
                    value={"value"}
                    t={t}
                />
                {this.handleAuth(auth_type)}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const auth = connect(
    mapStateToProps
)(Auth);

export default withTranslation("common")(auth);