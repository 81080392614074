import {CustomRequest} from "vendor/infrastructure/custom_requests";
import {updateSending} from "vendor/application/disptach";
import axios from "axios";
// Amplitude API
import {AmplitudeAPI} from "@api/Amplitude";

export class IntegrationsAPI {
    constructor(id, token, props, body) {
        this.id = id;
        this.token = token;
        this.props = props;
        this.body = body;

        this.props.dispatch(updateSending(true));
    }

    async get_integrations() {
        let request = new CustomRequest("GET", `projects/${this.id}/channels`, null, this.token, this.props);
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_integrations_by_account() {
        let request = new CustomRequest("GET", `accounts/${this.id}/integrations`, null, this.token, this.props, true);
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_integration_switchboard_by_id(channel_id) {
        let request = new CustomRequest("GET", `projects/${this.id}/channels/${channel_id}/switchboard/integrations`, null, this.token, this.props);
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_integration_by_id() {
        let request = new CustomRequest("GET", `projects/${this.id}/channels/${this.props.match.params.id}`, null, this.token, this.props);
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async post_integration() {
        const request = new CustomRequest("POST", `projects/${this.id}/channels`, JSON.stringify(this.body), this.token, this.props);
        const req = await request.action();

        const {user, device_id} = this.props.data;

        new AmplitudeAPI({
            event_type: `Click on create ${this.body.channel}`,
            device_id: device_id,
            user_id: user.email
        }).eventLog();

        this.props.dispatch(updateSending(false));
        return req;
    }

    async post_integration_account() {
        const request = new CustomRequest("POST", `accounts/${this.id}/integrations`, JSON.stringify(this.body), this.token, this.props, true);
        const req = await request.action();

        const {user, device_id} = this.props.data;

        new AmplitudeAPI({
            event_type: `Click on create ${this.body.channel}`,
            device_id: device_id,
            user_id: user.email
        }).eventLog();

        this.props.dispatch(updateSending(false));
        return req;
    }

    async put_integration() {
        const request = new CustomRequest("PUT", `projects/${this.id}/channels/${this.props.match.params.id}`, JSON.stringify(this.body), this.token, this.props);
        const req = await request.action();

        const {user, device_id} = this.props.data;

        new AmplitudeAPI({
            event_type: `Click on save ${this.body.channel}`,
            device_id: device_id,
            user_id: user.email
        }).eventLog();

        this.props.dispatch(updateSending(false));
        return req;
    }

    async put_switchboard() {
        const request = new CustomRequest("PUT", `projects/${this.id}/channels/${this.props.match.params.id}/switchboard`, null, this.token, this.props);
        const req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async put_campaigns() {
        const request = new CustomRequest("PUT", `projects/${this.id}/channels/${this.props.match.params.id}/campaigns`, JSON.stringify(this.body), this.token, this.props);
        const req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async createTicket() {
        const request = new CustomRequest("POST", `projects/${this.id}/channels/zendesk/ticket`, JSON.stringify(this.body), this.token, this.props);
        const req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async interest_integrations(id) {
        let request = new CustomRequest("POST", `accounts/${id}/channels/interest`, JSON.stringify(this.body), this.token, this.props);
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_app_info(type) {
        let request = new CustomRequest("GET", `${type}/app`, null, this.token, this.props);
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_sunco_info() {
        let request = new CustomRequest("GET", "sunco", null, this.token, this.props);
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_zendesk_groups(channel_id) {
        let request = new CustomRequest("GET", `projects/${this.id}/channels/${channel_id}/groups`, null, this.token, this.props);
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        req.unshift({name: "-", id: ""});
        return req;
    }

    async custom_fields(channel_id, all) {
        var param = "";
        if (all) {
            param = "?fields=all";
        }
        let request = new CustomRequest("GET", `projects/${this.id}/channels/${channel_id}/custom_fields${param}`, null, this.token, this.props);
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async delete_integration(id) {
        let request = new CustomRequest("DELETE", `projects/${this.id}/channels/${id}`, null, this.token, this.props);
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async getNonExpiringToken(userAccessToken, FACEBOOK_APP_ID, FACEBOOK_APP_SECRET) {
        const url = `https://graph.facebook.com/oauth/access_token?client_id=${FACEBOOK_APP_ID}&client_secret=${FACEBOOK_APP_SECRET}&grant_type=fb_exchange_token&fb_exchange_token=${userAccessToken}`;
        const response = await axios.get(url);
        this.props.dispatch(updateSending(false));
        return response.data.access_token;
    }

    async getPagesWithTokens(userAccessToken, FACEBOOK_APP_ID, FACEBOOK_APP_SECRET) {
        const url = `https://graph.facebook.com/me/accounts?client_id=${FACEBOOK_APP_ID}&client_secret=${FACEBOOK_APP_SECRET}&access_token=${userAccessToken}&limit=500`;
        const response = await axios.get(url);
        this.props.dispatch(updateSending(false));
        return response.data;
    }

}