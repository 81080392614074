// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Routing
import {withRouter} from "react-router-dom";
// Translations
import {withTranslation} from "react-i18next";
// Components
import {Input} from "@components/Input";
import {Divider, Switch} from "@material-ui/core";
import ElementColor from "../element_color";
import ElementIcon from "../element_icon";
import ElementSwitch from "../element_switch";
// Svg
import {directionBorderRadius, proportionsLink} from "assets";
// Vendor
import {ElementsHandleAPI} from "vendor/application/handleMethods";
import {addArtifact} from "vendor/application";

class OpenChat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            proportions: JSON.parse(JSON.stringify(this.handleProportions(props)))
        };
    }

    handleProportions = (props) => {
        const {config} = props;
        var spl = config.customization.open_shape.split(" ");

        const allEqual = arr => arr.every(val => val === arr[0]);
        return allEqual(spl);
    };

    updateElementConfig = (e, type) => {
        e.preventDefault();
        const {config, updateConfig} = this.props;
        const obj = new ElementsHandleAPI(config, e.target.value, type);
        const res = obj.updateObj("edit");
        updateConfig(res);
    };

    updateRadius = (e, arr, i) => {
        const {config, updateConfig} = this.props;
        if (this.isInDesiredForm(e.target.value) || !e.target.value) {
            if (i === "all") {
                arr.forEach(val => {
                    val.value = e?.target.value ? e?.target.value : 0;
                });
            } else {
                arr[i].value = e?.target.value ? e?.target.value : 0;
            }
            config.customization.open_shape = `${arr[0].value}px ${arr[1].value}px ${arr[2].value}px ${arr[3].value}px`;
            updateConfig(config);
        }
    };

    isInDesiredForm = (str) => {
        str = str.trim();
        if (!str) {
            return false;
        }
        str = str.replace(/^0+/, "") || "0";
        var n = Math.floor(Number(str));
        return n !== Infinity && String(n) === str && n >= 0;
    };

    handleArtifacts = (e, i, artifact_type, type) => {
        const {config, updateConfig} = this.props;
        var res = addArtifact(i, artifact_type, type, config);
        updateConfig(res);
    };

    handleRadius = (border_radius) => {
        const {proportions} = this.state;
        var spl = border_radius.split(" "),
            radius = {
                str: border_radius,
                array: []
            };

        spl.forEach((sp => {
            radius.array.push({str: sp, value: sp.replace("px", "")});
        }));

        if (proportions) {
            return <div>
                <Input value={radius.array[0].value} type={"primary"}
                       onChange={(e) => this.updateRadius(e, radius.array, "all")}/>
            </div>;
        }

        return radius.array.map((r, i) => {
            return (
                <div key={i}>
                    <Input value={r.value} type={"primary"}
                           onChange={(e) => this.updateRadius(e, radius.array, i)}/>
                </div>
            );
        });
    };

    render() {
        const {t, config, updateCustomization, updateConfig, updateIcon} = this.props;
        const {proportions} = this.state;
        return (
            <div className={"cen_card"}>
                {/* TEXT */}
                <div className={"content"}>
                    <div className="elements_custom">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t("card.content.project.web_interface.customization.open_chat.text.title")}</span>
                            <span
                                className="elements_custom__description">{t("card.content.project.web_interface.customization.open_chat.text.description")}</span>
                        </div>
                        <div className={"elements__info"}>
                            <Input value={config.text_expanded} type={"primary"}
                                   placeholder={t("card.content.project.web_interface.customization.open_chat.placeholder")}
                                   onChange={(e) => this.updateElementConfig(e, "text_expanded")}/>
                        </div>
                    </div>
                </div>
                <Divider/>
                {/* EXPAND */}
                <div className={"content"}>
                    <div className="elements_custom">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t("card.content.project.web_interface.customization.open_chat.expand.title")}</span>
                            <span
                                className="elements_custom__description">{t("card.content.project.web_interface.customization.open_chat.expand.description")}</span>
                        </div>
                        <Switch checked={!!config.expanded}
                                onClick={(e) => {
                                    this.updateElementConfig(e, "expanded");
                                }}/>
                    </div>
                </div>
                <Divider/>
                {/* OPEN SHAPE */}
                <div className={"content"}>
                    <div className="elements_custom">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t("card.content.project.web_interface.customization.open_chat.border_radius.title")}</span>
                            <span
                                className="elements_custom__description">{t("card.content.project.web_interface.customization.open_chat.border_radius.description")}</span>
                        </div>
                        <div style={{display: "grid"}}>
                            <div className={"elements__radius"}>
                                {
                                    !proportions &&
                                        <div className={"elements__radius_svg direction"}>
                                            {directionBorderRadius()}
                                        </div>
                                }
                                {this.handleRadius(config.customization.open_shape)}
                                <div onClick={() => this.setState({proportions: !proportions})} className={"elements__radius_svg proportion"}>
                                    {proportionsLink(proportions)}
                                </div>
                            </div>
                            {
                                proportions && !this.handleProportions(this.props) &&
                                <span style={{fontSize: "11px"}}>{t("card.content.project.web_interface.customization.open_chat.border_radius.info")}</span>
                            }
                        </div>
                    </div>
                </div>
                <Divider/>
                {/* COLORS */}
                <div className={"content"}>
                    <div className="elements_custom grid">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t("card.content.project.web_interface.customization.open_chat.colors.title")}</span>
                            <span
                                className="elements_custom__description">{t("card.content.project.web_interface.customization.open_chat.colors.description")}</span>
                        </div>
                        <div>
                            <ElementColor
                                updateCustomization={updateCustomization}
                                element={"bg"}
                                value={config.customization.open_bg_color}
                                value_updated={"open_bg_color"}
                            />
                            <ElementSwitch
                                color_1={"open_bg_color"}
                                color_2={"open_text_color"}
                                updateConfig={updateConfig}
                                config={config}
                            />
                            <ElementColor
                                updateCustomization={updateCustomization}
                                element={"text"}
                                value={config.customization.open_text_color}
                                value_updated={"open_text_color"}
                            />
                        </div>
                    </div>
                </div>
                <Divider/>
                {/* ICON */}
                <div className={"content"}>
                    <div className="elements_custom grid">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t("card.content.project.web_interface.customization.open_chat.icon.title")}</span>
                            <span
                                className="elements_custom__description">{t("card.content.project.web_interface.customization.open_chat.icon.description")}</span>
                        </div>
                        <ElementIcon
                            updateIcon={updateIcon}
                            updateCustomization={updateCustomization}
                            element={"open_chat"}
                            img={config.chat_icon}
                            icon_type={"chat"}
                        />
                        <div className={"element__icon_switch"}>
                            <Switch
                                checked={config.customization.open_icon_show}
                                onClick={(e) => {
                                    updateCustomization(e, "bool", "open_icon_show");
                                }}
                            />
                            <span>{t("card.content.project.web_interface.customization.show_icon")}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const a = connect(
    mapStateToProps
)(OpenChat);

export default withTranslation("common")(withRouter(a));